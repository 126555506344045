// third party
@import "~react-datepicker/dist/react-datepicker.css";
@import "~grapesjs/dist/css/grapes.min.css";
@import "~grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css";
@import "~react-tagsinput/react-tagsinput.css";

// misc
@import "./misc/normalize.css";
@import "./misc/vars";
@import "./misc/atomic.css";
@import "./misc/react-select.min.css";
@import "./misc/mixin";
@import "./misc/common";
@import "./misc/balloon.css";
@import "./misc/baseStyle";

// components
@import "./components/buttons";
@import "./components/checkbox";
@import "./components/radiobutton";
@import "./components/switch";
@import "./components/inputfield";
@import "./components/commonTable";
@import "./components/customTable";
@import "./components/dropdown";
@import "./components/downArrow";
@import "./components/lowCreditsAlert";
@import "./components//buyCreditsFlow";
@import "./components/loginForm";
@import "./components/analytics";
@import "./components/layoutcomp";
@import "./components/error";
@import "./components/topPerforming";
@import "./components/campaignList";
@import "./components/suggestedTargetCampaign";
@import "./components/createCampaign";
@import "./components/campaignSMS";
@import "./components/campaignEmail";
@import "./components/overlaySidebar";
@import "./components/inlineSuggestions";
@import "./components/createCampaignWho";
@import "./components/templateEditor";
@import "./components/dropdownCustom";
@import "./components/paginator";
@import "./components/filterSidebar";
@import "./components/multiSelectFilter";
@import "./components/selectFilter";
@import "./components/modal";
@import "./components/sectionHeader";
@import "./components/dropdownButtons";
@import "./components/dateCompareFilter";
@import "./components/newDateCompareFilter";
@import "./components/operationalEfficiency";
@import "./components/tagsInput";
@import "./components/copyToClipboard";
@import "./components/formSidebar";
@import "./components/sidebarContainer";
@import "./components/sideNavContainer";
@import "./components/textarea";
@import "./components/uploader";
@import "./components/locationEntityAssociation";
@import "./components/itemGroupItemAssociation";
@import "./components/cascadePriceEntity.scss";
@import "./components/ilgpsEntityAssociation";
@import "./components/bulkOperation";
@import "./components/formFilters";
@import "./components/searchFilter.scss";
@import "./components/archiveRestore";
@import "./components/modifierGroupEntityAssociation";
@import "./components/itemLocationCustomization";
@import "./components/captureLocation";
@import "./components/selectFilterCustom";
@import "./components/selectFilterCustomGroup";
@import "./components/dateFilterDropdown";
@import "./components/accordion";
@import "./components/colorPicker";
@import "./components/panels";
@import "./components/selectAllFields.scss";
@import "./components/closeDrawerMessage.scss";
@import "./components/jsonInput";
@import "./components/arrayOfObject";
@import "./components/rightClickContext";
@import "./components/timelineTrail.scss";
@import "./components/syncStatus";
@import "./components/globalNotifications.scss";
@import "./components/monogramGenerator.scss";
@import "./components/formTable.scss";
@import "./components/popover";
@import "./components/numericalStepDisplay";
@import "./components/progressBar";
@import "./components/carousel";
@import "./components/nivoCharts";
@import "./components/archiveRestoreModal.scss";
@import "./components/imageSizeWarningModal.scss";
@import "./components/tableColumnSelector.scss";
@import "./components/contextMenu.scss";
@import "./components/placeholder.scss";
@import "./components/breadcrumbs.scss";
@import "./components/entityAccordion.scss";
@import "./components/foodTypeIndicator.scss";
@import "./components/listShimmer.scss";
@import "./components/rangeSlider.scss";
@import "./components/multiActionsButton.scss";
@import "./components/textHighlightChip.scss";
@import "./components/entityRulesAssociationList.scss";
@import "./components/inifiniteSpinner.scss";
@import "./components/statusAlertBox.scss";
@import "./components/statusInfo.scss";
@import "./components/ongoingTasks.scss";
@import "./components/draggableModifierAccordion.scss";
@import "./components/dragDropWrapper.scss";
@import "./components/periscope.scss";
@import "./components/notImportant.scss";
@import "./components/menuStatusBox.scss";
@import "./components/onboardingSetupHeader.scss";
@import "./components/FreeTrial.scss";

// containers
@import "./containers/loginPage";
@import "./containers/login";
@import "./containers/dashboard";
@import "./containers/layout";
@import "./containers/campaigns";
@import "./containers/campaignStatus";
@import "./containers/settings";
@import "./containers/creditSettings";
@import "./containers/feedback";
@import "./containers/transactionsList";
@import "./containers/transactionDetails";
@import "./containers/customerDetails";
@import "./containers/analytics";
@import "./containers/salesAnalytics";
@import "./containers/InvoiceList";
@import "./containers/InvoiceDetail";
@import "./containers/businessProfile";
@import "./containers/locations";
@import "./containers/errorPage";
@import "./containers/help";
@import "./containers/categories";
@import "./containers/items";
@import "./containers/modifierGroups";
@import "./containers/modifiers";
@import "./containers/taxes";
@import "./containers/entityGroups";
@import "./containers/charges";
@import "./containers/userManagement";
@import "./containers/apiAccessManagement";
@import "./containers/meraki";
@import "./containers/hub";
@import "./containers/dspIntegration";
@import "./containers/discounts";
@import "./containers/coupons";
@import "./containers/itemTags";
@import "./containers/catalogueVerification";
@import "./containers/merakiWeb";
@import "./containers/merakiPlansBilling";
@import "./containers/webhooks";
@import "./containers/gallery";
@import "./containers/reports";
@import "./containers/customerFeedback";
@import "./containers/customIntegrations";
@import "./containers/developerTools.scss";
@import "./containers/acceptInvite";
@import "./containers/pendingInvites";
@import "./containers/merakiWebEditor";
@import "./containers/auditEvents";
@import "./containers/piperAcademy";
@import "./containers/billingRecovery.scss";
@import "./containers/referrals.scss";
@import "./containers/reconciliation.scss";
@import "./containers/catalogueBackups.scss";
@import "./containers/payoutUploadHelp.scss";
@import "./containers/autoLogin";
@import "./containers/authServiceLogin";
@import "./containers/brands.scss";
@import "./containers/menusList.scss";
@import "./containers/menuCreate.scss";
@import "./containers/menuEdit.scss";
@import "./containers/sectionsList.scss";
@import "./containers/timingGroup.scss";
@import "./containers/onboarding.scss";
@import "./containers/onboarding-brands.scss";
@import "./containers/onboarding-locations.scss";
@import "./containers/onboarding-menus.scss";
@import "./containers/onboarding-common.scss";
@import "./containers/periscopeDashboard.scss";
@import "./containers/periscopeItemAvailability.scss";
@import "./containers/periscopeIocationAvailability.scss";
@import "./containers/periscopeItemSpecificItemTable.scss";
@import "./containers/periscopeItemSpecificLocationTable.scss";
@import "./containers/periscopeItemIssues.scss";
@import "./containers/periscopeSpecificItemIssue.scss";
@import "./containers/periscopeActivityLog.scss";
@import "./containers/periscopeEarlyAccess.scss";
@import "./containers/periscopeShowLocations.scss";

//values as per styleguide
body {
	// background-color: #f8f8f8;
	width: 100%;
}

// remove tap highlight color for touchscreen devices
* {
	-webkit-tap-highlight-color: transparent;
}

// fixing box-sizing for a few components that are being overridden by horizon css
// this issue is happening because the css in Atlas was never normalized or reset
.search-input,
.select-filter-custom,
.at-checkbox,
.at-foodtype-indicator,
.at-btn--filter,
.delete-icon,
.loading-container {
	box-sizing: content-box;
}

// why is this here?
// this is generic fix for svg alignment issues with text as text's have line height and svg's don't, this can be easily overridden if
// we add top property to the svg in a particular component due to specificity
// initially we used to add something like top: 2px or 3px for alignment overall but now we are using this approach as we now have box-sizing: border-box
svg {
	top: -1px;
}
