.menus-list-container {
	padding: 24px;
	.at-breadcrumbs-container {
		padding-bottom: 15px;
	}
	.location-platform-association-drawer {
		.filters-wrapper {
			padding: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.dropdown-custom {
				width: 220px;
				.container {
					height: 32px;
					padding: 1px 10px;
				}
				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
		}
		.location-platform-association-table-wrapper {
			padding: 24px;
			color: $color-light-black;
			.at-paginator {
				padding: 0;
			}

			.lpa-table {
				padding: 0px;
				border: 1px solid $divider-color;
				margin-bottom: 24px;
			}
			.locationName {
				width: 25%;
			}
			.assocPlatforms {
				width: 40%;
			}
			.versions {
				width: 15%;
			}
			.publishedOn {
				width: 20%;
			}
			.locationName--container {
				p {
					white-space: initial;
				}
			}
			.versions--container {
				.version-wrapper {
					height: 64px;
				}
			}
			.publishedOn--container {
				.publish-details {
					.publish-details-wrapper {
						height: 64px;
						.time {
							font-size: 12px;
							color: $mc-6;
							margin-top: 4px;
						}
					}
				}
			}
			.assocPlatforms {
				display: flex;
				justify-content: space-between;
				padding-right: 25px;
				.popover-wrapper-container {
					.popover-wrapper--content {
						[aria-label][data-balloon-pos]:after {
							right: 15px;
							top: 65%;
						}
						[aria-label][data-balloon-pos]:before {
							right: 23px;
							top: 65%;
						}
					}
					.popover-container {
						top: 35px;
						right: 0;
						min-width: unset;
						.data {
							padding: 4px;
						}
						width: fit-content;
						&::before {
							right: 17px;
							top: -13px;
						}
						&::after {
							right: 13px;
							top: -15px;
						}
					}
				}
				.platforms-list {
					display: flex;
					flex-wrap: wrap;
					min-height: 64px;
					.platform {
						position: relative;
						img {
							width: 24px;
							height: 24px;
							object-fit: cover;
							margin-right: 15px;
							margin-bottom: 15px;
							border-radius: $default-radius;
							cursor: pointer;
							transition: 180ms;
						}
					}
				}
			}
		}
	}
	.header-container {
		display: flex;
		margin-bottom: 24px;
		justify-content: space-between;
		align-items: flex-start;
		.btn-wrapper {
			display: flex;
			align-items: center;
			gap: 12px;
		}
		.header-text {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 8px;
			.title {
				font-size: $large-font-size;
				@extend .font--semibold;
				line-height: 0.7;
			}

			.subtitle {
				color: $mc-5;
				padding-top: 5px;
			}
		}
	}
	.placeholder-main {
		padding: 24px 0;
	}

	.menu-filters {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
		.search-filter {
			margin-right: 0;
		}
		.react-select__control {
			min-width: 200px;
		}
		.brand-state-filters {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			.dropdown-custom {
				width: 220px;
				.container {
					height: 32px;
					padding: 1px 10px;
				}
				.dropdown-options {
					.option {
						height: 37px;
						padding: 1px 10px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
		}
	}
	.menu-info {
		padding: 12px;
		min-height: 80px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		border: 1px solid $border-color;
		justify-content: space-between;
		position: relative;
		&--content {
			display: flex;
			gap: 12px;
			// flex-wrap: wrap;
			width: 60%;
			@include bp(768px) {
				width: 100%;
			}
			.lock-status-indicator {
				position: absolute;
				border-radius: 2px;
				width: 100px;
				background-color: rgba(#000, 0.4);
				min-height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.img-container {
				height: 80px;
				width: 100px;
				background-color: $color-lightest-grey;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: $border-radius-default;
				flex-shrink: 0;
				.menu-cover-img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: $border-radius-default;
				}
			}
			.info {
				padding: 4px 0;
				display: flex;
				flex-direction: column;
				.brand-name {
					font-weight: 600;
					text-transform: uppercase;
					font-size: $exta-small-font-size;
					color: $mc-6;
					margin-bottom: 4px;
				}
				.menu-title {
					font-weight: 600;
					font-size: $small-font-size;
					margin-bottom: 12px;
					display: flex;
					gap: 8px;
					.created-by {
						color: #aaaaaa;
					}
				}
				.basic-overview {
					display: flex;
					&--entity {
						font-size: $very-small-font-size;
						display: flex;
						gap: 5px;
						margin-right: 12px;
						color: $color-dark-grey;
						.icon {
							height: 15px;
							width: 15px;
						}
					}
					&--onclick {
						cursor: pointer;
						font-weight: 600;
					}
					&--onclick:hover {
						text-decoration: underline;
					}
					.popover-wrapper-container {
						.popover-wrapper--content {
							[aria-label][data-balloon-pos]:after {
								right: 15px;
								top: 65%;
							}
							[aria-label][data-balloon-pos]:before {
								right: 23px;
								top: 65%;
							}
						}
						.popover-container {
							top: 35px;
							right: 0;
							min-width: unset;
							padding: 12px;
							.close-popover {
								top: unset;
							}
							.data {
								white-space: nowrap;
								padding: 4px;
								.title {
									font-weight: 600;
									margin-bottom: 8px;
								}
							}
							width: fit-content;
							&::before {
								right: 17px;
								top: -13px;
							}
							&::after {
								right: 13px;
								top: -15px;
							}
						}
					}
				}
			}
		}

		&--activities {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			height: 25px;
			justify-content: space-between;
			align-items: center;
			.context-menu {
				position: static;
				.menu {
					top: 40px;
					.action-item {
						&--archive {
							color: $color-red;
						}
						&--restore {
							color: $color-green;
						}
					}
				}
				@include bp(768px) {
					position: absolute;
					top: 15px;
					right: 10px;
				}
			}
			.publish-state {
				height: 20px;
				color: $color-green;
				background-color: $color-green-10;
				padding: 2px 8px;
				text-transform: capitalize;
				border-radius: $border-radius-default;
				@include bp(768px) {
					display: none;
				}
				&.failed {
					color: $color-red-7;
					background-color: $color-red-1;
				}
				&.draft {
					color: $color-neutral-black-50;
					background-color: $color-neutral-black-05;
				}
				&.intermediate-state {
					background-color: $color-yellow-10;
					color: $color-yellow-70;
				}
				&.success {
					background-color: $color-green-10;
					color: $color-green-70;
				}
			}
			.last-update {
				color: $mc-6;
				font-size: $very-small-font-size;
				@include bp(768px) {
					display: none;
				}
			}
		}
	}
	.published-version-restore--modal {
		.modal-header {
			text-align: left;
			padding: 16px 24px;
			.header-left {
				.title {
					font-size: $medium-font-size;
					color: $color-neutral-black-80;
				}
			}
		}
		&--failure {
			border: 1px solid $color-red-7;
			padding: 24px;
			color: $color-red-7;
			background-color: $color-red-1;
			border-radius: $border-radius-default;
		}
		&--success {
			border: 1px solid $color-green-70;
			padding: 24px;
			color: $color-green-70;
			background-color: $color-green-10;
			border-radius: $border-radius-default;
		}
		.modal-content {
			padding: 0 24px;
			color: $color-neutral-black-80;
			line-height: 1.5;
			.body-main-text {
				font-weight: $font--semibold;
				font-size: $medium-font-size;
			}
			.body-subtext {
				font-size: $small-font-size;
			}
		}
		.modal-footer {
			padding: 16px 12px;
			text-align: right;
		}
	}
	.form-sidebar-container {
		.form-table {
			padding: 24px;

			.name {
				width: 30%;
				font-size: 14px;
				color: #363636;
				font-weight: 600;
			}
			.issue {
				width: 70%;
				.fix-error-issue-list-data {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 16px;
				}
			}
		}
		.fix-error-msg-wrapper {
			margin: 0px 24px;
			display: flex;
			align-items: center;
			gap: 8px;
			img {
				margin-left: 8px;
			}
		}
	}
	.form-sidebar-container {
		.form-container {
			.fix-error-drawer-table-wrapper {
				.fix-error-msg-wrapper {
					border-radius: 2px;
					padding: 0;
					margin-bottom: 12px;
					display: flex;
					gap: 12px;
					align-items: center;
					p {
						color: $color-neutral-black-80;
						font-size: 14px;
					}
				}
				.form-table {
					border: 1px solid $border-color;
					.form-table--header {
						.item {
							width: 30%;
						}
						.issue {
							width: 45%;
						}
						.platforms {
							width: 25%;
						}
					}
					.form-table--list {
						&:last-child {
							border-bottom: none;
						}
						.item {
							width: 30%;
							.fix-error-item-list-data {
								.name {
									font-size: $very-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-80;
								}
								.type {
									text-transform: uppercase;
									font-size: $exta-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-40;
								}
							}
						}
						.issue {
							width: 45%;
							white-space: unset;
							.fix-error-issue-list-data {
								display: flex;
								gap: 8px;
							}
						}
						.platforms {
							width: 25%;
							min-height: inherit;
							&--container {
								display: flex;
								align-items: center;
							}
							.fix-error-issue-list-data {
								display: flex;
								align-items: center;
								gap: 8px;
								img {
									width: 28px;
									height: 28px;
									object-fit: cover;
									border-radius: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.import-menu-pos-wrapper {
	padding: 16px 24px;

	.pos-menu-content {
		color: $color-neutral-black-60;
		font-size: $very-small-font-size;
		line-height: 22px;
		margin-bottom: 25px;
	}

	.pos-name {
		color: $color-neutral-black-80;
		font-size: $medium-font-size;
		font-weight: $font--semibold;
	}
	.checkbox-wrapper {
		margin: 24px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		span {
			color: $color-neutral-black-60;
			font-size: $very-small-font-size;
		}
	}
	.catalogue-alert {
		border-left: 4px solid $color-red-50;
		background-color: $color-red-05;
		border-radius: 2px;
		display: flex;
		gap: 12px;
		align-items: center;
		padding: 12px 16px;
		p {
			margin: 0;
			color: $color-black-80;
			font-size: $very-small-font-size;
		}
	}
	.menu-import-filters {
		display: flex;
		flex-direction: column;
		gap: 24px;
		.dropdown-custom {
			width: 100%;
			.container {
				height: 32px;
				padding: 1px 10px;
			}
			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}
			}
			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;
					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $default-radius;
					}
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}
	}
}
.pos-menu-import-inprogress-wrapper {
	height: 100%;
	.pos-menu-content {
		height: 95%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 16px 24px;
	}
	.import-notice {
		border-left: 4px solid $color-blue-40;
		background-color: $color-blue-15;
		border-radius: 2px;
		display: flex;
		gap: 12px;
		align-items: center;
		padding: 12px 16px;
		img {
			width: 24px;
			height: 24px;
		}
		p {
			margin: 0;
			color: $color-black-80;
			font-size: $very-small-font-size;
		}
	}
}

.menu-publish-container {
	.locations-selection-table-container {
		padding: 0 24px;
		.filters-container {
			margin-bottom: 24px;
			display: flex;
			justify-content: space-between;

			.search-filter {
				margin-right: 0;
			}

			.dropdown-filters-container {
				display: flex;
				gap: 12px;
				.react-select__control {
					width: 140px;
				}
			}
		}

		.form-table {
			margin-top: 24px;
			border-right: 1px solid $border-color;
			border-left: 1px solid $border-color;
			margin-bottom: 24px;

			.name {
				width: 60%;
				.at-checkbox {
					padding-left: 15px;
				}
			}
			.city {
				width: 40%;
			}
		}

		.at-paginator {
			padding: 0;
			margin-bottom: 30px;
		}
	}

	.platforms-selection-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		gap: 24px;

		.platforms-selection-section {
			padding: 24px;
			border-top: 1px solid $border-color;
			.to-you-jahez-warning-wrapper {
				padding: 8px 16px;
				display: flex;
				gap: 12px;
				align-items: center;
				background-color: $warning-light;
				border: none;
				border-radius: 2px;
				border-left: 4px solid #e5b917;
				.warning-text {
					color: $color-black-80;
					font-size: 14px;
				}
			}
			.platforms-header-actions-container {
				margin-top: 16px;
				font-size: $very-small-font-size;
				color: #646464;
				display: flex;
				justify-content: space-between;
				.header {
					font-weight: $font--semibold;
				}
				margin-bottom: 15px;
			}
			.platform-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 15px;
				.platform-selector-title {
					display: flex;
					.at-checkbox {
						height: 18px;
						width: 18px;
						padding-left: 12px;
					}
				}
				.platform-logo-container {
					.platform-logo {
						height: 30px;
						width: 30px;
						object-fit: cover;
					}
				}
			}
			.no-platforms-found {
				display: grid;
				place-content: center;
				padding: 24px;
				font-weight: normal;
				color: $mc-6;
			}
		}

		.platforms-note {
			font-size: $very-small-font-size;
			color: $color-neutral-black-60;
			padding: 24px;
		}
	}
	.publish-date-selection-container {
		.dropdown-custom {
			padding: 0 24px;
			.container {
				padding: 8px 10px;
				width: 275px;
				border: 1px solid $border-color-dark;
				.dropdown-arrow {
					position: absolute;
					right: 10px;
				}
			}
			.dropdown-component {
				left: 24px;
				width: 296px;
			}
		}
		.react-datepicker {
			border: none;
			box-shadow: none;
			left: 0;
			.react-datepicker__header {
				border-bottom: 1px solid $border-color;
				background-color: $mc-1;
			}
			.react-datepicker__current-month {
				color: $color-dark-grey;
				font-size: $very-small-font-size;
			}
			.react-datepicker__navigation {
				position: absolute;
				top: 6px;
				background: url("/assets/icons/dropdown-1.svg") no-repeat;
				background-size: 8px 8px;
				background-position: center;
				width: 20px;
				height: 20px;
				border: 1px solid rgba($color: #000000, $alpha: 0.6);
				border-radius: $border-radius-default;
				filter: invert(0.8);
			}
			.react-datepicker__navigation--previous {
				left: 8px;
				rotate: 90deg;
			}
			.react-datepicker__navigation--next {
				right: 8px;
				rotate: -90deg;
			}
			.react-datepicker__day-names {
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin-top: 15px;
				.react-datepicker__day-name {
					font-size: 10px;
					font-weight: 600;
					color: $mc-6;
					text-transform: uppercase;
				}
			}
			.react-datepicker__week {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.react-datepicker__day {
				margin: 3px;
				padding: 5px;
				border-radius: $border-radius-default;
				font-size: $very-small-font-size;
			}
			& > div {
				&:nth-of-type(3) {
					margin-left: 20px;
				}
			}
		}
		.time-fields-container {
			display: flex;
			gap: 12px;
			padding: 0 24px;
			margin-top: 34px;
			position: relative;
			.header {
				position: absolute;
				top: -20px;
				font-size: $exta-small-font-size;
				font-weight: $font--semibold;
				color: $mc-5;
			}
			.multi-select-filter-container {
				width: 90px;
			}
		}
	}
}
