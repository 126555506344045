.meraki-web-editor-container {
	padding-left: $sidebar-width;
	@include bp(768px) {
		padding-left: 0px;
	}
	.at-modal {
		.modal-container {
			min-height: 10vh;
			.modal-content {
				min-height: 42px;
			}
		}
	}
	.custom-page-modal-container {
		margin: 0 25px;

		.alert-msg {
			font-size: $medium-font-size;
			text-align: center;
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			&.row-full > div {
				width: 100%;
			}
			&.row-half > div {
				width: 47%;
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
	}
	.page-container {
		width: 100%;
		box-sizing: border-box;
		width: 100%;
		margin: auto;
		padding-top: 80px;
		padding-bottom: 150px;
		&:before {
			content: " ";
			display: table;
		}
		& > .content {
			display: block;
			margin: 0px 30px;
		}
		@include bp(768px) {
			padding-top: 70px;
			& > .content {
				margin: 0px 10px;
			}
		}
		.content-container {
			max-width: 95%;
			margin: auto;
			&.preview-mode-tablet {
				width: 768px;
			}
			&.preview-mode-phone {
				width: 400px;
			}
		}
	}
	.section-container-common {
		height: 95vh;
		padding: 0px;
	}
	.meraki-web-editor-section {
		.preview-container {
			height: 100%;
			iframe {
				border-radius: $default-radius;
			}
		}
	}
	// .meraki-web-generic-text-section {
	// }
	.meraki-web-raw-html-section {
		.raw-html-editor-container {
			height: inherit;
			background: #f5f2f0;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			overflow-y: auto;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
			& > div {
				font-variant-ligatures: common-ligatures;
				textarea {
					&:focus-visible {
						outline: none;
					}
				}
			}
		}
	}
}
.meraki-web-editor-container .landing-page-selector {
	max-width: 95%;
	margin: auto 2.5% auto 2.5%;
	background-color: #fff;
	padding: 15px;
	margin-bottom: 20px;

	.landing-page-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.selector-message {
			font-weight: bold;
		}
		.selector-buttons {
			margin-left: auto;
		}
	}
	.landing-page-variants-list {
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
		margin-top: 10px;
		.landing-page-variant {
			padding: 10px;
			width: 30%;
			box-sizing: border-box;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: $default-radius;
			border: 2px solid transparent;
			cursor: pointer;
			&:hover {
				box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
			}
			&.selected {
				border-color: $color-blue;
				background-color: #eaeefe;
			}
			.variant-info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				.variant-label {
					font-weight: bold;
					font-size: $very-small-font-size;
					text-decoration: none;
					&:visited {
						color: inherit;
					}
					.info-icon {
						width: 12px;
						margin-left: 5px;
					}
				}
				.published-tag {
					display: inline-block;
					background-color: $color-green;
					color: white;
					font-size: 12px;
					padding: 5px;
					border-radius: $default-radius;
				}
			}
			img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
}

.meraki-web-editor-container .site-header {
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0px;
	box-sizing: border-box;
	background: $site-header-default-bg-color;
	padding-left: $sidebar-width;
	z-index: $zI-level-2;
	height: 58px;
	border-bottom: 1px solid #ececec;
	.global-loader-container {
		position: fixed;
		top: 57px;
		z-index: $zI-level-2;
		width: 100%;
		display: none;
		padding-left: $sidebar-width;
		box-sizing: border-box;
		left: 0;
		&.active {
			display: block;
		}
	}
	@include bp(768px) {
		padding-left: 0px;
		.global-loader-container {
			padding-left: 0px;
		}
	}
	.header-section-container {
		max-width: 95%;
		margin: auto;
		padding: 0;
		@include bp(768px) {
			margin-top: 0px;
		}
		.header-section {
			&.header-section--right {
				float: right;
				margin: 0;
				.preview-mode-container {
					position: relative;
					display: inline-block;
					margin-right: 25px;
					vertical-align: middle;
					.preview-mode-icon {
						padding: 10px 20px;
						cursor: pointer;
						border-bottom: 2px solid transparent;
						&.mode-selected {
							border-bottom: 2px solid $color-blue;
						}
					}
				}
				.store-url {
					position: relative;
					display: inline-block;
					margin-right: 25px;
					a {
						text-decoration: none;
						color: $color-blue-light;
						font-size: $very-small-font-size;
						font-weight: 600;
						cursor: pointer;
						display: flex;
						align-items: center;
						transition: 180ms;
						&:hover {
							color: $color-blue;
						}
						img {
							margin-right: 10px;
							width: 20px;
						}
					}
				}
			}
			&.header-section--left {
				float: left;
				margin: 0 25px 0 0;
				.page-selector-container {
					display: inline-block;
					vertical-align: middle;
					margin-bottom: 0;
					.dropdown-custom {
						.container {
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 7px 8px;
							.dropdown-arrow {
								position: relative;
							}
						}
					}
				}
				.editor-view-panel {
					display: inline-block;
					vertical-align: middle;
					margin-left: 15px;
				}
				.dropdown-custom {
					.dropdown-arrow {
						position: absolute;
						right: 10px;
					}
				}
			}
		}
	}
}

.meraki-web-editor-container .side-bar-holder {
	pointer-events: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: $zI-level-2 + 1;
	transition: 180ms;
	.side-bar {
		pointer-events: all;
		box-sizing: border-box;
		width: $sidebar-width;
		position: fixed;
		left: 0px;
		top: 0px;
		background: $sidebar-bg-color;
		border-right: 1px solid #ececec;
		height: 100%;
		color: #fff;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		@extend .font--semibold;
		overflow-y: auto;
		@extend .remove-scrollbar;
		display: flex;
		flex-direction: column;
		color: $mc-9;
		.back-navigation-container {
			height: 57px;
			line-height: 57px;
			background-color: $mc-1;
			border-bottom: 1px solid #ececec;
			color: #2f58f2;
			padding-left: 15px;
			z-index: 10;
			@include bp(768px) {
				padding-left: 0px;
				text-align: center;
			}
			.back-arrow-img {
				display: inline-block;
				vertical-align: middle;
				margin-right: 14px;
			}
		}
		.sidebar-editor-container {
			flex: 1;
			margin: 10px 0;
			padding-bottom: 24px;
			overflow-y: auto;
			[aria-label][data-balloon-pos][data-balloon-pos="up-right"]:after {
				right: -70px;
			}

			.at-input-container [aria-label][data-balloon-pos]:after,
			.at-switch-cnt [aria-label][data-balloon-pos]:after {
				width: 140px;
			}

			.at-uploader-container .field-label [aria-label][data-balloon-pos]:after,
			.at-color-picker .field-label [aria-label][data-balloon-pos]:after {
				width: 150px;
			}
			.at-uploader-container .field-label [aria-label][data-balloon-pos]:after,
			[aria-label][data-balloon-pos][data-balloon-pos="down-left"]:after {
				left: -102px;
			}
			.at-switch-cnt .field-label [aria-label][data-balloon-pos]:after,
			[aria-label][data-balloon-pos][data-balloon-pos="down-left"]:after {
				left: -95px;
			}
			.at-switch-cnt .field-label [aria-label][data-balloon-pos]:after,
			[aria-label][data-balloon-pos][data-balloon-pos="down-right"]:after {
				right: -99px;
			}
			@extend .remove-scrollbar;
			.editor-title {
				font-weight: bold;
				margin-bottom: 10px;
				padding: 0 12px;
				padding-bottom: 10px;
				border-bottom: 1px solid $border-color;
			}
			.editor-section-navigation {
				margin-bottom: 10px;
				text-transform: uppercase;
				background-color: #f8f8f8;
				color: $mc-7;
				padding: 5px 10px;
				display: flex;
				align-items: center;
				font-size: 12px;
				font-weight: bold;
				img {
					height: 16px;
					margin-right: 5px;
				}
			}
			.page-components {
				margin: 10px;
				.component-container {
					display: flex;
					align-items: center;
					padding: 10px;
					margin-bottom: 15px;
					border: 1px solid $border-color;
					border-radius: $default-radius;
					font-size: $very-small-font-size;
					box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
					transition: 180ms;
					cursor: pointer;
					img {
						height: 20px;
						padding: 5px;
						border: 1px solid $border-color-dark;
						border-radius: $default-radius;
						background-color: #f3f3f3;
						margin-right: 10px;
						object-fit: cover;
					}
					&:hover {
						box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
					}
				}
			}
			.config-section-container {
				margin-bottom: 30px;
				.header {
					text-transform: uppercase;
					color: $mc-7;
					padding: 5px 10px;
					margin-bottom: 10px;
					background-color: #f8f8f8;
					font-size: 12px;
					@extend .font--semibold;
				}
			}
			.editor-control-item {
				margin: 0 10px;
				margin-bottom: 15px;
				.multi-select-filter-container {
					opacity: 1;
					margin-bottom: 0;
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					.meta-info {
						font-weight: normal;
						color: $mc-6;
						margin-top: 3px;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
				& > .at-btn {
					width: 100%;
					margin: 25px 0;
				}
				.at-uploader-container {
					.uploader-area-container {
						width: auto;
						.upload-icon {
							width: 30px;
							height: 30px;
						}
						.hover-icons {
							img {
								background-color: rgba($color: $mc-9, $alpha: 0.8);
								padding: 5px;
								border-radius: $default-radius;
							}
							.icon-edit {
								width: 23px;
							}
							.icon-delete {
								width: 23px;
							}
						}
					}
				}
			}
		}
		.theme-settings-btn-container {
			flex: 0;
			height: 100px;
			margin-bottom: 5px;
			.theme-settings-btn {
				padding: 15px;
				width: 100%;
				background-color: #f8f8f8;
				border-top: 1px solid $border-color-dark;
				border-bottom: 1px solid $border-color-dark;
				font-weight: bold;
				cursor: pointer;
				box-sizing: border-box;
			}
		}
	}
}
