.new-date-compare-filter {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: fit-content;
	&.loading {
		pointer-events: none;
		cursor: default;
	}
	.preset-type,
	.compare-preset-type {
		padding: 8px 10px;
		margin-bottom: 5px;
		text-align: center;
		min-width: 25px;
		border: 1px solid $border-color-dark;
		border-left: none;
		color: $mc-5;
		font-size: $very-small-font-size;
		background-color: $mc-1;
		transition: 180ms;
		cursor: pointer;
		&:hover {
			background-color: $mc-2;
		}
		&.selected {
			transition: 180ms;
			color: $color-blue;
			background-color: rgba($color: $color-blue, $alpha: 0.09);
			outline: 1px solid $color-blue;
			outline-offset: -1px;
			z-index: 10;
			// @extend .font--semibold;
		}
		&:first-child {
			border: 1px solid $border-color-dark;
			border-radius: 4px 0 0 4px;
		}
		&:last-child {
			border-radius: 0 4px 4px 0;
		}
		.custom-date-time-label {
			span {
				margin: 0 7px;
				@extend .font--semibold;
			}
		}
	}
	.compare-preset-type {
		&:last-child {
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
		}
	}
	.date-filter-dropdown {
		&.preset-type,
		&.compare-preset-type {
			padding: 0;
			border-radius: 0 4px 4px 0;
			.dropdown-custom {
				.container {
					padding: 8px 10px;
					border: none;
					height: auto;
					background-color: transparent;
					span {
						color: $mc-5;
						font-size: $very-small-font-size;
						div {
							display: flex;
							align-items: center;
							.calendar {
								margin-right: 8px;
								position: relative;
								top: -1px;
								svg {
									width: 12px;
									height: 15px;
									path {
										stroke-width: 1em;
									}
								}
							}
							.compare {
								margin-right: 8px;
							}
							.cancel {
								margin-left: 8px;
								&:hover {
									svg {
										path {
											stroke: $color-red;
										}
									}
								}
							}
						}
					}
					@include bp(768px) {
						margin: 0;
					}
				}
				.dropdown-component {
					left: -1px;
					right: auto;
					top: 120%;
					box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
					min-width: 251px;
					z-index: 150;
					opacity: 1;
					&.left {
						left: -1px;
						right: auto;
					}
					&.right {
						left: auto;
						right: -2px;
					}
				}
			}
			&.selected {
				.dropdown-custom {
					.container {
						span {
							color: $color-blue;
							// @extend .font--semibold;
							div {
								.compare {
									opacity: 1;
								}
							}
						}
					}
					.dropdown-component {
						@extend .font--regular;
					}
				}
			}
		}
		&.preset-type {
			margin-right: 10px;
		}
		&.compare-preset-type {
			border-radius: $default-radius;
		}
	}
}

.dropdown-date-time-filter {
	display: flex;
	align-items: center;
	gap: 10px;
	width: max-content;
	position: relative;
	&.loading {
		pointer-events: none;
		cursor: default;
	}
	.dropdown-custom {
		position: relative;
		.meta-info {
			@extend .font--regular;
			.info {
				position: relative;
				top: 1px;
				width: 12px;
				margin-left: 5px;
			}
		}
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: auto;
			font-size: $very-small-font-size;
			padding: 7px 10px;
			min-width: 120px;
			border-radius: $border-radius-default;
			.dropdown-label {
				display: flex;
				align-items: center;
				margin-right: 15px;
				.calendar {
					margin-right: 6px;
					svg {
						position: relative;
						// top: 1px;
						width: 12px;
					}
				}
				.compare {
					margin-right: 6px;
					svg {
						position: relative;
						// top: 2px;
					}
				}
				.cancel {
					margin: 0 10px;
					svg {
						position: relative;
						// top: 2px;
					}
				}
				span {
					font-weight: 600;
					margin: 0 4px;
				}
			}
			.dropdown-placeholder {
				display: flex;
				align-items: center;
				font-size: $very-small-font-size;
				color: #d2d2d2;
				margin-right: 15px;
				.calendar {
					margin-right: 6px;
					svg {
						position: relative;
						// top: 1px;
						width: 12px;
					}
				}
			}
		}
		.dropdown-component {
			position: absolute;
			top: 110%;
			display: flex;
			width: max-content;
			.date-preset-types,
			.time-preset-types {
				.preset,
				.custom {
					display: flex;
					align-items: center;
					min-width: 120px;
					padding: 10px;
					font-size: $very-small-font-size;
					transition: 180ms;
					cursor: pointer;
					&:hover {
						background: $mc-2;
					}
					&.selected {
						color: $color-blue;
						background-color: rgba($color: $color-blue, $alpha: 0.08);
						// font-weight: 600;
					}
					svg {
						position: relative;
						// top: 1px;
						width: 12px;
						margin-right: 6px;
					}
				}
				.custom {
					&.selected {
						color: $color-blue;
						background-color: rgba($color: $color-blue, $alpha: 0.08);
					}
				}
			}
			.date-preset-types {
				&.with-custom {
					border-right: 1px solid $border-color;
				}
			}
			.time-preset-types {
				&.with-custom {
					border-left: 1px solid $border-color;
				}
			}
			.date-time-calendar {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				padding: 10px;
				width: 100%;
				.react-datepicker {
					border: none;
					box-shadow: none;
					// min-height: 325px;
					.react-datepicker__header {
						border-bottom: 1px solid $border-color;
						background-color: $mc-1;
					}
					.react-datepicker__current-month {
						color: $color-dark-grey;
						font-size: $very-small-font-size;
					}
					.react-datepicker__navigation {
						position: absolute;
						top: 6px;
						background: url("/assets/icons/dropdown-1.svg") no-repeat;
						background-size: 8px 8px;
						background-position: center;
						width: 20px;
						height: 20px;
						border: 1px solid rgba($color: #000000, $alpha: 0.6);
						border-radius: $border-radius-default;
						filter: invert(0.8);
					}
					.react-datepicker__navigation--previous {
						left: 8px;
						rotate: 90deg;
					}
					.react-datepicker__navigation--next {
						right: 8px;
						rotate: -90deg;
					}
					.react-datepicker__day-names {
						display: flex;
						align-items: center;
						justify-content: space-around;
						margin-top: 15px;
						.react-datepicker__day-name {
							font-size: 10px;
							font-weight: 600;
							color: $mc-6;
							text-transform: uppercase;
						}
					}
					// .react-datepicker__month {
					// 	min-height: 246px;
					// }
					.react-datepicker__week {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
					.react-datepicker__day {
						margin: 3px;
						padding: 5px;
						border-radius: $border-radius-default;
						font-size: $very-small-font-size;
					}
					& > div {
						&:nth-of-type(3) {
							margin-left: 20px;
						}
					}
				}
				.time-selector {
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: left;
					margin: 15px 0 20px 0;
					.multi-select-filter-container {
						width: 60px;
						div,
						span {
							font-size: $very-small-font-size;
						}
						margin-bottom: 0;
						.meta-info {
							margin-bottom: 0;
							span {
								font-size: 12px;
							}
						}
					}
					.Select-menu-outer {
						width: 60px;
					}
					.Select-arrow-zone {
						display: none;
					}
					.menu-wrapper {
						width: 60px !important;
						.react-select__option {
							padding: 10px;
						}
					}
					// .react-select__dropdown-indicator {
					// 	display: none;
					// }
					.meridiem-container {
						margin-left: 20px;
						.meridiem {
							width: 38px;
							height: 34px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid $border-color-dark;
							border-radius: $border-radius-default;
							font-size: $very-small-font-size;
							transition: 180ms;
							cursor: pointer;
							&:hover {
								color: $color-blue;
								border-color: $color-blue;
							}
						}
					}
					.colon {
						position: relative;
						font-size: $medium-font-size;
						margin: 10px 5px;
						@extend .font--semibold;
					}
					.actions-container {
						margin-left: 38px;
					}
				}
				.date-time-footer {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 5px;
					.date-time-selector {
						.date-range-selector {
							text-align: left;
							display: flex;
							align-items: center;
							gap: 0 8px;
							justify-content: space-between;
							padding: 5px 7px;
							.start-date-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
							}
							.end-date-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
								&.disabled {
									opacity: 0.4;
									cursor: default;
								}
							}
							.meta-info {
								color: $mc-7;
								margin-bottom: 3px;
								@extend .font--semibold;
							}
							.date {
								display: flex;
								align-items: center;
								min-width: 75px;
								min-height: 18px;
								padding: 8px 10px;
								border: 1px solid $border-color;
								border-radius: $border-radius-default;
								background-color: $mc-1;
								font-size: $very-small-font-size;
								&.placeholder {
									color: $mc-5;
								}
							}
							.selected {
								opacity: 1;
								color: $color-blue;
								.date {
									border: 1px solid $color-blue;
								}
							}
						}
						.date-time-range-selector {
							display: flex;
							align-items: center;
							.date-time {
								text-align: left;
								display: flex;
								align-items: center;
								gap: 0 8px;
								justify-content: space-between;
								padding: 5px 7px;
								// &:last-child {
								// 	padding-bottom: 10px;
								// }
							}
							.start-date-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
							}
							.start-time-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
							}
							.end-date-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
								&.disabled {
									opacity: 0.4;
									cursor: default;
								}
							}
							.end-time-container {
								width: 100%;
								transition: 180ms;
								cursor: pointer;
							}
							.meta-info {
								color: $mc-7;
								margin-bottom: 3px;
								@extend .font--semibold;
							}
							.date {
								display: flex;
								align-items: center;
								width: 150px;
								min-height: 18px;
								padding: 8px 10px;
								border: 1px solid $border-color;
								border-radius: $border-radius-default;
								background-color: $mc-1;
								font-size: $very-small-font-size;
								&.placeholder {
									color: $mc-5;
								}
							}
							.selected {
								color: $color-blue;
								.date {
									border: 1px solid $color-blue;
								}
							}
						}
					}
					.actions-container {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						padding: 5px 7px;
						.action {
							font-size: 12px;
							text-transform: uppercase;
							margin-left: 15px;
							cursor: pointer;
							@extend .font--semibold;
						}
						.reset {
							position: relative;
							top: -1px;
							width: 16px;
							margin-right: auto;
							opacity: 0.6;
							cursor: pointer;
							&:hover {
								opacity: 1;
							}
						}
						.apply {
							color: $color-blue;
						}
						.cancel {
							opacity: 0.6;
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.custom-message {
					color: $mc-6;
					width: 100%;
					text-align: left;
					font-size: 11px;
					word-spacing: 1px;
					text-transform: uppercase;
					@extend .font--semibold;
				}
			}
		}
	}
	&.left {
		.dropdown-custom {
			.dropdown-component {
				left: 0;
				right: auto;
			}
		}
	}
	&.right {
		.dropdown-custom {
			.dropdown-component {
				right: 0;
				left: auto;
			}
		}
	}
	.validation-message {
		position: absolute;
		bottom: -19px;
	}
}
