.discounts-container {
	.action-buttons-container {
		margin-right: 10px;
		.multi-select-filter-container {
			width: 175px;
			font-size: $very-small-font-size;
		}
	}
	.overlay-sidebar-container {
		.content {
			overflow: visible;
			overflow-x: visible;
		}
	}
	.discounts-list-table-container {
		.title {
			width: 30%;
			padding-left: 25px;
			.title-desc {
				display: inline-block;
				width: calc(100% - 70px);
				.badge {
					padding: 1px 6px;
					margin: 0 5px;
					border-radius: 10px;
					text-align: center;
					font-size: 11px;
					display: inline-block;
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.type {
			width: 15%;
		}
		.platform {
			width: 15%;
		}
		.starts {
			width: 20%;
		}
		.expires {
			width: 20%;
			padding-right: 25px;
		}
		.at-cell-text {
			&.platform {
				text-transform: capitalize;
			}
		}
	}
}
.discount-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.associated-items-tab,
	.associated-locations-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.crm-title-search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.discount-edit-items-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.item-title {
				width: 60%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.item-desc {
					display: flex;
					.food-type {
						margin: auto 0;
					}
					.link-text {
						margin-left: 10px;
					}
				}
				.crm-title {
					color: $mc-5;
					padding-left: 22px;
				}
				.item_tags {
					padding-left: 23px;
					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}
			.category {
				width: 20%;
			}
			.price {
				width: 20%;
				padding-right: 25px;
				text-align: right;
			}
		}
		.discount-edit-locations-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 70px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 60%;
				padding-left: 25px;
			}
			.city {
				width: 40%;
				padding-right: 25px;
			}
		}
	}
}
