.at-json-input-container {
	position: relative;
	.meta-info {
		width: 100%;
		font-size: 12px;
		color: $mc-5;
		padding-bottom: 5px;
	}
	.info {
		position: relative;
		top: 1px;
		width: 12px;
		margin-left: 5px;
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.validation-message {
		margin-top: 5px;
	}
	.react-json-view {
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		padding: 10px;
		min-height: 70px;
		max-height: 400px;
		overflow: auto;
		// prevent scroll-chaining to parent elements
		overscroll-behavior: contain;
		.copy-icon,
		.click-to-add-icon,
		.click-to-remove-icon {
			svg {
				width: 14px !important;
				height: 14px !important;
			}
		}
	}
	&.read-only {
		.react-json-view {
			background-color: #eaeaea !important;
			filter: grayscale(1);
			.object-key-val,
			.variable-row {
				.object-key-val,
				.variable-row {
					border-left: 1px solid #c2c2c2 !important;
				}
			}
		}
	}
}
