.buy-credits-flow {
	&.overlay-sidebar-container .content {
		width: 600px;
		right: -600px;
		@include bp(768px) {
			width: 75%;
			right: -75%;
		}
		.overlay-sidebar-content {
			padding: 25px 0;
		}
	}
	.save-campaign-title {
		input {
			outline: 0;
			padding: 10px;
			padding-left: 0px;
			font-size: $large-font-size;
			border: 0px;
			border-bottom: 1px solid #111;
			width: 304px;
			max-width: 100%;
		}
	}
	.loading-info {
		font-size: 12px;
		margin-top: 15px;
		text-align: center;
		min-height: 24px; //avoids layout shift
	}
}

.buy-credits-area {
	.credit-balance {
		font-size: $very-small-font-size;
		margin-bottom: 20px;
	}
	.credit-input-container {
		margin-bottom: 20px;
		min-height: 130px; // to avoid layout shift when there are less number of taxes
		.credit-input {
			float: left;
			@include bp(768px) {
				float: none;
				margin-bottom: 15px;
			}
			input {
				outline: 0;
				padding: 5px;
				padding-left: 0;
				font-size: $very-small-font-size;
				border: 0;
				border-bottom: 1px solid $divider-color;
				width: 200px;
				max-width: 100%;
				margin-bottom: 5px;
				-moz-appearance: textfield;
			}
			input::-webkit-inner-spin-button,
			input::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			.meta-info {
				font-size: 12px;
				color: $mc-7 !important;
				font-style: italic;
				.markup-price {
					text-decoration: line-through;
				}
				.actual-price {
					margin-left: 4px;
				}
			}
		}
		.error-message {
			font-size: 12px;
			color: transparent;
			transition: 180ms ease-out;
			visibility: hidden;
			font-style: italic;
			&[data-active="true"] {
				visibility: visible;
				color: $color-red;
			}
		}
		.credit-payment-amount {
			float: right;
			transition: 300ms ease opacity;
			@include bp(768px) {
				float: none;
			}
			&[data-loading="true"] {
				opacity: 0.2;
			}
			.tax-details-item {
				font-size: 12px;
				margin-bottom: 5px;
				&[data-type="net-pay"] {
					font-size: $very-small-font-size;
					@extend .font--semibold;
				}
				.tax-source,
				.seperator,
				.tax-value {
					display: inline-block;
					vertical-align: top;
					color: $mc-7;
				}
				.tax-source {
					width: 120px;
					@include bp(768px) {
						width: 40%;
					}
				}
				.seperator {
					margin: 0 10px;
				}
				.tax-value {
					width: 80px;
					text-align: right;
					@include bp(768px) {
						width: 50%;
					}
				}
			}
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
	.credit-inp-suggestions {
		margin-bottom: 20px;
		display: grid;
		grid-gap: 4%;
		grid-template-columns: repeat(4, 1fr);
		.suggestion-item {
			padding: 8px;
			border: 1px solid $mc-5;
			border-radius: 4px;
			text-align: center;
			box-sizing: border-box;
			height: 100%;
			cursor: pointer;
			.credits-amt {
				font-size: 16px;
				color: $mc-8;
			}
			.currency-amt {
				font-size: 14px;
				color: $mc-6;
				white-space: nowrap;
			}
			.markup-price {
				text-decoration: line-through;
			}

			&:hover {
				background: $color-blue;
				.credits-amt,
				.currency-amt {
					color: #fff;
				}
			}
		}
	}
	.credit-notes {
		margin-bottom: 20px;
		.meta-info {
			font-size: 14px;
			margin-bottom: 5px;
		}
		.credit-notes-textarea {
			height: 70px;
			width: 100%;
			resize: none;
			box-sizing: border-box;
			outline: none;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
		}
		.error-message {
			font-size: 12px;
			color: transparent;
			transition: 180ms ease-out;
			visibility: hidden;
			&[data-active="true"] {
				visibility: visible;
				color: $color-red;
			}
		}
	}
	@include bp(768px) {
		.at-btn {
			width: 100%;
		}
	}
}

.piper-credits-rate-chart {
	width: 100%;
	margin-top: 16px;
}
