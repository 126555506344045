.placeholder-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $mc-4;
	.at-multi-actions-button-container {
		display: unset;
		width: 30%;
		margin-top: 16px;
	}
	.placeholder {
		&--image-container {
			img {
				height: 180px;
				width: 240px;
				object-fit: contain;
			}
		}
		&--text {
			color: $color-dark-grey;
			margin-top: 16px;
			font-size: $medium-large-font-size;
			@extend .font--semibold;
		}
		&--sub-text {
			color: $color-dark-grey;
			margin-top: 8px;
			text-align: center;
			max-width: 450px;
		}
		&--action-btn {
			margin-top: 20px;
		}
		&--redirection-link {
			margin-top: 20px;
			display: flex;
			align-items: center;
			gap: 5px;
			.learn-more-img {
				height: 13px;
				width: 13px;
			}
		}
		&--link-content {
			color: $color-dark-grey;
		}
		&--link-text {
			@extend .hyperlink;
		}
	}
}

.medium {
	.placeholder--image-container {
		img {
			height: 240px;
			width: 360px;
		}
	}
}
