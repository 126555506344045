.As\(fs\) {
	align-self: flex-start;
}
.Bgc\(\#f2f2f2\) {
	background-color: #f2f2f2;
}
.C\(\#7b8a9a\) {
	color: #7b8a9a;
}
.C\(\#979797\) {
	color: #979797;
}
.C\(\#c0392b\) {
	color: #c0392b;
}
.C\(\#ccc\) {
	color: #ccc;
}
.Cur\(p\) {
	cursor: pointer;
}
.D\(f\) {
	display: flex;
}
.D\(ib\) {
	display: inline-block;
}
.D\(n\) {
	display: none;
}
.Jc\(sb\) {
	justify-content: space-between;
}
.Fl\(end\) {
	float: right;
}
.Fl\(start\) {
	float: left;
}
.Fw\(600\) {
	font-weight: 600;
}
.Fz\(10px\) {
	font-size: 10px;
}
.Fz\(12px\) {
	font-size: 12px;
}
.Fz\(20px\) {
	font-size: 20px;
}
.H\(0px\) {
	height: 0px;
}
.H\(100px\) {
	height: 100px;
}
.H\(110px\) {
	height: 110px;
}
.H\(140px\) {
	height: 140px;
}
.H\(16px\) {
	height: 16px;
}
.H\(200px\) {
	height: 200px;
}
.H\(20px\) {
	height: 20px;
}
.H\(255px\) {
	height: 255px;
}
.H\(250px\) {
	height: 250px;
}
.H\(315px\) {
	height: 315px;
}
.H\(405px\) {
	height: 405px;
}
.H\(600px\) {
	height: 600px;
}
.H\(25px\) {
	height: 25px;
}
.H\(30px\) {
	height: 30px;
}
.H\(40px\) {
	height: 40px;
}
.H\(50px\) {
	height: 50px;
}
.H\(55px\) {
	height: 55px;
}
.H\(60px\) {
	height: 60px;
}
.H\(65px\) {
	height: 65px;
}
.H\(70px\) {
	height: 70px;
}
.H\(72px\) {
	height: 72px;
}
.H\(80px\) {
	height: 80px;
}
.H\(83px\) {
	height: 83px;
}
.M\(0px\) {
	margin: 0 !important;
}
.Mt\(10px\) {
	margin-top: 10px;
}
.Mt\(15px\) {
	margin-top: 15px;
}
.Mt\(20px\) {
	margin-top: 20px;
}
.Mt\(25px\) {
	margin-top: 25px;
}
.Mt\(30px\) {
	margin-top: 30px;
}
.Mt\(5px\) {
	margin-top: 5px;
}
.Mend\(10px\) {
	margin-right: 10px;
}
.Mend\(25px\) {
	margin-right: 25px;
}
.Mend\(35px\) {
	margin-right: 35px;
}
.Mb\(10px\) {
	margin-bottom: 10px;
}
.Mb\(15px\) {
	margin-bottom: 15px;
}
.Mb\(20px\) {
	margin-bottom: 20px;
}
.Mb\(25px\) {
	margin-bottom: 25px;
}
.Mb\(30px\) {
	margin-bottom: 30px;
}
.Mb\(50px\) {
	margin-bottom: 50px;
}
.Mb\(2px\) {
	margin-bottom: 2px;
}
.Mb\(3px\) {
	margin-bottom: 3px;
}
.Mb\(5px\) {
	margin-bottom: 5px;
}
.Mb\(220px\) {
	margin-bottom: 220px;
}
.Mstart\(8px\) {
	margin-left: 8px;
}
.Mstart\(10px\) {
	margin-left: 10px;
}
.Mstart\(20px\) {
	margin-left: 20px;
}
.Mstart\(25px\) {
	margin-left: 25px;
}
.Mstart\(35px\) {
	margin-left: 35px;
}
.Mih\(65px\) {
	min-height: 65px;
}
.Op\(0\) {
	opacity: 0;
}
.P\(10px\) {
	padding: 10px;
}
.P\(200px\) {
	padding: 200px;
}
.P\(20px\) {
	padding: 20px;
}
.P\(25px\) {
	padding: 25px;
}
.P\(50px\) {
	padding: 50px;
}
.P\(5px\) {
	padding: 5px;
}
.Pend\(10px\) {
	padding-right: 10px;
}
.Pend\(25px\) {
	padding-right: 25px;
}
.Pb\(5px\) {
	padding-bottom: 5px;
}
.Pb\(30px\) {
	padding-bottom: 30px;
}
.Pb\(50px\) {
	padding-bottom: 50px;
}
.Pstart\(10px\) {
	padding-left: 10px;
}
.Pstart\(25px\) {
	padding-left: 25px;
}
.Pt\(5px\) {
	padding-top: 5px;
}
.Pos\(a\) {
	position: absolute;
}
.Pos\(r\) {
	position: relative;
}
.Ta\(c\) {
	text-align: center;
}
.Top\(0px\) {
	top: 0px !important;
}
.W\(0px\) {
	width: 0px;
}
.W\(100\%\) {
	width: 100%;
}
.W\(90\%\) {
	width: 90%;
}
.W\(85\%\) {
	width: 85%;
}
.W\(100px\) {
	width: 100px;
}
.W\(150px\) {
	width: 150px;
}
.W\(120px\) {
	width: 120px;
}
.W\(200px\) {
	width: 200px;
}
.W\(20\%\) {
	width: 20%;
}
.W\(240px\) {
	width: 240px;
}
.W\(250px\) {
	width: 250px;
}
.W\(300px\) {
	width: 300px;
}
.W\(330px\) {
	width: 330px;
}
.W\(400px\) {
	width: 400px;
}
.W\(600px\) {
	width: 600px;
}
.W\(47\%\) {
	width: 47%;
}
.W\(50px\) {
	width: 50px;
}
.W\(25px\) {
	width: 25px;
}
.W\(60px\) {
	width: 60px;
}
.W\(80px\) {
	width: 80px;
}
.Cf:before,
.Cf:after {
	content: " ";
	display: table;
}
.Cf:after {
	clear: both;
}
.Cf {
	zoom: 1;
}
.Fd\(row\) {
	flex-direction: row;
}
.Fd\(column\) {
	flex-direction: column;
}
.F1 {
	flex: 1;
}
.Ff {
	flex: 1;
	width: "100%";
}
.G\(10px\) {
	gap: 10px;
}
.Gr\(10px\) {
	row-gap: 10px;
}
.Gc\(10px\) {
	column-gap: 10px;
}
.B1 {
	border: 1px solid #d2d2d2;
}
.Br\(4px\) {
	border-radius: 4px;
}
.Ai\(fs\) {
	align-items: flex-start;
}
.Bs\(small\) {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
