.capture-location-polygons,
.capture-location {
	.gmap-container {
		margin-bottom: 15px;
		width: 100%;
		height: 250px;
		position: relative;
		#pac-input {
			margin: 5px;
		}
		#firstHeading {
			color: $mc-9;
			font-size: $small-font-size;
			font-weight: 500;
			text-align: center;
			img {
				position: relative;
				height: 20px;
				width: 20px;
				margin-right: 5px;
				top: 3px;
			}
		}
		.pac-container {
			z-index: 2147483647 !important;
		}
		.fullscreen-pac-container[style] {
			z-index: 2547483647 !important;
			top: 50px !important;
		}
		#bodyContent {
			color: $mc-7;
			font-size: $very-small-font-size;
			text-align: center;
		}
	}
	.action-buttons {
		margin-bottom: 30px;
		.polygon-save {
			margin-right: 15px;
		}
	}
}
