.sync-status-container {
	.sync-icon {
		padding: 0 30px;
		text-align: center;
		img {
			width: 250px;
			object-fit: cover;
		}
	}
	.message {
		max-width: 750px;
		margin: 0 auto;
		margin-bottom: 100px;
		padding: 0 30px;
		text-align: center;
		color: $mc-5;
		font-size: $medium-font-size;
		line-height: 1.5;
	}
}
