.select-all {
	padding: 10px 25px;
	font-size: 14px;
	background-color: $mc-3;
	text-align: center;
	animation: fade-in-out 0.5s forwards;
	border-radius: $default-radius;
	.hyperlink {
		color: $color-blue;
		cursor: pointer;
		text-transform: initial;
		position: relative;
		margin-left: 5px;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			transform: scaleX(0);
			height: 1px;
			bottom: 0;
			left: 0;
			background-color: $color-blue-light;
			transform-origin: bottom right;
			transition: transform 0.25s ease-out;
		}
		&:hover::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

@keyframes fade-in-out {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
