.periscope-container {
	.item-issues-periscope {
		.header-item-issues-availability {
			display: flex;
			justify-content: space-between;

			.item-share-info {
				display: flex;
				gap: 12px;
				align-items: center;
				font-family: Source Sans Pro;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;

				.fetch-time {
					color: #8a8a8a;
				}
			}

			.refresh-btn {
				border: 1px solid #eaeaea;
				padding: 8px 16px 8px 12px;
				border-radius: 2px;
				display: flex;
				align-items: center;
				cursor: pointer;

				.refresh-img {
					margin: 0 5px 0 0;
				}

				.refresh-text {
					color: #363636;
				}
			}

			.share-report {
				cursor: pointer;
				padding: 8px 16px 8px 12px;
				display: flex;
				gap: 8px;
				background: #3b5feb;

				.share-text {
					color: white;
				}
			}
		}

		.item-issues-stackedbar {
			.item-issues-stackedbar-div {
				display: flex;
				flex-direction: column;
				border: 1px solid #eaeaea;
				border-radius: 4px;
				padding: 12px 24px 18px 18px;
				gap: 14px;

				.space-middle {
					margin: 0 6px;
				}

				.loading-stackedbar {
					width: 33%;

					.shimmer {
						height: 12px;
						width: 100.4%;
						border-radius: 0px;
					}
				}
			}

			.issues-stackedbar-firstdiv {
				display: flex;
				justify-content: space-between;

				.item-issues-all-info {
					display: flex;
					gap: 16px;

					.item-issues-graph-info {
						cursor: pointer;
						display: flex;
						flex-direction: row;
						gap: 12px;
						padding: 8px 10px;
						border-radius: 4px;

						.color-info {
							margin: 3px 0 0 0;
							border-radius: 2px;
							width: 16px;
							height: 16px;
						}

						.issues-graph-display {
							display: flex;
							flex-direction: column;

							.issue-text-title {
								font-family: Source Sans Pro;
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								color: #646464;
							}

							.issue-percentage-display {
								font-family: Source Sans Pro;
								font-size: 18px;
								font-weight: 600;
								line-height: 24px;
								color: #363636;

								.shimmer-container {
									padding: 3px 0 0 0;

									.shimmer {
										border-radius: 0px;
									}
								}
							}
						}
					}

					.item-issues-graph-info:hover,
					.issue-filter-selected {
						background: #eaeaea;
					}

					.item-issues-red {
						background: #b02b2b;
					}

					.item-issues-orange {
						background-color: #ff7557;
					}

					.item-issues-yellow {
						background-color: #fd9d53;
					}

					.item-issues-blue {
						background-color: #c5d1fc;
					}
				}

				.total-issue-count {
					display: flex;
					flex-direction: column;

					.issue-text-title {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						color: #646464;
						padding: 8px 0 0 0;
						text-align: right;
					}

					.issue-total-items {
						font-family: Source Sans Pro;
						font-size: 18px;
						font-weight: 600;
						line-height: 24px;
						text-align: left;
						color: #363636;
						text-align: right;
					}
				}
			}

			.bar-graph {
				height: 12px;
				margin: 0 0 0 5px;
			}
		}
	}

	.section-container-common-periscope {
		.item-issues-filters {
			.left-filters-item {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;

				.custom-label,
				.item-availability-status,
				.dropdown-custom span {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
				}

				.city-filter {
					.multi-select-filter-container {
						.react-select__control {
							width: 200px;
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 22px;
						}
					}
				}

				.status,
				.items {
					.item-status {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						color: #363636;
						padding: 0 8px;
					}
				}
			}
		}

		.filters-location {
			display: flex;
			justify-content: space-between;
		}
	}

	.brandcell-item-isses {
		display: flex;

		.issue-important-item {
			margin: 20px 12px 20px 24px;

			border-radius: 50%;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
			display: flex;
			cursor: pointer;

			.brand-label-img {
				width: 15.02px;
				height: 11.03px;
			}
		}

		.issues-brand-item-text {
			padding: 20px 24px 20px 16px;
			display: flex;
			align-items: center;
			gap: 8px;

			.brand-display-issues {
				display: flex;
				gap: 8px;

				.brand-img-text {
					border-radius: 2px;
					padding: 1px 1.5px;
					width: 19px;
					height: 20px;
					font-weight: 600;
					margin: 0 0 0 -0.5px;
				}

				.issues-brand-item {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					align-self: center;
					text-align: left;
					color: #363636;
					white-space: nowrap;
					overflow: hidden;
					max-width: 212px;
					text-overflow: ellipsis;
				}
			}

			.issues-stars-text {
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				color: #8a8a8a;
				display: flex;
				align-items: center;
				gap: 4px;

				.issues-star {
					height: 8.02px;
					width: 8.45px;
				}
			}
		}
	}

	.table-item-issues-availability {
		background: #ffffff;

		.item-issues-thead {
			border: 1px solid #eaeaea;

			.issues-brand-label {
				border: 1px solid #eaeaea;
				min-width: 340px;
				max-width: 308.5px;
				padding: 0 0 0 68px;
			}

			.issues-location-label {
				padding: 12px 24px 12px 24px;
				border: 1px solid #eaeaea;
			}
		}

		.item-issues-tbody {
			border-bottom: 1px solid #eaeaea;
		}

		.paginator-location {
			text-align: right;
		}

		.issues-tablehead-text {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			color: #646464;
		}

		.item-platform-head {
			text-transform: capitalize;
			gap: 8px;
			display: flex;
			align-items: center;
			justify-content: right;
			padding: 16px 20px 16px 16px;

			.table-platform-img {
				width: 20px;
				height: 20px;
			}
		}

		.issues-store:hover {
			background: #ffe5e5 !important;
		}
		.online-green-image {
			display: none;

			width: 12.28px;
			height: 9.41px;
		}
		.no-issues-store:hover {
			background: #f6fbf8;
			.online-green-image {
				display: flex;
				transform: translate(0.5px, 0px);
			}
			.online-black-image {
				display: none;
			}
		}
		.popover-wrapper-container.down-left .popover-container {
			right: auto;
			left: 85px;
			top: 55px;
		}
		.info-popover {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			color: #363636;
			padding: 8px 12px;
			width: fit-content;
			text-wrap: nowrap;
		}
	}

	.table-item-issues-availability {
		.tooltip-itemm {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		.tooltip-itemm {
			position: relative;
			padding: 20px 0;
			width: 100%;

			.tooltiptext-item {
				visibility: hidden;
				background-color: white;
				color: black;
				text-align: center;
				border-radius: 6px;
				position: absolute;
				z-index: 1;
				right: 50%;
				transform: translateX(50%);
				display: flex;
				flex-direction: column;
				padding: 8px 12px;
				color: #363636;
			}

			.all-offline-display {
				width: 175px;
			}
		}

		.tooltip-itemm .tooltiptext-item::after {
			content: "";
			position: absolute;
			bottom: 100%;
			transform: translateX(-50%);
			left: 50%;
			border-width: 7px;
			border-style: solid;
			border-color: transparent transparent white transparent;
		}

		.tooltip-itemm:hover .tooltiptext-item {
			visibility: visible;
		}

		.tooltiptext-item {
			.tooltip-description-item {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				text-align: center;
			}
		}

		.tooltip-itemm {
			.missing-items-display {
				top: 117%;
				box-shadow: 0px 0px 12px 0px #00000029;
				text-wrap: nowrap;
				padding: 8px 12px 8px 12px;

				.missing-items-info {
					.issues-click-cell {
						font-family: Source Sans Pro;
						font-size: 12px;
						font-weight: 400;
						line-height: 16px;
						text-align: left;
						color: #8a8a8a;
					}

					.issues-text-info {
						.missing-color-display {
							color: #b02b2b;
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 22px;
							text-align: left;
						}

						.issues-no-locations {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
						}
					}
				}
			}

			.issues-nill-stores {
				box-shadow: 0px 0px 12px 0px #00000029;
				text-wrap: nowrap;
				padding: 8px 12px 8px 12px;
				top: 117%;
			}

			.no-missing-details {
				box-shadow: 0px 0px 12px 0px #00000029;
				text-wrap: nowrap;
				padding: 8px 12px 8px 12px;
				top: 117%;
			}
		}
	}

	.item-issues-loading {
		.shimmer {
			border-radius: 0px;
		}

		.intro-icon-loading {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 24px;
		}

		.all-loading-span {
			display: flex;
		}

		.star-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 12px;
		}

		.brand-image-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 11px;
		}

		.branditem-text-loading {
			width: 134px;
			height: 20px;
			padding: 20px 0px 20px 0px;
		}

		.platform-loading-item {
			padding: 16px 16px 16px 50px;
		}
	}
}
