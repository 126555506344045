@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.accept-invite-container {
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	.webp & {
		background-image: url("/assets/background/background-tiny.webp");
	}
	.no-webp & {
		background-image: url("/assets/background/background-tiny.png");
	}
	.page-container {
		padding: 35px 0;
		@include bp(768px) {
			height: auto;
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	.atlas-logo {
		text-align: center;
		img {
			width: 130px;
		}
	}
	.header {
		display: flex;
		align-items: center;
		img {
			position: relative;
			width: 45px;
			top: 2px;
			margin-right: 10px;
		}
	}
	.text {
		&.text--large {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 22px;
		}
		&.text--small {
			font-size: 13px;
		}
		&.text--medium {
			padding: 17px 32px 0;
			-webkit-animation: fade 1s;
			animation: fade 1s;
		}
		.logout {
			color: $color-blue;
			font-size: 12px;
			opacity: 0.6;
			cursor: pointer;
			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}
	}
	.user-form-container {
		position: relative;
		box-shadow: 0 0 70px 15px rgba(0, 0, 0, 0.3);
		background-color: $mc-2;
		border-radius: 4px;
		padding: 25px 30px;
		max-width: 400px;
		min-height: 50px;
		margin: 30px auto;
		@include bp(768px) {
			max-width: 100%;
			margin: 30px 20px;
			padding: 20px 10px;
		}
		@include bp(768px) {
			& > .text {
				text-align: center;
			}
		}
		.subtitle {
			color: $mc-5;
			margin-top: 8px;
		}
		.loader-backdrop {
			background-color: transparent;
		}
		.user-form {
			margin-top: 20px;
			a {
				text-decoration: none;
			}
			.required-label {
				color: $color-red;
				font-size: 12px;
			}
			// override styles for input field and select filter component inside form
			.at-input-container {
				margin-bottom: 15px;
				.validation-message {
					margin-top: -1px;
				}
				.at-label {
					width: 36px;
					padding: 0;
					justify-content: center;
					font-size: $very-small-font-size;
				}
				.multi-select-filter-container {
					width: 68px;
					.menu-wrapper {
						width: 200px !important;
					}
				}
				.at-input--dropdown {
					padding: 0px 10px 0px 77px;
				}
			}
			.multi-select-filter-container {
				margin-bottom: 20px;
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				.validation-message {
					margin-top: 5px;
				}
			}
			.info-text {
				font-size: 12px;
				color: $mc-5;
				margin-top: 3px;
			}
			label.user-text-input {
				font-size: 15px;
				color: $mc-7;
				letter-spacing: 0px;
				padding-bottom: 8px;
			}
			input.user-text-input {
				margin-bottom: 5px;
				&::placeholder {
					color: #c3c3c3;
				}
			}
			.remember-me {
				margin-top: 10px;
				font-size: 13px;
				line-height: 17px;
				color: $mc-7;
				@extend .font--regular;
				.checkmark {
					height: 16px;
					width: 16px;
				}
				input:checked ~ .checkmark {
					background-color: $color-violet;
				}
			}
			.submit-btn {
				display: block;
				margin-top: 25px;
				margin-bottom: 5px;
				padding-left: 0;
				padding-right: 0;
			}
			.message {
				margin-top: 10px;
				color: $color-blue;
				text-align: center;
				cursor: pointer;
			}
			.login-modal-nav {
				color: $mc-6;
				.left-arrow {
					height: 10px;
					transition: 0.3s;
				}
				&:hover {
					.left-arrow {
						transform: translateX(-10px);
					}
				}
			}
		}
	}
	.login-loader-bar {
		height: 3px;
		position: absolute;
		width: 100%;
		animation: hloader infinite 1s;
		background: #2c3e50;
	}
	.login-loader-container {
		position: absolute;
		height: 2px;
		width: 100%;
		display: none;
		left: 0;
		top: 0;
		&.active {
			display: block;
		}
	}
}
