.template-editor-container {
	&.overlay-sidebar-container .content {
		width: 1100px;
		right: -1100px;
		overflow-x: hidden;
		.overlay-sidebar-content {
			margin: 0;
			padding: 0;
		}
	}
	.action-buttons {
		text-align: right;
		.template-cancel {
			margin-right: 20px;
		}
	}
}

#template-editor {
	border: 3px solid #444;
	margin-bottom: 20px;
}
.gjs-editor {
	height: 550px;
}
.gjs-cv-canvas {
	overflow: auto;
}
.gjs-pn-panels {
	.gjs-pn-views-container {
		height: 550px;
		overflow: auto;
		.gjs-fonts::before {
			font-size: 3em;
		}
	}
}
.gjs-editor-cont .sp-container {
	left: 730px !important;
}
