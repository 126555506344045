.meraki-web-section {
	.header-action-container {
		display: flex;
		.help-btn-container {
			margin-right: 10px;
			svg {
				// top: 3px;
			}
		}
	}
	.publish-changelog-container {
		margin: 0 25px;
	}
	.meraki-web-container {
		.at-top-bar-wrapper-v2 {
			z-index: 100;
		}
	}
	.workspace-container {
		display: flex;
		justify-content: space-between;
		padding: 25px;
		.workspace-theme {
			width: 60%;
			box-shadow: $default-shadow;
			padding: 25px;
			.theme-text {
				display: flex;
				justify-content: space-between;
			}
			.theme-name {
				font-weight: 600;
				font-size: $medium-font-size;
				text-transform: capitalize;
				margin-bottom: 5px;
			}
			.theme-subtext {
				color: $mc-6;
				margin-bottom: 20px;
			}
			.theme-feature-image {
				width: 100%;
				object-fit: cover;
				border-radius: $default-radius;
				border: 1px solid $border-color;
			}
		}
		.workspace-info {
			width: 30%;
			padding-top: 25px;
			.welcome-msg {
				font-weight: 600;
				margin-bottom: 20px;
				font-size: $medium-large-font-size;
			}
			.welcome-msg-subtext {
				color: $mc-6;
				margin-bottom: 20px;
			}
			.release-status {
				font-size: 12px;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				padding: 5px 10px;
				display: inline-block;
				margin-bottom: 10px;
				font-weight: bold;
				&.Published {
					background-color: $color-green;
					color: white;
					border-color: $color-green;
				}
			}
			.current-version {
				font-weight: 600;
				margin-bottom: 20px;
				font-size: $medium-large-font-size;
			}
			.published-by {
				color: $mc-6;
				margin-bottom: 20px;
				font-size: 12px;
			}
			.unpublished-count {
				font-weight: light;
				font-size: 48px;
				margin-bottom: 10px;
			}
			.unpublished-count-label {
				color: $mc-7;
				margin-bottom: 20px;
			}
			.store-url a {
				text-decoration: none;
				color: $color-blue-light;
				font-size: $very-small-font-size;
				font-weight: 600;
				cursor: pointer;
				display: flex;
				align-items: center;
				transition: 180ms;
				&:hover {
					color: $color-blue;
				}
				img {
					margin-right: 10px;
					width: 20px;
				}
			}
			.workspace-buttons {
				margin-bottom: 20px;
				.btn-start-building {
					margin-right: 10px;
				}
			}
			.get-help {
				display: flex;
				align-items: center;
				text-decoration: none;
				img {
					width: 15px;
					margin-right: 5px;
				}
				span {
					font-size: $very-small-font-size;
				}
			}
		}
		.status-tag {
			border-radius: $moderate-border-radius;
			padding: 4px 12px;
			font-size: $exta-small-font-size;
			color: $mc-1;
		}
		.inprogress-tag {
			background-color: $color-orange-light;
		}
		.saved-tag {
			background-color: $color-orange-light;
		}
		.published-tag {
			background-color: $color-green-light;
		}
		.failed-tag {
			background-color: $color-error;
		}
		.mockup-container {
			min-height: 330px;
			margin-top: 20px;
			@media only screen and (min-width: 2000px) {
				min-height: 650px;
			}
		}
		.image-desktop-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 0;
			left: 0;
			right: 0;
			aspect-ratio: 16/9;
		}
		.image-desktop-bottom {
			position: absolute;
			width: 95%;
			bottom: 0;
			z-index: 2;
			aspect-ratio: 16/9;
		}
		.image-mobile-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 0;
			left: 0;
			right: 0;
			aspect-ratio: 9/16;
		}
		.image-mobile-bottom {
			position: absolute;
			width: 90%;
			bottom: 0;
			z-index: 2;
			aspect-ratio: 9/16;
		}
		.publish-info {
			background-color: $warning-light;
			span {
				color: $color-yellow-dark;
			}
		}
	}
	.pages-container {
		.form-content {
			padding: 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.select-page-text {
			font-weight: 600;
			margin-bottom: 15px;
		}
		.select-page-dropdown {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			min-height: 50px;
			.at-switch-cnt {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.meta-info {
					margin-right: 10px;
				}
			}
			.page-selector-container {
				margin-bottom: 0;
				.dropdown-custom {
					.dropdown-arrow {
						position: absolute;
						right: 10px;
					}
				}
			}
		}
		.multi-select-filter-container {
			width: 250px;
			margin-bottom: 20px;
			.at--dropdown {
				font-size: $very-small-font-size;
			}
		}
		.page-details {
			.section-highlight {
				background-color: #f8f8f8;
				padding: 10px 25px;
				margin: 0 -25px;
				margin-bottom: 20px;
				font-size: 12px;
				font-weight: 600;
				color: $mc-6;
			}
			.page-settings-container {
				border: 1px solid $border-color;
				border-radius: $default-radius;
				width: 60%;
				margin-bottom: 20px;
				.page-setting-row {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px;
					&:hover {
						background-color: rgba(47, 88, 242, 0.05);
					}
					&:nth-of-type(odd) {
						background-color: #f3f5f8;
						&:hover {
							background-color: rgba(47, 88, 242, 0.05);
						}
					}
					.setting-label {
						font-weight: 600;
						font-size: $very-small-font-size;
						margin-bottom: 5px;
					}
					.setting-desc {
						font-size: 12px;
						color: $mc-6;
					}
					.at-switch-cnt {
						margin-top: -15px;
					}
				}
			}
			.page-seo-input {
				width: 60%;
				.at-input-container {
					margin-bottom: 15px;
				}
			}
		}
	}
	.labels-container {
		.form-content {
			padding: 25px;
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.label-filters-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				.at-input-label {
					text-transform: capitalize;
				}
				input::placeholder {
					font-size: 12px;
				}
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				margin-bottom: 0;
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				.meta-info {
					font-weight: normal;
					color: $mc-6;
					margin-top: 3px;
					margin-bottom: 0;
					padding-bottom: 5px;
				}
			}
		}
		.labels-list-table-container {
			.transaction-rows {
				min-height: initial;
				.page {
					text-transform: capitalize;
				}
			}
			.page {
				width: 30%;
				padding-left: 25px;
			}
			.label {
				width: 30%;
				text-align: left;
			}
			.language {
				width: 30%;
			}
		}
	}
	.versions-container {
		.latest-version-status {
			padding: 25px;
			background-color: $color-blue;
			color: white;
			margin: 25px;
			border-radius: $default-radius;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.version-info {
				width: 60%;
				.version-no {
					font-weight: 600;
					font-size: $large-font-size;
					margin-bottom: 10px;
				}
				.version-date {
					font-size: $very-small-font-size;
				}
			}
			.unpublished-info {
				.unpublished-count {
					font-weight: 600;
					font-size: $large-font-size;
					margin-bottom: 10px;
					text-align: center;
				}
			}
		}
		.versions-list-table-container {
			.transaction-rows {
				min-height: initial;
			}
			.version {
				width: 10%;
				padding-left: 25px;
			}
			.comment {
				width: 20%;
				text-align: left;
			}
			.theme {
				width: 10%;
				padding-left: 25px;
			}
			.publishedOn {
				width: 20%;
			}
			.publishedBy {
				width: 20%;
				padding-right: 25px;
			}
			.action {
				width: 20%;
				text-align: right;
				padding-right: 25px;
				.action-item {
					color: $color-blue-light;
					margin-left: 10px;
					font-size: 12px;
					font-weight: bold;
					cursor: pointer;
					transition: 180ms;
					&:hover {
						color: $color-blue;
					}
				}
			}
		}
	}
	.domains-container {
		.domain-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px 25px 5px 25px;
		}
		.at-modal .modal-container .modal-footer {
			border-top: 1px solid #eaeaea;
		}
		.domains-list-table-container {
			.transaction-rows {
				min-height: initial;
			}
			.domain {
				width: 50%;
				padding-left: 25px;
			}
			.hyperlink {
				&--black-color {
					color: $color-neutral-black-dark;
					&::after {
						background-color: $color-neutral-black-dark;
					}
				}
			}
			.verified {
				width: 25%;
				text-align: left;
				.domain-verified {
					color: $color-green;
					font-size: 12px;
					font-weight: bold;
					border: 1px solid $color-green;
					padding: 2px 4px;
					border-radius: $default-radius;
				}
				.domain-not-verified {
					font-size: 12px;
					font-weight: bold;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.action {
				width: 25%;
				text-align: right;
				padding-right: 25px;
				.action-item {
					color: $color-blue-light;
					margin-left: 10px;
					font-size: 12px;
					font-weight: bold;
					cursor: pointer;
					transition: 180ms;
					&:hover {
						color: $color-blue;
					}
				}
			}
		}
		.add-domain-container {
			margin: 0 25px;
		}
		.cname-container {
			padding: 24px;
			border-top: 1px solid #eaeaea;
			max-height: 250px;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				width: 10px;
			}
			&::-webkit-scrollbar-track {
				background: $mc-1;
			}
			&::-webkit-scrollbar-thumb {
				background: #989898;
				border-radius: 8px;
			}

			.cname-header {
				font-size: $small-font-size;
				font-weight: $font--semibold;
				margin: 2px 0px;
			}
			.cname-sub-header {
				font-size: $very-small-font-size;
				color: #8a8a8a;
				margin: 4px 0px 12px 0px;
			}
			.cname-url {
				padding: 16px;
				background-color: #fafafa;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid #eaeaea;
				border-radius: 2px;
				.copy-btn {
					margin: 0;
				}
			}
			.a-records-header {
				font-size: $small-font-size;
				font-weight: $font--semibold;
				margin: 24px 0px 8px 0px;
			}
			.a-record {
				margin: 12px 0px;
			}
		}
	}
	.apps-container {
		.form-content {
			padding: 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			@include bp(768px) {
				flex-direction: column;
				margin-bottom: 0;
			}
			&.row-full > div {
				width: 100%;
				@include bp(768px) {
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 47%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					@include bp(768px) {
						margin-bottom: 30px;
					}
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.card-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			margin-bottom: 25px;
			&:nth-last-of-type(1) {
				margin-bottom: 10px;
			}
			&.loading {
				opacity: 0.6;
				pointer-events: none;
			}
			.card-header {
				padding: 15px 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include bp(768px) {
					flex-direction: column;
					align-items: flex-start;
				}
				.header-container {
					display: flex;
					align-items: center;
					.app-icon {
						img {
							height: 40px;
						}
					}
					.app-header {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						flex-wrap: wrap;
						.header-text {
							font-size: $small-font-size;
							@extend .font--semibold;
						}
						.header-subtext {
							color: $mc-5;
							font-size: $very-small-font-size;
							padding-top: 5px;
						}
					}
				}
				.at-switch-cnt,
				.multi-select-filter-container {
					display: flex;
					align-items: center;
					padding-left: 100px;
					.at--dropdown {
						width: 230px;
					}
					.meta-info {
						margin-right: 10px;
					}
					.at-switch {
						.switch-rail {
							margin: 2px 0;
						}
						.switch-ball {
							top: 4px;
						}
					}
					.validation-message {
						margin: 0 0 0 10px;
					}
					@include bp(768px) {
						padding-left: 0px;
						margin-top: 25px;
					}
				}
				.actions {
					display: flex;
					align-items: center;
					.at-switch-cnt,
					.multi-select-filter-container {
						padding-left: 30px;
						@include bp(768px) {
							.at--dropdown {
								width: 100%;
							}
							width: 100%;
							padding-left: 0;
						}
					}
					@include bp(768px) {
						width: 100%;
						flex-direction: column;
						margin-top: 5px;
					}
				}
			}
			.form-container {
				padding: 15px 25px 20px 25px;
				border-top: 1px solid $border-color;
				.form-row {
					justify-content: flex-start;
					max-width: 950px;
					@include bp(768px) {
						max-width: 100%;
						flex-direction: column;
					}
					&:nth-last-child(1) {
						margin-bottom: 5px;
					}
					&.row-full > div {
						width: 64%;
						@include bp(768px) {
							width: auto;
							margin-bottom: 30px;
							&:last-child {
								margin: 0;
							}
						}
					}
					&.row-half > div {
						width: 30%;
						margin-right: 35px;
						@include bp(768px) {
							width: auto;
							margin-bottom: 30px;
							margin-right: 0;
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	.settings-container {
		.form-content {
			padding: 25px 25px 0 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
				}
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
		.form-row {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 30px;
			max-width: 950px;
			@include bp(768px) {
				max-width: 100%;
				flex-direction: column;
				margin-bottom: 0px;
			}
			&.row-full > div {
				width: 64%;
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
				}
			}
			&.row-half > div {
				width: 30%;
				margin-right: 40px;
				&:nth-last-child(1) {
					margin-right: 0px;
				}
				@include bp(768px) {
					width: auto;
					margin-bottom: 30px;
					margin-right: 0;
				}
			}
		}
		.meta-info {
			font-weight: normal;
		}
	}
	.raw-config-container {
		.form-content {
			padding: 25px;
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}
}

.page-selector-container {
	margin-bottom: 20px;
	.dropdown-custom {
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 7px 8px;
			box-sizing: border-box;
			font-size: $very-small-font-size;
			.dropdown-arrow {
				position: relative;
			}
		}
		.dropdown-component {
			left: 0;
			right: initial;
			width: 250px;
			&:before {
				content: initial;
			}
		}
	}
	.pages-list-container {
		max-height: 400px;
		overflow-y: auto;
		.page-group-title {
			font-weight: bold;
			color: $mc-7;
			padding: 10px 15px;
			font-size: 12px;
			background-color: #f8f8f8;
		}
		.page-group-item {
			padding: 10px;
			font-size: $very-small-font-size;
			cursor: pointer;
			display: flex;
			align-items: center;
			img {
				width: 25px;
				margin-right: 10px;
			}
			&:hover {
				background-color: $mc-2;
			}
			&[data-selected="true"] {
				color: $color-blue;
			}
			&[data-selected="false"] {
				img {
					filter: grayscale(1);
					opacity: 0.8;
				}
			}
		}
		.create-page-btn {
			padding: 15px;
			color: $color-blue;
			font-size: $very-small-font-size;
			cursor: pointer;
		}
	}
}

.meraki-web-themes-section {
	.theme-selector-container {
		margin: 0 24px;
		padding: 24px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: $default-radius;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.header-text {
				flex: 1;
				.title {
					font-weight: 600;
					font-size: $medium-font-size;
					margin-bottom: 5px;
				}
				.subtext {
					color: $mc-6;
					margin-bottom: 20px;
				}
			}
			.header-img {
				width: 125px;
			}
		}
		.themes-grid-container {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.theme-item {
				width: 30%;
				margin-bottom: 20px;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
				border-radius: $default-radius;
				background-color: #fbfcfc;
				padding: 15px;
				.header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 15px;
					.name {
						font-weight: 600;
						text-transform: capitalize;
					}
					.view-link {
						font-size: 12px;
						color: $color-blue;
						text-decoration: none;
					}
				}
				.feature-img {
					width: 100%;
					object-fit: cover;
					border-radius: $default-radius;
					border: 1px solid $border-color;
					margin-bottom: 15px;
				}
			}
		}
		.mockup-container {
			min-height: 250px;
			@media only screen and (min-width: 2000px) {
				min-height: 500px;
			}
		}
		.image-desktop-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			aspect-ratio: 16/9;
		}
		.feature-image-bottom {
			position: absolute;
			width: 72%;
			z-index: 2;
			margin-left: 1%;
			margin-top: 1%;
			aspect-ratio: 16/11.6;
			transform: rotateX(-20deg) skewX(-0.1deg);
		}
		.image-mobile-top {
			position: absolute;
			width: 100%;
			z-index: 1;
			bottom: 10%;
			left: 0;
			right: 0;
			aspect-ratio: 9/18;
		}
		.feature-image-mobile-bottom {
			position: absolute;
			width: 85%;
			bottom: 10%;
			z-index: 2;
			margin-bottom: 12%;
			aspect-ratio: 9/18;
		}
	}
	.meraki-guide-container {
		margin: 25px;
		padding: 20px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: $default-radius;
		.title {
			font-weight: 600;
			font-size: $medium-font-size;
			margin-bottom: 5px;
		}
		.subtext {
			color: $mc-6;
			margin-bottom: 20px;
		}
	}
}

.meraki-web-theme-details-container {
	.form-sidebar-container .header .title {
		text-transform: capitalize;
	}
	.theme-image-gallery {
		white-space: nowrap;
		overflow-y: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		.gallery-item {
			margin-right: 25px;
			margin-bottom: 25px;
			border-radius: $default-radius;
			border: 1px solid $border-color;
			width: 60%;
			object-fit: cover;
		}
	}
	.theme-info {
		color: $mc-7;
		font-size: $very-small-font-size;
		.theme-description {
			margin-bottom: 15px;
		}
	}
}

.meraki-web-locked {
	padding: 25px;
	.meraki-web-info-container {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.intro-container {
			width: 45%;
			padding: 0 25px;
			.header {
				font-size: $large-font-size;
				margin-bottom: 25px;
				@extend .font--semibold;
				img {
					position: relative;
					top: 3px;
					width: 26px;
					margin-right: 5px;
					object-fit: cover;
				}
			}
			.description {
				color: $mc-7;
				margin-bottom: 25px;
			}
			.actions {
				.subscribe {
					margin-right: 10px;
				}
			}
		}
		.meraki-frodo-demo-container {
			width: 55%;
			padding: 0 25px;
			img {
				width: 100%;
				min-width: 400px;
				max-width: 700px;
				object-fit: cover;
			}
		}
		@include bp(1070px) {
			flex-direction: column;
			align-items: flex-start;
			.intro-container {
				width: 100%;
				margin-bottom: 25px;
				padding: 0;
			}
			.meraki-frodo-demo-container {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 0;
				img {
					width: 80%;
					margin: 15px auto;
					min-width: 300px;
					object-fit: cover;
				}
			}
		}
	}
	.meraki-web-features-container {
		display: flex;
		padding: 25px 0;
		.feature {
			width: 50%;
			display: flex;
			padding: 0 25px;
			img {
				height: fit-content;
				margin-right: 10px;
				padding: 10px;
				border-radius: $default-radius;
				background-color: $color-blue;
			}
			.info {
				.header {
					font-size: $medium-font-size;
					margin-bottom: 10px;
					@extend .font--semibold;
				}
				.description {
					color: $mc-7;
				}
			}
		}
		@include bp(1070px) {
			flex-direction: column;
			padding: 0;
			.feature {
				margin: 0 0 30px 0;
				padding: 0;
				width: 100%;
			}
		}
	}
}
