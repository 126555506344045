.status-alert-container {
	padding: 15px;
	margin: 24px 0;
	display: flex;
	align-items: flex-start;
	border: 1px solid $border-color;
	border-radius: $border-radius-default;
	gap: 12px;
	.error-text-link {
		color: $color-dark-grey;
		.main-error-header {
			font-size: $medium-font-size;
			font-weight: $font--semibold;
			margin-bottom: 4px;
		}
		.main-error-desc {
			font-size: $very-small-font-size;
			margin-bottom: 12px;
		}
		.hyperlink {
			display: flex;
			align-items: center;
			gap: 4px;
			img {
				width: 16px;
				height: 16px;
			}
		}
	}
}
