.pending-invites-container {
	.credits-section-header {
		padding: 0 25px 10px 25px;
		.header-text {
			display: flex;
			align-items: center;
			flex-grow: 1;
			margin-top: 25px;
			.subtitle {
				color: $mc-9;
			}
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
		.campaign-list-filter {
			position: relative;
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				left: auto;
				top: -10px;
			}
		}
		.action-buttons-container {
			margin-right: 10px;
		}
	}
	.credits-section-header.transactions-list-header {
		.search-input-container {
			display: flex;
			.search-filter {
				width: 170px;
				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;
						img {
							padding-left: 0;
						}
					}
					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}
				.cancel-search {
					right: 24px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.overlay-sidebar-container {
		.content {
			overflow: visible;
			overflow-x: visible;
		}
	}
	.pending-invites-list-table-container {
		.email {
			width: 25%;
			padding-left: 25px;
		}
		.roles {
			width: 30%;
			.tag-item {
				text-overflow: clip;
				max-width: fit-content;
				margin: 0px 5px 5px 0;
			}
		}
		.created_at {
			width: 15%;
		}
		.status {
			width: 10%;
			.badge {
				width: fit-content;
				padding: 2px 8px;
				border-radius: $default-radius;
				font-size: 12px;
				font-weight: bold;
				transition: 180ms;
				cursor: default;
				&.cancelled {
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
				&.accepted {
					color: $color-green;
					border: 1px solid $color-green;
					background: rgba(46, 204, 113, 0.1);
				}
				&.pending {
					color: $color-yellow;
					border: 1px solid $color-yellow;
					background: rgba(255, 217, 0, 0.1);
				}
			}
		}
		.action {
			width: 20%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			padding-right: 25px;
			.action-item {
				color: $color-blue-light;
				margin-left: 10px;
				font-size: 12px;
				font-weight: bold;
				cursor: pointer;
				transition: 180ms;
				height: fit-content;
				&:hover {
					color: $color-blue;
				}
				&.cancel {
					color: rgba($color: $color-red, $alpha: 0.6);
					&:hover {
						color: $color-red;
					}
				}
			}
			.copy-btn {
				position: relative;
				border: none;
				background: none;
				margin: 0 0 0 10px;
				padding: 0;
				height: fit-content;
				line-height: 14px;
				font-size: 12px;
				font-weight: bold;
				color: $color-blue-light;
				&:hover {
					color: $color-blue;
				}
			}
			.copy-success {
				color: $color-green;
				font-size: 12px;
				font-weight: bold;
				text-transform: uppercase;
				&:hover {
					color: $color-green;
				}
			}
		}
	}
}

.unified-invites-container {
	.filters {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 25px 25px 25px;
		box-sizing: border-box;
		.filter-buttons {
			display: flex;
			align-items: center;
			.multi-select-filter-container {
				width: 220px;
				margin-right: 10px;
				font-size: $very-small-font-size;
			}
		}
		.header-text {
			display: flex;
			align-items: center;
			flex-grow: 1;
			margin-top: 25px;
			.subtitle {
				color: $mc-9;
			}
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
		.filter-in-header {
			position: relative;
			.filter-count {
				background: $color-blue;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
			&.active {
				.container {
					border: 1px solid $color-blue;
					.filter-title {
						color: $color-blue;
					}
					.filter-icon {
						filter: invert(32%) sepia(89%) saturate(4568%) hue-rotate(224deg) brightness(96%) contrast(97%);
					}
				}
			}
		}
		.search-input-container {
			display: flex;
			align-items: center;
			.search-filter {
				position: relative;
				width: 200px;
				margin: 0;
				.at-input-container {
					width: 100%;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 4px 4px 0;
						}
					}
				}
				.cancel-search {
					right: 12px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				font-size: $very-small-font-size;
				.react-select__control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.overlay-sidebar-container {
		.content {
			overflow: visible;
			overflow-x: visible;
		}
	}
	.pending-invites-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.table-header {
			z-index: 70;
			top: 95px;
			.table-cell {
				display: flex;
				align-items: center;
			}
		}
		.email {
			display: flex;
			width: 25%;
			padding-left: 25px;
			a {
				width: fit-content;
				height: fit-content;
				text-transform: none;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.created-at {
			width: 15%;
		}
		.atlas {
			width: 22%;
			.app {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.app-biz-name {
				font-size: 12px;
				text-transform: uppercase;
				color: $mc-6;
				@extend .font--semibold;
			}
		}
		.prime {
			width: 23%;
			.app {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.app-biz-name {
				font-size: 12px;
				text-transform: uppercase;
				color: $mc-6;
				@extend .font--semibold;
			}
		}
		.status {
			width: 15%;
			padding-right: 25px;
			.badge {
				width: fit-content;
				padding: 4px 8px;
				border-radius: $default-radius;
				font-size: 12px;
				font-weight: bold;
				transition: 180ms;
				color: $color-dark-grey;
				background: #f5f5f5;
				cursor: default;
			}
		}
	}
	.at-modal {
		.modal-header {
			padding: 15px 25px;
			border-bottom: 1px solid $border-color;
			.header-left {
				.title {
					text-align: left;
					margin: 0;
					font-size: $medium-font-size;
					color: $color-light-black;
				}
			}
		}
		.modal-container {
			width: 40%;
			max-width: 570px;
			min-height: 5vh;
		}
		.modal-content {
			padding: 15px 25px;
			min-height: 60px;
			.description {
				margin-bottom: 10px;
				color: $color-light-black;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.modal-footer {
			border-top: 1px solid $border-color;
			text-align: right;
			padding: 15px 25px;
		}
	}
}

.unified-invite-detail-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.header {
		.header-right {
			.edit-roles {
				margin-right: 10px;
			}
		}
	}
	.sub-heading {
		color: $color-dark-grey;
		margin: 10px 0 20px 0;
		@extend .font--semibold;
	}
	.user-info-and-roles {
		margin-top: 10px;
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		margin-bottom: 20px;
		.card-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5px 15px;
			height: 50px;
			.product {
				display: flex;
				align-items: center;
				.name {
					font-size: $medium-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					img {
						width: 18px;
						height: 18px;
						object-fit: cover;
					}
				}
			}
			.status {
				color: $color-dark-grey;
				background: #f5f5f5;
				padding: 4px 8px;
				font-size: $very-small-font-size;
				border-radius: 2px;
				@extend .font--semibold;
				&.pending {
					color: $color-yellow-dark;
					background-color: rgba($color: $color-yellow-dark, $alpha: 0.1);
				}
				&.cancelled {
					color: $color-red;
					background-color: rgba($color: $color-red, $alpha: 0.1);
				}
			}
			.at-switch-cnt {
				display: flex;
				align-items: center;
				.meta-info {
					position: relative;
					top: -1px;
					margin-right: 10px;
					font-size: $very-small-font-size;
				}
				.at-switch {
					&.active {
						.switch-rail {
							background-color: $color-green;
						}
					}
				}
			}
		}
		.description {
			padding: 15px;
			color: $color-dark-grey;
			font-size: $very-small-font-size;
			border-top: 1px solid $border-color;
		}
		.card-content {
			border-top: 1px solid $border-color;
			padding: 15px;
			.meta-info {
				font-size: 12px;
				font-weight: 400;
				color: $mc-5;
				margin-bottom: 5px;
			}
			.business {
				.info {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 10px;
						border-radius: $default-radius;
						img {
							width: 28px;
							height: 28px;
							object-fit: cover;
						}
					}
					.name {
						font-size: $very-small-font-size;
					}
					.initials {
						padding: 5px 6px;
						font-size: $small-font-size;
						border-radius: $default-radius;
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						@extend .font--semibold;
					}
				}
			}
			.locations,
			.roles,
			.full-name,
			.email,
			.phone {
				.info {
					font-size: $very-small-font-size;
				}
				.view-all {
					margin-top: 10px;
					color: $color-blue;
					font-size: 12px;
					cursor: pointer;
					@extend .font--semibold;
				}
				.popover-wrapper-container {
					.popover-container {
						top: 1px;
						left: -8px;
						&::before {
							right: 17px;
							z-index: -10;
						}
						&::after {
							display: none;
							right: 13px;
						}
						.locations-list {
							padding: 10px;
							box-sizing: border-box;
							overflow-y: auto;
							background-color: $mc-1;
							max-height: 200px;
							border-radius: $default-radius;
							.name {
								margin-bottom: 3px;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			.biz-info {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				.name {
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
				.logo {
					display: flex;
					align-items: center;
					margin-right: 10px;
					img {
						width: 36px;
						height: 36px;
						object-fit: cover;
					}
				}
				.initials {
					padding: 8px 9px;
					font-size: $medium-font-size;
					border-radius: $default-radius;
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					@extend .font--semibold;
				}
			}
			.form-row {
				&:last-child {
					margin-bottom: 10px;
				}
			}
		}
		.card-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px;
			font-size: 12px;
			border-top: 1px solid $border-color;
			img {
				width: 14px;
				margin-right: 5px;
			}
			.text {
				color: $color-dark-grey;
				margin-right: 5px;
			}
		}
	}
	.at-modal {
		.modal-header {
			padding: 15px 25px;
			border-bottom: 1px solid $border-color;
			.header-left {
				.title {
					text-align: left;
					margin: 0;
					font-size: $medium-font-size;
					color: $color-light-black;
				}
			}
		}
		.modal-container {
			width: 500px;
			min-height: 5vh;
		}
		.modal-content {
			padding: 15px 25px;
			min-height: 60px;
			.description {
				margin-bottom: 10px;
				color: $color-light-black;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.modal-footer {
			border-top: 1px solid $border-color;
			text-align: right;
			padding: 15px 25px;
		}
	}
}
