.payout-upload-help-container {
	.payout-upload-custom-title {
		//border: 1px solid red;
		display: flex;
		position: relative;
		.help-button-container {
			background-color: rgba($color: #6c717a, $alpha: 0.275);
			border-radius: 50%;
			width: 50px;
			height: 50px;
			position: relative;
		}
		.help-icon {
			position: absolute;
			top: -3px;
			left: -3px;
			padding: 3px;
			border-radius: 50%;
			width: 50px;
			opacity: 0.375;
			text-align: center;
			display: block;
		}
		.title {
			width: 600px;
			position: absolute;
			left: 65px;
			top: -2px;
			.sub-title {
				font-size: $very-small-font-size;
				font-weight: 100;
				color: $mc-7;
			}
		}
	}
}
