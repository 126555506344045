.segment-action-button {
	float: right;
	margin-top: -25px;
}

.data-container .campaign-segments-data {
	max-height: 250px;
	overflow: auto;
}

.segment-item-container {
	border-left: 3px solid $btn-grey-color;
	background-color: $mc-2;
	margin-bottom: 15px;
	position: relative;
	cursor: pointer;

	@include bp(768px) {
		border-left: 0px solid $btn-grey-color;
		padding-left: 50px;
		.action-icon {
			display: none;
		}
	}
	.stats,
	.meaning {
		display: inline-block;
		vertical-align: top;
		margin-left: 22px;
		padding-top: 13px;
		padding-bottom: 13px;
	}
	.stats {
		width: 66px;
		text-align: center;
		.amount {
			@extend .font--semibold;
			font-size: $large-font-size;
			color: $mc-8;
		}
		.title {
			font-size: 12px;
			color: $mc-6;
		}
		@include bp(768px) {
			text-align: left;
			position: absolute;
			left: 0px;
			margin-left: 10px !important;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.meaning {
		width: 500px;
		.text--title {
			font-size: $medium-font-size;
		}
		.text--subtitle {
			font-size: 12px;
			color: $mc-6;
			text-align: justify;
		}
		@include bp(768px) {
			width: auto;
			margin-left: 35px;
			margin-right: 10px;
		}
	}
	.action-icon {
		right: 29px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}
