@keyframes slide {
	0% {
		transform: translate(100%, 0);
		transition: 300ms;
	}
	100% {
		transform: translate(0, 0);
		transition: 300ms;
	}
}
@keyframes backgroundSlide {
	0% {
		transform: translate(100%, 0) scale(1.1);
		transition: 300ms;
	}
	100% {
		transform: translate(0, 0) scale(1.1);
		transition: 300ms;
	}
}
@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes stripes {
	to {
		background-size: 100% 100%;
	}
}

.login-container {
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	.webp & {
		background-image: url("/assets/background/background-tiny.webp");
	}
	.no-webp & {
		background-image: url("/assets/background/background-tiny.png");
	}
	.page-container {
		padding: 35px 0;
		@include bp(768px) {
			height: auto;
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	.atlas-logo {
		text-align: center;
		img {
			width: 130px;
		}
		.banner {
			width: 866px;
			border-radius: $default-radius;
			@include bp(768px) {
				margin: 0 10px;
				width: 95%;
			}
		}
	}
	.login-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		.back-navigation-arrow {
			margin: 10px 0 0 0;
			display: flex;
			align-items: center;
			.navigation-title {
				font-size: 12px;
				margin: 0 5px 0 0;
				text-transform: none;
			}
			img {
				transform: rotateY(180deg);
				width: 16px;
				vertical-align: middle;
			}
			&:hover {
				img {
					transform: rotateY(180deg) translateX(-3px);
				}
			}
		}
	}
	.message-container {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border: 1px solid $mc-4;
		border-radius: 100px;
		padding: 5px;
		margin-bottom: 20px;
		font-size: $very-small-font-size;
		background-color: $mc-1;
		box-shadow: $default-shadow;
		.message {
			color: $mc-9;
		}
		.timer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: 5px;
			div {
				text-align: center;
				width: 35px;
				.value {
					@extend .font--semibold;
				}
				.unit {
					color: $mc-5;
					font-size: 12px;
					@extend .font--semibold;
				}
			}
		}
	}
	.front-layer {
		max-width: 866px;
		margin: 30px auto 0 auto;
		box-sizing: border-box;
		display: flex;
		min-height: 480px;
		.announcement-wrap {
			flex: 1.5;
		}
		.login-wrap {
			flex: 1;
			position: relative;
		}
		@include bp(768px) {
			flex-direction: column-reverse;
			.announcement-wrap {
				flex: 1;
				margin: 50px 10px;
			}
			.login-wrap {
				flex: 1;
				margin: 0 10px;
			}
		}
	}
	.text {
		&.text--large {
			font-size: 22px;
		}
		&.text--small {
			font-size: 13px;
		}
		&.text--medium {
			padding: 17px 32px 0;
			-webkit-animation: fade 1.5s;
			animation: fade 1.5s;
		}
	}
	.announcement-item {
		box-shadow: 0 0 70px 15px rgba(0, 0, 0, 0.3);
		height: 100%;
		width: 95%;
		background-color: $mc-2;
		border-radius: 4px;
		overflow: hidden;
		@include bp(768px) {
			width: 100%;
		}
		.announcement-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			position: relative;
			.feature-image {
				width: 100%;
				height: 315px;
				object-fit: contain;
				border-top-left-radius: $default-radius;
				border-top-right-radius: $default-radius;
				-webkit-animation: slide 1s;
				animation: slide 1s;
				z-index: 10;
			}
			.background-image {
				width: 100%;
				height: 315px;
				object-fit: cover;
				border-top-left-radius: $default-radius;
				border-top-right-radius: $default-radius;
				-webkit-animation: backgroundSlide 1s;
				animation: backgroundSlide 1s;
				position: absolute;
				filter: blur(12px);
				transform: scale(1.1);
			}
			.info {
				background-color: $mc-2;
				z-index: 10;
				min-height: 165px;
			}
			.description {
				margin-top: 5px;
				margin-bottom: 5px;
				color: $mc-5;
				padding: 7px 32px;
				-webkit-animation: fade 1.5s;
				animation: fade 1.5s;
			}
			.link {
				color: $link-color;
				text-decoration: none;
			}
			.read-more {
				padding: 7px 32px 0;
				@include bp(768px) {
					padding: 7px 32px 27px;
				}
				.read-more-btn {
					padding-left: 15px;
					padding-right: 15px;
					height: 28px;
					line-height: 26px;
					background: $mc-2;
					color: $btn-color;
					font-size: $very-small-font-size;
				}
			}
			.carousel-item {
				padding: 5px 32px;
				text-align: center;
				@include bp(768px) {
					margin-bottom: 7px;
				}
				.carousel-indicator {
					padding: 4px;
					margin: 4px;
					background: $mc-4;
					border-radius: $default-radius;
					cursor: pointer;
					display: inline-block;
					transition: 180ms;
					user-select: none;
					box-sizing: border-box;
				}
				.active {
					transition: 300ms;
					background: linear-gradient($color-blue, $color-blue) $mc-4 no-repeat 0 0;
					background-size: 0 100%;
					animation: stripes 0.5s linear 0s forwards;
				}
				.previous {
					transition: 300ms;
					background: linear-gradient($mc-4, $mc-4) $color-blue no-repeat 0 0;
					background-size: 0 100%;
					animation: stripes 0.5s linear 0s forwards;
				}
			}
		}
		.no-data-placeholder {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 130px;
			img {
				width: 100px;
				margin-bottom: 5px;
				opacity: 0.2;
			}
			@include bp(768px) {
				margin: 100px 0;
			}
			.message--large {
				color: #aaaaaa;
				font-size: $medium-font-size;
				width: 70%;
				text-align: center;
				margin-bottom: 30px;
			}
			.message--small {
				color: #aaaaaa;
				.link-text {
					color: #aaaaaa;
					text-decoration: underline;
					text-transform: lowercase;
					cursor: pointer;
					@extend .font--regular;
				}
			}
		}
	}
	.login-item {
		box-shadow: 0 0 70px 15px rgba(0, 0, 0, 0.3);
		width: 100%;
		height: 100%;
		background-color: $mc-2;
		display: inline-block;
		border-radius: 4px;
		.login-form-container {
			padding: 23px 32px;
			@include bp(768px) {
				padding: 20px 10px;
			}
		}
	}
}

.login-loader-bar {
	height: 3px;
	position: absolute;
	width: 100%;
	animation: hloader infinite 1s;
	background: #2c3e50;
}

.login-loader-container {
	position: absolute;
	height: 2px;
	width: 100%;
	display: none;
	&.active {
		display: block;
	}
}
