.create-campaign-header {
	.create-campaign-next {
		margin-top: -2px !important;
	}
}

.create-campaign-section {
	.create-segment--segment-title {
		color: $color-blue;
		font-size: 22px;
		margin-bottom: 10px;
		text-transform: capitalize;
	}

	.create-campaign-header {
		padding: 25px;
		.header-text {
			font-size: 20px;
		}
		.subheader-text {
			font-size: $small-font-size;
			margin-top: 7px;
			color: $mc-6;
		}
		.header-text-container {
			float: left;
			width: 80%;
			@include bp(768px) {
				float: none;
				width: 100%;
			}
		}
		.segment-gallery-btn {
			float: right;
			text-align: center;
			cursor: pointer;
			.btn-text {
				font-size: $small-font-size;
				color: $color-blue;
			}

			@include bp(768px) {
				float: none;
				text-align: left;
				margin-top: 5px;
				img {
					display: none;
				}
			}
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
}

.match-options-container {
	padding: 0 25px;
	.match-options-radio {
		font-size: $very-small-font-size;
		line-height: 36px;
		span.checkmark {
			line-height: 36px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.text-inline {
		display: inline-block;
		vertical-align: middle;
		font-size: $very-small-font-size;
		line-height: 36px;
	}
	.dropdown-inline {
		display: inline-block;
		width: 55px;
		height: 36px;
		vertical-align: middle;
		margin: 0 5px;
	}
}

.create-campaign-forms-container {
	display: flex;
	margin: 30px 0;
	@include bp(768px) {
		flex-direction: column;
		padding: 25px;
		margin: 0;
	}

	&.create-campaign-forms-container--preview {
		display: block;
		font-size: $very-small-font-size;

		.campaign-preview-block-content-title {
			font-size: $large-font-size;
			margin-bottom: 18px;
			@include bp(768px) {
				font-size: 16px;
			}
		}
		.campaign-preview-block {
			margin: 25px 25px 0 25px;
			border: 1px solid $mc-2;
			border-radius: 4px;

			@include bp(768px) {
				margin: 0px;
				margin-bottom: 15px;
			}
		}
		.campaign-preview-block-container {
			padding-left: 73px;
			padding-top: 15px;
			padding-bottom: 15px;
			position: relative;
			padding-right: 30px;
			@include bp(768px) {
				padding-left: 15px;
			}
		}

		.counter {
			width: 40px;
			height: 40px;
			background-color: white;
			color: $color-blue;
			border-radius: 100%;
			line-height: 40px;
			text-align: center;
			font-size: $large-font-size;
			position: absolute;
			left: 16.5px;

			@include bp(768px) {
				display: none;
			}

			border: 1px solid $color-blue;
		}
		.preview-content-label {
			color: $mc-5;
		}
		.preview-content-row {
			margin-bottom: 5px;
		}
		.preview-message-wrap {
			white-space: pre-wrap;
		}
		.campaign-edit {
			color: $color-blue;
			position: absolute;
			right: 20px;
			padding: 5px 15px;
			border-radius: 2px;
			cursor: pointer;

			@include bp(768px) {
				img {
					display: none;
				}
				right: 2px;
				top: 10px;
			}
		}
	}

	.sidebar-container {
		float: left;
		width: 25%;
		border-right: 1px solid $mc-4;
		margin-right: 25px;
		flex: 1;
		min-height: 200px;
	}
	.data-container {
		float: left;
		width: 75%;
		@include bp(768px) {
			width: 100%;
			box-sizing: border-box;
			margin-top: 25px;
		}
		// overflow: hidden;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.sub-content-preview {
	background: rgba(245, 247, 255, 0.5);
	max-width: 460px;
	border-radius: $default-radius;
	border: solid 0.5px rgba(0, 0, 0, 0.1);

	.sub-content-preview--title {
		padding: 10px;
		border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);
	}
	.sub-content-preview--content {
		padding: 5px 10px;
		color: $mc-5;
	}
}
.segment-filters-preview-item {
	margin-bottom: 5px;
	font-size: 14px;
	padding: 2px 0;
	.preview-item-title {
		display: inline-block;
		vertical-align: top;
		width: 25%;
	}
	.preview-item-value {
		display: inline-block;
		vertical-align: top;
		width: 75%;
	}
}

.show-more-filters-preview {
	margin-top: 5px;
	cursor: pointer;
	color: $mc-7;
}
.filters-preview-highlight {
	color: black;
	@extend .font--semibold;
}

.at-top-bar-wrapper {
	overflow-x: auto;
	white-space: nowrap;
}
.at-top-bar {
	padding-left: 20px;
	border-bottom: 1px solid $divider-color;

	@include bp(768px) {
		// border-bottom: 0px;
		padding-left: 0px;
	}

	height: 38px;
	box-sizing: border-box;
	.topbar-column {
		display: inline-block;
		padding: 11px 20px;
		position: relative;
		text-align: center;
		color: $mc-5;
		cursor: pointer;
		user-select: none;

		&:after {
			left: 0px;
			width: 100%;
			height: 1px;
			bottom: 0px;
			position: absolute;
			content: "";
			background: transparent;
			// border: 0.5px solid $color-blue;
		}
		@include bp(768px) {
			&:after {
				background: #ddd;
			}
		}
		&.selected {
			&:after {
				color: $color-blue;
				background: $color-blue;
			}
		}
	}
}

.campaign--input-with-label {
	.label {
		font-size: 12px;
		color: $mc-5;
		margin-bottom: 5px;
	}
	.input-block-container {
		width: 200px;
		.at-dropdown {
			width: 150px;
		}
	}
	.at-input-container .react-datepicker-wrapper input {
		font-size: $very-small-font-size;
	}
	margin-bottom: 15px;
	&.campaign-track {
		.input-block-container {
			width: auto;
		}
		.at-dropdown {
			display: inline-block;
			vertical-align: top;
			margin-right: 15px;
		}
		.inline-input {
			display: inline-block;
			vertical-align: top;
			height: 36px;
			width: 150px;
		}
	}
}

.cron-exp-container {
	& > span {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;

		& > span {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}
		// .at-dropdown{
		// 	width: 200px;
		// }
	}
}

.time-picker-campaign {
	& > div {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
}

.react-datepicker-popper {
	z-index: $zI-top + 1;
	ul.react-datepicker__time-list {
		padding-right: 0 !important;
	}
}
