.periscope-container {
	.popover-wrapper-container {
		.popover-container {
			.description {
				background-color: $mc-1;
				border-radius: $default-radius;
				padding: 12px;
				box-sizing: border-box;
				font-size: $very-small-font-size;
				color: $color-neutral-black-dark;
				line-height: 1.2;
				z-index: 10;
			}
		}
	}

	.periscope-activity-log {
		.activity-div-header {
			display: flex;
			justify-content: space-between;
		}

		.specificitemissue-platform-filter .placeholder,
		.select-filter-custom .dropdown-custom .container .option,
		.city-filter .react-select__single-value,
		.status-heading {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			color: #363636;
		}

		.status-main {
			display: flex;
			gap: 8px;

			.status-display {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				color: #8a8a8a;
			}
		}

		.filters-activity-log {
			gap: 8px;
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;

			.left-filters-log {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;
			}
		}

		.activity-log-table-div {
			.activity-log-table {
				border-spacing: 0;
				border-collapse: collapse;
				width: 100%;
				border: 1px solid #eaeaea;
				background: #ffffff;

				.log-thead {
					border: 1px solid #eaeaea;

					.table-header-log {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #8a8a8a;
						text-wrap: nowrap;
						padding: 16px 24px 16px 24px;
						text-align: left;
					}
				}

				.first-col-bold-text {
					color: #363636;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
					text-align: left;
				}

				.medium-bold-text {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #363636;
				}

				.status-log {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;

					.went-offline,
					.went-online,
					.removed {
						width: fit-content;
						padding: 2px 8px;
						border-radius: 2px;
						text-wrap: nowrap;
					}

					.went-online {
						background: #ecf7f0;
						color: #0b6e1b;
					}

					.removed {
						background: #f5f5f5;
						color: #646464;
					}

					.went-offline {
						background-color: #ffe5e5;
						color: #b02b2b;
					}
				}
			}

			.shimmer {
				border-radius: 0px;
			}
		}

		.brand-state-filters .dropdown-custom {
			width: 200px;
		}

		.form-sidebar-container {
			.header {
				width: 100%;
				padding: 0px;
			}
			.footer {
				padding: 12px;
			}
			.close-btn {
				width: auto;
			}

			.form-container {
				// padding: 20px 24px;
				.sidebar-activity-log {
					.header-sidebar {
						display: flex;
						justify-content: space-between;
						padding: 20px 24px;
						border-bottom: 1px solid #eaeaea;

						.heading-text {
							.link-text {
								display: flex;
								gap: 8px;
								align-items: center;
								.link {
									color: #646464;
									font-family: Source Sans Pro;
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									text-align: left;
								}
								.link-img {
									width: 6.31px;
									height: 10.12px;
								}
							}

							.title {
								margin-top: 6px;
								font-family: Source Sans Pro;
								font-size: 18px;
								font-weight: 600;
								line-height: 24px;
								text-align: left;
								color: #363636;
							}
						}
					}
					.sidebar-content {
						padding: 0px 24px 20px 24px;
						display: flex;
						flex-direction: column;
						gap: 12px;
						margin-top: 15px;

						.time-dropdown {
							width: 160px;
						}
						.metrics-info {
							display: flex;
							gap: 24px;
							.metrics-display {
								.icon-text {
									display: flex;
									gap: 4px;
									align-items: center;
									.icon-heading {
										font-family: Source Sans Pro;
										font-size: 14px;
										font-weight: 400;
										line-height: 22px;
										text-align: left;
										color: #646464;
									}
									.icon-img {
										width: 10px;
										height: 10px;
									}
								}
								.count {
									font-family: Source Sans Pro;
									font-size: 18px;
									font-weight: 600;
									line-height: 24px;
									text-align: left;
									color: #363636;
								}
							}
						}
					}
				}
			}
			.ls {
				// height: 14px;
				height: 13.34px;
				// background-color: green;
			}
		}
	}
}
