.form-filters-container {
	.section-1 {
		display: flex;
		gap: 12px;
		width: 50%;
	}
	display: flex;
	justify-content: space-between;
	margin-bottom: 35px;
	.section-2 {
		max-width: 272px;
		width: 30%;
	}
	.at-input-container {
		.at-input-label {
			text-transform: capitalize;
		}
	}
	.multi-select-filter-container {
		width: 50%;
		opacity: 1;
		.meta-info {
			font-weight: normal;
			color: $mc-5;
			margin-top: 3px;
			margin-bottom: 0;
			padding-bottom: 5px;
		}
	}
	.form-filter-btn {
		.container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			text-align: center;
			padding: 8px 16px;
			position: relative;
			margin-top: 20px;
			cursor: pointer;
			img {
				width: 16px;
			}
		}
		&.active {
			.container {
				border-color: #000;
				.filter-title {
					color: #000;
				}
			}
		}
		.filter-icon,
		.filter-title {
			display: inline-block;
			vertical-align: middle;
		}
		.filter-title {
			font-size: 15px;
			color: $mc-9;
			line-height: normal;
			margin-left: 8px;
			transition: 0.3s;
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
		&:hover {
			.filter-title {
				color: $mc-8;
			}
		}
	}
}
