.campaign-email {
	.template-opts {
		margin-bottom: 15px;
		.at-radiobutton {
			display: inline-block;
			margin-right: 15px;
		}
	}
}
.email-templates-container {
	text-align: center;
	.email-template {
		display: inline-block;
		width: 100px;
		cursor: pointer;
		.img-container {
			border: 1px solid $mc-5;
			height: 100px;
			margin: 10px;
			background: #f2f2f2;
		}
		.template-title {
			margin-top: 10px;
			font-size: 12px;
		}
	}
}
