.campaign-sms,
.campaign-email,
.campaign-app,
.campaign-web {
	width: 45%;
	@include bp(768px) {
		width: 100%;
	}
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-bottom: 5px;
	}
	.at-dropdown {
		margin-bottom: 15px;
	}
	.sms-meta {
		margin-top: 6px;
		.sms-count {
			float: left;
			font-size: 12px;
			color: $mc-5;
		}
		.gen-content {
			float: right;
			font-size: 12px;
			color: $color-blue;
			cursor: pointer;
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.sms-send-test {
		margin-top: 6px;
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			color: $color-blue;
		}
	}
	.show-notification {
		margin-top: 8px;
		.show-notification-checkbox {
			font-size: 12px;
		}
	}
}

.create-campaign-section {
	position: relative;
	min-height: 450px;

	.sms-content {
		// border: 1px solid;
		margin-top: 90px;
		margin-left: 40px;
		margin-right: 40px;
	}
	.meta-content {
		text-align: center;
		font-weight: 600;
		font-size: 12px;
		margin-bottom: 10px;
	}
	.sms-body-content {
		background: #f2f2f2;
		border: 1px solid #ddd;
		border-radius: 4px;
		padding: 6px;
		font-size: 12px;
		word-break: break-all;
		white-space: pre-wrap;
		min-height: 100px;
		max-height: 200px;
		overflow: auto;
	}
	.sms-content {
		margin-top: 90px;
		margin-left: 40px;
		margin-right: 40px;
	}
	.sms-preview-on-phone {
		@include bp(768px) {
			display: none;
		}
		position: absolute;
		bottom: 10px;
		right: 0px;
		background-image: url("/assets/icons/smartphone.png");
		height: 440px;
		width: 250px;
		background-size: 400px;
		background-position: bottom;
		background-repeat: no-repeat;
		&.sms-preview-on-phone--push-notification {
			background-size: 215px;
			background-image: url("/assets/icons/push-notification-tiny.png");
			.meta-content {
				color: #fff;
				margin-bottom: 50px;
			}
			.notification-top-bar {
				position: relative;
				padding: 4px;
				padding-left: 20px;
			}
			.icon {
				background-image: url("/assets/icons/up-logo.png");
				background-size: 100%;
				position: absolute;
				background-position: center;
				width: 15px;
				height: 15px;
				left: 3px;
				top: 3px;
				background-color: #3726af;
				background-repeat: no-repeat;
			}
			.notification-content {
				padding: 4px;
				padding-top: 0px;
			}
			.notification-banner {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
			.biz-name {
				font-weight: 600;
				margin-bottom: 2px;
				font-size: 12px;
			}
			.push-body-content {
				font-size: 12px;
				word-break: break-all;
				white-space: pre-wrap;
				background: #fff;
				position: relative;
				border-radius: 0px;
				min-height: 30px;
				max-height: 230px;
				overflow: auto;
			}
		}
	}
}
