.modifier-group-entity-association-container {
	.header {
		.header-right {
			display: flex;
			gap: 8px;
		}
	}
	.form-filters-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.at-input-container {
			width: 38%;
		}
		.container {
			border: 1px solid $mc-4;
			padding: 6px 16px;
			margin-top: 22px;
		}
	}
	.filter-section-item {
		&.multi-select-filter-container {
			margin-bottom: 20px !important;
		}
	}
	.selection {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: $very-small-font-size;
		margin-bottom: 15px;
		color: $mc-7;
		.at-checkbox {
			font-size: $very-small-font-size;
		}
	}
	.form-table {
		border: 1px solid $border-color;
		border-radius: $border-radius-default;
		.optionGroupTitle {
			width: 40%;
		}
		.crmTitle {
			width: 30%;
		}
		.modifiersCount {
			width: 25%;
		}
		.isVariant {
			width: 30%;
		}
		.secondary-name {
			font-size: 12px;
			color: $color-neutral-black-40;
			display: block;
			margin-top: 4px;
		}
	}
	.at-paginator {
		padding: 0;
	}
	.modifier-groups-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.modifier-group-item {
			width: 47%;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			padding: 10px;
			box-sizing: border-box;
			margin-bottom: 15px;
			font-size: $very-small-font-size;
			transition: 180ms;
			&.restriction {
				border: 1px solid $color-red;
			}
			.header-row {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
				.title {
					font-weight: 600;
				}
				.at-checkbox {
					color: transparent;
					font-size: 0;
				}
			}
			.selectable {
				color: $mc-5;
				margin-bottom: 10px;
				font-size: 12px;
			}
			.handle {
				font-size: 12px;
				color: $mc-5;
			}
		}
	}
}
