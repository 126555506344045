.modifier-groups-container {
	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}
	}
	.bulk-actions-container {
		margin-right: 10px;
		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.credits-section-header.transactions-list-header {
		.search-input-container {
			display: flex;
			.search-filter {
				width: 170px;
				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;
						img {
							padding-left: 0;
						}
					}
					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}
				.cancel-search {
					right: 24px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		z-index: $zI-level-1;
	}
	.modifier-groups-list-table-container {
		.transaction-header-row {
			top: 95.5px;
		}
		.name {
			width: 40%;
			padding-left: 25px;
			.name-desc {
				display: inline-block;
				width: calc(100% - 20px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.type {
			width: 20%;
			.type-desc {
				display: inline-block;
				width: calc(100% - 20px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.associated-items {
			width: 20%;
			text-align: right;
		}
		.modifiers {
			width: 20%;
			padding-right: 25px;
			text-align: right;
		}
	}
}

.modifier-group-edit-container {
	.item-error-msg-wrapper {
		margin: 12px 16px;
		margin-top: 0;
		border-radius: 2px;
		padding: 0 16px;
		display: flex;
		gap: 12px;
		align-items: center;
		p {
			color: $color-neutral-black-80;
			font-size: 14px;
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
	.associated-items-tab,
	.modifiers-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.modifier-groups-edit-associated-items-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.item-title {
				width: 60%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.item-desc {
					display: flex;
					.food-type {
						margin: auto 0;
					}
					.link-text {
						margin-left: 10px;
					}
				}
				.crm-title {
					color: $mc-5;
					padding-left: 22px;
				}
				.item_tags {
					padding-left: 23px;
					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}
			.category {
				width: 20%;
			}
			.price {
				width: 20%;
				padding-right: 25px;
				text-align: right;
			}
		}
		.modifier-groups-edit-modifiers-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 65px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.title {
				width: 60%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.title-desc {
					display: flex;
					.food-type {
						margin: auto 0;
					}
					.link-text {
						margin-left: 10px;
					}
				}
				.handle {
					color: $mc-5;
					padding-left: 23px;
				}
			}
			.price {
				width: 40%;
			}
		}
	}
	.associated-items-tab {
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.crm-title-search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
	}
	.modifiers-tab {
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
	}
}
