.modifiers-container {
	.action-buttons-container {
		margin-right: 10px;
	}
	.bulk-actions-container {
		margin-right: 10px;
		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
		}
	}
	.credits-section-header.transactions-list-header {
		.search-input-container {
			display: flex;
			.search-filter {
				width: 170px;
				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;
						img {
							padding-left: 0;
						}
					}
					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}
				.cancel-search {
					right: 24px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.modifiers-list-table-container {
		.name {
			width: 40%;
			padding-left: 25px;
			.name-desc {
				display: inline-block;
				width: calc(100% - 20px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.crm-title {
			width: 15%;
		}
		.associated-modifier-groups {
			width: 15%;
			text-align: right;
		}
		.associated-locations {
			width: 15%;
			text-align: right;
		}
		.price {
			width: 15%;
			padding-right: 25px;
			text-align: right;
		}
	}
}
.modifier-edit-container {
	.form-container {
		.form-content {
			padding: 0 25px 100px 25px;
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
	.custom-select-option {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.modifier-custom-title {
		display: flex;
		align-items: center;
		.food-type {
			height: 20px;
			width: 20px;
			margin-right: 10px;
			&:after {
				height: 10px;
				width: 10px;
			}
		}
	}
	.modifier-images-tab {
		.at-uploader-container-v2 {
			width: 50%;
		}
		.modifier-upload-container {
			width: 47%;
			margin-bottom: 15px;
			padding: 5px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-sizing: border-box;
			.uploader-area-container {
				width: 100%;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.multi-select-filter-container {
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
					margin-bottom: 0;
					padding-bottom: 5px;
				}
			}
			.at-btn {
				width: 100%;
				margin-top: 10px;
			}
		}
		.image-container {
			margin-bottom: 15px;
			.header-text {
				@extend .font--semibold;
				margin-bottom: 15px;
			}
			.images {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.image-modifier {
					width: 47%;
					padding: 5px;
					border: 1px solid $border-color;
					border-radius: $default-radius;
					margin-bottom: 15px;
					box-sizing: border-box;
					position: relative;
					.image {
						display: inline-block;
						width: 100%;
						height: 200px;
						border-radius: $default-radius;
						position: relative;
						overflow: hidden;
						box-sizing: border-box;
						background: #f2f2f2;
						z-index: 0;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						&:after {
							content: " ";
							white-space: pre;
							animation-duration: 2s;
							animation-fill-mode: forwards;
							animation-iteration-count: infinite;
							animation-name: placeHolderShimmer;
							animation-timing-function: ease-in-out;
							background: linear-gradient(
								to right,
								rgba(228, 226, 226, 0) 0%,
								#979797 50%,
								rgba(228, 226, 226, 0) 100%
							);
							background-size: 100%;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							position: absolute;
							background-repeat: no-repeat;
							will-change: transform, opacity;
							z-index: -1;
						}
					}
					.at-checkbox {
						margin-top: 15px;
						font-size: $very-small-font-size;
					}
					.platform-tag {
						margin-top: 5px;
						.title {
							font-size: 12px;
							color: $mc-7;
							margin-bottom: 5px;
						}
						.platform-value {
							display: flex;
							flex-wrap: wrap;
							padding: 5px 5px 0 5px;
							border: 1px solid $border-color-dark;
							border-radius: $default-radius;
							font-size: $very-small-font-size;
							text-transform: capitalize;
							.tag {
								padding: 4px 5px;
								margin: 0 5px 5px 0;
								color: #007eff;
								border: 1px solid rgba(0, 126, 255, 0.24);
								background-color: rgba(0, 126, 255, 0.08);
								border-radius: 20px;
								font-size: 12px;
							}
						}
					}
					.image-hover-icons {
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background: rgba(0, 0, 0, 0.3);
						color: white;
						display: flex;
						justify-content: space-evenly;
						align-items: center;
						border-radius: $default-radius;
						img {
							width: 30px;
							height: auto;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.associated-locations-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.filter-section {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 25px;
			.brand-selector {
				.filter-text {
					font-size: $very-small-font-size;
					font-size: 12px;
					color: $mc-5;
				}
				display: flex;
				align-items: center;
				.dropdown-custom {
					width: 220px;
					.container {
						height: 32px;
						padding: 1px 10px;
						border: 0px;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
					}
					.is-open {
						border-color: $color-blue;
					}
					.dropdown-options {
						overflow-x: hidden;
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $border-radius-default;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $border-radius-default;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
			.search-container {
				display: flex;
				gap: 12px;
				// justify-content: space-between;
				// margin-bottom: 35px;
				.at-input-container {
					width: 150px;
					.at-input {
						height: 36px;
					}
				}
				.multi-select-filter-container {
					width: 150px;
					opacity: 1;
					.meta-info {
						font-weight: normal;
						color: $mc-6;
					}
				}
			}
		}
		.toggle-item-availability {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 20px;
			.at-btn {
				margin-left: 10px;
			}
		}
		.locations-table-container {
			.transaction-header-row {
				top: -0.2px !important;
			}
		}
		.modifiers-edit-locations-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 70px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.at-table-header {
				&.name {
					display: flex;
					flex-direction: column;
					justify-content: center;
					opacity: 1;
					.checkbox-sort {
						position: relative;
					}
					.sort-button {
						position: absolute;
						height: 8px;
						margin-bottom: 4px;
						cursor: pointer;
						padding: 5px;
						left: -18px;
						top: -1px;
					}
					.at-checkbox {
						font-size: 12px;
						padding-bottom: 1px;
						.text {
							opacity: 0.6;
						}
						&.read-only {
							.text {
								opacity: 1;
							}
						}
					}
					.selected {
						.at-checkbox {
							.checkmark {
								border: 1px solid $color-blue;
							}
						}
					}
				}
			}
			.name {
				width: 40%;
				padding-left: 15px;
				.location-desc {
					display: flex;
					align-items: center;
					.availableAtLocation {
						padding: 1px 6px;
						margin: 0 5px;
						height: 12px;
						border-radius: 10px;
						text-align: center;
						font-size: 11px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 180ms;
						cursor: pointer;
						&[data-is-available="true"] {
							color: $color-green;
							border: 1px solid $color-green;
							background: rgba(46, 204, 113, 0.1);
						}
						&[data-is-available="false"] {
							color: $color-red;
							border: 1px solid $color-red;
							background: rgba(192, 57, 43, 0.1);
						}
						.loader {
							display: flex;
							position: relative;
							bottom: -3px;
							& > div {
								width: 3px;
								height: 3px;
								background: $mc-5;
								border-radius: 50%;
								margin: 3px;
								animation: loader 0.3s infinite alternate;
							}
							& > div:nth-child(2) {
								animation-delay: 0.1s;
							}
							& > div:nth-child(3) {
								animation-delay: 0.2s;
							}
							&.out {
								padding: 0 8px;
								& > div {
									background: $color-red;
								}
							}
							&.in {
								padding: 0 17px;
								& > div {
									background: $color-green;
								}
							}
						}
						@keyframes loader {
							to {
								transform: translate(0, -5px);
							}
						}
						.stock {
							display: flex;
							margin-left: 5px;
							cursor: pointer;
							padding: 1px 0;
							&.out {
								img {
									width: 10px;
									border: 1px solid $color-red;
									border-radius: 30px;
									background: rgba(192, 57, 43, 0.1);
								}
							}
							&.in {
								img {
									width: 10px;
									border: 1px solid $color-green;
									border-radius: 30px;
									background: rgba(46, 204, 113, 0.1);
								}
							}
						}
					}
				}
				.at-checkbox {
					font-size: $very-small-font-size;
					&.read-only {
						.location-desc {
							pointer-events: all;
						}
					}
				}
				.tags {
					margin-left: 25px;
				}
			}
			.city {
				width: 20%;
			}
			.stock-count {
				width: 18%;
			}
			.price {
				width: 22%;
				text-align: right;
				padding-right: 15px;
			}
		}
	}
	.associated-modifier-groups-tab,
	.nested-modifier-groups-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-right: 15px;
				padding-top: 5px;
				&:nth-child(3) {
					@extend .font--semibold;
				}
			}
		}
		.modifier-list {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
			padding: 20px;
			.header-container {
				margin-bottom: 10px;
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
			}
		}
	}
	.item-custom-fields-container {
		.custom-field-group {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding-bottom: 20px;
			margin-bottom: 25px;
			position: relative;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			.delete-grp-content {
				padding: 12px;
			}
			.group-title {
				display: flex;
				border-bottom: 1px solid $border-color;
				align-items: center;
				padding-bottom: 25px;
				margin-bottom: 10px;
				padding: 20px;
				background-color: rgba($mc-2, 0.6);
				.title {
					font-weight: 600;
					color: $mc-8;
					margin-right: 20px;
				}
				.key-input.at-input-container {
					width: 73.5%;
				}
				.delete-icon {
					width: 15px;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					cursor: pointer;
					padding: 5px;
					margin-bottom: 5px;
					align-self: flex-end;
					margin-left: auto;
				}
			}
			.key-value-pair {
				.header {
					padding: 15px 20px;
					display: flex;
					justify-content: space-between;
					.key-input.at-input-container {
						width: 40%;
					}
					.key-input--wide.at-input-container {
						width: 48%;
					}
					.delete-icon {
						width: 15px;
						border: 1px solid $border-color-dark;
						border-radius: $default-radius;
						cursor: pointer;
						padding: 5px;
						margin-bottom: 5px;
						align-self: flex-end;
					}
				}
				.description {
					padding: 5px 20px 20px 20px;
					.value-input.at-textarea-container {
						width: 100%;
					}
				}
			}
			.action-buttons {
				border-top: 1px solid $border-color;
				padding-top: 20px;
				margin-top: 20px;
				padding-left: 20px;
				.save-btn {
					margin-right: 10px;
				}
			}
		}
	}
	.at-modal {
		.modal-container {
			width: 70%;
			.modal-content {
				min-height: 50px;
			}
		}
		.modal-gap-correction {
			padding: 0 25px;
			color: $mc-5;
		}
	}
}
