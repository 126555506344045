.at-textarea-container {
	position: relative;
	.at-textarea-label {
		@extend .body-text3;
		font-size: 12px;
		display: inline-block;
		width: 100%;
		padding-bottom: 5px;
		color: $mc-5;
		.label-multi-lang-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				transition: 180ms;
				padding-right: 3px;
				&:nth-of-type(even) {
					cursor: pointer;
				}
				&:nth-last-child(1) {
					padding: 0;
				}
			}
			.selected {
				color: $color-blue;
				text-decoration: underline;
			}
			.info {
				width: 12px;
				margin: 0;
			}
		}
		.info {
			position: relative;
			top: 1px;
			width: 12px;
			margin-left: 5px;
		}
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.at-textarea {
		resize: vertical;
		min-height: 70px;
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		font-size: $very-small-font-size;
		border-radius: $default-radius;
		outline: none;
		border: 1px solid $border-color-dark;
		&::placeholder {
			color: #d2d2d2;
		}
	}
	.end-icon {
		position: absolute;
		right: 5px;
		top: 50%;
	}
	.end-icon-included {
		position: relative;
		padding-right: 24px;
	}
	.validation-message {
		margin-top: 1px;
	}
	&.at-textarea--save {
		.at-textarea {
			padding: 10px 62px 10px 10px;
		}
	}
	@keyframes animation-spin {
		to {
			transform: rotate(1turn);
		}
	}
	@-webkit-keyframes animation-spin {
		to {
			-webkit-transform: rotate(1turn);
		}
	}
	.loading-container {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		bottom: 4px;
		width: 16px;
		height: 70px;
		padding: 0 10px;
	}
	.at-textarea-loading {
		-webkit-animation: animation-spin 0.4s infinite linear;
		-o-animation: animation-spin 0.4s infinite linear;
		animation: animation-spin 0.4s infinite linear;
		width: 16px;
		height: 16px;
		box-sizing: border-box;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-right-color: #333;
		display: inline-block;
		position: relative;
		vertical-align: middle;
	}
	.at-btn {
		position: absolute;
		right: 0;
		bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 69.5px;
		width: 55px;
		margin: 0;
		border: 1px solid $mc-4;
		border-radius: 0 4px 4px 0;
		font-size: 11px;
		user-select: none;
		cursor: pointer;
		color: $mc-5;
		transition: 180ms;
		background: $mc-1;
		&.action-success {
			color: $color-green;
			background: #fff;
			border: 1px solid $color-green;
			pointer-events: none;
		}
		&:hover {
			opacity: 1;
			color: $mc-7;
		}
	}
	@-moz-document url-prefix() {
		.at-btn {
			bottom: 0.5px;
		}
	}
	.at-btn + .loading-container {
		right: 10px;
	}
}
