@keyframes slideUp {
	from {
		transform: translateY(20px);
	}
	to {
		transform: translateY(0px);
	}
}
.hub-config-section {
	min-height: 95vh;
	&.section-container-common {
		padding-bottom: 0;
		.filter-section-item {
			margin-bottom: 0;
		}
	}
	.header-action-button {
		display: flex;
		width: 500px;
		justify-content: flex-end;
		gap: 12px;
		margin-top: 10px;
		.multi-select-filter-container {
			width: 200px;
			.react-select__control {
				border-radius: 2px;
			}
		}
		.dropdown-custom {
			width: 200px;
			.container {
				height: 32px;
				padding: 1px 10px;
				border-radius: $border-radius-default;
			}
			.dropdown-component {
				z-index: 1000;
			}
			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}
			}
			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $border-radius-default;
					@extend .font--semibold;
					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $border-radius-default;
					}
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}
		@include bp(768px) {
			width: auto;
			padding: 0 5px;
		}
	}
	.hub-channels-navigation {
		display: flex;
		align-items: center;
		position: sticky;
		bottom: 20px;
		left: 50%;
		width: fit-content;
		padding: 7px 20px;
		margin: -20px 0 20px 0;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		box-shadow: $default-shadow;
		text-transform: capitalize;
		cursor: pointer;
		z-index: 20;
		@include bp(768px) {
			bottom: 80px;
			transform: translateX(-50%);
		}
		span {
			color: #fff;
			font-size: $small-font-size;
			font-weight: 600;
		}
		img {
			width: 17px;
			margin-right: 5px;
		}
		.channels-list {
			position: absolute;
			bottom: 125%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: $default-shadow;
			min-height: 150px;
			max-height: 400px;
			width: 200px;
			overflow-y: auto;
			color: $mc-7;
			padding: 5px 10px 5px 15px;
			font-size: $small-font-size;
			transition: 180ms;
			.channel-list-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				margin-bottom: 5px;
				padding: 1px 3px;
				&:hover {
					color: $mc-9;
				}
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
				&[data-active="true"]:before {
					content: " ";
					height: 6.5px;
					width: 6.5px;
					border-radius: 50%;
					background-color: $color-green;
					position: absolute;
					top: 50%;
					left: -4%;
					transform: translateY(-50%);
				}
				img {
					width: 18px;
					height: 18px;
					border-radius: $default-radius;
					object-fit: cover;
				}
			}
		}
	}
	.integrations-container {
		.header-text {
			color: $color-black-60;
			font-size: $medium-font-size;
			margin-bottom: 25px;
			@extend .font--semibold;
		}
		.status-info-wrapper {
			margin-bottom: 20px;
			svg {
				height: 22px;
				width: 22px;
			}
		}

		.status-alert-container {
			padding: 15px;
			margin: 24px 0;
			display: flex;
			align-items: flex-start;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;
			gap: 12px;
			.error-text-link {
				color: $color-dark-grey;
				.main-error-header {
					font-size: $medium-font-size;
					font-weight: $font--semibold;
					margin-bottom: 4px;
				}
				.main-error-desc {
					font-size: $very-small-font-size;
					margin-bottom: 0px;
				}
				.hyperlink {
					display: flex;
					align-items: center;
					gap: 4px;
					img {
						width: 16px;
						height: 16px;
					}
				}
			}
		}

		.dsps {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			gap: 15px;
		}
		.plf-card-container {
			display: flex;
			align-items: center;
			gap: 15px;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;
			box-sizing: border-box;
			min-height: 64px;
			width: 49%;
			transition: 180ms;
			padding: 15px;
			cursor: pointer;
			&:hover {
				background-color: $color-neutral-black-05;
			}
			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 64px;
					border-radius: $border-radius-default;
				}
			}
			.platform {
				display: flex;
				flex-direction: row;
				gap: 20px;
				flex-grow: 1;
				.link {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					.name {
						color: $color-black-80;
						font-size: $small-font-size;
						text-transform: capitalize;
						@extend .font--semibold;
					}
					img {
						opacity: 0.6;
						width: 7px;
					}
				}
				.info {
					display: flex;
					justify-content: right;
					justify-items: right;
					gap: 15px;
					.live-stores {
						display: flex;
						align-items: center;
						gap: 5px;
						font-size: $exta-small-font-size;
						color: $color-neutral-black-60;
					}
					.store-status {
						width: Hug (110px) px;
						height: Hug (20px) px;
						padding: 2px 8px 2px 8px;
						gap: 8px;
						border-radius: 4px;

						.text {
							font-family: Source Sans Pro;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							text-align: left;
						}
					}

					.store-status.store-status-success {
						background-color: $color-green-10;
						color: $color-green-70;
					}
					.store-status.store-status-inprogress {
						background-color: $color-yellow-10;
						color: $color-yellow-70;
					}
					.store-status.store-status-error {
						color: $color-red;
						background-color: rgba($color-red, 0.2);
					}
				}
			}
		}
	}
}
.hub-config-container {
	.at-top-bar-wrapper-v2 {
		z-index: $zI-level-1;
		margin-bottom: 25px;
	}
	.form-container {
		height: 100%;
		.form-content {
			min-height: 67vh;
			padding: 0 25px 15px 25px;
			@include bp(768px) {
				padding: 15px;
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		margin-bottom: 25px;
		&.automation {
			// max-width: 850px;
			margin: 0 auto 25px auto;
		}
		.card-header {
			padding: 15px 25px;
			display: flex;
			justify-content: space-between;
			.at-switch-cnt {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.meta-info {
					margin-right: 10px;
				}
				.at-switch {
					.switch-rail {
						margin: 2px 0;
					}
					.switch-ball {
						top: 4px;
					}
				}
				.validation-message {
					margin: 0 0 0 10px;
				}
			}
			.platform-info {
				display: flex;
				align-items: center;
				img {
					border-radius: $default-radius;
					margin-right: 10px;
					height: 24px;
				}
				span {
					font-size: $small-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}
			}
			&.non-platform {
				padding: 10px 25px 10px 22px;
			}
			.header-container {
				display: flex;
				align-items: center;
				@include bp(768px) {
					flex-wrap: wrap;
				}
				.app-icon {
					img {
						height: 80px;
					}
				}
				.app-header {
					display: flex;
					flex-direction: column;
					margin-left: 10px;
					flex-wrap: wrap;
					width: 100%;
					@include bp(768px) {
						width: 80%;
					}
					.header-text {
						font-size: $small-font-size;
						@extend .font--semibold;
					}
					.header-subtext {
						color: $mc-5;
						font-size: $very-small-font-size;
						padding-top: 5px;
					}
				}
				.slider-container {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 65%;
					@include bp(768px) {
						width: 100%;
						padding: 10px;
					}
					& > div {
						& > div {
							svg {
								circle {
									&:nth-child(1) {
										// visibility: hidden;
										opacity: 0.8;
									}
								}
							}
						}
					}
					.label-container {
						line-height: 1.15;
						-webkit-text-size-adjust: 100%;
						-webkit-tap-highlight-color: transparent;
						position: absolute;
						top: 29%;
						left: 29%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						width: 55px;
						color: $mc-9;
						z-index: 10;
						&.edit {
							top: 26%;
						}
						.label {
							cursor: default;
						}
						.slider-value {
							cursor: pointer;
							font-size: $medium-large-font-size;
							width: 30px;
							text-align: center;
							position: relative;
							&:hover {
								&::after {
									content: "";
									position: absolute;
									top: -2px;
									left: -13px;
									padding: 15px 0;
									width: 54px;
									border: 1px solid $border-color-dark;
									border-radius: $default-radius;
								}
							}
						}
						.at-input-container {
							width: 55px;
						}
					}
				}
				.actions-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-evenly;
					.at-btn {
						margin: 12px 0;
					}
					@include bp(768px) {
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						width: 100%;
						.at-btn {
							margin: 10px;
						}
					}
				}
			}
		}
		.form-container {
			padding: 25px 25px 0;
			border-top: 1px solid $border-color;
			.form-row {
				max-width: 970px;
				display: flex;
				flex-wrap: wrap;
				@include bp(1285px) {
					width: 100%;
				}
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				& > div {
					width: 29%;
					margin-right: 40px;
					margin-bottom: 30px;
					@include bp(1285px) {
						width: 35%;
					}
					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}
				.custom-switch {
					.at-switch-cnt {
						margin-bottom: 15px;
					}
					.meta-info {
						font-size: 12px;
						color: $mc-5;
						margin-top: 3px;
						padding-top: 3px;
					}
					.default {
						margin: 14px 0 12px 0;
						font-size: $small-font-size;
					}
					.link-text {
						font-size: $very-small-font-size;
					}
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}
	}

	.order-preparation-time {
		padding: 24px;
		.preparation-time-actions {
			display: flex;
			.action-info {
				display: flex;
				justify-self: flex-start;
				align-items: flex-start;
				gap: 5px;
				.preparation-time-icon {
					height: 20px;
					width: 20px;
				}
				.info-text-section {
					display: flex;
					flex-direction: column;
					gap: 6px;
					.header-text {
						color: $color-light-black;
						font-weight: 600;
					}
					.body-text {
						color: $color-dark-grey;
						font-size: $very-small-font-size;
					}
				}
			}
			.action-buttons {
				width: 40%;
				display: flex;
				gap: 12px;
				flex-wrap: wrap;
				justify-content: flex-end;
				.at-btn {
					width: 120px;
				}
			}
		}
		.prep-time-container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.prep-time-selector {
				width: 45%;
				border: 1px solid $border-color;
				margin-top: 24px;
				padding: 16px;
				@include bp(768px) {
					width: 100%;
				}
				.header {
					font-weight: 600;
					margin-bottom: 24px;
				}
				.at-input-container {
					margin-bottom: 24px;
				}
			}
		}
	}
	.automation-list-table-container {
		.transactions-list-table {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			.transaction-header-row {
				border-top: none;
				top: 95px;
			}
		}
		.at-switch-cnt {
			.at-switch {
				.switch-rail {
					margin: 2px 0;
				}
				.switch-ball {
					top: 4px;
				}
			}
		}
		.platform {
			width: 45%;
			padding-left: 25px;
			.platform-info {
				display: flex;
				align-items: center;
				img {
					border-radius: $default-radius;
					margin-right: 10px;
					height: 24px;
				}
				span {
					font-size: $small-font-size;
					text-transform: capitalize;
					@extend .font--semibold;
				}
			}
		}
		.auto-confirmation {
			width: 30%;
			.default {
				font-size: $small-font-size;
			}
			.link-text {
				font-size: $very-small-font-size;
			}
		}
		.auto-mfr {
			width: 25%;
			padding-right: 25px;
			text-align: right;
		}
	}
}

.UNSTransitionBanner {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		padding: 0;
		margin: 0;
	}

	position: relative;
	top: -5px;
}
