.developer-tools-section {
	&.section-container-common {
		padding-bottom: 0;
		.filter-section-item {
			margin-bottom: 0;
		}
	}
	.integrations-header {
		.highlight {
			@extend .font--semibold;
		}
	}
	.header-action-button {
		width: 250px;
		margin-top: 10px;
		@include bp(768px) {
			width: auto;
			padding: 0 5px;
		}
	}
	.at-top-bar-wrapper-v2 {
		&.wrapper-sticky-top {
			z-index: 100;
		}
	}
}
.developer-tools-container {
	.form-container {
		height: 100%;
		.status-message-container {
			padding: 25px;
			text-align: center;
			margin: 50px 25px;
			border-radius: $default-radius;
		}
		.status-success {
			background-color: rgba($color-green, 0.1);
			color: $color-green;
			border: 1px solid $color-green;
		}
		.status-failure {
			background-color: rgba($color-red, 0.1);
			color: $color-red;
			border: 1px solid $color-red;
		}
		.form-content {
			padding: 0 25px 15px 25px;
			@include bp(768px) {
				padding: 15px;
			}
		}
		.footer {
			border-top: 1px solid $border-color;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			padding: 20px 25px;
			background: white;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in;
			position: sticky;
			bottom: 0;
		}
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		margin: 25px;
		.card-header {
			padding: 15px 25px;
			display: flex;
			justify-content: space-between;
			.pos-info {
				font-size: $small-font-size;
				text-transform: capitalize;
				@extend .font--semibold;
			}
		}
		.form-container {
			padding: 25px 25px 0;
			border-top: 1px solid $border-color;
			.form-row {
				max-width: 970px;
				display: flex;
				flex-wrap: wrap;
				@include bp(1285px) {
					width: 100%;
				}
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
				}
				&:nth-last-child(1) {
					margin-bottom: 5px;
				}
				& > div {
					width: 29%;
					margin-right: 40px;
					margin-bottom: 30px;
					@include bp(1285px) {
						width: 35%;
					}
					@include bp(768px) {
						width: auto;
						margin-right: 0;
					}
				}
				.at-json-input-container {
					width: 100%;
				}
				.no-items-placeholder {
					flex-grow: 1;
					margin-right: 0;
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
				.dropdown-custom {
					.container {
						height: 32px;
						padding: 1px 8px 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $default-radius;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $default-radius;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
	}
}
