.piper-academy {
	.header-container {
		padding: 15px 25px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.piper-academy-icon {
			position: relative;
			top: 2px;
			width: 50px;
			border: 1px solid $color-java;
			border-radius: 50%;
			background-color: rgba(24, 208, 178, 0.1);
		}
		.help-icon {
			position: relative;
			top: 2px;
			padding: 3px;
			border-radius: 50%;
			background-color: rgba($color: #6c717a, $alpha: 0.275);
			width: 50px;
			opacity: 0.375;
			text-align: center;
			display: block;
		}
		.settings-header {
			padding: 0;
			margin-left: 10px;
			span {
				@extend .font--regular;
			}
		}
	}
	.form-sidebar-container {
		.header-left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 0;
			.piper-academy-icon {
				position: relative;
				top: 2px;
				width: 50px;
				border: 1px solid $color-java;
				border-radius: 50%;
				background-color: rgba(24, 208, 178, 0.1);
			}
			.help-icon {
				position: relative;
				top: 2px;
				padding: 3px;
				border-radius: 50%;
				background-color: rgba($color: #6c717a, $alpha: 0.275);
				width: 45px;
				opacity: 0.375;
				text-align: center;
				display: block;
			}
			.settings-header {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;
				padding: 0;
				margin-left: 10px;
				span {
					@extend .font--regular;
				}
				.header-text {
					font-size: $medium-large-font-size;
				}
				.subheader-text {
					font-size: $very-small-font-size;
					color: $mc-7;
				}
			}
		}
		.wrapper-sticky-top {
			top: 0px;
		}
	}
	.iframe-container {
		display: flex;
		padding: 15px 25px;
		@include bp(768px) {
			flex-direction: column;
		}
		.video-container {
			display: flex;
			flex-direction: column;
			width: 100%;
			.video-player {
				position: relative;
				width: 100%;
				height: 0;
				padding-bottom: 56.25%;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					overflow: hidden;
					border-radius: $default-radius;
					border: 1px solid $mc-4;
					z-index: 1;
				}
				.loader-backdrop {
					background-color: transparent;
					z-index: 0;
					.loading-indicator-container {
						margin-top: 0px;
					}
				}
			}
			.video-info {
				margin-top: 20px;
				.title {
					font-size: $medium-font-size;
					margin-bottom: 15px;
					@extend .font--semibold;
				}
				.description {
					margin: 15px 0;
				}
				.chapters {
					margin-top: 10px;
					width: 70%;
					@include bp(768px) {
						width: 100%;
					}
					.chapter-info {
						display: flex;
						align-items: center;
						// justify-content: space-between;
						margin-bottom: 10px;
						.name {
							position: relative;
							padding-left: 15px;
							// &::before {
							// 	content: '';
							// 	position: absolute;
							// 	top: 6.5px;
							// 	left: 2px;
							// 	width: 6px;
							// 	height: 6px;
							// 	background-color: $color-java;
							// 	border-radius: 50%;
							// }
						}
						.timestamp {
							color: $color-blue;
							cursor: pointer;
							// margin-left: 15px;
							&:hover {
								text-decoration: underline dashed;
							}
						}
					}
				}
			}
		}
		.syllabus {
			width: 50%;
			height: fit-content;
			// max-height: 25vw;
			// overflow-y: auto;
			margin-left: 15px;
			border: 1px solid $mc-4;
			border-radius: $default-radius;
			// prevent scroll-chaining to parent elements
			overscroll-behavior: contain;
			@include bp(768px) {
				width: 100%;
				margin: 15px 0;
			}
			.at-accordion-container {
				.header {
					border-bottom: 1px solid $mc-4;
				}
				&:nth-last-of-type(1) {
					.header {
						border-bottom: none;
					}
					.lesson-container {
						&:last-child {
							border-bottom: none;
						}
					}
				}
				&.expanded {
					.header {
						border-bottom: 1px solid $mc-4;
					}
				}
			}
			.lesson-container {
				display: flex;
				align-items: center;
				padding: 15px;
				cursor: pointer;
				opacity: 0;
				transition: opacity ease-in 300ms;
				&:last-child {
					border-bottom: 1px solid $mc-4;
				}
				img {
					width: 20px;
				}
				.title {
					margin-left: 10px;
				}
				&:hover {
					background-color: $mc-2;
				}
				&.selected {
					background-color: rgba(47, 88, 242, 0.1);
					// background-color: rgba(24, 208, 178, 0.08);
					.title {
						color: $color-blue;
						@extend .font--semibold;
					}
				}
			}
			.contents {
				&.expanded {
					.lesson-container {
						opacity: 1;
					}
				}
			}
		}
	}
	.drawer-container {
		.header-container {
			padding: 25px;
			.header-text {
				font-size: $medium-large-font-size;
			}
			.subheader-text {
				font-size: $very-small-font-size;
				color: $mc-7;
			}
			img {
				width: 45px;
			}
		}
		.iframe-container {
			padding: 0 25px 25px 25px;
			flex-direction: column;
			.syllabus {
				width: 100%;
				margin: 15px 0;
			}
		}
	}
}
