.bizprofile-section {
	&.section-container-common {
		padding-bottom: 0;
	}
	.profile-container {
		.form-container {
			height: 100%;
			.form-content {
				padding: 10px 25px;
				@include bp(768px) {
					padding: 15px;
				}
			}
			.form-row {
				display: flex;
				justify-content: flex-start;
				max-width: 950px;
				margin-bottom: 30px;
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
					margin-bottom: 0;
				}
				&:nth-last-child(1) {
					margin-bottom: 10px;
				}
				&.row-full > div {
					width: 64%;
					&:nth-last-child(1) {
						margin-right: 0;
					}
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
					}
				}
				&.row-half > div {
					width: 30%;
					margin-right: 35px;
					&:nth-last-child(1) {
						margin-right: 0;
					}
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
						margin-right: 0;
					}
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						margin-bottom: 0;
						@include bp(768px) {
							margin-bottom: 30px;
						}
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
				.custom-meta-info {
					font-size: 12px;
					color: $mc-5;
					margin-bottom: 10px;
				}
			}
			.footer {
				border-top: 1px solid $border-color;
				border-bottom-left-radius: $default-radius;
				border-bottom-right-radius: $default-radius;
				padding: 20px 25px;
				background: white;
				box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
				z-index: 10;
				animation: slideUp 180ms;
				transition: 180ms ease-in;
				position: sticky;
				bottom: 0;
				&.exception {
					margin-left: 25%;
					border-left: 1px solid $border-color;
				}
			}
		}
		.card-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			margin-bottom: 25px;
			&.loading {
				opacity: 0.6;
				pointer-events: none;
			}
			.card-header {
				padding: 15px 25px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include bp(768px) {
					flex-direction: column;
					align-items: flex-start;
				}
				.header-container {
					display: flex;
					align-items: center;
					.app-icon {
						img {
							height: 40px;
						}
					}
					.app-header {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						flex-wrap: wrap;
						.header-text {
							font-size: $small-font-size;
							@extend .font--semibold;
						}
						.header-subtext {
							color: $mc-5;
							font-size: $very-small-font-size;
							padding-top: 5px;
						}
					}
				}
				.at-switch-cnt,
				.multi-select-filter-container {
					display: flex;
					align-items: center;
					padding-left: 100px;
					margin: 0;
					.meta-info {
						margin-right: 10px;
					}
					.at-switch {
						.switch-rail {
							margin: 2px 0;
						}
						.switch-ball {
							top: 4px;
						}
					}

					.at--dropdown {
						width: 230px;
					}
					.meta-info {
						margin-right: 10px;
					}
					.validation-message {
						margin: 0 0 0 10px;
					}
					@include bp(768px) {
						padding-left: 0px;
						margin-top: 25px;
					}
				}
				.actions {
					display: flex;
					align-items: center;
					.at-switch-cnt,
					.multi-select-filter-container {
						padding-left: 30px;
						@include bp(768px) {
							.at--dropdown {
								width: 100%;
							}
							width: 100%;
							padding-left: 0;
						}
					}
					@include bp(768px) {
						width: 100%;
						flex-direction: column;
						margin-top: 5px;
					}
				}
			}
			.config-form-container {
				.note {
					display: flex;
					align-items: center;
					color: $color-yellow-dark;
					background-color: rgba($color: $color-yellow-dark, $alpha: 0.1);
					border-radius: $default-radius;
					font-size: $very-small-font-size;
					padding: 6px;
					margin-bottom: 25px;
					width: fit-content;
					svg {
						width: 16px;
						height: 16px;
						margin-right: 5px;
					}
				}
				.empty-config-message {
					color: $mc-5;
					text-align: center;
					padding-bottom: 24px;
				}
				padding: 25px 25px 0;
				border-top: 1px solid $border-color;
				.shimmer {
					&:nth-last-child(1) {
						margin-bottom: 25px;
					}
				}
				.config-form-row {
					max-width: 980px;
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 30px;
					@include bp(1265px) {
						width: 100%;
					}
					@include bp(768px) {
						max-width: 100%;
						flex-direction: column;
					}
					&:nth-last-child(1) {
						margin-bottom: 5px;
					}
					& > div {
						width: 29%;
						margin-right: 35px;
						margin-bottom: 30px;
						&:nth-last-child(1) {
							margin-right: 0;
						}
						@include bp(1265px) {
							width: 35%;
						}
						@include bp(768px) {
							width: auto;
							margin-right: 0;
						}
					}
					// override style for select filter component inside form
					.multi-select-filter-container {
						margin-bottom: 0;
						.at--dropdown {
							font-size: $very-small-font-size;
						}
						&.filter-section-item {
							opacity: 1;
							.meta-info {
								font-weight: 400;
								margin-bottom: 0;
								padding-bottom: 5px;
							}
						}
					}
				}
			}
		}
		.dropdown-custom {
			width: 230px;
			.container {
				height: 32px;
				padding: 1px 10px;
				border-radius: $border-radius-default;
			}
			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}
			}
			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;
				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $border-radius-default;
					@extend .font--semibold;
					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $border-radius-default;
					}
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}
	}
}

.bizprofile-header {
	padding: 25px;
	height: auto;
	overflow: hidden;
	@include bp(768px) {
		padding: 10px;
	}
	.header-details {
		display: flex;
		.profile-details {
			.header-text {
				font-size: $large-font-size;
				margin: 1px 5px;
				@extend .font--semibold;
			}
			.header-sub-text {
				font-size: $very-small-font-size;
				margin: 10px 5px;
				color: $mc-5;
			}
			span {
				border-radius: 4px;
				font-size: $very-small-font-size;
				background-color: #2f58f2;
				color: #fff;
				margin-left: 5px;
				padding: 1px 10px;
			}
		}

		.avatar {
			flex-shrink: 0;
			vertical-align: middle;
			margin-right: 10px;
			width: 90px;
			height: 90px;
			border-radius: 4px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			box-shadow:
				0 1px 3px rgba(0, 0, 0, 0.12),
				0 1px 2px rgba(0, 0, 0, 0.24);
			.avatar-edit {
				z-index: 9999;
				height: 45%;
				width: 45%;
				padding: 25px;
				position: relative;
				border-radius: 4px;
				display: none;
			}
			.loader {
				border: 16px solid #f3f3f3; /* Light grey */
				border-top: 16px solid #3498db; /* Blue */
				border-radius: 50%;
				width: 120px;
				height: 120px;
				animation: spin 2s linear infinite;
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
		.avatar:hover {
			.avatar-edit {
				background: rgba(0, 0, 0, 0.3);
				display: inline-block;
				cursor: pointer;
			}
		}
		.avatar-text {
			flex-shrink: 0;
			vertical-align: middle;
			margin-right: 10px;
			width: 90px;
			height: 90px;
			border-radius: 4px;
			background: #963fff;
			font-size: 60px;
			color: #fff;
			text-align: center;
			line-height: 90px;
			box-shadow:
				0 1px 3px rgba(0, 0, 0, 0.12),
				0 1px 2px rgba(0, 0, 0, 0.24);
			position: relative;
			.avatar-edit {
				z-index: 9999;
				height: 45%;
				width: 45%;
				padding: 25px;
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 4px;
				display: none;
			}
		}
		.avatar-text:hover {
			.avatar-edit {
				background: rgba(0, 0, 0, 0.3);
				display: inline-block;
				cursor: pointer;
			}
		}
	}
	.action-button {
		float: right;
		padding-top: 10px;
	}
}

.bizprofile-container {
	.form-container {
		padding: 25px;
		border-top: 1px solid $border-color;
	}
	.react-tagsinput-input {
		color: $mc-9;
		&::placeholder {
			font-family: "Source Sans Pro", sans-serif;
		}
	}
	.react-tagsinput-remove {
		margin-left: 5px;
	}
}

.at-input-container {
	.at-input-label {
		font-size: 12px;
	}
	.at-input {
		font-size: 14px;
	}
}

.business-profile-confirmation-modal {
	.at-modal {
		.modal-header {
			.header-left {
				.title {
					color: $color-light-black;
					text-align: left;
					font-size: 18px;
				}
			}
			border-bottom: 1px solid $border-color;
		}
		.modal-container {
			width: 30%;
			min-width: 500px;
		}
		.modal-footer {
			text-align: right;
			border-top: 1px solid $border-color;
		}
		.action-details {
			padding: 25px;
			.enable-action-warning {
				font-weight: $font--semibold;
				font-size: 18px;
				color: $color-light-black;
			}

			.confirm-process {
				margin-top: 15px;
				display: flex;
				gap: 13px;
				align-items: center;
				justify-content: center;
				.at-checkbox {
					position: relative;
					padding-left: 0;
					height: 20px;
					width: 20px;
					.checkmark {
						position: relative;
						display: block;
						height: 14px;
						width: 14px;
					}
				}
			}
		}
	}
}
