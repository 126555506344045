.copy-btn {
	position: relative;
	padding-left: 8px;
	padding-right: 8px;
	margin: 5px 10px;
	height: 24px;
	line-height: 22px;
	border: 1px solid $mc-4;
	border-radius: 2px;
	font-size: 11px;
	user-select: none;
	cursor: pointer;
	display: inline-block;
	color: $mc-5;
	transition: 180ms;
	text-align: center;
	background: $mc-1;
	box-sizing: border-box;
	img {
		width: 20px;
	}
	&.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3px 6px;
		border-radius: $default-radius;
		box-sizing: initial;
	}
	.copied {
		position: absolute;
		bottom: 30px;
		color: $color-green;
	}
}
.copy-success {
	color: $color-green;
	background: #fff;
	border: 1px solid $color-green;
	pointer-events: none;
}
