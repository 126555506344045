.cascade-price-entity-container {
	.search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.success-container {
		.success-message {
			margin: 25px;
			padding: 20px;
			font-size: $small-font-size;
			color: $color-green;
			border: 1px solid $color-green;
			border-radius: $default-radius;
			background-color: rgba(46, 204, 113, 0.1);
		}
		.buttons {
			width: 100%;
			padding: 20px 25px;
			margin-top: 45vh;
			box-sizing: border-box;
			background: white;
			box-shadow: -4px -2px 4px 0px rgba(0, 0, 0, 0.08);
			z-index: 10;
			animation: slideUp 180ms;
			transition: 180ms ease-in-out;
			* {
				margin: 10px;
			}
		}
	}
	.locations-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 70px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 40%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
			.tags {
				padding-left: 25px;
			}
		}
		.city {
			width: 20%;
		}
		.price {
			width: 20%;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}
