.periscope-container {
	.periscope-early-access {
		.early-access-container {
			background: #f2f5ff;
			width: 100%;
			display: flex;
			justify-content: space-between;
			position: relative;
			overflow: hidden;
			border-top: 1px solid #eaeaea;

			.early-access-text {
				flex-wrap: wrap;
				max-width: 374px;
				margin: auto 30px auto 57px;
				display: flex;
				gap: 40px;

				.info-text {
					display: flex;
					flex-direction: column;
					gap: 4px;

					.heading-text {
						font-size: 28px;
						font-weight: 600;
						line-height: 36px;
						text-align: left;
						color: #363636;
						text-wrap: balance;
					}

					.para-text {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #363636;
					}

					.free-access {
						font-size: 12px;
						font-weight: 600;
						line-height: 18px;
						text-align: left;
						color: #2543b6;

						.access-text {
							margin-left: 4px;
						}
					}
				}

				.access-button {
					cursor: pointer;
					padding: 8px 16px 8px 16px;
					gap: 8px;
					border-radius: 2px;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					color: #ffffff;
					background: #3b5feb;
					border: none;
				}

				.verified {
					display: flex;
					flex-direction: row;
					gap: 8px;

					.verified-img {
						width: 22.75px;
						height: 22.75px;
						margin-top: 3px;
					}

					.verified-bold {
						font-size: 16px;
						font-weight: 600;
						line-height: 24px;
						text-align: left;
						color: #363636;
					}

					.verified-para {
						font-size: 12px;
						font-weight: 400;
						line-height: 16px;
						text-align: left;
						color: #363636;
					}
				}
			}

			.background-image {
				position: absolute;
				background-image: url(/assets/periscope/background-dotted.png);
				right: -22px;
				top: 15px;
				height: 100%;
				width: 520px;
			}

			.frame-girl {
				user-select: none;
				position: absolute;
				right: 20px;
				bottom: 0px;
				width: 205px;
				z-index: 2;
				height: 320px;
			}

			.early-access-images {
				user-select: none;
				flex-basis: 40%;
				background-position: right;
				z-index: 1;

				.first-set-images {
					display: flex;
					justify-content: right;
					padding: 0 50px 0 10px;
					gap: 20px;
					overflow: hidden;

					.mushroom-pizza {
						display: flex;
						flex-direction: column;
						align-self: flex-end;
						width: 225.01px;
						border-radius: 16.08px;
						background: #ffffff;
						box-shadow: 0px 0px 16.57px 0px #00000014;

						.deliver {
							display: flex;
							flex-direction: column;
							padding: 16.57px;
							gap: 22.09px;

							.mushroom-main {
								display: flex;
								gap: 5.52px;

								.mushroom-img {
									width: 22.09px;
									height: 22.09px;
									padding: 1.11px 1.11px 1.38px 1.38px;
								}

								.text {
									font-size: 16.57px;
									font-weight: 600;
									line-height: 20.82px;
									text-align: left;
								}
							}

							.location-ratings {
								display: flex;
								flex-direction: column;
								gap: 5.52px;

								.yellow-rating {
									display: flex;
									padding: 2.76px 5.52px 2.76px 5.52px;
									gap: 2.76px;
									background: #fef6e9;
									width: fit-content;
									border-radius: 2.76px;
								}

								.text {
									font-size: 13.11px;
									line-height: 17.48px;

									.normal-text {
										font-weight: 400;
									}

									.bold-text {
										font-weight: 600;
									}
								}
							}
						}

						.mushroom-ratings {
							display: flex;
							flex-direction: column;
							border-radius: 1.38px;
							padding: 16.57px;
							gap: 5.52px;
							border-top: 1.38px dashed #eaeaea;

							.view-ratings {
								display: flex;
								justify-content: space-between;

								.location-text {
									font-size: 13.8px;
									font-weight: 400;
									line-height: 22.09px;
								}
							}
						}
					}

					.right-side-images {
						display: flex;
						flex-direction: column;
						gap: 20px;

						.offers-coupon {
							box-shadow: 0px 0px 16.57px 0px #00000014;
							border-radius: 16.08px;
							background-color: #ffffff;
							opacity: 0.7;

							.orion-mall {
								display: flex;
								gap: 3.98px;
								padding: 11.94px;

								.mall-text {
									font-size: 11.94px;
									font-weight: 400;
									line-height: 15.92px;

									.bold-text {
										font-weight: 600;
									}
								}
							}

							.orion-discounts {
								padding: 11.94px;
								display: flex;
								gap: 11.94px;
								border-top: 0.99px dashed #eaeaea;

								.coupon-border {
									background: #f2f5ff;
									border: 1px dashed #6d7aa8;
									padding: 0 3.98px;
									border-radius: 1.99px;
									font-size: 9.95px;
									font-weight: 600;
									line-height: 15.92px;
									letter-spacing: 0.05em;
								}

								.offer-text {
									font-size: 9.95px;
									font-weight: 400;
									line-height: 15.92px;
								}
							}
						}

						.burger {
							display: flex;
							flex-direction: column;
							padding: 12.06px 16.62px 12.06px 16.62px;
							gap: 16.62px;
							border-radius: 16.08px;
							background: #ffffff;
							box-shadow: 0px 0px 16.62px 0px #00000014;

							.item-title {
								display: flex;
								align-items: center;
								gap: 5.54px;
							}

							.burger-img {
								width: 20.08px;
								height: 16.27px;
							}

							.burger-text {
								font-size: 16.62px;
								font-weight: 600;
								line-height: 20.89px;
								text-align: left;
								color: #363636;
							}

							.burger-info {
								display: flex;
								flex-direction: column;
								gap: 22.16px;

								.view-locations {
									color: #3b5feb;

									img {
										width: 12.51px;
										height: 9.79px;
									}
								}

								.availability {
									display: flex;
									width: fit-content;
									padding: 2.77px 5.54px 2.77px 5.54px;
									gap: 2.77px;
									border-radius: 2.77px;
									background: #ffe5e5;
								}

								.locations-text {
									font-size: 16.62px;
									line-height: 22.16px;
									text-align: left;
									gap: 3.98px;
									display: flex;

									.bold-text {
										font-weight: 600;
									}

									.normal-text {
										font-weight: 400;
									}
								}
							}
						}
					}
				}
			}

			.second-set-images {
				display: flex;
				justify-content: right;
				gap: 25px;
				padding: 25px 25px 0 25px;
				overflow: hidden;

				.city-centers {
					display: flex;
					flex-direction: column;
					gap: 25px;

					.city-center-mall-platform {
						width: 314.09px;
						background-color: #ffffff;
						border-radius: 16.08px;
						box-shadow: 0px 0px 16.57px 0px #00000014;

						.city-center {
							display: flex;
							align-items: center;
							padding: 16.08px;
							gap: 21.45px;

							.bold-text {
								font-size: 16.08px;
								font-weight: 600;
								line-height: 20.22px;
								color: #363636;
							}

							img {
								width: 18.76px;
								height: 15.75px;
							}
						}

						.all-platforms {
							display: flex;
							border-top: 1.34px dashed #eaeaea;

							.border-right {
								border-right: 1.34px dashed #eaeaea;
							}

							.platform {
								flex-basis: 25%;
								display: flex;
								flex-direction: column;
								align-items: center;
								padding: 16.08px 10.72px;
								gap: 5px;

								.green {
									background: #ecf7f0;
									color: #0b6e1b;
								}

								.red {
									background: #ffe5e5;
									color: #b02b2b;
								}

								.platform-star {
									display: flex;
									align-items: center;
									width: fit-content;
									gap: 5.36px;
									padding: 0px 5.36px;
									border-radius: 2.68px;

									img {
										width: 10.07px;
										height: 9.55px;
									}

									.rating-text {
										font-size: 13.4px;
										font-weight: 600;
										line-height: 16.08px;
										text-align: left;
									}
								}

								.platform-text {
									font-size: 13.4px;
									font-weight: 400;
									line-height: 21.45px;
									text-align: left;
								}
							}
						}
					}
				}

				.issues-info {
					border-radius: 13.24px;
					opacity: 0.7;
					background: #ffffff;
					box-shadow: 0px 0px 12.35px 0px #00000014;
					align-self: flex-end;
					width: fit-content;

					.info-text {
						display: flex;
						flex-direction: column;
						padding: 12.35px;
						gap: 8.23px;
						font-size: 10.29px;
						font-weight: 400;
						line-height: 16.47px;
						text-align: left;

						.justify-between {
							display: flex;
							justify-content: space-between;
							align-items: center;
							gap: 4.12px;

							.platform-img {
								width: 16.47px;
								height: 16.47px;
								object-fit: cover;
							}
						}

						.platform-info-display {
							display: flex;
							align-items: baseline;
							gap: 4.91px;

							.issue-image {
								width: 10.29px;
								height: 10.29px;
							}
						}
					}

					.mall-info {
						width: 183.22px;
						display: flex;
						gap: 4.12px;
						border-bottom: 1.38px dashed #eaeaea;
						padding: 9.49px 12.35px 9.49px 12.35px;

						.city-center-name {
							font-size: 12.35px;
							font-weight: 600;
							line-height: 15.53px;
							text-align: left;
							color: #363636;
						}
					}
				}

				.mall-platforms {
					box-shadow: 0px 0px 12.77px 0px #00000014;
					background: #ffffff;
					align-self: flex-start;
					min-width: 222.68px;
					border-radius: 16.08px;
					opacity: 0.7;

					.para-one {
						padding: 12.77px;
						gap: 17.03px;
						display: flex;
						justify-content: space-between;
						border-bottom: 1.06px dashed #eaeaea;

						.green-circle {
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background: rgb(16, 245, 16);
						}

						.active-menus {
							display: flex;
							align-items: center;
							gap: 4px;
							font-size: 10.65px;
							font-weight: 400;
							line-height: 12.77px;
							color: #363636;
						}
					}

					.header-mall {
						display: flex;
						gap: 4.26px;

						.storefront-mall {
							width: 16.47px;
							height: 16.47px;
						}

						.orion-text {
							font-size: 12.77px;
							font-weight: 600;
							line-height: 16.06px;
							color: #363636;
						}
					}

					.platforms-display {
						min-width: 222.68px;
						display: flex;
						flex-direction: column;
						padding: 12.77px;
						gap: 8.52px;
						border: 1.06px;
						opacity: 0.7;

						.all-platforms-between {
							display: flex;
							justify-content: space-between;

							.show-menu {
								display: flex;
								align-items: baseline;
								gap: 4.26px;
								font-size: 12.65px;
								font-weight: 600;
								color: #363636;

								.menu-icon {
									width: 9.62px;
									height: 7.53px;
								}
							}

							.view-platform {
								.plt-img {
									width: 17.03px;
									height: 17.03px;
									object-fit: cover;
									border-radius: 1px;
								}

								display: flex;
								gap: 4.21px;
								align-items: center;
								font-size: 10.65px;
								font-weight: 400;
								color: #363636;
							}
						}
					}
				}
			}
		}

		.details {
			display: flex;
			justify-content: space-around;

			.specific-one {
				display: flex;
				gap: 12px;
				flex-direction: column;
				padding: 20px 35px;

				.info-img {
					width: 24px;
					height: 24px;
				}

				.text {
					text-wrap: balance;

					.heading {
						font-size: 18px;
						font-weight: 600;
						line-height: 24px;
						color: #363636;
					}

					.info-text {
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #363636;
					}
				}
			}
		}
	}

	.early-access-header {
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		padding: 15px 25px 15px 25px;

		.header-text {
			font-size: 28px;
			font-weight: 600;
			line-height: 36px;
			color: #363636;
		}

		.enquiry {
			display: flex;
			align-items: center;
			gap: 4px;

			.questions {
				font-weight: 400;
				line-height: 22px;
				color: #646464;
			}

			.contact {
				cursor: pointer;
				font-weight: 600;
				line-height: 20px;
				color: #2f58f2;
			}
		}
	}

	.get-started-periscope {
		margin-bottom: 0;
		overflow: auto;

		.get-started-content {
			display: flex;
			align-items: center;
			padding: 80px 80px 120px 122px;
			justify-content: space-between;
			gap: 32px;

			.get-started-div {
				padding-top: 40px;
				padding-bottom: 40px;
				gap: 96px;
				justify-content: center;
				max-width: 368px;
			}

			.checkout-div {
				height: 472px;
				justify-content: space-between;
			}

			.get-started-div,
			.checkout-div {
				display: flex;
				flex-direction: column;
				max-width: 368px;
				gap: 28px;

				.location-info {
					display: flex;
					flex-direction: column;
					gap: 32px;

					.select-location {
						display: flex;
						flex-direction: column;
						color: #646464;
						gap: 4px;

						.location-get-started {
							font-size: 24px;
							font-weight: 600;
							line-height: 32px;
						}

						.location-text {
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
						}
					}

					.locations-btn {
						cursor: pointer;
						border: none;
						padding: 6px 24px 6px 24px;
						border-radius: 2px;
						background: #3b5feb;
						width: fit-content;
						font-size: 14px;
						font-weight: 600;
						line-height: 20px;
						color: #ffffff;
					}

					.selected-data,
					.checkout-data {
						display: flex;
						flex-direction: column;

						.options {
							display: flex;
							flex-direction: column;
							gap: 16px;

							.location-text {
								width: 315px;
								overflow: hidden;
								text-overflow: ellipsis;
								width: 315px;
								margin-right: 5px;
								text-wrap: nowrap;
							}

							.brand-text,
							.platform-text,
							.location-text {
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								text-align: left;
								color: #363636;
							}

							.selected-locations {
								.loc-view {
									padding: 8px;
									display: flex;
									justify-content: space-between;
									border: 1px solid #eaeaea;
								}

								.pen {
									cursor: pointer;
									width: 16px;
									height: 16px;
								}
							}

							.option-tag {
								font-size: 12px;
								font-weight: 400;
								line-height: 16px;
								color: #646464;
							}

							.details {
								.details-view {
									border: 1px solid #eaeaea;

									.brands,
									.platforms {
										display: flex;
										justify-content: space-between;
										padding: 12px;

										.plt-images {
											display: flex;
											cursor: pointer;
										}
									}

									.brands {
										border-bottom: 1px dashed #eaeaea;

										.brand-images {
											display: flex;
											cursor: pointer;
										}
									}
								}
							}

							.img-outer-div {
								width: 20px;
								height: 20px;
								background: #ffffff;
								z-index: 1;
								border-radius: 50%;
								margin: 0 -3px 0 0;
							}

							.img-inside-div {
								width: 20px;
								height: 20px;
								border-radius: 50%;
								margin-left: 3px;
							}

							.img-text-inside-div {
								text-align: center;
								font-weight: 600;
								padding: 1px 2px 1px 0px;
								border-radius: 50%;
								margin-left: 3px;
							}
						}

						.brand-platform-details {
							position: relative;
						}

						.brand-plt-display {
							position: absolute;
							width: 160px;
							box-shadow: 0px 0px 12px 0px #00000029;
							z-index: 100;
							right: 0;
							margin-top: 10px;

							background-color: white;

							.heading {
								color: #666666;
								font-size: 12px;
								font-weight: 600;
								line-height: 18px;
								text-align: left;
								padding: 8px 12px 0px 12px;
							}

							.brand-text,
							.plt-text {
								color: #646464;
								font-size: 12px;
								font-weight: 400;
								line-height: 16px;
								text-align: left;
							}

							.brand-text {
								padding: 4px 12px;
							}

							.plt-text {
								padding: 4px 12px 8px 12px;
							}

							.brands-board {
								border-bottom: 1px solid #eaeaea;
							}
						}

						.brand-plt-display::after {
							content: "";
							position: absolute;
							bottom: 100%;
							right: 10%;
							margin-left: -5px;
							border-style: solid;
							border-width: 10px;
							border-color: transparent transparent white transparent;
						}

						.btn-info {
							display: flex;
							justify-content: space-between;

							.continue {
								transition: 180ms;
								border: none;
								padding: 6px 24px;
								background: #3b5feb;
								width: fit-content;
								border-radius: 2px;
								color: #ffffff;
								height: fit-content;
								cursor: pointer;
								&:hover {
									opacity: 0.6;
								}
							}
							.btn-loading {
								opacity: 0.6;
							}
						}
					}

					.selected-data {
						gap: 40px;
					}

					.checkout-data {
						.btn-info {
							.continue {
								margin-top: 35px;
							}
						}
					}
				}

				.plan-view {
					display: flex;
					flex-direction: column;

					.main-font {
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						color: #363636;
					}

					.text-display {
						font-size: 12px;
						font-weight: 400;
						line-height: 16px;
						color: #646464;
					}
				}
			}
		}

		.select-location-img {
			align-self: flex-start;
		}

		.terms {
			width: 264px;
			font-size: 12px;
			line-height: 16px;
			color: #646464;
			margin: auto auto 20px auto;

			.bold-text {
				font-weight: 600;
				margin: 4px;
			}
		}
	}

	.show-locations-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 16px;

		.info-display {
			padding: 12px 16px 12px 16px;
			border-radius: 2px;
			border-left: 4px solid #3b5feb;
			background: #f2f5ff;
			gap: 12px;
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;

			.icon-image {
				width: 20px;
				height: 20px;
			}
		}
	}

	.periscope-intro-location {
		z-index: 71;
		position: fixed;
		background: #374f78;
		width: 284px;
		bottom: 20px;
		right: 58px;
		color: #ffffff;
		bottom: 15px;

		.periscope-intro-img {
			width: 284px;
			height: 164px;
		}

		.periscope-intro-text {
			padding: 16px;
			display: flex;
			gap: 16px;
			flex-direction: column;
			cursor: pointer;

			.know-more {
				display: flex;
				gap: 6px;
				align-items: center;

				.right-arrow {
					width: 12.05px;
					height: 9.43px;
				}
			}

			.text-display {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.intro-heading {
					font-size: 16px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}

				.into-text {
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					text-align: left;
				}
			}
		}

		.intro-location-container {
			position: relative;

			.close-img {
				position: absolute;
				left: 252px;
				top: 12px;
				cursor: pointer;
			}
		}
	}
}
