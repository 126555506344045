.customer-feedback-section {
	.at-top-bar-wrapper-v2 {
		margin-bottom: 15px;
		z-index: 50;
	}
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.header-actions {
			display: flex;
			align-items: center;
			.campaign-list-filter {
				margin-top: 10px;
				position: relative;
				.filter-count {
					background: $color-green;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
			.search-filter {
				position: relative;
				margin: 10px 10px 0 0;
			}
			.search-input-container {
				display: flex;
				.search-filter {
					width: 170px;
					margin: 10px 0 0 0;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 4px 4px 0;
						}
					}
					.cancel-search {
						right: 14px;
					}
				}
				.multi-select-filter-container {
					margin: 10px 0 0 0;
					width: 100px;
					.Select-control {
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
					}
				}
			}
		}
	}
	.date-filter {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: 25px;
		.date-filter-container {
			margin-bottom: 0;
			min-height: 265px;
			box-sizing: border-box;
		}
		.date-filter-action-container {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			@include bp(768px) {
				padding: 5px;
			}
			.date-filter-action {
				text-align: right;
				position: absolute;
				bottom: 10px;
				right: 10px;
				cursor: pointer;
				font-size: 12px;
				text-transform: uppercase;
				@extend .font--semibold;
				.apply {
					color: $color-blue;
					margin-right: 25px;
					display: inline-block;
					vertical-align: top;
				}
				.cancel {
					display: inline-block;
					vertical-align: top;
					color: $mc-5;
					transition: 180ms;
					&:hover {
						color: $mc-7;
					}
				}
			}
		}
		.calender-range-first,
		.calender-range-second {
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			@extend .react-datepicker--inline;
		}
	}
	.customer-feedback-container {
		.customer-feedback-list-container {
			.filters {
				display: flex;
				align-items: center;
				padding: 10px 25px;
				.dropdown-date-time-filter {
					margin-right: 10px;
				}
				.header-actions {
					margin-left: auto;
					.search-filter {
						margin: 0;
					}
				}
			}
		}
		.customer-feedback-list-table-container {
			.transaction-header-row {
				top: 95px;
			}
			.name {
				width: 15%;
				padding-left: 25px;
				.name-desc {
					display: inline-block;
					width: calc(100% - 20px);
					.desc-text {
						margin-top: 5px;
					}
				}
			}
			.created_at {
				width: 20%;
			}
			.comments {
				width: 20%;
				.choice-text {
					margin-bottom: 5px;
				}
				.comments-text {
					color: $mc-5;
					font-size: $very-small-font-size;
				}
			}
			.rating {
				width: 15%;
				display: flex;
				.rating-info {
					display: flex;
					.rating-icon {
						margin-right: 5px;
						img {
							width: 18px;
						}
					}
				}
				.reply-icon {
					position: relative;
					top: -2px;
					cursor: pointer;
					margin-left: 30%;
					img {
						width: 15px;
						padding: 3px;
						border: 1px solid $mc-4;
						border-radius: $default-radius;
						&:hover {
							border: 1px solid $mc-5;
							background-color: $mc-3;
						}
					}
				}
			}
			.order {
				width: 12%;
				.type {
					color: $mc-5;
					margin-top: 5px;
				}
			}
			.location {
				width: 18%;
				padding-right: 25px;
			}
		}
		.customer-feedback-nps-container {
			padding: 10px 25px;
			.dropdown-date-time-filter {
				margin-bottom: 25px;
			}
			.header-container {
				margin-bottom: 25px;
			}
			.title {
				font-size: $medium-large-font-size;
				margin-bottom: 5px;
				@extend .font--semibold;
			}
			.sub-title {
				font-size: $small-font-size;
				margin-bottom: 10px;
				color: $mc-6;
				.highlight {
					@extend .font--semibold;
					color: $mc-9;
				}
			}
			.no-items-placeholder {
				text-align: left;
				font-size: $small-font-size;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			.status-metrics-blocks-container {
				min-height: 140px;
				margin-bottom: 30px;
				.metric-block {
					display: inline-block;
					margin-right: 15px;
					padding: 20px 0px;
					background: $mc-2;
					width: 128px;
					background: $mc-2;
					border-radius: 2px;
					text-align: center;
					user-select: none;
					.value {
						padding: 5px;
						font-size: 24px;
						margin-bottom: 5px;
					}
					.percentage {
						font-size: 12px;
					}
					.display-name {
						font-size: 12px;
						color: $mc-8;
						&.promoter {
							color: $color-green;
						}
						&.passive {
							color: $color-yellow;
						}
						&.detractor {
							color: $color-red;
						}
					}
				}
			}
			.status-metrics-block-container,
			.frequency-block-container {
				margin-bottom: 30px;
			}
			.trends,
			.frequency {
				margin-bottom: 30px;
			}
			.rating-frequency-chart {
				border: dashed 1px rgb(204, 204, 204);
				padding: 2px;
			}
		}
	}
}

.replies-container {
	.form-sidebar-container {
		.title {
			margin: 0;
			.rating-info {
				display: flex;
				flex-direction: row;
				align-items: center;
				img {
					height: 48px;
					margin-right: 10px;
				}
				.rating {
					margin-bottom: 5px;
				}
				.rating-type {
					font-size: $very-small-font-size;
					color: $mc-7;
					@extend .font--regular;
				}
			}
		}
		.wrapper-sticky-top {
			top: 0px;
		}
	}
	.customer-comments {
		position: relative;
		padding: 35px 25px 30px 25px;
		background-color: $mc-2;
		border-left: 3px solid $color-blue;
		text-align: justify;
		margin-bottom: 30px;
		text-indent: 35px;
		.customer-name {
			margin-top: 15px;
			text-align: right;
			@extend .font--semibold;
		}
		&::before {
			content: open-quote;
			position: absolute;
			font-size: 100px;
			color: $color-blue;
			opacity: 0.2;
			top: 0px;
			left: -18px;
		}
	}
	.reply-action-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		.header-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 15px;
			border-bottom: 1px solid $border-color;
			.title {
				font-size: $small-font-size;
				@extend .font--semibold;
			}
			.multi-select-filter-container {
				width: 200px;
			}
		}
		.reply-body {
			padding: 15px;
			.at-input {
				margin-bottom: 10px;
			}
			.at-textarea {
				min-height: 150px;
			}
		}
	}
	.last-reply-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		margin-bottom: 30px;
		.header-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			border-bottom: 1px solid $border-color;
			background-color: $mc-2;
			font-size: $very-small-font-size;
		}
		.title {
			margin-right: 10px;
			@extend .font--semibold;
			span {
				margin-left: 5px;
				font-size: $very-small-font-size;
				color: $mc-5;
				@extend .font--regular;
			}
		}
		.reply-body {
			padding: 15px 15px 0 15px;
		}
		.subject-container,
		.message-container {
			display: flex;
			margin-bottom: 15px;
			text-align: justify;
		}
	}
}
