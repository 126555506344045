.global-error-modal {
	position: fixed;
	background: #c0392b;
	bottom: 10px;
	width: 350px;
	left: 50%;
	margin-left: -125px;
	padding: 10px;
	border-radius: 2px;
	z-index: 1003;
	box-sizing: border-box;
	@include bp(768px) {
		width: 100%;
		margin-left: 0px;
		border-radius: 0px;
		bottom: 0px;
		left: 0px;
		text-align: center;
	}
	// .header {
	// 	color: #e74c3c;
	// 	margin-bottom: 10px;
	// 	font-size: 16px;
	// }
	color: #fff;

	.error-message {
		font-size: 14px;
	}

	animation: slideUp 180ms;
	box-shadow: 1px 1px 5px rgba(45, 62, 79, 0.6);
}
.global-success-modal {
	@extend .global-error-modal;
	background: #2ecc71;
}

@keyframes slideUp {
	from {
		transform: translateY(300px);
	}

	to {
		transform: translateY(0px);
	}
}

.inline-error {
	background: #c0392b;
	padding: 10px;
	color: #fff;
	font-size: 13px;
	text-align: center;
	border-radius: 2px;
}

.inline-success {
	@extend .inline-error;
	background: #2ecc71;
}
