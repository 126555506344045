.top-perf-table-container {
	border: 1px solid $border-color;
	border-radius: $default-radius;
	@include bp(768px) {
		overflow: auto;
	}
	.at-table.at-table--4cols {
		@include bp(768px) {
			width: 500px;
			overflow: auto;
		}
		.striped {
			background: $mc-1;
		}
		.at-cell-text {
			border-bottom: 1px solid $border-color;
			min-height: 70px;
		}
		.at-header-text {
			background-color: #f8f8f8;
			color: rgba($color: $mc-9, $alpha: 0.6);
			border-bottom: 1px solid $border-color;
			height: fit-content;
		}
		.name {
			width: 20%;
			&.link-text {
				color: $color-blue;
				@extend .font--semibold;
			}
		}
		.count {
			width: 20%;
			text-align: right;
		}
		.sales {
			width: 20%;
			text-align: right;
		}
		.percentChange {
			width: 20%;
			text-align: center;
			.val-container {
				background-position: left;
				background-repeat: no-repeat;
				background-size: 12px;
				padding-left: 15px;
			}
			.positive {
				color: $color-java;
				background-image: url("/assets/icons/up.png");
			}
			.negative {
				color: $color-red;
				background-image: url("/assets/icons/down-tiny.png");
			}
		}
		.brands {
			display: flex;
			justify-content: space-between;
			width: 20%;
			padding-right: 25px;
			.brands-list {
				display: flex;
				flex-wrap: wrap;
				height: fit-content;
				.brand-initials {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					text-transform: uppercase;
					border-radius: $default-radius;
					margin-right: 15px;
					margin-bottom: 15px;
					cursor: default;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
					img {
						width: 24px;
						height: 24px;
						object-fit: cover;
						border-radius: $default-radius;
						margin: 0;
					}
				}
			}
		}
		&.single-brand {
			.name,
			.count,
			.sales,
			.percentChange {
				width: 25%;
			}
		}
	}
	&--empty {
		border-width: 0;
	}
}
