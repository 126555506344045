.categories-container {
	.placeholder-main {
		padding-bottom: 20px;
	}
	.header-text {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.action-buttons {
			display: flex;
			gap: 12px;
			.bulk-actions-container {
				margin-right: 0;
				.at-btn--secondary {
					&:hover {
						svg {
							path {
								fill: $color-blue;
							}
						}
					}
				}
			}
			.action-buttons-container {
				margin-right: 0;
			}
		}
	}
	.credits-section-header {
		.header-text {
			padding-bottom: 15px;
		}
		.header-action-button {
			display: flex;
			width: 100%;
			gap: 12px;
			.filter-buttons {
				.container {
					border-radius: 2px;
				}
				.dropdown-custom {
					width: 230px;
					.container {
						height: 32px;
						padding: 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $border-radius-default;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $border-radius-default;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
			.filter-section-2 {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.search-filter {
					margin-right: 0;
				}
				.multi-select-filter-container {
					margin-bottom: 0px;
					width: 210px;
				}
			}
		}
	}

	.action-buttons-container {
		margin-right: 10px;
	}
	.bulk-actions-container {
		margin-right: 10px;
		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
		}
	}
	.custom-category-sidebar-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.action-item {
			padding: 5px;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			height: 12px;
			width: 12px;
			// margin-left: 90px;
			box-sizing: content-box;
			cursor: pointer;
		}
	}
	.root-category-dip {
		height: 5px;
		display: flex;
		justify-content: center;
		position: relative;
		span {
			position: absolute;
			border: 1px solid $color-blue;
			border-radius: 50%;
			height: 7px;
			width: 7px;
			top: -0.5vh;
		}
		.left-circle {
			left: 0vw;
		}
		.split-line {
			width: 94%;
			height: 2px;
			background-color: $color-blue;
		}
		.right-circle {
			right: 0vw;
		}
	}
	.common-sidebar-container {
		margin-top: 25px;
		border-top: 1px solid $border-color;
		.sidebar-tabs-container {
			margin-right: 0;
		}
	}
	.at-side-bar {
		position: initial;
		top: initial;
		.no-items-placeholder {
			padding: 30% 0;
		}
	}
	.sidebar-content-container {
		padding: 25px 0 0 0;
	}
	.subcat-container {
		border-radius: $border-radius-default;
		margin: 0 25px;
		// box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.08);
		background-color: #fff;
		border: solid 1px rgba(0, 0, 0, 0.05);
		margin-bottom: 15px;
		@include bp(768px) {
			margin-left: 10px;
			margin-right: 10px;
			box-shadow: none;
		}
		.subcat-header {
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			padding: 15px;
			.subcat-name {
				.link-text {
					font-size: $very-small-font-size;
				}
				.items-count {
					font-size: 12px;
					margin-left: 10px;
					color: $mc-5;
				}
			}
			.subcat-actions {
				.action-item {
					padding: 5px;
					border: 1px solid $border-color;
					border-radius: $default-radius;
					height: 12px;
					width: 12px;
					margin-left: 10px;
					cursor: pointer;
				}
				.show-items .subcat-down-arrow {
					filter: brightness(0%);
					vertical-align: baseline;
					cursor: pointer;
					&:hover {
						transform: none;
					}
				}
			}
		}
		.subcat-items {
			// padding: 10px 15px;
		}
	}
	.categories-list-table-container {
		.transactions-list-table {
			margin-top: 0;
			border-radius: 0;
			border-right: 0;
			border-left: 0;
		}
		.transaction-rows {
			transition: 0s;
			position: relative;
			.round {
				position: absolute;
				border: 2px solid $color-blue;
				border-radius: 50%;
				height: 7px;
				width: 7px;
				top: 95%;
				right: 99.9%;
				//z-index: 1;
			}
			.round-top {
				position: absolute;
				border: 2px solid $color-blue;
				border-radius: 50%;
				height: 7px;
				width: 7px;
				top: -6%;
				right: 99.9%;
				//z-index: 1000000000;
			}
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.items {
			width: 40%;
			padding-left: 25px;
			@keyframes placeHolderShimmer {
				0% {
					transform: translateX(-100%);
					opacity: 0;
				}
				50% {
					transform: translateX(100%);
					opacity: 1;
				}
				100% {
					transform: translateX(-100%);
					opacity: 0;
				}
			}
			.item-image {
				height: 60px;
				width: 60px;
				display: inline-block;
				margin-right: 10px;
				vertical-align: top;
				border-radius: $default-radius;
				position: relative;
				overflow: hidden;
				box-sizing: border-box;
				background: #f2f2f2;
				z-index: 10;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				&:after {
					content: " ";
					white-space: pre;
					animation-duration: 2s;
					animation-fill-mode: forwards;
					animation-iteration-count: infinite;
					animation-name: placeHolderShimmer;
					animation-timing-function: ease-in-out;
					background: linear-gradient(
						to right,
						rgba(228, 226, 226, 0) 0%,
						#979797 50%,
						rgba(228, 226, 226, 0) 100%
					);
					background-size: 100%;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					background-repeat: no-repeat;
					will-change: transform, opacity;
					z-index: -1;
				}
			}
			.item-desc {
				display: inline-block;
				width: calc(100% - 70px);
				.food-type {
					vertical-align: middle;
					margin-right: 5px;
					margin-bottom: 3px;
				}
				.desc-text {
					margin-top: 5px;
					margin-left: 17px;
				}
			}
		}
		.associated_locations {
			width: 20%;
			text-align: right;
		}
		.brands {
			width: 30%;
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			.brand-image {
				height: 20px;
				width: 20px;
			}
		}
		.price {
			width: 10%;
			padding-right: 25px;
			text-align: right;
		}
	}
	.section-container-common {
		padding-bottom: 25px;
		.at-paginator {
			padding: 0 25px;
		}
	}

	.categories-section {
		.header-text-container {
			padding: 0 25px 25px 25px;
			color: $color-dark-grey;
			.header-text {
				font-size: $medium-font-size;
				font-weight: $font--semibold;
			}
			.header-subtext {
				font-size: $exta-small-font-size;
			}
		}
	}

	.uploader-area-container {
		width: auto;
	}
}

.category-edit-container {
	.form-container {
		.form-content {
			padding: 0 25px 100px 25px;
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
	.associated-items-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			// border: 1px solid $border-color;
			border-radius: $default-radius;
			// padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
			}

			.section-1 {
				display: flex;
				gap: 12px;
				.dropdown-custom {
					width: 200px;
					.container {
						border-radius: $border-radius-default;
						height: 32px;
						padding: 1px 10px;
						.custom-label {
							width: 160px;
							@include truncate_text;
						}
					}
					.dropdown-options {
						overflow-x: hidden;
						.option {
							width: 100%;
							height: 37px;
							padding: 1px 10px;
							@include truncate_text;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $border-radius-default;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $border-radius-default;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
				.multi-select-filter-container {
					.at--dropdown {
						width: 150px;
					}
				}
			}
			.section-2 {
				.at-input-container {
					width: 100%;
				}
			}
			.multi-select-filter-container {
				// width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.categories-edit-items-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.items {
				width: 40%;
				padding-left: 25px;
				display: flex;
				gap: 12px;
				.item-image {
					width: 64px;
					height: 64px;
				}
				.item-desc {
					display: flex;
					flex-direction: column;
					.food-type {
						margin-top: 3px;
					}
					.link-text {
						// margin-left: 10px;
						width: 100%;
						@include truncate_text;
					}
					.item-image {
						width: 64px;
						height: 64px;
					}
				}
				.crm-title {
					color: $mc-5;
					// padding-left: 22px;
				}
				.item_tags {
					// padding-left: 23px;
					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}
			.locationsCount {
				width: 20%;
			}
			.brands {
				width: 30%;
				display: flex;
				gap: 8px;
				flex-wrap: wrap;
				.brand-image {
					border-radius: 2px;
					height: 20px;
					width: 20px;
				}
			}
			.price {
				width: 10%;
				padding-right: 25px;
			}
		}
	}
	.alert {
		background-color: rgba(245, 166, 35, 0.05);
		border: 1px solid rgba(245, 166, 35, 0.4);
		padding: 15px;
		border-radius: $default-radius;
		margin-bottom: 20px;
		img {
			height: 18px;
			vertical-align: top;
			margin-right: 5px;
		}
		span {
			font-size: $very-small-font-size;
		}
	}

	.associated-subcat-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border-radius: $default-radius;
			margin-bottom: 35px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.form-table {
			border-right: 1px solid $border-color;
			border-left: 1px solid $border-color;
			border-top: 1px solid $border-color;
			.name {
				width: 50%;
				font-weight: $font--semibold;
				.at-checkbox {
					padding-left: 10px;
				}
			}
			.paginatedItems {
				width: 50%;
				position: relative;
				overflow: visible;
				.paginatedItems--container {
					width: 100%;
					.assoc-items-content {
						width: 90%;
						@include truncate_text;
					}
				}
				.action-item {
					color: $color-red;
				}
			}
		}
		.bulk-actions-container {
			position: absolute;
			width: 700px;
			top: 110px;
			// padding: 0 24px;
			display: flex;
			justify-content: space-between;
			.selected-entity-counter {
				color: $color-dark-grey;
				font-size: $very-small-font-size;
			}
			.actions-container {
				display: flex;
				gap: 12px;
				font-size: $very-small-font-size;
				.remove-loc {
					color: $color-red;
					&::after {
						background-color: $color-red;
					}
				}
			}
		}
	}

	.remove-categories-modal {
		.at-modal {
			.modal-header {
				.header-left {
					.title {
						color: $color-light-black;
						text-align: left;
						font-size: 18px;
					}
				}
			}
			.modal-container {
				width: 30%;
				min-width: 500px;
			}
			.modal-footer {
				text-align: right;
			}
			.remove-category-details {
				padding: 0 25px;
				.category-info {
					font-size: 18px;
					@extend .font--semibold;
					color: $color-light-black;
				}

				.warning {
					margin: 24px 0;
				}

				.confirm-process {
					display: flex;
					gap: 13px;
					.at-checkbox {
						position: relative;
						padding-left: 0;
						.checkmark {
							position: relative;
							display: block;
							height: 14px;
							width: 14px;
						}
					}
				}

				.end-result {
					display: flex;
					padding: 25px 0;
					border-radius: $border-radius-default;
					justify-content: center;
					&.success {
						background-color: rgba($color-green, 0.1);
						color: $color-green;
						border: 1px solid $color-green;
					}
					&.failure {
						background-color: rgba($color-red, 0.1);
						color: $color-red;
						border: 1px solid $color-red;
					}
				}
			}
		}
	}
}

.subcategories-association--sidebar {
	.form-container {
		.at-paginator {
			padding: 25px;
		}
	}
}
