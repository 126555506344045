.audit-events-container {
	.overlay-sidebar-container {
		&.content-show {
			scrollbar-width: auto;
			&::-webkit-scrollbar {
				display: block;
			}
		}
	}
	.at-copy-icon {
		position: relative;
		padding: 4px;
		transition: 300ms;
		top: 1px;
		margin-left: 5px;
		cursor: pointer;
		svg {
			g {
				g {
					transition: 300ms;
				}
			}
			height: 12px;
			width: 12px;
		}
		&:hover {
			border-color: $color-blue;
			svg {
				g {
					g {
						fill: $color-blue;
					}
				}
			}
		}
	}
	.audit-events-list-container {
		.custom-placeholder {
			padding: 30px 0;
			.message {
				font-size: 22px;
				margin-bottom: 10px;
			}
			.action {
				span {
					position: relative;
					color: $color-blue;
					text-transform: initial;
					cursor: pointer;
					&::after {
						content: "";
						position: absolute;
						width: 78px;
						transform: scaleX(0);
						height: 1px;
						bottom: 0;
						left: 0px;
						background-color: $color-blue;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}
					&:hover::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
				}
			}
		}
		.settings-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.campaign-list-filter {
				position: relative;
				margin-left: 10px;
				.filter-count {
					background: $color-blue;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
		}
		.search-input-container {
			display: flex;
			align-items: center;
			margin-left: auto;
			.search-filter {
				position: relative;
				width: 200px;
				margin: 0;
				.at-input-container {
					width: 100%;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 4px 4px 0;
						}
					}
				}
				.cancel-search {
					right: 12px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 150px;
				font-size: $very-small-font-size;
				.react-select__control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
		.note {
			display: flex;
			align-items: center;
			color: $color-yellow-dark;
			background-color: rgba($color: $color-yellow-dark, $alpha: 0.1);
			border-radius: $default-radius;
			font-size: $very-small-font-size;
			padding: 6px;
			margin: 0 25px;
			width: fit-content;
			svg {
				width: 16px;
				height: 16px;
				margin-right: 5px;
			}
		}
		.filters {
			display: flex;
			align-items: center;
			padding: 25px 25px 10px 25px;
			.dropdown-date-time-filter {
				margin-right: 10px;
			}
		}
		.at-accordion-container {
			background-color: $mc-1;
			// &:nth-of-type(odd) {
			// 	background-color: $mc-2;
			// }
			&:hover {
				background-color: #f5f6ff;
			}
			&.view {
				background-color: #f5f6ff;
				.accordion-row {
					position: relative;
					&::before {
						content: "";
						position: absolute;
						width: 3px;
						height: 100%;
						top: 0;
						left: 0;
						background-color: $color-blue;
					}
				}
			}
			.contents {
				overflow-y: auto;
				max-height: 360px;
				background-color: $mc-1;
				border-bottom: 1px solid $border-color;
				box-shadow:
					inset 0 12px 15px -16px rgba(0, 0, 0, 0.25),
					inset 0 -12px 15px -16px rgba(0, 0, 0, 0.25);
				.transaction-rows {
					padding-top: 12px;
					padding-bottom: 12px;
					background-color: transparent;
					margin-left: 23px;
					border-left: 1px solid $border-color-dark;
					position: relative;
					&:hover {
						&::before {
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: -23px;
							padding-right: 23px;
							background-color: rgba(47, 88, 242, 0.05);
						}
					}
					&.view {
						&::before {
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: -23px;
							padding-right: 23px;
							background-color: rgba(47, 88, 242, 0.05);
							border-left: 3px solid $color-blue;
						}
					}
					.event {
						position: relative;
						width: 18%;
						padding-left: 25px;
						&::before {
							content: "";
							position: absolute;
							top: 15px;
							left: 0;
							width: 15px;
							height: 1px;
							background-color: $border-color-dark;
						}
						&::after {
							content: "";
							position: absolute;
							top: 14px;
							left: 13px;
							width: 3px;
							height: 3px;
							border-radius: 50%;
							background-color: $border-color-dark;
						}
					}
					.source,
					.destination {
						text-transform: capitalize;
					}
					.done-by {
						.name {
							text-transform: capitalize;
						}
					}
				}
				.loader {
					margin-left: 23px;
					border-left: 1px solid $border-color-dark;
				}
				&.loading {
					.transaction-rows,
					.loader {
						opacity: 0.6;
					}
				}
				&.expanded {
					.no-items-placeholder {
						margin-left: 23px;
						border-left: 1px solid $border-color-dark;
					}
				}
			}
		}
		.audit-events-list-table-container {
			.event {
				display: flex;
				width: 20%;
				padding-left: 15px;
				.event-title {
					display: flex;
					flex-direction: column;
				}
				.info {
					display: flex;
					flex-direction: column;
					color: $mc-5;
					.trace-id-container {
						display: flex;
						align-items: center;
						margin-top: 6px;
						.trace-id {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							@extend .font--semibold;
						}
						.at-copy-icon {
							padding: 0;
						}
					}
					.events-count {
						margin-top: 5px;
					}
				}
			}
			.time-stamp {
				width: 10%;
				.view {
					display: flex;
					align-items: center;
					position: relative;
					left: -4px;
					margin-top: 6px;
					color: $mc-7;
					background-color: rgba($color: $mc-9, $alpha: 0.05);
					width: fit-content;
					padding: 2px 4px;
					border-radius: $default-radius;
					svg {
						margin-right: 5px;
					}
				}
			}
			.location {
				width: 13%;
			}
			.brand {
				width: 12%;
			}
			.done-by {
				width: 15%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.name {
					text-transform: capitalize;
				}
				.username {
					margin-top: 6px;
					color: $mc-5;
				}
			}
			.source {
				width: 10%;
				white-space: nowrap;
			}
			.destination {
				width: 10%;
				white-space: nowrap;
			}
			.status {
				width: 10%;
				padding-right: 25px;
				text-align: right;
				span {
					text-transform: uppercase;
					padding: 4px 8px;
					border-radius: $default-radius;
					font-weight: 600;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.success {
					color: #0f5c2a;
					background-color: #ecf7f0;
				}
				.error {
					color: #961d1d;
					background-color: #ffe5e5;
				}
				.failed {
					color: #961d1d;
					background-color: #ffe5e5;
				}
				.failure {
					color: #961d1d;
					background-color: #ffe5e5;
				}
			}
			.transaction-header-row {
				.event {
					padding-left: 42px;
				}
			}
			.accordion-row {
				.at-table-cell {
					&.source,
					&.destination {
						text-transform: capitalize;
					}
				}
				&.expanded {
					// trail
					&::after {
						content: "";
						position: absolute;
						left: 23px;
						top: 35px;
						width: 1px;
						height: 100px;
						background-color: $border-color-dark;
					}
				}
			}
		}
	}
	.audit-events-preview-container {
		.header-left {
			.title {
				text-transform: capitalize;
			}
		}
		.event-table-container {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			position: relative;
			.table-header {
				position: sticky;
				top: 0;
				z-index: 100;
				.table-row {
					display: flex;
					align-items: center;
					text-transform: uppercase;
					font-size: 12px;
					color: #666666;
					padding: 10px 15px;
					background-color: #f8f8f8;
					border-bottom: 1px solid $border-color;
					@extend .font--semibold;
				}
			}
			.table-body {
				.table-row {
					display: flex;
					box-sizing: border-box;
					flex-grow: 1;
					width: 100%;
					overflow: hidden;
					list-style: none;
					font-size: $very-small-font-size;
					padding: 15px;
					min-height: 60px;
					border-bottom: 1px solid $border-color;
					&:nth-of-type(even) {
						background-color: $mc-2;
					}
					&:last-child {
						border-bottom: none;
					}
					&:hover {
						background-color: rgba(47, 88, 242, 0.05);
					}
					.key {
						text-transform: capitalize;
						&.url {
							text-transform: uppercase;
						}
					}
				}
			}
			.key {
				width: 25%;
				@extend .font--semibold;
			}
			.value {
				width: 75%;
				a {
					text-decoration: none;
					color: $color-blue;
					word-break: break-all;
				}
				.status {
					padding: 0px 6px;
					border-radius: $default-radius;
					@extend .font--semibold;
					&.success {
						background-color: rgba($color-green-dark, 0.1);
						color: $color-green-dark;
					}
					&.error {
						background-color: rgba($color-red-dark, 0.1);
						color: $color-red-dark;
					}
				}
				&.trace-id {
					span {
						font-family: monospace;
						font-weight: bold;
					}
				}
				&.method {
					span {
						padding: 0px 6px;
						border-radius: $default-radius;
						background-color: rgba($color-blue-dark, 0.1);
						color: $color-blue-dark;
						text-transform: uppercase;
						@extend .font--semibold;
					}
				}
				&.root {
					span {
						padding: 0px 6px;
						border-radius: $default-radius;
						background-color: rgba($color-java, 0.1);
						color: $color-java;
						text-transform: capitalize;
						filter: brightness(0.95);
						@extend .font--semibold;
					}
				}
				&.source,
				&.destination,
				&.purpose,
				&.user {
					span {
						text-transform: capitalize;
					}
				}
			}
			.react-json-view {
				background-color: $mc-1 !important;
			}
		}
	}
}
