.section-header {
	padding: 25px;
	display: flex;
	@include bp(768px) {
		padding: 15px 5px;
	}
	.left {
		font-size: $large-font-size;
	}
}
