.item-location-customization-container {
	.item-location-custom-title {
		display: flex;
		align-items: center;
		.food-type {
			height: 20px;
			width: 20px;
			&:after {
				height: 10px;
				width: 10px;
			}
		}
		.item-title {
			margin-left: 10px;
		}
	}
	.modifier-group-location-container {
		margin-bottom: 25px;
		.modifier-group-type {
			color: $mc-8;
			margin-bottom: 5px;
		}
		.modifier-group-title {
			font-size: $very-small-font-size;
			color: $mc-6;
		}
		.modifier-location-price-table-container {
			.transactions-list-table {
				margin-top: 10px;
			}
			.transaction-header-row {
				top: 0;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-header-text {
				opacity: initial;
				color: $mc-7;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 55%;
				padding-left: 25px;
				display: flex;
				align-items: flex-start;
				.availableAtLocation {
					padding: 1px 6px;
					margin-left: 5px;
					border-radius: 10px;
					text-align: center;
					font-size: 11px;
					&[data-is-available="true"] {
						color: $color-green;
						border: 1px solid $color-green;
						background: rgba(46, 204, 113, 0.1);
					}
					&[data-is-available="false"] {
						color: $color-red;
						border: 1px solid $color-red;
						background: rgba(192, 57, 43, 0.1);
					}
				}
			}
			.currentStockAtLocation {
				width: 20%;
			}
			.priceAtLocation {
				width: 25%;
				padding-right: 25px;
			}
		}
	}
}
