.at-multi-actions-button-container {
	display: flex;
	position: relative;
	.button-container {
		padding: 0px 12px 0px 16px;
		height: 36px;
		transition: all 500ms ease-in-out;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: $border-radius-default;
		background-color: $color-blue;
		color: white;
		cursor: pointer;
		.button-title {
			height: 24px;
			font-size: $very-small-font-size;
			line-height: 24px;
		}
		.chevron-container {
			height: 24px;
			width: 18px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: relative;
			svg {
				transition: all 300ms ease-in-out;
				// position: absolute;
				path {
					fill: white;
				}
				// top: 2px;
				// left: 5px;
				height: 16px;
				width: 16px;
			}
		}
	}
	.actions-container {
		position: absolute;
		background-color: white;
		color: $color-neutral-black-80;
		top: 40px;
		right: 0;
		z-index: 200;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
		min-width: 100%;
		width: auto;
		height: auto;
		.action {
			white-space: nowrap;
			font-size: $very-small-font-size;
			line-height: 22px;
			padding: 6px 12px;
			transition: all 300ms ease-in-out;
			cursor: pointer;
			&:hover {
				background-color: $color-neutral-black-05;
			}
		}
	}

	&.top {
		.actions-container {
			top: auto;
			bottom: 40px;
		}
	}

	&--open {
		.button-container {
			background-color: $color-blue-dark-2;
		}
	}
}
