.at-foodtype-indicator {
	height: 12px;
	width: 12px;
	position: relative;

	&--veg {
		border: 2px solid #388e3c;
		&::after {
			position: absolute;
			height: 8px;
			width: 8px;
			content: "";
			top: 50%;
			left: 50%;
			border-radius: 50%;
			background-color: #388e3c;
			transform: translate(-50%, -50%);
		}
	}

	&--non-veg {
		border: 2px solid #bf360c;
		&::after {
			position: absolute;
			height: 0px;
			width: 0px;
			content: "";
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-bottom: 8px solid #bf360c;
			top: 15%;
			left: 15%;
		}
	}

	&--egg {
		border: 2px solid #a8870f;
		&::after {
			position: absolute;
			height: 8px;
			width: 8px;
			content: "";
			top: 50%;
			left: 50%;
			border-radius: 50%;
			background-color: #a8870f;
			transform: translate(-50%, -50%);
		}
	}
}
