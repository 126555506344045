.onboarding-brands {
	// reset the existing browser styles for the heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
		margin: 0;
	}
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 0; // overriding padding from section-container class

	.onboarding-brand-step {
		min-height: 80vh;
		padding: 24px;
		.onboarding-brand-creation-success {
			display: grid;
			place-items: center;
			margin-top: 10%;
			.success-icon {
				margin: 0 auto 24px;
				text-align: center;
			}
			.success-heading {
				margin-bottom: 24px;
			}
			.continue-with-setup--cta,
			.view-brands--cta {
				margin-bottom: 24px;
				width: fit-content;
			}
		}
		.onboarding-view-brands {
			min-height: 60vh;
			margin-top: 16px;
			.brands-list {
				display: flex;
				flex-wrap: wrap;
				gap: 16px;
				.brand-card {
					border-radius: 2px;
					height: 210px;
					width: 180px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 16px;
					border: 1px solid var(--up-black-10);

					.brand-img,
					.monochrome-generator {
						width: 145px;
						height: 145px;
					}

					.brand-title {
						font-weight: 600;
						margin-top: 8px;
					}
				}

				.add-another-brand-card {
					border-radius: 2px;
					cursor: pointer;
					width: 180px;
					height: 210px;
					background-color: var(--up-blue-5);
					border: 1px dashed var(--up-blue-50);
					padding: 16px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					.add-another-brand-text {
						font-weight: 600;
						margin-top: 8px;
						line-height: 22px;
					}
				}
			}

			.brands-list-loading {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				flex-direction: column;
				margin-top: 20%;
				.infinite-spinner-loader {
					height: 48px;
					width: 48px;
				}
			}
		}
	}

	.onboarding-brand-footer {
		box-shadow: $default-shadow;
		position: sticky;
		display: flex;
		bottom: 0;
		left: 0;
		right: 0;

		flex-direction: row-reverse;
		gap: 12px;
		padding: 16px 24px;
		background-color: white;
	}
}
