.table-column-selector {
	.dropdown-custom {
		.container {
			width: 32px;
			border-radius: 2px;
			gap: 5px;
			img {
				position: relative;
				top: 1px;
			}
		}
		.dropdown-component {
			width: 200px;
			border-radius: 2px;
		}
	}
}
