.monochrome-generator {
	border-radius: 2px;
	text-transform: uppercase;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	&.xsmall {
		height: 20px;
		width: 20px;
	}
	&.msmall {
		height: 24px;
		width: 24px;
	}
	&.small {
		height: 32px;
		width: 32px;
		min-height: 32px;
		min-width: 32px;
	}
	&.medium {
		height: 56px;
		width: 56px;
	}
	&.large {
		height: 160px;
		width: 160px;
		font-size: 60px;
	}
	&.xlarge {
		height: 240px;
		width: 240px;
		font-size: 100px;
	}
}
