.entity-rules-association-container {
	.header-container {
		padding-bottom: 24px;
		display: flex;
		justify-content: space-between;

		.text-container {
			.title {
				font-weight: $font--semibold;
				color: $color-neutral-black-90;
			}
			.sub-title {
				font-size: $very-small-font-size;
				color: $color-dark-grey;
			}
		}
	}
	.entity-rules-container {
		border: 1px solid $border-color;
		.rule-info {
			padding: 12px;
			border-bottom: 1px solid $border-color;
			.header-actions-container {
				display: flex;
				justify-content: space-between;
				.actions-container {
					display: flex;
					.at-switch-cnt {
						.at-switch {
							.switch-rail {
								margin: 0;
								background-color: $btn-grey-color;
							}
							.switch-ball {
								top: 2px;
							}
							&.active {
								.switch-rail {
									background-color: $color-green-50;
								}
							}
						}
					}
					.context-menu {
						position: relative;
						top: 0;
						right: 0;
						.icon-dots {
							padding-top: 4px;
						}
						.menu {
							top: 30px;
							.action-item--archive {
								color: $color-red;
							}
						}
					}
				}
				.rule-title {
					font-weight: $font--semibold;
					font-size: $very-small-font-size;
					color: $color-neutral-black-50;
					margin-bottom: 12px;
				}
			}
			.rule-description {
				padding: 16px 0;
				font-size: $very-small-font-size;
				color: $color-neutral-black-60;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				row-gap: 10px;
			}
			&:last-child {
				border-width: 0;
			}
		}
	}
}
