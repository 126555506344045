.common-table-container {
	a {
		text-decoration: none;
	}
	.transactions-list-table {
		margin-top: 20px;
		margin-bottom: 10px;
		&.bordered {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			.transaction-header-row {
				border-top: none;
			}
		}
		@include bp(768px) {
			margin-top: 5px;
		}
		.at-table-row {
			transform-origin: center;
			will-change: transform;
			.at-table-header {
				padding-top: 10px;
				padding-bottom: 10px;
				@include bp(768px) {
					padding-top: 5px;
					padding-bottom: 5px;
				}
				&.active {
					color: $color-blue;
				}
				&.archived {
					color: $color-red;
				}
			}
		}
		.at-cell-text {
			font-size: $very-small-font-size;
			color: $mc-9;
			@include bp(768px) {
				font-size: $very-small-font-size;
				padding: 5px;
			}
		}
		.transaction-header-row {
			border-top: 1px solid $divider-color;
			border-bottom: 1px solid $divider-color;
			background-color: $mc-2;
			position: sticky;
			top: 57px;
			z-index: 1;
			@include bp(768px) {
				position: initial;
			}
		}
		.transaction-rows {
			border-bottom: 1px solid $divider-color;
			padding-top: 8px;
			padding-bottom: 8px;
			transition: 180ms;
			min-height: 70px;
			box-sizing: border-box;
			@include bp(768px) {
				min-height: initial;
				padding-top: 2px;
				padding-bottom: 2px;
			}
			&:last-child {
				border-bottom: 0px;
			}
			.at-table-cell {
				& > div {
					margin-bottom: 4px;
					&:last-child {
						margin-bottom: 0;
						&.phone,
						&.date-time {
							padding-left: 0px;
						}
					}
				}
			}
			.link-text {
				color: $color-blue;
				text-transform: capitalize;
				@extend .font--semibold;
				&.archived {
					color: $color-red;
				}
			}
			.context-menu {
				// position: absolute;
				// top: 15px;
				// right: 15px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 28px;
				width: 30px;
				cursor: pointer;
				.menu {
					min-width: 150px;
					position: absolute;
					width: fit-content;
					top: 25px;
					// right: 0px;
					background-color: $mc-1;
					box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
					border-radius: $border-radius-default;
					padding-left: 25px;
					z-index: 50;
					.action-item {
						width: auto;
						padding: 10px 25px 10px 15px;
						font-size: $very-small-font-size;
						white-space: nowrap;
						background-color: $mc-1;
						transition: 180ms;
						cursor: pointer;
						&:hover {
							background-color: $mc-2;
						}
						// &:first-child {
						// 	border-radius: 4px 4px 0 0;
						// }
						// &:last-child {
						// 	border-radius: 0 0 4px 4px;
						// }
					}
				}
				.icon-dots {
					padding: 0;
					padding-left: 25px;
					// padding-right: 10px;
				}
				&.open {
					.icon-dots {
						// background-color: $color-lightest-grey;
						svg {
							path {
								fill: $color-light-black;
							}
						}
					}
				}
			}
		}
		.custom-placeholder {
			text-align: center;
			padding: 10% 0;
			box-sizing: border-box;
			font-size: $small-font-size;
			color: $mc-6;
			img {
				width: 300px;
				opacity: 0.9;
			}
			&.placeholder-img {
				padding: 2% 0;
			}
			.placeholder-text {
				margin-top: 10px;
			}
		}
	}
}
