.catalogue-backups-container {
	.archive-restore-container {
		.alert {
			img {
				margin-right: 6px;
			}
			.title {
				display: flex;
				font-weight: 600;
				padding-top: 10px;
				span {
					font-size: $small-font-size;
				}
			}
			.message {
				font-size: $very-small-font-size;
				color: $mc-7;
				line-height: 20px;
				span {
					font-weight: 600;
				}
			}
		}
	}
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.filter-buttons {
			display: flex;
			flex-direction: row-reverse;
			margin-top: 10px;
			.campaign-list-filter {
				position: relative;
				margin-left: 10px;
				.filter-count {
					background: $color-green;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
			.multi-select-filter-container {
				width: 175px;
				margin: 0;
				.Select-value-label,
				.Select-option {
					text-transform: capitalize;
				}
			}
		}
	}
	.auto-backup {
		padding: 15px 25px;
		margin: 10px 0 25px;
		border-block: 1px solid $border-color;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 44px;
		.header-text {
			margin-bottom: 5px;
			@extend .font--semibold;
		}
		.subheader-text {
			font-size: $very-small-font-size;
			color: $mc-5;
		}
	}
	.catalogueBackups-list-table-container {
		.name {
			width: 42%;
			padding-left: 25px;
			.item-desc {
				display: flex;
				.status {
					display: flex;
					align-items: center;
					border-radius: 50px;
					margin-left: 10px;
					color: $color-green;
					border: 1px solid $color-green;
					font-size: 11px;
					padding: 0 5px;
					background-color: rgba($color: $color-green, $alpha: 0.1);
					@keyframes rotate {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
					svg {
						width: 10px;
						margin-right: 5px;
						animation: rotate 1s infinite ease-out;
						border-radius: 50%;
					}
				}
			}
			.entities-count {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 5px;
				div {
					font-size: 14px;
					margin-right: 15px;
					margin-bottom: 2px;
					color: $mc-5;
				}
			}
		}
		.backup-on {
			width: 20%;
		}
		.user {
			width: 20%;
		}
		.actions {
			width: 18%;
			display: flex;
			justify-content: flex-end;
			padding-right: 25px;
			.actions-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				min-width: 115px;
				div {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-wrap: wrap;
				}
			}
			.action-item {
				color: $color-blue-light;
				margin: 0 10px 10px 0;
				padding-right: 10px;
				border-right: 1px solid $border-color-dark;
				font-size: 12px;
				font-weight: bold;
				cursor: pointer;
				transition: 180ms;
				height: fit-content;
				&:hover {
					color: $color-blue;
				}
				&.delete {
					margin: 0;
					color: rgba($color: $color-red, $alpha: 0.6);
					&:hover {
						color: $color-red;
					}
				}
				&:last-child {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}
}

.create-backup-container {
	.meta-info {
		font-size: 14px;
		color: $mc-5;
		margin-bottom: 5px;
		color: $color-red;
	}
	.manual-backups-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		.backup {
			display: flex;
			align-items: center;
			padding: 15px;
			border-bottom: 1px solid $border-color;
			font-size: $very-small-font-size;
			&:hover {
				background-color: rgba(47, 88, 242, 0.05);
			}
			&:nth-of-type(odd) {
				background-color: #f3f5f8;
				&:hover {
					background-color: #f5f6ff;
				}
			}
			.name {
				width: 40%;
				.at-radiobutton {
					font-size: $very-small-font-size;
				}
			}
			.created {
				width: 30%;
			}
			.user {
				width: 30%;
				text-align: right;
			}
			&:last-child {
				border-bottom: none;
			}
			&.header {
				padding: 10px 15px;
				font-size: 12px;
				text-transform: uppercase;
				color: $mc-7;
				background: #f8f8f8;
				&:hover {
					background-color: #f8f8f8;
				}
				@extend .font--semibold;
			}
		}
	}
	.note {
		font-size: 12px;
		color: $mc-5;
		margin-top: 6px;
	}
}

.preview-backup-container {
	.header {
		.title {
			display: flex;
			align-items: center;
			.status {
				display: flex;
				align-items: center;
				border-radius: 50px;
				margin-left: 10px;
				color: $color-green;
				border: 1px solid $color-green;
				font-size: 11px;
				padding: 0 5px;
				background-color: rgba($color: $color-green, $alpha: 0.1);
				@keyframes rotate {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
				svg {
					width: 10px;
					margin-right: 5px;
					animation: rotate 1s infinite ease-out;
					border-radius: 50%;
				}
			}
		}
	}
	.description-container {
		padding: 0 25px;
		margin-bottom: 25px;
		color: $mc-6;
		font-size: $very-small-font-size;
		span {
			color: $mc-9;
			font-size: $small-font-size;
			margin-right: 5px;
			// @extend .font--semibold;
		}
	}
	.custom-topbar {
		.at-top-bar-v2 {
			height: 41px;
			border-bottom: none;
			.topbar-column {
				padding: 8px 25px;
				border-bottom: 1px solid $border-color;
				&.selected {
					position: relative;
					color: $color-blue;
					&:after {
						position: absolute;
						bottom: -1px;
						color: $color-blue;
						background: $color-blue;
						height: 2px;
					}
				}
				&.scroll-left {
					padding: 10px;
				}
				&.scroll-right {
					padding: 10px;
				}
			}
			.count {
				position: relative;
				bottom: 1px;
				display: flex;
				align-items: center;
				justify-content: center;
				float: left;
				min-width: 10px;
				height: 23px;
				margin-right: 7px;
				font-size: $very-small-font-size;
				color: $mc-5;
				background-color: $mc-2;
				text-align: center;
				border-radius: 15px;
				padding: 0 7px;
			}
			.text {
				float: right;
				padding: 2px 0;
			}
			.selected {
				.count {
					color: $color-blue;
					background-color: rgba($color: $color-blue, $alpha: 0.1);
				}
			}
		}
	}
	.entities {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: white;
		padding: 10px 0 30px 0;
		position: sticky;
		top: 0;
		z-index: 10;
		.entity {
			min-width: 150px;
			padding: 8px 0;
			color: $mc-5;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			text-align: center;
			cursor: pointer;
			transition: 180ms;
			&.selected {
				color: $mc-1;
				background: $color-blue;
				border: 1px solid $color-blue;
			}
		}
	}
	.search-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 25px;
		.at-input-container {
			width: 30%;
			.at-input-field {
				.at-label {
					height: 36px;
					border-right: 0;
					background-color: white;
					img {
						width: 16px;
						margin-top: 3px;
					}
				}
				.at-input {
					height: 36px;
					&.at-input--label {
						padding: 0px 34px;
					}
				}
			}
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.at-json-input-container {
		.react-json-view {
			max-height: 450px;
		}
	}
	.entity-table-container {
		.sync-status-container {
			.sync-icon {
				padding: 0 30px;
				text-align: center;
				img {
					width: 150px;
					object-fit: cover;
				}
			}
			.message {
				max-width: 750px;
				margin: 0 auto;
				margin-bottom: 50px;
				padding: 0 30px;
				text-align: center;
				color: $mc-5;
				font-size: $small-font-size;
				line-height: 1.5;
			}
		}
		.entity-table {
			position: relative;
			top: 1px;
			.transactions-list-table {
				margin-top: 0;
			}
			.transaction-header-row {
				top: 41px;
			}
			.transaction-rows {
				min-height: 50px;
				&.no-pad {
					padding: 0;
				}
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.at-accordion-container {
				width: 100%;
				border-radius: $default-radius;
				.accordion-row {
					min-height: 49px;
					display: flex;
					align-items: center;
					padding: 0;
					border-bottom: none;
					.down-arrow-global {
						right: 25px;
					}
				}
				.header {
					position: relative;
					padding: 15px 25px;
					.title {
						font-size: $very-small-font-size;
						padding: 0;
					}
					.info {
						font-size: 12px;
						color: $mc-5;
						position: absolute;
						right: 55px;
					}
				}
				.contents {
					font-size: $very-small-font-size;
					background-color: $mc-1;
					border-top: 1px solid $border-color;
				}
				.link {
					position: relative;
					margin-right: 15px;
					margin-left: 35px;
					padding: 15px 10px 0px 25px;
					border-left: 1px solid $border-color;
					&:last-child {
						padding: 15px 10px 15px 25px;
						border-left: 1px solid $mc-1;
						&::before {
							content: "";
							position: absolute;
							top: 0px;
							left: -1px;
							padding: 17px 1px;
							border-left: 1px solid $border-color;
						}
					}
				}
				.subcat-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: relative;
					padding: 10px 25px;
					border: 1px solid $border-color;
					border-radius: $default-radius;
					.info {
						font-size: 12px;
						color: $mc-5;
						margin-right: 5px;
					}
					&::before {
						content: "";
						position: absolute;
						top: 15px;
						left: -27px;
						padding: 1px 13px;
						border-bottom: 1px solid $border-color;
					}
					&:hover {
						background-color: $mc-2;
						border: 1px solid $border-color;
					}
				}
			}
			.name {
				width: 45%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.item-desc {
					display: flex;
					.food-type {
						margin: auto 10px auto 0;
						flex-shrink: 0;
					}
				}
				.crm-title {
					color: $mc-5;
					padding-left: 22px;
				}
				.item_tags {
					padding-left: 23px;
					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}
			.title {
				padding-left: 25px;
				padding-right: 25px;
				width: 40%;
				.item-desc {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.info {
						font-size: 12px;
						color: $mc-5;
						margin-right: 30px;
					}
				}
			}
			.category {
				width: 30%;
			}
			.type {
				width: 30%;
			}
			.applicable-on {
				width: 30%;
			}
			.description {
				width: 30%;
			}
			.price {
				width: 20%;
				padding-right: 25px;
				text-align: right;
			}
		}
	}
}
