.global-notifications-container {
	position: fixed;
	top: 50px;
	right: 2%;
	z-index: 10000;
	.notification-content {
		display: flex;
		align-items: center;
		width: 350px;
		background-color: $mc-1;
		border-radius: $default-radius;
		padding: 10px 5px;
		box-shadow: $box-shadow;
		margin-top: 20px;
		border: 1px solid $border-color;
		.header {
			font-size: $small-font-size;
			font-weight: 600;
			margin-bottom: 8px;
			color: $mc-7;
		}
		.icon {
			padding: 2px 10px;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			.type-img {
				height: 16px;
				width: 16px;
				object-fit: contain;
			}
		}
		.notification-body {
			font-size: $very-small-font-size;
			.message {
				color: $mc-7;
			}
			.action {
				margin-top: 12px;
				display: flex;
				gap: 10px;
			}
		}
		.close-icon-container {
			display: flex;
			padding: 0 5px;
			margin-left: auto;
			.close-icon {
				height: 10px;
				width: 10px;
				padding: 4px;
				cursor: pointer;
				&:hover {
					background-color: $mc-2;
				}
			}
		}
		&--dark {
			background-color: #222222;
			.notification-body {
				.message {
					color: $mc-1;
				}
			}
			.close-icon-container {
				.close-icon {
					&:hover {
						background-color: $mc-8;
					}
				}
			}
		}
	}
}
