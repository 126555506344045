.side-nav-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 201;
	box-sizing: border-box;
	.side-nav-comp {
		width: $sidebar-width;
		&--collapsed {
			width: $sidebar-width-minimized;
		}
	}
	&.with-banner {
		top: 57px;
		.side-nav-comp {
			height: calc(100vh - 57px);
		}
	}
	.atlas-logo-container {
		position: relative;
		display: flex;
		align-items: center;
		height: auto;
		line-height: initial;
		padding: 0;
		background-color: transparent;
		border: none;
		a {
			display: flex;
			align-items: center;
			position: relative;
			width: fit-content;
			img {
				flex-shrink: 0;
			}
		}
		&.collapsed {
			padding-left: 10px;
			justify-content: center;
			a {
				justify-content: center;
			}
		}
	}
	.santa-hat {
		position: absolute;
		top: -27px;
		left: 74px;
		width: 40px;
		height: 50px;
		object-fit: cover;
		transform: rotate(26deg);
	}
	.santa-hat-collapsed {
		position: absolute;
		top: -26px;
		left: 3px;
		width: 40px;
		height: 50px;
		object-fit: none;
		transform: rotate(26deg);
	}
	.side-nav-onboarding-progress {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 7px;
		padding: 10px 15px;
		.side-nav-onboarding-progress-row {
			width: 100%;
			display: flex;
			justify-content: center;
			cursor: pointer;
			.side-nav-onboarding-user-icon {
				height: 20px;
				width: 20px;
				margin-bottom: 4px;
				flex-shrink: 0;
			}
			.side-nav-onboarding-text {
				color: $color-blue-40;
				margin-left: 12px;
				font-weight: 600;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.side-nav-onboarding-percentage-text {
				color: $color-blue-40;
				flex-grow: 1;
				text-align: right;
				font-weight: 600;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.side-nav-onboarding-progress-bar {
			width: 100%;
		}
		&.collapsed {
			gap: 2px;
			.side-nav-onboarding-progress-bar {
				width: 100%;
				max-width: 30px;
			}
		}
	}
	.freshchat-support-btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 50px;
		padding: 7px;
		& > div {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.freshchat-help-button {
			max-width: 150px;
			width: 100%;
			font-weight: 600;
			overflow: hidden;
		}
	}
	.sidebar-modal {
		.at-modal {
			.content-show {
				.modal-header {
					color: black;
					opacity: 1;
				}
				.modal-content {
					color: $mc-9;
					text-align: center;
					font-size: 16px;
					.meraki-ad-image {
						width: 380px;
						height: 260px;
					}
				}
			}
		}
	}
}
