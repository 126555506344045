.help-section {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	margin-bottom: 15px;
	border-radius: 4px;
	height: 1200px;
	padding-bottom: 0px;
}
.helpjuice-container {
	text-align: center;
	border-radius: 4px;
	height: 1649px;
	position: relative;
	& > iframe {
		width: 100%;
		border-radius: 4px !important;
		height: 1649px;
	}
	iframe:not(:last-of-type) {
		display: none;
	}

	.helpjuice-loading-spinner {
		position: absolute;
		top: 250px;
		left: 50%;
		transform: translateX(-50%);
		height: 25px;
		width: 25px;
	}
}
