.image-size-warning--modal {
	.at-modal {
		.modal-header {
			.header-left {
				.title {
					color: $color-light-black;
					text-align: left;
					font-size: 18px;
				}
			}
			border-bottom: 1px solid $border-color;
		}
		.modal-container {
			width: 30%;
			min-width: 500px;
		}
		.modal-footer {
			text-align: right;
			border-top: 1px solid $border-color;
		}
		.warning-message {
			padding: 25px;
		}
	}
}
