.meraki-plans-billing {
	padding: 25px;
	.header {
		text-align: center;
		font-size: $medium-large-font-size;
		margin-bottom: 15px;
		@extend .font--semibold;
	}
	.description {
		text-align: center;
		font-size: $small-font-size;
		color: $mc-5;
		margin-bottom: 15px;
	}
	.plan-type {
		display: flex;
		align-items: center;
		justify-content: center;
		.type {
			position: relative;
			top: 7px;
			font-size: $medium-font-size;
			margin: 0 15px;
			transition: 180ms;
			opacity: 0.4;
			@extend .font--semibold;
		}
		.selected {
			position: relative;
			top: 7px;
			font-size: $medium-font-size;
			margin: 0 15px;
			transition: 180ms;
			@extend .font--semibold;
		}
		.offer {
			position: relative;
			top: 7px;
			color: $color-java;
			background-color: rgba($color: $color-java, $alpha: 0.1);
			border-radius: $default-radius;
			padding: 5px 7px;
			@extend .font--semibold;
		}
		.arrow {
			position: absolute;
			top: 15px;
			right: -60px;
			.path {
				stroke-dasharray: 1000;
				stroke-dashoffset: 1000;
				animation: dash 5s linear infinite;
			}
			.head {
				stroke-dasharray: 840;
				stroke-dashoffset: 1000;
				animation: dash 5s linear infinite;
			}
			@keyframes dash {
				to {
					stroke-dashoffset: 0;
				}
			}
		}
	}
	.plans-table-container {
		margin: 70px 0 30px 0;
		.table-row {
			display: flex;
			align-items: center;
			&.bordered {
				border-bottom: 1px solid $border-color;
			}
			&:nth-child(1) {
				.pro {
					border-radius: 4px 4px 0 0;
				}
			}
			&:nth-last-child(1) {
				.pro {
					border-radius: 0 0 4px 4px;
				}
			}
			.features {
				width: 50%;
				padding: 15px;
			}
			.basic {
				width: 25%;
				text-align: center;
				padding: 15px;
				img {
					width: 16px;
				}
			}
			.pro {
				width: 25%;
				text-align: center;
				padding: 15px;
				background: rgba($color: $color-blue, $alpha: 0.1);
				img {
					width: 16px;
				}
			}
			.text--header {
				font-size: $medium-large-font-size;
				@extend .font--semibold;
			}
			.text--small {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.subscription {
				font-size: $large-font-size;
				color: $color-blue;
				margin-bottom: 15px;
				@extend .font--semibold;
			}
			.commission {
				font-size: $very-small-font-size;
				color: $color-blue;
				margin-bottom: 5px;
				@extend .font--semibold;
			}
		}
	}
}
