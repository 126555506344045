.at-modal--backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zI-top + 1;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0;
	pointer-events: none;
	transition: 180ms;
	cursor: pointer;
	&.backlayer-show {
		opacity: 0.5;
		pointer-events: all;
	}
}

.overlay-sidebar-container {
	.content {
		display: flex;
		position: fixed;
		top: 0;
		right: -400px;
		width: 400px;
		@include bp(768px) {
			right: -75%;
			width: 75%;
		}
		background-color: white;
		height: 100%;
		z-index: ($zI-top + 1);
		transition: 180ms ease;
		will-change: transform;
		overflow: auto;
		overflow-x: hidden;
		&.content-show {
			transform: translateX(-100%);
			box-shadow: -4px 0px 12px 0px rgba(0, 0, 0, 0.08);
			// hide scroll bar
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&.banner-message-adjusted {
			top: 57px;
			height: calc(100% - 57px);
		}
		.resize-form-sidebar {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			// border-left: 3px solid transparent;
			position: absolute;
			left: 0;
			width: 7px;
			height: 100%;
			cursor: pointer;
			z-index: 100;
			transition:
				width 100ms linear,
				background-color 100ms linear;
			overflow: hidden;
			.arrow {
				width: 8px;
				display: none;
				opacity: 0.7;
				@keyframes leftArrow {
					0% {
						transform: translateX(1px);
						opacity: 0.1;
					}
					50% {
						transform: translateX(-2px);
						opacity: 1;
					}
					100% {
						transform: translateX(1px);
						opacity: 0.1;
					}
				}
				@keyframes rightArrow {
					0% {
						transform: translateX(1px);
						opacity: 1;
					}
					50% {
						transform: translateX(-2px);
						opacity: 0.1;
					}
					100% {
						transform: translateX(1px);
						opacity: 1;
					}
				}
				&.right {
					animation: rightArrow 1s infinite ease 100ms;
					animation-fill-mode: forwards;
				}
				&.left {
					animation: leftArrow 1s infinite ease 100ms;
					animation-fill-mode: forwards;
				}
			}
			&:hover {
				background-color: #d5ddfe;
				width: 15px;
				img {
					display: block;
				}
			}
		}
	}
	&.mini {
		.content {
			right: -250px !important;
			width: 250px !important;
			&.content-show {
				transform: translateX(-100%);
			}
		}
	}
	&.small {
		.content {
			right: -400px !important;
			width: 400px !important;
			&.content-show {
				transform: translateX(-100%);
			}
		}
	}
	&.medium {
		.content {
			right: -550px !important;
			width: 550px !important;
			&.content-show {
				transform: translateX(-100%);
			}
		}
	}
	&.large {
		.content {
			right: -750px;
			width: 750px;
			&.content-show {
				transform: translateX(-100%);
			}
		}
	}
	&.is-nested {
		.at-modal--backdrop.backlayer-show {
			opacity: 0.4;
		}
		.content {
			right: -90%;
			width: 90%;
		}
	}
	&.is-filters {
		.at-modal--backdrop.backlayer-show {
			opacity: 0.4;
		}
		.content {
			right: -400px;
			width: 400px;
		}
	}

	.overlay-sidebar-content {
		padding: 47px 0 40px 0;
		margin: 0 31px;
		@include bp(768px) {
			padding: 0;
			margin: 10px;
		}
		.title {
			@extend .font--semibold;
			font-size: $large-font-size;
		}
		.subtitle {
			margin: 5px 0 0 0;
			font-size: $small-font-size;
			color: $mc-6;
		}
		.main-content {
			margin-top: 25px;
			.meta-info {
				color: $mc-6;
			}
		}
		.btn-container {
			margin-top: 25px;
		}
	}
}

.global-confirm-modal-container {
	.global-confirm-modal {
		position: fixed;
		left: 0;
		bottom: 0;
		transform: translateY(100%);
		width: 100%;
		background-color: white;
		z-index: ($zI-top + 1);
		transition: 180ms;
		will-change: transform;
		// border-top-right-radius: 10px;
		// border-top-left-radius: 10px;
		&.content-show {
			min-height: 130px;
			transform: translateY(0%);
			.global-confirm-modal--content {
				padding: 25px;
			}
		}
	}

	&.no-backdrop {
		.global-confirm-modal {
			box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
		}
	}

	.global-confirm-modal--content {
		max-width: 1020px;
		margin: auto;
		box-sizing: border-box;
		// padding-bottom: 100px;
	}

	.global-confirm-modal--btn {
		display: block;
		width: 100%;
		// position: fixed;
		box-sizing: border-box;
		position: sticky;
		bottom: 0px;
		max-width: 1020px;
		margin: auto;
		padding: 20px 25px;
	}
}

.global-deactivation-blocker-modal-container {
	.global-deactivation-blocker-modal {
		position: fixed;
		left: 25%;
		top: 30vh;
		width: 50%;
		min-height: 40vh;
		transform: translateY(100%);
		background-color: white;
		min-height: 130px;
		z-index: ($zI-top + 1);
		transition: 180ms;
		will-change: transform;
		border-radius: 5px;
		// border-top-right-radius: 10px;
		// border-top-left-radius: 10px;
		&.content-show {
			transform: translateY(0%);
		}
		box-shadow: 0px 2px 20px 3px #8c8c8c;
	}

	.global-deactivation-blocker-modal--content {
		max-width: 1020px;
		margin: auto;
		padding: 20px;
		box-sizing: border-box;
		// padding-bottom: 100px;
		.header {
			text-align: center;
			font-size: 24px;
			padding: 20px;
		}
		.body {
			padding: 15px;
			font-size: 16px;
		}
	}

	.global-deactivation-blocker-modal--btn {
		display: block;
		width: 100%;
		box-sizing: border-box;
		bottom: 0px;
		max-width: 1020px;
		padding: 20px;
		text-align: center;
		.at-btn {
			background-color: #2f58f2;
			color: white;
			margin: 0 auto;
			display: inline-block;
			height: 50px;
			line-height: 50px;
		}
	}
}
