.select-filter-custom {
	.meta-info {
		font-size: 12px;
		font-weight: 400;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 5px;
	}
	.dropdown-custom {
		color: #333;
		width: auto;
		span {
			margin-right: auto;
		}
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 34px;
			padding: 0px 10px;
			width: auto;
			font-size: $very-small-font-size;
			border-radius: $border-radius-default;
			.option {
				display: flex;
				align-items: center;
				.option-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-right: 8px;
					img {
						width: 22px;
						object-fit: cover;
						border-radius: $default-radius;
					}
				}
			}
			.dropdown-arrow-zone {
				right: 10px;
			}
			.dropdown-arrow {
				top: 0px;
				right: 0px;
			}
			.dropdown-clear {
				top: 0px;
				right: 0px;
			}
			&.is-multi {
				height: auto;
				min-height: 32px;
				padding: 2px 10px 2px 2px;
				.placeholder {
					position: relative;
					left: 5px;
				}
				.option {
					&.all {
						padding-left: 8px;
					}
					.custom-label {
						display: flex;
						flex-wrap: wrap;
					}
					.option-tag {
						display: flex;
						align-items: center;
						margin: 3px;
						color: #007eff;
						background-color: #ebf5ff;
						border: 1px solid rgba(0, 126, 255, 0.24);
						border-radius: $default-radius;
						cursor: default;
						.option {
							padding: 4px 6px;
						}
						.cross {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 4px;
							width: 16px;
							height: 16px;
							cursor: pointer;
							&:hover {
								background-color: rgba(0, 126, 255, 0.08);
							}
							svg {
								position: relative;
								// top: 0.5px;
								width: 14px;
								height: 14px;
								fill: #007eff;
							}
						}
					}
				}
			}
		}
		.dropdown-component {
			width: 100%;
			top: 115%;
		}
		.dropdown-options {
			position: relative;
			.option-header-container {
				padding: 12px 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid $divider-color;
				border-right: none;
				border-left: none;
				.option-header {
					font-size: 12px;
					text-transform: uppercase;
					font-weight: 600;
					color: $color-neutral-black-50;
				}
				.option-select-all {
					margin-right: unset;
				}
			}
			.max-selectable-message {
				position: sticky;
				top: 0;
				z-index: 1;

				font-weight: bold;
				color: $mc-7;
				padding: 10px 15px;
				font-size: 12px;
				background-color: #f8f8f8;
			}
			.max-selection-reached-color {
				color: $color-red-dark;
			}
			input {
				width: 100%;
				border: none;
				outline: none;
				height: 40px;
				border-bottom: 1px solid $mc-4;
				padding: 0px 10px;
				border-radius: 4px 4px 0 0;
				&::placeholder {
					font-size: 14px;
					color: #d2d2d2;
				}
			}
			margin-bottom: 0;
			max-height: 200px;
			width: auto;
			box-sizing: border-box;
			overflow-y: auto;
			// prevent scroll-chaining to parent elements
			overscroll-behavior: contain;
			.option {
				display: flex;
				align-items: center;
				margin: auto;
				padding: 0px 10px;
				height: 39px;
				border-bottom: 1px solid $border-color-dark;
				cursor: pointer;
				transition: 180ms;
				font-size: $very-small-font-size;
				.option-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-right: 8px;
					width: fit-content;
					img {
						width: 22px;
						object-fit: cover;
						border-radius: $default-radius;
					}
				}
				.initials {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					min-width: 22px;
					height: 22px;
					border-radius: $default-radius;
					background-color: $mc-3;
					font-size: 12px;
					@extend .font--semibold;
					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}
					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}
					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}
					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}
					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
				.at-checkbox {
					display: flex;
					align-items: center;
					font-size: inherit;
					width: 100%;
					padding: 10px 25px;
					.checkmark {
						margin-top: 11px;
					}
				}
				&.search-input {
					padding: 0;
				}
				&.selected {
					background-color: $mc-3;
				}
				&:nth-last-child(1) {
					border-bottom: none;
				}
				&:hover {
					background-color: $mc-3;
				}
				&.no-hover {
					color: $mc-5;
					cursor: default;
					&:hover {
						background-color: transparent;
					}
				}
			}
		}
		.placeholder {
			color: #d2d2d2;
			font-size: $very-small-font-size;
		}
	}
	&.read-only {
		pointer-events: none;
		cursor: default;
		.dropdown-custom {
			color: #777;
			.container {
				background-color: #eaeaea;
			}
		}
		.option-tag {
			filter: grayscale(1);
		}
	}
}
