.customer-details-container .customer-summary {
	@include bp(768px) {
		padding: 15px 5px;
	}
	.at-input-field {
		.at-label {
			padding: 0;
			img {
				width: 20px;
				margin-top: 3px;
				filter: grayscale(1);
				object-fit: cover;
			}
		}
	}
	.multi-select-filter-container {
		.Select-value-icon,
		.react-select__multi-value__remove {
			display: none;
		}
		.Select-value-label {
			padding: 2px 8px;
		}
	}
	.orders-info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 30px;
		.info-item {
			margin-right: 55px;
			.title {
				font-size: 12px;
				color: $mc-5;
				margin-bottom: 5px;
			}
			.value {
				font-size: $medium-font-size;
				color: $mc-9;
				@extend .font--semibold;
			}
			.logo {
				height: 15px;
				margin-right: 5px;
			}
		}
		@include bp(768px) {
			justify-content: space-between;
			margin-bottom: 15px;
			.info-item {
				margin-right: initial;
				margin-bottom: 10px;
				width: 33%;
				.value {
					font-size: $very-small-font-size;
				}
			}
		}
	}
	.tag-item {
		display: block;
		max-width: 100%;
		overflow: auto;
		white-space: normal;
		text-overflow: unset;
	}
}

.customer-details-tab {
	min-height: 350px;
	box-sizing: border-box;
	.subtitle {
		color: $mc-6;
		margin-bottom: 25px;
		@include bp(768px) {
			margin: 15px 5px;
		}
	}
}

.customer-details-container {
	.form-sidebar-container {
		.title {
			margin: 0;
			.personal-info {
				display: flex;
				flex-direction: row;
				align-items: center;
				img {
					height: 52px;
					margin-right: 10px;
				}
				.name {
					margin-bottom: 5px;
				}
				.contact {
					font-size: $very-small-font-size;
					color: $mc-7;
					@extend .font--regular;
				}
			}
		}
		.wrapper-sticky-top {
			top: 0px;
		}
	}
}

.customer-details-container .customer-basic {
	padding: 0 25px 25px 25px;
	@include bp(768px) {
		padding: 15px 5px;
	}
	[aria-label][data-balloon-pos] {
		height: fit-content;
	}
	.section {
		margin-bottom: 20px;
		.title {
			font-size: 12px;
			color: $mc-5;
		}
		.value {
			font-size: $very-small-font-size;
			color: $mc-9;
			text-transform: capitalize;
		}
	}
	.personal-info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.info-item {
			margin-right: 55px;
			.title {
				margin-bottom: 5px;
			}
		}
		@include bp(768px) {
			justify-content: space-between;
			margin-bottom: 15px;
			.info-item {
				margin-right: initial;
				margin-bottom: 10px;
				width: 33%;
			}
		}
	}
	.customer-engagement {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		.title {
			margin-bottom: 15px;
		}
		.engagement-checkbox {
			margin-bottom: 10px;
			width: 100%;
			.info {
				height: 16px;
				vertical-align: middle;
				margin-left: 5px;
				cursor: pointer;
			}
		}
	}
	.customer-addresses {
		.title {
			margin-bottom: 10px;
		}
		.addresses {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			.address-item {
				margin-bottom: 20px;
				box-sizing: border-box;
				background-color: $mc-3;
				padding: 15px 10px;
				display: flex;
				font-size: 12px;
				width: 47%;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				@include bp(768px) {
					width: 100%;
				}
				.address-icon {
					margin-right: 10px;
					height: 20px;
				}
				.tag {
					@extend .font--semibold;
					margin-bottom: 5px;
					text-transform: capitalize;
				}
				.longform {
					color: $mc-5;
				}
			}
		}
	}
}

.customer-details-container .customer-feedbacks {
	padding: 0 25px 25px 25px;
	position: relative;
	.transactions-list-table {
		.transaction-header-row {
			top: 38px;
		}
		.transaction-rows {
			height: auto;
			min-height: 80px;
			position: relative;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.at-table-row.customer-feedbacks-table {
			.rating {
				width: 15%;
				display: flex;
				.rating-info {
					display: flex;
					.rating-icon {
						margin-right: 5px;
						img {
							width: 18px;
						}
					}
				}
				.reply-icon {
					position: relative;
					top: -2px;
					cursor: pointer;
					margin-left: 15px;
					img {
						width: 15px;
						padding: 3px;
						border: 1px solid $mc-4;
						border-radius: $default-radius;
						&:hover {
							border: 1px solid $mc-5;
							background-color: $mc-3;
						}
					}
				}
			}
			.created {
				width: 15%;
				padding-left: 15px;
				.date-time {
					margin-top: 5px;
				}
			}
			.notes {
				width: 35%;
				display: flex;
				overflow: initial;
				justify-content: space-between;
				.notes-desc {
					width: 83%;
					.choice-text {
						margin-bottom: 5px;
					}
					.comments {
						color: $mc-5;
						font-size: $very-small-font-size;
					}
				}
			}
			.order {
				width: 15%;
				.type {
					color: $mc-5;
					margin-top: 5px;
					font-size: $very-small-font-size;
				}
			}
			.location {
				width: 20%;
				padding-right: 15px;
			}
		}
		@include bp(768px) {
			.transaction-rows {
				min-height: initial;
			}
			.at-table-row.customer-feedbacks-table {
				.rating {
					width: 20%;
					padding-left: 5px;
				}
				.created {
					width: 25%;
				}
				.notes {
					width: 55%;
					.notes-desc {
						width: 47%;
						.comments {
							font-size: 12px;
						}
					}
					.notes-action {
						width: 52%;
					}
				}
			}
		}
	}
	.feedback-response-message {
		input,
		textarea {
			outline: 0;
			padding: 5px;
			padding-left: 0px;
			font-size: $very-small-font-size;
			border: 0px;
			border-bottom: 1px solid #111;
			width: 50%;
			max-width: 100%;
			box-sizing: border-box;
		}
		textarea {
			resize: none;
			width: 100%;
			height: 100px;
		}
	}
}

.customer-details-container .customer-notes {
	padding: 0 25px 25px 25px;
	.subtitle {
		margin: 0;
		color: $mc-6;
	}
	@include bp(768px) {
		padding: 5px;
		.subtitle {
			margin: 15px 0;
		}
	}
	.add-note {
		margin-top: 25px;
		margin-bottom: 25px;
		cursor: pointer;
		@extend .font--semibold;
		color: $color-blue;
	}
	.note-item {
		box-sizing: border-box;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		// border-left: 4px solid $color-blue;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		font-size: $very-small-font-size;
		line-height: $very-small-font-size;
		margin-bottom: 25px;
		color: $mc-5;
		&:last-child {
			margin-bottom: 0;
		}
		.subject {
			padding: 10px;
			margin-bottom: 10px;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			border-bottom: 1px solid $border-color;
			background-color: rgba($color: $mc-2, $alpha: 0.6);
			.subject-text {
				color: $mc-7;
				font-size: 12px;
				font-weight: 600;
				span {
					@extend .font--regular;
				}
				&:first-child {
					font-size: $very-small-font-size;
				}
			}
			.subject-created {
				text-align: right;
				font-size: 13px;
				color: $mc-5;
			}
		}
		.description {
			padding: 0 10px;
			margin-bottom: 15px;
			color: $mc-7;
		}
		.note-actions {
			display: flex;
			flex-direction: row-reverse;
			text-transform: uppercase;
			@extend .font--semibold;
			font-size: 12px;
			line-height: 12px;
			padding: 0 10px 10px 10px;
			.edit {
				margin-right: 15px;
				cursor: pointer;
				color: $color-blue;
				opacity: 0.6;
				transition: 180ms;
				&:hover {
					opacity: 1;
				}
			}
			.delete {
				cursor: pointer;
				color: $color-red;
				opacity: 0.6;
				transition: 180ms;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

.customer-details-container .customer-timeline {
	padding: 0 25px 25px 25px;
	.transactions-list-table {
		.transaction-header-row {
			top: 38px;
		}
		.transaction-rows {
			height: auto;
			min-height: 80px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.at-table-row.customer-timeline-table {
			.event {
				width: 18%;
				padding-left: 25px;
			}
			.created {
				width: 18%;
			}
			.notes {
				width: 64%;
				.comments {
					color: $mc-5;
					font-size: $very-small-font-size;
				}
			}
		}
		@include bp(768px) {
			.transaction-rows {
				min-height: initial;
			}
			.at-table-row.customer-timeline-table {
				.event {
					width: 18%;
					padding-left: 5px;
				}
				.created {
					width: 25%;
				}
				.notes {
					width: 57%;
					.comments {
						color: $mc-5;
						font-size: $very-small-font-size;
					}
				}
			}
		}
	}
}

.customer-details-container .customer-wallet {
	padding: 0 25px;
	.wallet-balance {
		font-size: $small-font-size;
		color: $mc-6;
		margin-bottom: 30px;
		@extend .font--semibold;
		span {
			color: $mc-9;
		}
	}
}

.customer-create-container {
	.at-input-container .at-input-field .at-label {
		display: flex;
		justify-content: center;
		font-size: $very-small-font-size;
	}
}
