.date-filter-dropdown {
	.meta-info {
		font-size: 12px;
		font-weight: 400;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 5px;
	}
	.dropdown-custom {
		color: #333;
		width: auto;
		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 34px;
			padding: 0px 10px;
			width: auto;
			font-size: $very-small-font-size;
			.dropdown-arrow-zone {
				right: 10px;
			}
			.dropdown-arrow {
				right: 8px;
			}
			.placeholder {
				font-size: $very-small-font-size;
				color: #d2d2d2;
			}
		}
		.dropdown-component {
			left: 0;
			right: auto;
			top: 110%;
			cursor: default;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.custom-type-selector {
			text-align: left;
			padding: 10px 10px 2px;
			background-color: rgba($color: $color-blue, $alpha: 0.1);
			.multi-select-filter-container {
				margin-bottom: 0;
				.at--dropdown {
					font-size: $very-small-font-size;
					span {
						font-size: $very-small-font-size;
					}
				}
			}
		}
		.date-range-selector {
			text-align: left;
			display: flex;
			align-items: center;
			gap: 0 15px;
			justify-content: space-between;
			background-color: rgba($color: $color-blue, $alpha: 0.1);
			padding: 10px;
			.start-date-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
			}
			.end-date-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
				&.disabled {
					opacity: 0.4;
					cursor: default;
				}
			}
			.meta-info {
				color: $mc-7;
				margin-bottom: 3px;
				@extend .font--semibold;
			}
			.date {
				display: flex;
				align-items: center;
				min-width: 85px;
				min-height: 18px;
				padding: 8px 10px;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				background-color: $mc-1;
				&.placeholder {
					color: $mc-5;
					font-size: $very-small-font-size;
				}
			}
			.selected {
				opacity: 1;
				color: $color-blue;
				// @extend .font--semibold;
				.date {
					border: 1px solid $color-blue;
				}
			}
		}
		.date-time-range-selector {
			display: flex;
			flex-direction: column;
			.date-time {
				text-align: left;
				display: flex;
				align-items: center;
				gap: 0 15px;
				justify-content: space-between;
				background-color: rgba($color: $color-blue, $alpha: 0.1);
				padding: 10px;
				padding-bottom: 0;
				&:last-child {
					padding-bottom: 10px;
				}
			}
			.start-date-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
			}
			.start-time-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
			}
			.end-date-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
				&.disabled {
					opacity: 0.4;
					cursor: default;
				}
			}
			.end-time-container {
				width: 100%;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
			}
			.meta-info {
				color: $mc-7;
				margin-bottom: 3px;
				@extend .font--semibold;
			}
			.date {
				display: flex;
				align-items: center;
				min-width: 85px;
				min-height: 18px;
				padding: 8px 10px;
				border: 1px solid $border-color;
				border-radius: $default-radius;
				background-color: $mc-1;
				&.placeholder {
					color: $mc-5;
					font-size: $very-small-font-size;
				}
			}
			.selected {
				opacity: 1;
				color: $color-blue;
				// @extend .font--semibold;
				.date {
					border: 1px solid $color-blue;
				}
			}
		}
		.actions-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 6px 10px;
			box-sizing: border-box;
			width: 100%;
			.action {
				font-size: 12px;
				text-transform: uppercase;
				margin-left: 15px;
				cursor: pointer;
				@extend .font--semibold;
			}
			.reset {
				position: relative;
				top: -1px;
				width: 16px;
				margin-right: auto;
				opacity: 0.6;
				cursor: pointer;
				&:hover {
					opacity: 1;
				}
			}
			.apply {
				color: $color-blue;
			}
			.cancel {
				opacity: 0.6;
				&:hover {
					opacity: 1;
				}
			}
		}
		.react-datepicker {
			box-shadow: none;
			border: none;
			.react-datepicker__year-read-view--down-arrow {
				display: none;
			}
		}
		.placeholder {
			color: $mc-5;
			font-size: 12px;
		}
	}
	.time-selector {
		display: flex;
		align-items: center;
		text-align: left;
		padding: 0 20px;
		margin: 10px 0;
		border: 1px solid transparent;
		.multi-select-filter-container {
			width: 38px;
			div,
			span {
				font-size: $very-small-font-size;
			}
			margin-bottom: 0;
			.meta-info {
				margin-bottom: 0;
				span {
					font-size: 12px;
				}
			}
		}
		.Select-menu-outer {
			width: 60px;
		}
		.Select-arrow-zone {
			display: none;
		}
		.menu-wrapper {
			width: 60px !important;
			.react-select__option {
				padding: 10px;
			}
		}
		.react-select__dropdown-indicator {
			display: none;
		}
		.meridiem-container {
			margin-left: 20px;
			.meridiem {
				width: 38px;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				transition: 180ms;
				cursor: pointer;
				&:hover {
					color: $color-blue;
					border-color: $color-blue;
				}
			}
		}
		.colon {
			position: relative;
			top: 11px;
			font-size: $medium-font-size;
			margin: 10px 5px;
			@extend .font--semibold;
		}
	}
	&.range {
		.react-datepicker {
			border: 1px solid $border-color;
			margin-top: 10px;
		}
	}
}
