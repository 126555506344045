@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 50px 50px;
	}
}
.progress-bar-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	.progress-bar {
		box-sizing: content-box;
		height: 20px; /* Can be anything */
		position: relative;
		background: $mc-3;
		border-radius: 25px;
		width: 100%;
		box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
		span {
			display: block;
			height: 100%;
			border-radius: 20px;
			background-color: $color-blue;
			// box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
			position: relative;
			overflow: hidden;
			transition: 300ms;
			&::after {
				content: "";
				position: absolute;
				inset: 0;
				background-image: none;
				background-image: linear-gradient($color-blue, $color-blue);
				z-index: 1;
				background-size: 50px 50px;
				border-radius: 20px;
				overflow: hidden;
			}
		}
		&.animated {
			span {
				&::after {
					content: "";
					position: absolute;
					inset: 0;
					background-image: linear-gradient(
						-45deg,
						rgba(255, 255, 255, 0.2) 25%,
						transparent 25%,
						transparent 50%,
						rgba(255, 255, 255, 0.2) 50%,
						rgba(255, 255, 255, 0.2) 75%,
						transparent 75%,
						transparent
					);
					z-index: 1;
					background-size: 50px 50px;
					animation: move 2s linear infinite;
					border-radius: 20px;
					overflow: hidden;
				}
			}
		}
	}
	.progress {
		margin-top: 10px;
		font-size: $very-small-font-size;
		color: $mc-5;
	}
}

// .progress-bar-container > span:after,
// .animate > span > span {

// }

// .animate > span:after {
//   display: none;
// }
