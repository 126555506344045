.inline-sugg-input-container {
	position: relative;
	.input-text {
		border: 1px solid $border-color-dark;
		height: 138px;
		box-sizing: border-box;
		padding: 8px;
		font-size: $small-font-size;
		line-height: 21px;
		overflow-x: hidden;
		overflow-y: overlay;
		z-index: $zI-level-1;
		white-space: pre-wrap;
		.dynamic-variables {
			color: white;
			background-color: $color-blue;
			padding: 0 5px;
			margin: 1px 2px;
			border-radius: 3px;
		}
		&:focus {
			outline: none;
		}
	}
	.input-suggestion {
		box-sizing: border-box;
		position: absolute;
		bottom: 100%;
		width: 100%;
		border-radius: 3px;
		overflow: hidden;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
		border: 1px solid rgba(0, 0, 0, 0.15);
		transform: translateY(-5px);
		background-color: white;
		visibility: hidden;
		z-index: $zI-top;
		&[data-visible="true"] {
			visibility: visible;
		}
		.suggestion-header {
			padding: 8px;
			background-color: $mc-7;
			color: white;
		}
		.suggestion-option {
			padding: 8px;
			font-size: 12px;
			cursor: pointer;
			&:hover {
				background-color: $mc-2;
			}
			&:focus {
				background-color: $color-blue;
				color: white;
				outline: none;
			}
		}
	}
}
