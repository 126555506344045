.at-input-container {
	position: relative;
	.at-input-label {
		@extend .body-text3;
		display: inline-block;
		width: 100%;
		padding-bottom: 5px;
		color: $mc-5;
		.label-multi-lang-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				transition: 180ms;
				padding-right: 3px;
				&:nth-of-type(even) {
					cursor: pointer;
				}
				&:nth-last-child(1) {
					padding: 0;
				}
			}
			.selected {
				color: $color-blue;
				text-decoration: underline;
			}
			.cascade {
				color: $color-blue-light;
				cursor: pointer;
				&:hover {
					color: $color-blue;
				}
			}
			.info {
				width: 12px;
				margin: 0;
			}
		}
		.info {
			position: relative;
			top: 1px;
			width: 12px;
			margin-left: 5px;
		}
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.at-input-field {
		display: flex;
		.at-label {
			display: flex;
			align-items: center;
			position: absolute;
			height: 34px;
			width: 34px;
			box-sizing: border-box;
			font-size: $body-text-font-size;
			border-top-left-radius: $default-radius;
			border-bottom-left-radius: $default-radius;
			outline: none;
			border: 1px solid $border-color-dark;
			background-color: #fafafa;
			text-align: center;
			cursor: default;
			& > span {
				margin: auto;
			}
			&.at-label--disabled {
				background-color: #ececec;
			}
		}
		.multi-select-filter-container {
			margin: 0 !important;
			position: absolute;
			.react-select__control {
				border-radius: 4px 0 0 4px;
			}
			.Select-value-label {
				color: $mc-9 !important;
			}
			.at-dropdown--read-only.Select--single {
				pointer-events: none;
				cursor: default;
				.Select-control {
					border: 1px solid $border-color-dark;
					background-color: #eaeaea;
				}
				.Select-value-label {
					color: #777 !important;
				}
			}
		}
		.at-copy-to-clipboard {
			position: absolute;
			right: 0;
			.copy-btn {
				display: flex;
				align-items: center;
				height: 34px;
				margin: 0;
				padding: 0 8px;
				border-top-left-radius: 0;
				border-top-right-radius: $default-radius;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: $default-radius;
				box-sizing: border-box;
			}
		}
		.end-icon {
			position: absolute;
			right: 5px;
			top: 55%;
		}
		.character-limit {
			position: absolute;
			right: 0;
			color: #666666;

			margin-right: 10px;
		}
		.input-value-unit {
			position: absolute;
			right: 8px;
			top: 55%;
			color: $mc-6;
			background-color: white;
			font-size: $very-small-font-size;
			padding-left: 5px;
		}
		@keyframes animation-spin {
			to {
				transform: rotate(1turn);
			}
		}
		@-webkit-keyframes animation-spin {
			to {
				-webkit-transform: rotate(1turn);
			}
		}
		.loading-container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 0;
			width: 16px;
			height: 34px;
			padding: 0 10px;
		}
		.at-input-loading {
			-webkit-animation: animation-spin 0.4s infinite linear;
			-o-animation: animation-spin 0.4s infinite linear;
			animation: animation-spin 0.4s infinite linear;
			width: 16px;
			height: 16px;
			box-sizing: border-box;
			border-radius: 50%;
			border: 2px solid #ccc;
			border-right-color: #333;
			display: inline-block;
			position: relative;
			vertical-align: middle;
		}
		.at-btn {
			position: absolute;
			right: 0;
			display: flex;
			justify-content: center;
			height: 34px;
			width: 55px;
			margin: 0;
			border: 1px solid $mc-4;
			border-radius: 0 4px 4px 0;
			font-size: 11px;
			line-height: 32px;
			user-select: none;
			cursor: pointer;
			color: $mc-5;
			transition: 180ms;
			background: $mc-1;
			&.action-success {
				color: $color-green;
				background: #fff;
				border: 1px solid $color-green;
				pointer-events: none;
			}
			&:hover {
				opacity: 1;
				color: $mc-7;
			}
		}
		.at-btn + .loading-container {
			right: 10px;
		}
		.action {
			@include font-line-eq(12px);
			@extend .font--regular;
			text-align: right;
			color: $color-blue;
			cursor: pointer;
			position: absolute;
			top: 34px;
			right: 10px;
			z-index: 1;
			text-decoration: underline;
			img {
				position: relative;
				right: 2px;
				width: 12px;
			}
		}
		.at-input--dropdown + .action {
			top: 35px;
		}
	}
	.at-input {
		height: 34px;
		width: 100%;
		padding: 0px 10px;
		box-sizing: border-box;
		font-size: $body-text-font-size;
		border-radius: $default-radius;
		outline: none;
		border: 1px solid $border-color-dark;
		&.at-input--disabled {
			pointer-events: none;
			cursor: default;
			background-color: #d8d8d8;
		}
		&.at-input--label {
			padding: 0px 10px 0px 44px;
		}
		&.at-input--copy {
			padding: 0px 73px 0px 10px;
		}
		&.at-input--save {
			padding: 0px 64px 0px 10px;
		}
		&.at-input--dropdown {
			height: 36px;
		}
		&::placeholder {
			color: #d2d2d2;
		}
		&:placeholder-shown {
			text-overflow: ellipsis;
		}
	}
	&.at-input--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
	// below style is to hide native browser buttons around input fields
	input {
		-moz-appearance: textfield;
	}
	input::-webkit-inner-spin-button,
	input::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
