.periscope-container {
	.brand-state-filters {
		display: flex;
		flex-wrap: wrap;

		.dropdown-custom {
			width: 220px;

			.container {
				height: 32px;
				padding: 1px 10px;
			}

			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}

				.search-input {
					padding: 0;
				}
			}

			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;

				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;

					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $default-radius;
					}

					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}

					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}

					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}

					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}

					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}
	}

	.actions-required-peri {
		border-radius: 2px;
		border: $box-shadow;

		.action-div-peri {
			.action-title,
			.main-action {
				padding: 0 12px;
			}
			padding: 0 10px 10px 10px;
		}
	}

	.justify-content-between {
		display: flex;
		justify-content: space-between;
	}

	.align-items-center,
	.ai-center {
		display: flex;
		align-items: center;
	}

	.small-size-text {
		font-family: Source Sans Pro;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;
		color: #8a8a8a;
	}

	.medium-size-text {
		font-family: Source Sans Pro;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		text-align: left;
	}

	.section-container-common-periscope,
	.border {
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
		background-color: #ffffff;
		border: solid 1px rgba(0, 0, 0, 0.05);
		border-radius: 2px;
	}

	.parent-container-peri {
		display: flex;

		.first-div-peri {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			gap: 24px;
		}
	}

	.header-periscope {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.dashboard-container-periscope {
		border-radius: 4px;
	}

	.title-peri {
		font-weight: 600;
		font-size: 28px;
	}
	.head-right-div {
		box-sizing: border-box;
		display: flex;
		gap: 16px;

		.auto-refresh {
			user-select: none;
			padding: 8px 8px 8px 12px;
			border: 1px solid #eaeaea;
			display: flex;
			border-radius: 2px;
			align-items: center;
			transition: 180ms;
			height: 42px;

			.popover-container::before {
				display: none;
			}
			.popover-container {
				user-select: none;
			}
			.popover-wrapper-container .popover-container::after {
				top: -7px;
				border-color: transparent transparent #363636 transparent;
				left: 2px;
				border-width: 0 13px 18px 12px;
			}

			.refresh-icon {
				border-right: 1px solid #eaeaea;
				padding: 0 8px 0 0;
				cursor: pointer;

				.popover-container {
					min-width: 90px;

					left: -2px;
				}
				.loading-circle {
					width: 24px;
					height: 24px;
					justify-content: center;
					align-items: center;
					display: flex;
					.circle {
						width: 16px;
						height: 16px;
					}
				}
			}
			.pop-container {
				.popover-container {
					width: 167px;
				}
			}

			.chevron {
				padding: 0 0 0 8px;

				.container {
					padding: 1px 3px;
					height: auto;
					border: none;
					transition: 0;
					&:hover {
						background: #f5f5f5;
					}
				}
				.dropdown-component {
					width: 150px;

					top: 158%;
					left: -119px;
				}

				cursor: pointer;
				.popover-container {
					left: -2px;
					min-width: 126px;
				}
				.chevron-icon {
					border-radius: 1px;
					width: 24px;
					display: flex;
					height: 24px;
					justify-content: center;
					align-items: center;
					&:hover {
						background: #3b5feb;
					}
				}
				.title-option {
					font-weight: 600;
					font-size: 12px;
					line-height: 18px;
					color: #8a8a8a;
					display: flex;
					align-items: center;
					margin: auto;
					padding: 4px 12px;
					margin-top: 3px;
					border: none;
					cursor: pointer;
					transition: 180ms;
				}
				.is-open {
					background: #f2f5ff;
					svg {
						transition: 10ms;
						fill: #3b5feb;
					}
				}

				.refresh-opt {
					display: flex;
					justify-content: space-between;
					gap: 27px;
					width: 100%;
				}
				.option {
					font-family: Source Sans Pro;
					font-weight: 400;
					font-size: 14px;
					line-height: 22px;
					border: none;
					color: #363636;
					.custom-option {
						width: 100%;
					}
					.check-mark {
						margin-right: 5px;
					}
				}

				.option:hover {
					background: #f2f5ff;
					color: #3b5feb;
					transition: 180ms;
				}

				.dropdown-options {
					.option {
						padding: 4px 12px;
						height: auto;
						margin-bottom: 4px;
					}
				}
				.option.selected {
					background: #f2f5ff;
					color: #3b5feb;
					font-weight: 600;
					font-size: 14px;
				}
			}

			.info-popover {
				background: #363636;
				color: #ffffff;
				padding: 8px 12px;
				border-radius: 2px;
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 400;
			}
		}
		.btn-disable {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				height: 100%;
				top: 0;
				width: 53%;
				left: 0;
			}
		}

		.p-border-blue {
			border: 1px solid #3b5feb;
		}

		.help {
			display: flex;
			align-items: center;
			padding: 8px 16px 8px 12px;

			border-radius: 2px;
			line-height: normal;
			height: auto;

			font-family: Source Sans Pro;
			font-size: 16px;
			font-weight: 600;
			height: 41.9px;
		}
	}

	.filter-div {
		gap: 12px;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		width: 22px;
		height: 22px;
		text-transform: uppercase;
		border-radius: 4px;
	}

	.title-action-peri {
		font-family: "Source Sans Pro";
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		text-align: left;
		display: flex;
		align-items: center;
		margin: 18px 5px;
	}

	.action-required-all-divs {
		height: 180px;
	}

	.action-required-main-container {
		cursor: pointer;
		height: 60px;
		display: flex;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		align-items: center;
		.actions-title {
			padding: 0 12px;
			border: 1px solid;
		}

		.display-actions-periscope {
			flex-grow: 1;
			height: 24px;
			gap: 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.action-required-small-title {
				height: 22px;
				display: flex;
				align-items: center;
				.tool-peri {
					width: 13px;
					height: 13px;
					cursor: pointer;
				}

				.info-issues {
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					text-align: right;
					margin: 0 5px;
				}

				.label {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
					margin: 0 13px;
				}
			}

			.zero-percent {
				padding: 0 8px;
				display: flex;
				gap: 8px;

				.zero-arrow {
					.right-arrow {
						width: 10.12px;
						height: 10.31px;
						margin: 0 1px 0 0;
					}
				}
			}

			.actions-bar-periscope,
			.loader {
				width: 250px;
				gap: 24px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 8px;

				.action-percent-periscope {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 4px;

					.percent-action-periscope {
						padding: 4px 8px;

						p {
							font-size: 12px;
							font-weight: 600;
							line-height: 16px;
							text-align: right;
						}
					}

					.arrow-action-periscope {
						img {
							width: 10.12px;
							height: 10.31px;
						}
					}
				}
			}

			.popover-wrapper-container.down-left .popover-container {
				left: -20px;
			}
			.popover-wrapper-container .popover-container {
				position: absolute;
				top: 27px;
				.pop-info {
					padding: 12px;
					width: 262px;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
				}
			}
		}

		.image-div {
			width: 40px;
			height: 40px;
			border-radius: 2.73px;
			position: relative;
			margin: auto;

			.img {
				width: 20px;
				height: 20px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.image-div-red {
			background: #f7edf7;
		}

		.image-div-green {
			background-color: #ecf7f6;
		}
		&:hover {
			background: #f2f5ff;
		}
	}

	.action-required-main-container:last-child {
		border: none;
	}

	.action-required-first-container {
		display: flex;
		flex-basis: 50%;

		.action-required-text-peri {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
		}
	}

	.action-required-second-container {
		flex-basis: 50%;
		display: flex;
		justify-content: flex-end;
	}

	.location-availability-periscope {
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		.availability {
			flex-basis: 50%;
			position: relative;
			.pad-left-16 {
				padding: 0 16px;
			}

			.info-display-periscope {
				* {
					text-decoration: none;
				}
				padding: 12px 16px 12px 16px;
				cursor: pointer;
				&:hover {
					background-color: #f2f5ff;
				}
				margin: 0 0 18px 0;

				div {
					.image-info-peri {
						width: 16px;
						height: 16px;
						margin: 0px 5px 0 0px;
					}

					span {
						color: #646464;
						margin: 0 5px;
					}
				}

				.percentage-display-peri {
					width: 51px;
					height: 24px;
					justify-content: right;

					.show-percentage {
						width: 25px;
					}

					.arrow-periscope {
						width: 12px;
						height: 12px;
						margin: 0 0 0 5px;
					}

					.availability-percentage {
						height: 24px;

						span {
							font-family: Source Sans Pro;
							font-size: 12px;
							font-weight: 600;
							line-height: 16px;
							text-align: left;
							color: #b02b2b;
						}
					}

					.availability-red {
						background: #fff5f5;
						border-radius: 2px;

						span {
							color: #b02b2b;
						}
					}

					.availability-green {
						background: #ecf7f0;

						span {
							color: #0b6e1b;
						}
					}
				}
			}

			.last-div-la-periscope {
				margin: 0 0 16px 0;
				.hyperlink--black-color::after {
					background-color: #3b5feb;
				}

				a {
					text-decoration: none;
				}

				.detailed-info-peri {
					color: #3b5feb;
					width: fit-content;
				}
			}
		}

		.location-periscope {
			margin-right: 24px;
		}

		.information-container {
			height: auto;
			margin: 13px 0;
			border-bottom: solid 1px rgba(0, 0, 0, 0.05);

			.available-indicator-periscope {
				height: 24px;

				.no-of-locations-peri {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}

				div {
					span {
						color: #646464;
						text-align: left;
						margin: 0 5px;
					}
				}

				.main-icon-availability {
					width: 16.5px;
					height: 22.62px;
					margin: 4px;
				}
			}

			.online-locations {
				display: flex;
				align-items: baseline;
				margin: 12px 0;

				.display {
					.total-locations {
						font-family: Source Sans Pro;
						font-size: 24px;
						font-weight: 600;
						line-height: 32px;
						text-align: left;
						padding: 0 5px;
					}

					.online {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
					}
				}
			}

			.all-locations {
				width: 140px;
				height: 32px;
			}

			.bargraph-dashboard,
			.loader-availability {
				margin-top: 16px;
				padding: 0 0 16px 0;

				.status-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.legend-peri {
					margin: 16px 0 0 0;
					display: flex;
					gap: 48px;

					.graph-peri {
						flex-basis: 50%;
						padding: 8px;

						.graph-text {
							display: flex;
							align-items: center;

							.circle {
								width: 8px;
								height: 8px;
								border-radius: 50%;
								margin: 1px;
							}

							.offline {
								background: #b02b2b;
							}

							.online {
								background: #c6d2ff;
							}

							.partial-offline {
								background: #ffa05b;
							}

							.status {
								font-family: Source Sans Pro;
								font-size: 12px;
								font-weight: 600;
								line-height: 16px;
								text-align: left;
								color: #646464;
								margin: 5px;
							}

							.tool-peri {
								width: 10px;
								height: 10px;
								margin: 1px;
							}
						}

						.info {
							font-size: 28px;
							font-weight: 600;
							line-height: 36px;
							text-align: left;
							margin: 0 0 0 12px;
						}

						.arrow-top {
							color: #646464;
							width: 20px;
							height: 20px;
							margin: 0px 2.5px;
						}
					}

					.graph-peri:hover {
						background-color: #eaeaea;
						cursor: pointer;
					}
				}
			}
		}
		.popover-wrapper-container.down-left .popover-container {
			left: -20px;
		}
		.popover-wrapper-container .popover-container {
			position: absolute;
			top: 27px;
			.pop-info {
				padding: 12px;
				width: 249px;
				.main-title {
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}
				color: #363636;
				.info-desc {
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
				}
			}
		}
		.title-desc {
			display: flex;
			flex-direction: column;
			padding: 12px;
		}
		.move-left {
			.popover-wrapper-container.down-right .popover-container {
				right: -20px;
			}
			.popover-wrapper-container.down-right .popover-container::before {
				right: 16px;
			}
			.popover-wrapper-container.down-right .popover-container::after {
				left: auto;
				right: 11px;
			}
			.popover-wrapper-container .popover-container .pop-info {
				width: 219px;
			}
		}
		.title-action-peri {
			.popover-wrapper-container.down-left .popover-container {
				left: -19px;
			}
			.popover-wrapper-container .popover-container {
				top: 29px;
			}
			.ml-4 {
				margin-left: 4px;
				cursor: pointer;
			}
		}
	}

	.location-bar-graphs-place {
		top: 0;
		left: 0;
		width: 150px;
		height: 7.5px;
		top: -7.5px;
		position: absolute;
	}

	.small-bar {
		width: 150px;
		height: 7.5px;
		position: relative;
	}

	.item-loc-availability {
		height: 20px;
	}

	.tooltip-icon-peri {
		width: 13.33px;
		height: 13.33px;
		margin: 0 4px;
	}

	.tooltip {
		position: relative;
		display: inline-block;
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		background-color: white;
		color: black;
		text-align: center;
		border-radius: 6px;
		position: absolute;
		z-index: 1;
		top: 120%;
		left: 50%;
		margin-left: -60px;
		width: 263px;
		display: flex;
		flex-direction: column;
		padding: 12px;
	}

	.tooltip .tooltiptext::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 18.35%;
		border-width: 7px;
		border-style: solid;
		border-color: transparent transparent white transparent;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
	}

	.tooltiptext {
		.tooltip-description {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}
	}

	.right-arrow-peri {
		width: 16.05px;
		height: 16.43px;
		margin: 0 5px;
	}

	.shimmer-container {
		.shimmer {
			height: 20px;
			width: 100%;
		}
	}

	.validation {
		width: 15.35px;
		height: 11.77px;
	}

	.periscope-dashboard {
		overflow: auto;
		scrollbar-width: none;

		.shimmer {
			border-radius: 0px;
		}

		.stop-moving .shimmer::after {
			background: #f4f4f4 !important;
		}
	}

	.section-container-common-periscope {
		.fetch-time {
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			color: #8a8a8a;
			align-self: center;
		}
	}

	.periscope-dashboard {
		.show-left {
			margin-left: -200px;
			width: 232px;
		}

		.show-left::after {
			right: 19.5%;
			left: unset !important;
		}
	}
}
