.right-click-menu {
	display: inline-block;
	position: absolute;
	background-color: $mc-1;
	min-width: 160px;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
	list-style: none;
	border-radius: $border-radius-default;
	left: 0;
	top: 0;
	margin: 0;
	padding-inline-start: 0;
	text-align: left;
	font-size: $very-small-font-size;
	li {
		padding: 10px 15px;
		display: flex;
		text-align: center;
		border: 1px solid $border-color;
		position: relative;
		transition: 180ms;
		color: $color-light-black;
		cursor: default;
		svg,
		img {
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
		&:hover {
			background-color: $mc-2;
		}
	}
	&--unbordered {
		li {
			border-width: 0;
		}
	}
	&--fixed-pos {
		position: fixed;
	}
}
