.item-tags-container {
	.action-buttons-container {
		margin-right: 10px;
	}
	.filter-buttons {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-end;
		& > div {
			float: right;
			position: relative;
			@include bp(768px) {
				float: none;
			}
		}
		.search-icon {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
		.dismiss-search {
			cursor: pointer;
			position: absolute;
			right: 31px;
			top: 50%;
			transform: translateY(-50%);
			height: 13px;
			width: 13px;
			img {
				width: 100%;
			}
		}
		.search-input {
			display: inline-block;
			vertical-align: top;
			outline: 0;
			padding: 0 0 0 30px;
			font-size: $very-small-font-size;
			border: 0;
			border-bottom: 1px solid $divider-color;
			width: 200px;
			height: 36px;
			box-sizing: border-box;
			margin-right: 10px;
			@include bp(768px) {
				margin-right: 0px;
				width: 100%;
			}
		}
		.search-input-holder {
			@include bp(768px) {
				margin-top: 10px;
				display: flex;
			}
		}
		.filter-button-container {
			display: inline-block;
			vertical-align: top;
			border: 1px solid $mc-4;
			padding: 8px 16px;
			cursor: pointer;
			text-align: center;
			img {
				display: inline-block;
				vertical-align: top;
			}
			.filter-button-title {
				display: inline-block;
				vertical-align: top;
			}
		}
		.filter-count {
			background: $color-green;
			color: #fff;
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			border-radius: 100%;
			display: inline-block;
			position: absolute;
			right: -10px;
			top: -10px;
		}
	}
	.common-sidebar-container {
		border-top: 1px solid $border-color;
		margin-top: 10px;
	}
	.sidebar-tabs-container {
		max-height: 650px;
		margin: 0;
		.at-side-bar {
			position: initial;
			top: initial;
			.selected {
				.custom-sidebar-item {
					.text {
						padding: 7px 0;
					}
				}
			}
			.no-items-placeholder {
				padding-top: 35%;
				padding-bottom: 35%;
			}
		}
		.custom-sidebar-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.action-item {
				padding: 5px;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				height: 12px;
				width: 12px;
				margin-left: 10px;
				cursor: pointer;
			}
		}
		.badge {
			padding: 1px 6px;
			margin: 0 5px;
			border-radius: 10px;
			text-align: center;
			font-size: 11px;
			display: inline-block;
			color: $color-red;
			border: 1px solid $color-red;
			background: rgba(192, 57, 43, 0.1);
		}
	}
	.sidebar-content-container {
		padding: 20px 25px;
		.loading {
			margin-top: 15px;
		}
		.at-paginator {
			padding: 0;
			@include bp(768px) {
				padding: 0 15px;
			}
		}
	}
	.item-tags-list-table-container {
		@include bp(768px) {
			padding: 0 15px;
		}
		.transactions-list-table {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-top: 25px;
			.transaction-header-row {
				border-top: none;
			}
		}
		.title {
			width: 50%;
			padding-left: 25px;
			.title-desc {
				display: inline-block;
				width: calc(100% - 70px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.associated_items {
			width: 25%;
			text-align: right;
		}
		.associated_modifiers {
			width: 25%;
			text-align: right;
			padding-right: 25px;
		}
	}
}
.tag-group-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.item-tags-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.tag-group-item-tags-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 60px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.title {
				width: 40%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.crm-title {
					color: $mc-5;
				}
			}
			.num-items {
				width: 30%;
				text-align: right;
			}
			.num-modifiers {
				width: 30%;
				text-align: right;
				padding-right: 25px;
			}
		}
	}
}
.item-tag-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		padding: 15px;
		margin-bottom: 25px;
		.header-text {
			font-size: $very-small-font-size;
			@extend .font--semibold;
		}
		.header-subtext {
			color: $mc-5;
			font-size: $very-small-font-size;
			padding-top: 5px;
		}
	}
	.associated-items-tab {
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.item-tag-edit-associated-items-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.item-title {
				width: 60%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.item-desc {
					display: flex;
					.food-type {
						margin: auto 0;
					}
					.link-text {
						margin-left: 10px;
					}
				}
				.crm-title {
					color: $mc-5;
					padding-left: 22px;
				}
				.item_tags {
					padding-left: 23px;
					.tag-item {
						margin: 2px 5px 0px 0px;
					}
				}
			}
			.category {
				width: 20%;
			}
			.price {
				width: 20%;
				padding-right: 25px;
				text-align: right;
			}
		}
	}
	.associated-modifiers-tab {
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.item-tags-edit-modifiers-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 65px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.title {
				width: 60%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.title-desc {
					display: flex;
					.food-type {
						margin: auto 0;
					}
					.link-text {
						margin-left: 10px;
					}
				}
				.handle {
					color: $mc-5;
					padding-left: 23px;
				}
			}
			.price {
				width: 40%;
			}
		}
	}
}
