.date-compare-filter-container {
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	&:focus {
		outline: none;
	}
	.date-calender-input {
		@extend .react-datepicker--inline;
	}
	.date-control-ui {
		padding: 10px;
		min-height: 265px;
		box-sizing: border-box;
	}
	.date-filter-section {
		display: flex;
		flex-direction: row;
		.date-section-title {
			font-size: 12px;
			@extend .font--semibold;
			color: $mc-5;
			margin: 10px 10px 0 0;
			min-width: 65px;
		}
		.date-section-column {
			.date-section-control-row {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 5px;
			}
			.type-selector {
				.at-dropdown {
					width: 150px;
					display: inline-block;
					@include bp(768px) {
						margin-right: 20px;
					}
					.Select-control {
						border: none;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
						background-color: white;
						&:hover {
							box-shadow: none;
						}
					}
					&.Select.is-open > .Select-control {
						border-color: $mc-5;
					}
					&.Select.is-focused:not(.is-open) > .Select-control {
						border-color: $border-color-dark;
					}
				}
				.at-dropdown.dropdown-first {
					margin-right: 22px;
				}
			}
			.date-range-input {
				.seperator {
					margin: 0 5px;
					line-height: 34px;
					font-size: 12px;
					color: $mc-5;
					@extend .font--semibold;
				}
				.inline-input-date.at-input-container {
					display: inline-block;
					width: 150px;
					input {
						border: none;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
						font-size: $very-small-font-size;
						transition: 180ms;
						cursor: pointer;
					}
					&.active input {
						border-bottom-color: $color-blue;
					}
				}
			}
		}
	}
	.date-filter-section.date-compare {
		margin-top: 15px;
	}
	.date-filter-action {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		font-size: $small-font-size;
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-size: $very-small-font-size;
		font-size: 12px;
		text-transform: uppercase;
		@extend .font--semibold;
		.btn-apply {
			margin-right: 15px;
			color: $color-blue;
			cursor: pointer;
		}
		.btn-reset {
			margin-right: 15px;
			color: $color-blue;
			cursor: pointer;
		}
		.btn-cancel {
			color: $mc-5;
			cursor: pointer;
			transition: 180ms;
			&:hover {
				color: $mc-7;
			}
		}
	}
}
