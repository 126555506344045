.at-color-picker {
	.field-label {
		font-size: 12px;
		padding-top: 3px;
		padding-bottom: 5px;
		color: $mc-5;
		.info {
			position: relative;
			top: 1px;
			width: 12px;
			margin-left: 5px;
		}
		[aria-label][data-balloon-pos]:after {
			z-index: 1000;
			width: 350px;
			max-width: max-content;
			white-space: normal;
		}
	}

	.color-picker-area-container {
		width: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		position: relative;
		&.read-only {
			cursor: default;
			pointer-events: none;
			background-color: #eaeaea;
		}
		& > input {
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 0;
		}
		.color-preview {
			height: 32px;
			width: 32px;
			border: 1px solid $border-color-dark;
			background-color: $color-blue;
			border-radius: $default-radius;
			margin-right: 10px;
		}
		.at-input-container {
			flex: 1;
		}
	}
}
