.onboarding-container {
	padding-bottom: 30px;
	// reset the existing browser styles for the heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		padding: 0;
		margin: 0;
	}

	.onboarding-loader {
		height: 100vh;
	}
	.onboarding-step {
		// .onboarding-step-heading {
		// 	margin-bottom: 20px;
		// }
		margin-top: 24px;
		padding: 0 24px;
		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;
			.text-h5 {
				font-weight: 600;
			}
		}
		.flow-complete {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-top: 24px;
		}
	}
	.onboarding-all-steps {
		margin-top: 24px;
		padding: 0 24px;
		.text-h5 {
			font-weight: 600;
		}
		.onboarding-all-steps-heading {
			margin-bottom: 12px;
		}
		.onboarding-all-steps-list {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.text-btn-l {
				line-height: inherit;
			}

			.onboarding-step__info-component {
				width: 100%;
				.onboarding-step-text {
					display: flex;
					align-items: center;
					gap: 8px;
					margin-top: 2px;
					margin-bottom: 5px;
					font-weight: 600;
					justify-content: flex-end;
					.step-progress-indicator {
						height: 7px;
						width: 7px;
						background-color: $color-blue-40;
						border-radius: 100px;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}

				.onboarding-step__progress-bar-wrapper {
					width: 330px;
					display: flex;
					align-items: center;
					gap: 2px;
					margin-top: 2px;
					.progress-bar {
						transition: width 300ms ease;
						border-radius: 1px;
					}
					.onboarding-step-locations-text {
						white-space: nowrap;
						margin-left: 15px;
						font-weight: 600;
					}
				}

				.onboarding-step-complete-chip {
					background-color: $color-green-10;
					color: $color-green-70;
					padding: 2px 8px;
					border-radius: 2px;
					font-weight: 600;
				}
			}
		}
	}
	.onboarding-helper {
		margin-top: 40px;
		padding: 0 24px;
		.onboarding-helper-heading {
			margin-bottom: 24px;
		}
		.onboarding-helper-list {
			padding: 12px 0px 12px 0px;
			border: 1px solid var(--up-black-10);
			border-radius: 4px;
			.onboarding-helper-card {
				padding: 20px 24px;
				display: flex;
				align-items: center;

				.onboarding-helper-card-icon {
					height: 20px;
					width: 20px;
					margin-inline-end: 14px;
				}
				.onboarding-helper-card-title {
					flex-grow: 1;
				}
				.onboarding-helper-card-cta {
					.onboarding-helper-card-cta-icon {
						width: 12px;
						height: 10px;
					}
				}
			}
		}
	}
}

.onboarding-banner-card {
	padding: 24px 24px 0;
}
