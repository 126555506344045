.dropdown-action-buttons {
	cursor: pointer;
	position: relative;
	.action-title-container {
		box-sizing: border-box;
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		padding: 7px 2px 2px 7px;
		transition: 180ms;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: white;
		&:hover {
			box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
		}
	}
	.action-title {
		font-size: $very-small-font-size;
		line-height: $very-small-font-size;
	}
	.actions-list {
		position: absolute;
		top: 35px;
		right: 0;
		width: auto;
		min-width: 100%;
		white-space: nowrap;
		border-radius: $default-radius;
		background-color: white;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
	}
	.action-item {
		padding: 7px 5px;
		border-bottom: 1px solid $mc-2;
		font-size: $very-small-font-size;
		color: $color-blue;
		transition: 180ms;
		&:first-child {
			border-radius: 4px 4px 0 0;
		}
		&:last-child {
			border-radius: 0 0 4px 4px;
			border-bottom: 0;
		}
		&:hover {
			background-color: $color-blue;
			color: white;
			border-bottom-color: $color-blue;
		}
	}
}
