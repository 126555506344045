.archive-restore--modal {
	.at-modal {
		.modal-header {
			.header-left {
				.title {
					color: $color-light-black;
					text-align: left;
					font-size: 18px;
				}
			}
			border-bottom: 1px solid $border-color;
		}
		.modal-container {
			width: 30%;
			min-width: 505px;
		}
		.modal-footer {
			text-align: right;
			border-top: 1px solid $border-color;
			.restore-action {
				background-color: white;
				border: 1px solid $color-green;
				color: $color-green;
				&:hover {
					background-color: $color-green;
					color: $mc-1;
					opacity: 1;
				}
			}
		}
		.entity-details {
			padding: 25px;
			.brand-locations-count {
				font-weight: $font--semibold;
				font-size: 18px;
				color: $color-light-black;
			}
			.item-assoc-count {
				margin-top: 8px;
			}
			.warning {
				margin: 24px 0;
			}
			.confirm-process {
				display: flex;
				gap: 13px;
				align-items: center;
				.at-checkbox {
					position: relative;
					padding-left: 0;
					.checkmark {
						position: relative;
						display: block;
						height: 14px;
						width: 14px;
					}
				}
			}
			.end-result {
				display: flex;
				padding: 25px 0;
				border-radius: $border-radius-default;
				justify-content: center;
				&.success {
					background-color: rgba($color-green, 0.1);
					color: $color-green;
					border: 1px solid $color-green;
				}
				&.failure {
					background-color: rgba($color-red, 0.1);
					color: $color-red;
					border: 1px solid $color-red;
				}
			}
		}
	}
}
