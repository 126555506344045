.at-uploader-container {
	.field-label {
		font-size: 12px;
		padding-top: 3px;
		padding-bottom: 5px;
		color: $mc-5;
		[aria-label][data-balloon-pos]:after {
			z-index: 1000;
			width: 350px;
			max-width: max-content;
			white-space: normal;
		}
		.info {
			position: relative;
			top: 2px;
			width: 12px;
			margin-left: 5px;
		}
		.description {
			position: relative;
			top: -3px;
			font-size: 10px;
			color: $mc-5;
		}
	}
	.uploader-area-container {
		width: 100px;
		border: 1px dashed $color-blue;
		border-radius: $default-radius;
		padding: 15px 5px;
		background-color: transparentize($color-blue, 0.9);
		cursor: pointer;
		position: relative;
		text-align: center;
		&.read-only {
			cursor: default;
			pointer-events: none;
			border: 1px dashed $border-color-dark;
			background-color: #eaeaea;
			.upload-icon {
				filter: grayscale(1);
				opacity: 0.6;
			}
			.preview-image {
				opacity: 0.6;
			}
			.at-uploader-label {
				color: #777;
				span {
					color: #777;
				}
			}
		}
		.upload-icon {
			height: 50px;
			width: 50px;
			display: block;
			margin: 0 auto 10px auto;
		}
		.preview-image {
			max-width: 100%;
		}
		.at-uploader-label {
			font-size: 12px;
			text-align: center;
			span {
				color: $color-blue;
			}
		}
		.hover-icons {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.3);
			border-radius: $default-radius;
			color: white;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			flex-wrap: wrap;
			z-index: 10;
			span {
				font-size: 12px;
			}
			img {
				padding: 15px;
			}
			.icon-edit {
				width: 25px;
			}
			.icon-view {
				width: 25px;
			}
			.icon-download {
				width: 25px;
			}
			.icon-delete {
				width: 23px;
			}
		}
	}
}

.at-uploader-container-v2 {
	.uploader-area-container {
		width: auto;
		height: 180px;
		border: 1px dashed $border-color-dark;
		border-radius: $border-radius-default;
		padding: 15px 5px;
		background-color: $color-lightest-grey;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.at-uploader-label {
			color: $color-light-black;
			font-size: $very-small-font-size;
		}
		.img-size-limit-label {
			font-size: $exta-small-font-size;
			color: $mc-6;
		}
		.upload-icon {
			width: 70px;
			height: 60px;
		}
		.preview-image {
			max-width: 100%;
			max-height: 100%;
		}
	}
}
