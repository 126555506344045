.transaction-section {
	background-color: #fff;
	padding-bottom: 15px;
	margin-bottom: 15px;
	.new-date-compare-filter {
		width: auto;
		padding: 25px 25px 0;
	}
}

.store-and-date-selector {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 25px;
	@include bp(768px) {
		flex-direction: column;
		margin-bottom: 8px;
	}
	.store-type-selector {
		@include bp(768px) {
			display: flex;
			justify-content: space-between;
			margin: 10px;
		}
		.store-type {
			@include bp(768px) {
				width: 40%;
				margin-right: 0;
				text-align: center;
			}
			display: inline-block;
			vertical-align: top;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			margin-right: 10px;
			padding: 9px 12px;
			cursor: pointer;
			background-color: white;
			transition: 180ms;
			font-size: $very-small-font-size;
			text-transform: uppercase;
			&:hover {
				border-color: $color-blue;
				color: $color-blue;
			}
			&.active {
				background-color: $color-blue;
				border-color: $color-blue;
				color: white;
			}
			&.active:hover {
				opacity: 0.6;
			}
		}
	}
	.duration-dropdown-selector {
		white-space: nowrap;
		width: 170px;
		@include bp(768px) {
			width: auto;
			margin: 10px;
		}
	}
	.date-filter-container {
		margin-bottom: 0;
		min-height: 265px;
		box-sizing: border-box;
	}
	.date-filter-action-container {
		display: inline-block;
		vertical-align: top;
		padding: 10px;
		min-width: 350px;
		@include bp(768px) {
			width: auto;
			padding: 5px;
		}
		.date-filter-action {
			text-align: right;
			position: absolute;
			bottom: 10px;
			right: 10px;
			cursor: pointer;
			font-size: 12px;
			text-transform: uppercase;
			@extend .font--semibold;
			.apply {
				color: $color-blue;
				margin-right: 25px;
				display: inline-block;
				vertical-align: top;
			}
			.cancel {
				display: inline-block;
				vertical-align: top;
				color: $mc-5;
				transition: 180ms;
				&:hover {
					color: $mc-7;
				}
			}
		}
		.Select {
			.Select-control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
			}
		}
	}
	.calender-range-first,
	.calender-range-second {
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		@extend .react-datepicker--inline;
	}
}

.transactions-list-header.credits-section-header {
	.header--actions {
		margin-top: 14px;
		display: flex;
		justify-content: space-between;
		.filter-buttons {
			display: flex;
			gap: 12px;
			flex-wrap: wrap;
			.select-filter-custom {
				&.brand-filter {
					.dropdown-custom {
						width: 230px;
						.container {
							height: 32px;
							padding: 1px 10px;
						}
						.dropdown-options {
							.option {
								height: 37px;
								padding: 1px 10px;
							}
						}
						.custom-option,
						.custom-label {
							display: flex;
							align-items: center;
							.logo {
								display: flex;
								align-items: center;
								justify-content: center;
								margin-right: 8px;
								width: 22px;
								height: 22px;
								text-transform: uppercase;
								font-size: $very-small-font-size;
								border-radius: $border-radius-default;
								@extend .font--semibold;
								img {
									width: 22px;
									height: 22px;
									object-fit: cover;
									border-radius: $border-radius-default;
								}
								&.brown {
									color: $color-brown-dark;
									background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
								}
								&.blue {
									color: $color-blue-dark;
									background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
								}
								&.green {
									color: $color-green-dark;
									background-color: rgba($color: $color-green-dark, $alpha: 0.1);
								}
								&.red {
									color: $color-red-dark;
									background-color: rgba($color: $color-red-dark, $alpha: 0.1);
								}
								&.purple {
									color: $color-violet;
									background-color: rgba($color: $color-violet, $alpha: 0.1);
								}
							}
						}
					}
				}
			}
		}
		.section-2-filters {
			.search-input-holder {
				display: flex;
				margin: 0;
				.search-input {
					outline: none;
				}
				.dropdown-custom {
					margin-left: 12px;
					.container {
						border-radius: 2px;
					}
				}
			}
		}
		.container {
			border: 1px solid #d0dadd;
			border-radius: 2px;
			text-align: center;
			white-space: nowrap;
			cursor: pointer;
			// width: 100px;
			position: relative;
			.filter-icon {
				width: 16px;
			}
			.filter-title {
				font-size: 15px;
				color: #000000;
				line-height: normal;
				margin-left: 8px;
				transition: 0.3s;
				.filter-count {
					background: #2ecc71;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
		}
	}
	.campaign-list-filter .container {
		border: 1px solid $border-color-dark;
		padding: 7px 16px;
	}
	.product-type {
		.multi-select-filter-container {
			width: 150px;
			font-size: $very-small-font-size;
			margin-bottom: 15px;
		}
	}
	.search-input-holder {
		display: flex;
		margin: 0 10px;
		font-size: $very-small-font-size;
		.at-input-field {
			.at-input {
				min-height: 32px;
				border-radius: $border-radius-default;
			}
		}
		@include bp(768px) {
			width: fit-content;
			margin-left: 0px;
		}
		.search-input {
			padding-left: 10px;
			border: 1px solid $mc-4;
			border-radius: $default-radius;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-left: 0;
			margin: 0px;
			font-size: $very-small-font-size;
			&::placeholder {
				color: #d2d2d2;
			}
		}
		.multi-select-filter-container {
			width: 130px;
			.at--dropdown {
				.react-select__control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
		&.customer {
			.multi-select-filter-container {
				width: 160px !important;
			}
		}
		.dismiss-search {
			width: 11px;
			right: 15px;
			top: 16px;
		}
	}
	// override style for select filter component
	.multi-select-filter-container {
		&.filter-section-item {
			opacity: 1;
		}
	}
}

.orders-list-header {
	display: flex;
	flex-direction: column;
	.header-text {
		padding-bottom: 15px;
	}
	.header--actions {
		.search-input-holder {
			.at-input {
				padding-right: 25px;
			}
			.search-input-container {
				position: relative;
				.dismiss-search {
					position: absolute;
					right: 10px;
					top: 10px;
					img {
						height: 11px;
						width: 11px;
					}
				}
			}
		}
		.at-btn--filter {
			box-sizing: border-box;
		}
	}
}

.transactions-container,
.customer-feedbacks,
.customer-timeline,
.sales-analytics-performance {
	.placeholder-main {
		margin-top: 20px;
	}
	a {
		text-decoration: none;
	}
	&.customers-list {
		.customers-filters {
			display: flex;
			align-items: center;
			padding: 15px 25px;
			.dropdown-date-time-filter {
				margin-right: 10px;
			}
			.search-input-holder {
				display: flex;
				margin-left: auto;
				font-size: $very-small-font-size;
				position: relative;
				.at-input-field {
					.at-input {
						width: 170px;
						min-height: 32px;
						padding-right: 10px;
						border-radius: $border-radius-default;
					}
				}
				@include bp(768px) {
					width: fit-content;
					margin-left: 0px;
				}
				.search-input {
					padding-left: 10px;
					border: 1px solid $mc-4;
					border-radius: $default-radius;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
					border-left: 0;
					outline: none;
					margin: 0px;
					font-size: $very-small-font-size;
					&::placeholder {
						color: #d2d2d2;
					}
				}
				.multi-select-filter-container {
					width: 160px;
					.at--dropdown {
						.react-select__control {
							border-top-right-radius: 0px;
							border-bottom-right-radius: 0px;
						}
					}
				}
				.dismiss-search {
					position: absolute;
					width: 11px;
					right: 12px;
					top: 11px;
					cursor: pointer;
					img {
						width: 11px;
					}
				}
			}
		}
	}
	.transactions-list-table {
		margin-top: 20px;
		margin-bottom: 10px;
		@include bp(768px) {
			margin-top: 5px;
		}
		.at-table-row {
			transform-origin: center;
			will-change: transform;
			.at-table-header {
				padding-top: 10px;
				padding-bottom: 10px;
				@include bp(768px) {
					padding-top: 5px;
					padding-bottom: 5px;
				}
				&.active {
					color: $color-blue;
				}
			}
			&.ordering {
				.id {
					padding-left: 25px;
					.icon-link--container {
						display: flex;
						gap: 6px;
						align-items: center;
						.order-plt-icon {
							width: 20px;
							height: 20px;
							object-fit: contain;
							border-radius: $default-radius;
						}
					}
					.refund-alert {
						a {
							cursor: default;
						}
						position: absolute;
						right: 20px;
						top: 6px;
						.alert-icon {
							height: 15px;
							width: 15px;
						}
						.refund-message {
							top: 0;
							left: 15%;
							position: fixed;
							background-color: $mc-1;
							box-shadow: $default-shadow;
							border-radius: $default-radius;
							padding: 10px;
							font-size: $very-small-font-size;
							width: 150px;
							height: 50px;
							text-align: left;
							.header {
								font-weight: 600;
								margin-bottom: 5px;
							}
							.current-status {
								color: $mc-5;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								height: 70%;
								overflow-y: hidden;
							}
						}
						@include bp(1200px) {
							right: 0px;
						}
					}
				}
				.outlet {
					width: 17.3%;
				}
				.amount {
					width: 10.3%;
					text-align: right;
					padding-right: 25px;
				}
				.user {
					width: 14.3%;
					flex-direction: column;
				}
				.status {
					width: 13.3%;
					.status-value {
						text-transform: uppercase;
						width: 100%;
						padding: 4px 8px;
						height: 25px;
						color: white;
						line-height: 26px;
						border-radius: $border-radius-default;
						font-size: $very-small-font-size;
						text-align: center;
						font-weight: 600;
						@include truncate_text;
					}
				}
				.brand {
					.brand-icon {
						height: 20px;
						width: 20px;
					}
				}
				.date,
				.payment {
					width: 10.2%;
				}
				@include bp(768px) {
					.id {
						width: 29%;
						padding-left: 10px;
						position: relative;
						.ext-plt-icon {
							position: absolute;
							top: 8px;
							left: 1px;
							height: 14px;
						}
					}
					.date,
					.user {
						width: 25%;
					}
					.amount {
						width: 21%;
						padding-right: 25px;
						@include bp(768px) {
							padding-right: 10px;
						}
					}
				}
			}
			&.loyalty {
				.id {
					width: 22%;
				}
				.amount {
					width: 18.75%;
					text-align: right;
					padding-right: 25px;
				}
				.date,
				.outlet,
				.user {
					width: 18.75%;
				}
				@include bp(768px) {
					.id {
						width: 29%;
						padding-left: 10px;
					}
					.date,
					.user {
						width: 25%;
					}
					.amount {
						width: 21%;
						padding-right: 25px;
					}
				}
			}
			&.customers {
				.customers-details {
					width: 20%;
					padding-left: 25px;
					position: relative;
					.ext-plt-icon {
						position: absolute;
						top: 7px;
						left: 25px;
						height: 20px;
					}
				}
				.email {
					width: 20%;
				}
				.signup-date,
				.outlet {
					width: 15%;
				}
				.num-orders {
					position: relative;
					width: 15%;
					text-align: right;
					.recent-orders {
						position: absolute;
						color: $mc-5;
						top: 24px;
						right: 15px;
					}
				}
				.orders-value {
					padding-right: 25px;
					width: 15%;
					text-align: right;
				}
				.points {
					width: 13.3%;
					text-align: right;
				}
				.balance {
					padding-right: 25px;
					width: 13.3%;
					text-align: right;
				}
				@include bp(768px) {
					.customers-details {
						width: 25%;
						padding-left: 10px;
						position: relative;
						.ext-plt-icon {
							position: absolute;
							top: 8px;
							left: 1px;
							height: 14px;
						}
					}
					.signup-date {
						width: 15%;
					}
					.points,
					.balance {
						width: 18%;
						padding-right: 10px;
					}
					.email {
						width: 25%;
					}
					.num-orders {
						width: 15%;
						text-align: right;
					}
					.orders-value {
						padding-right: 25px;
						width: 15%;
						text-align: right;
					}
				}
			}
			.outlet {
				white-space: nowrap;
			}
		}
		.at-cell-text {
			font-size: $very-small-font-size;
			color: $mc-9;
			//@extend .font--semibold;
			@include bp(768px) {
				font-size: $very-small-font-size;
				padding: 5px;
			}
		}
		.transaction-header-row {
			border-top: 1px solid $divider-color;
			border-bottom: 1px solid $divider-color;
			background-color: $mc-2;
			position: sticky;
			top: 57px;
			z-index: 1;
			@include bp(768px) {
				position: initial;
			}
		}
		.transaction-rows {
			border-bottom: 1px solid $divider-color;
			padding-top: 8px;
			padding-bottom: 8px;
			transition: 180ms;
			min-height: 80px;
			box-sizing: border-box;
			@include bp(768px) {
				min-height: initial;
				padding-top: 2px;
				padding-bottom: 2px;
			}
			&:last-child {
				border-bottom: 0px;
			}
			.at-table-cell {
				& > div {
					margin-bottom: 4px;
					&:last-child {
						margin-bottom: 0;
						&.phone,
						&.date-time {
							padding-left: 0px;
						}
					}
				}
			}
			.upiper-id {
				padding-left: 25px;
				color: $color-blue;
				text-transform: capitalize;
				@extend .font--semibold;
			}
			.external-id {
				margin-top: 5px;
			}
			.external-id,
			.date-time,
			.phone {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-left: 0px;
				@include bp(768px) {
					font-size: 12px;
				}
			}
		}
	}
}
