.choose-onboarding-method-container {
	margin: 36px 24px;
	.choose-onboarding-method-header {
		&__title {
			line-height: 32px;
		}
		&__sub-title {
			margin-top: 12px;
			line-height: 22px;
		}
	}

	.choose-onboarding-method-body {
		display: flex;
		gap: 24px;
		margin-top: 32px;
		.onboarding-method-card {
			cursor: pointer;
			min-height: 426px;
			max-width: 400px;
			border: 1px solid var(--up-black-10);
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			&.coming-soon {
				cursor: default;
				pointer-events: none;
			}
			&__image {
				flex: 0.7;
				height: 100%;
				width: 100%;
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
			}
			&__content {
				padding: 24px;
				flex: 0.3;
				&__recommended {
					padding: 2px 8px;
					background-color: $color-yellow-10;
					width: fit-content;
					color: $color-yellow-70;
					line-height: 22px;
					border-radius: 2px;
					&--hidden {
						visibility: hidden;
					}
				}
				&__coming-soon {
					padding: 2px 8px;
					background-color: #f7edf7;
					width: fit-content;
					color: #753675;
					line-height: 22px;
					border-radius: 2px;
					&--hidden {
						visibility: hidden;
					}
				}
				&__title {
					margin-top: 8px;
					line-height: 24px;
					.title-arrow-icon {
						position: relative;
						top: 3px; // to align the arrow with the text
						margin-left: 2px;
					}
				}
				.text-h5 {
					font-weight: 600;
				}
			}
		}
	}
}

.choose-onboarding-brands {
	margin: 48px 24px;
	flex-grow: 1;
	.brands-list {
		margin-top: 32px;
		display: flex;
		align-items: center;
		gap: 32px;
		flex-wrap: wrap;
		.brand-card {
			padding: 16px 16px 0 16px;
			cursor: pointer;
			border-radius: 2px;
			border: 1px solid $divider-color;
			&--disabled {
				opacity: 0.3;
				cursor: not-allowed;
			}
			.brand-image-wrapper {
				position: relative;
				width: 96px;
				height: 96px;
				border-radius: 2px;
				div,
				img {
					border-radius: 2px;
					width: 100%;
					height: 100%;
				}

				.at-checkbox {
					position: absolute;
					top: -8%;
					right: -14%;
					height: 20px;
					width: fit-content;
					.checkmark {
						border: 2px solid $mc-3;
						border-radius: 10px;
						background-color: $mc-1;
						opacity: 0.8;
					}
					input:checked ~ .checkmark {
						background-color: $color-blue;
						opacity: 1;
					}
					&.at-checkbox--disabled {
						opacity: 0.3;
					}
				}
			}
			.brand-name {
				margin: 8px 0;
				line-height: 22px;
				width: 100%;
				text-align: center;
				max-width: 12ch; /* Limit the text to 12 characters */
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.no-brands-found {
		margin-top: 32px;
		text-align: center;
	}
}

.common-onboarding-banner {
	// reset the existing browser styles for the heading tags
	margin: 16px 0;
	display: flex;
	gap: 12px;
	border-left: solid 6px $color-blue-40;
	padding: 16px 24px;
	border-radius: 2px;
	background-color: $color-blue-05;
	.common-onboarding-banner__right {
		.title {
			font-size: 18px;
			margin: 0;
		}
		.subtitle {
			margin: 4px 0 0;
		}
		.back-to-setup-cta {
			margin-top: 24px;
		}
	}
}
