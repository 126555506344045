.at-checkbox {
	display: block;
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	font-size: $body-text-font-size;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 14px;
		width: 14px;
		border-radius: $chk-box-radius;
		border: 1px solid $btn-grey-color;
		&:after {
			content: "";
			position: absolute;
			left: 4.5px;
			top: 1px;
			width: 4px;
			height: 8px;
			border: solid transparent;
			border-width: 0px 1px 1px 0px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&.read-only {
		cursor: default;
		pointer-events: none;
		color: #777;
		input:checked ~ .checkmark {
			background-color: rgba($color: $color-blue, $alpha: 0.6);
		}
		&.at-checkbox--disabled {
			opacity: 0.3;
		}
	}
	&.read-only.disabled {
		opacity: 1;
	}
	input:checked ~ .checkmark {
		background-color: $color-blue;
		border-color: transparent;
		&:after {
			content: "";
			position: absolute;
			left: 4.5px;
			top: 1px;
			width: 4px;
			height: 8px;
			border: solid $mc-1;
			border-width: 0px 1px 1px 0px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	input:checked ~ .checkmark:after {
		display: block;
	}
	&.at-checkbox--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	&--partial {
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 14px;
			width: 14px;
			border-radius: $chk-box-radius;
			border: 1px solid $btn-grey-color;
			&:after {
				content: "";
				position: absolute;
				left: 4.5px;
				top: 1px;
				width: 4px;
				height: 8px;
				border: solid transparent;
				border-width: 0px 1px 1px 0px;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
		input:checked ~ .checkmark {
			background-color: $color-blue;
			border-color: transparent;
			&:after {
				content: "";
				position: absolute;
				left: 0px;
				top: 5.5px;
				width: 8px;
				height: 0px;
				border: 1px solid $mc-1;
				-webkit-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);
			}
		}
	}
}
