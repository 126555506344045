.menu-creation-container {
	padding: 0 25px;
	.at-textarea {
		resize: none;
	}
	.select-filter-custom {
		flex-grow: initial !important;
		max-width: none !important;
	}
	.dropdown-custom {
		max-width: none !important;
		.container {
			height: 32px;
			padding: 1px 10px;
		}
		.dropdown-options {
			.option {
				height: 37px;
				padding: 1px 10px;
			}
		}
		.custom-option,
		.custom-label {
			display: flex;
			align-items: center;
			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				width: 22px;
				height: 22px;
				text-transform: uppercase;
				font-size: $very-small-font-size;
				border-radius: $default-radius;
				@extend .font--semibold;
				img {
					width: 22px;
					height: 22px;
					object-fit: cover;
					border-radius: $default-radius;
				}
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
			}
		}
	}
}
