.charges-container {
	.action-buttons-container {
		margin-right: 10px;
	}
	.charges-list-table-container {
		.name {
			width: 40%;
			padding-left: 25px;
			.name-desc {
				display: inline-block;
				width: calc(100% - 70px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.applicable-on {
			width: 15%;
		}
		.merchant-ref-id {
			width: 15%;
		}
		.items {
			width: 15%;
			text-align: right;
		}
		.locations {
			width: 15%;
			padding-right: 25px;
			text-align: right;
		}
	}
	.card-container {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		padding: 20px;
		margin-bottom: 30px;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
		.form-row {
			margin-bottom: 25px;
		}
		.at-btn--secondary {
			width: auto;
			border: 1px solid $mc-4;
			color: $mc-9;
			&:hover {
				background: $mc-1;
			}
		}
	}
}
.charge-edit-container {
	.form-container {
		.form-content {
			padding: 0 25px 100px 25px;
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
}
.min-max-range {
	display: flex;
	align-items: center;
	justify-content: space-between;
	& > div {
		width: 45%;
	}
}
.value-range {
	display: flex;
	justify-content: space-between;
	& > div {
		width: 100%;
	}
	.delete-range {
		display: flex;
		align-items: flex-end;
		flex-direction: row-reverse;
		width: 25%;
		.delete-icon {
			width: 15px;
			padding: 5px;
			border: 1px solid $color-red;
			border-radius: $default-radius;
			cursor: pointer;
			margin-bottom: 5px;
		}
	}
}
.add-another-range {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}
