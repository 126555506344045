.create-campaign-who-container {
	margin-right: 25px;
	@include bp(768px) {
		margin: 0px;
	}
	.filters-container {
		width: 65%;
		float: left;
		@include bp(768px) {
			float: none;
			width: 100%;
			box-sizing: border-box;
		}
	}
	.segment-size-container {
		position: sticky;
		top: 100px;
		float: right;
		@include bp(768px) {
			float: none;
		}
		.credits-stats {
			margin-top: 20px;
			text-align: center;
			.numbers {
				font-size: $very-small-font-size;
				color: $mc-9;
				text-align: left;
				.inline-text {
					display: inline-block;
					vertical-align: top;
				}
				.title {
					width: 115px;
				}
				.seperator {
					margin-right: 15px;
				}
				.value {
					@extend .font--semibold;
					&.blue {
						color: $color-blue;
					}
					&.green {
						color: $color-green;
					}
					&.orange {
						color: $color-orange;
					}
					&.red {
						color: $color-red;
					}
				}
			}
		}
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.segment-size {
	height: 225px;
	width: 225px;
	border: 1px solid #e0e6ef;
	border-radius: 2px;
	box-shadow: 0 0 15px 0 rgba(51, 51, 51, 0.15);
	text-align: center;
	margin-top: 20px;
	transition: 300ms;
	@include bp(768px) {
		width: 100%;
		box-shadow: none;
		border: 0px;
	}

	.title {
		@extend .font--bold;
		font-size: $small-font-size;
		margin: 8px 0 20px 0;
		color: $mc-7;
	}

	.subtitle-dark {
		font-size: $small-font-size;
		margin-top: 5px;
	}
	.subtitle-light {
		font-size: 12px;
		color: $mc-8;
		margin-top: 5px;
	}

	.progress {
		position: relative;
		text-align: center;
	}
	.bar-overflow {
		/* Wraps the rotating .bar */
		position: relative;
		overflow: hidden; /* Comment this line to understand the trick */
		width: 180px;
		height: 90px; /* Half circle (overflow) */
		margin: 0 auto -25px auto; /* bring the numbers up */
	}
	.bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 180px;
		height: 180px; /* full circle! */
		border-radius: 50%;
		box-sizing: border-box;
		border: 7px solid #eee; /* half gray, */
		border-top-color: $color-blue; /* half azure */
		border-left-color: $color-blue;
		transform: rotate(-135deg);
		transition: 0.5s transform ease-in-out;
	}
	.progress-count {
		@extend .font--semibold;
		font-size: $large-font-size;
	}
}

.filter-section-container {
	.filter-section-item {
		margin-bottom: 15px;
		opacity: 0.6;
		transition: 300ms ease-out;
		&:hover {
			opacity: 1;
		}
		&.filter-applied {
			opacity: 1;
		}
		&.active {
			opacity: 1;
		}
	}
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-bottom: 8px;
		@extend .font--semibold;
	}
	.checkbox-group {
		.checkbox-inline {
			display: inline-block;
			width: 50%;
			box-sizing: border-box;
		}
	}
	.inline-input.at-input-container {
		display: inline-block;
		width: 80px;
		margin-right: 10px;
		.at-input {
			font-size: $very-small-font-size;
		}
		&.inline-input-time {
			width: 100px;
		}
		&.range-second {
			margin-left: 10px;
		}
	}
	span {
		font-size: 12px;
		color: $mc-5;
	}
	.at-dropdown {
		margin-right: 15px;
		width: 150px;
		display: inline-block;
		.Select-placeholder {
			font-size: 12px;
			color: $mc-5;
		}
	}
	.rating-dropdown-group {
		.at-dropdown {
			margin-right: 15px;
			width: 150px;
			display: inline-block;
		}
		.inline-range-text {
			display: inline-block;
			line-height: 36px;
			vertical-align: top;
			font-size: 12px;
			color: $mc-5;
			margin-right: 10px;
		}
	}
	.multi-select-filter-container {
		.at-dropdown {
			margin-bottom: 0;
			width: 300px;
			display: inline-block;
			@include bp(768px) {
				width: 100%;
			}
		}
		.selected-items {
			width: 300px;
			.selected-item {
				width: 95px;
			}
		}
	}
}

.create-campaign-who-container {
	.filter-section-container {
		span {
			font-size: $small-font-size;
		}
	}
	.date-filter-container {
		.type-selector {
			.at-dropdown {
				margin-right: 30px;
				width: 170px;
				height: 34px;
				display: inline-block;
				@include bp(768px) {
					margin-right: 20px;
				}
				.Select-control {
					border: 1px solid $border-color-dark;
					border-radius: 5px;
					background-color: white;
					&:hover {
						box-shadow: none;
					}
				}
				&.Select.is-open > .Select-control {
					border-color: $color-blue;
				}
				&.Select.is-focused:not(.is-open) > .Select-control {
					border-color: $border-color-dark;
				}
			}
		}
	}
	.number-range-filter-container {
		.type-selector {
			.at-dropdown {
				margin-right: 15px;
				width: 170px;
				height: 34px;
				display: inline-block;
				.Select-control {
					border: 1px solid $border-color-dark;
					border-radius: 5px;
					background-color: white;
					font-size: 12px;
					color: $mc-5;
					&:hover {
						box-shadow: none;
					}
				}
				&.Select.is-open > .Select-control {
					border-color: $color-blue;
				}
				&.Select.is-focused:not(.is-open) > .Select-control {
					border-color: $border-color-dark;
				}
			}
			.inline-input.at-input-container {
				input {
					border: none;
					border-bottom: 1px solid $border-color-dark;
					border-radius: 0;
					font-size: $very-small-font-size;
				}
			}
		}
	}
	.fixed-range-filter-container {
		.type-selector {
			.at-dropdown {
				margin-right: 15px;
				width: 170px;
				height: 34px;
				display: inline-block;
				.Select-control {
					border: 1px solid $border-color-dark;
					border-radius: 5px;
					background-color: white;
					&:hover {
						box-shadow: none;
					}
				}
				&.Select.is-open > .Select-control {
					border-color: $color-blue;
				}
				&.Select.is-focused:not(.is-open) > .Select-control {
					border-color: $border-color-dark;
				}
			}
		}
	}
	.time-range-filter-container {
		.type-selector {
			.at-dropdown {
				margin-right: 15px;
				width: 170px;
				height: 34px;
				display: inline-block;
				.Select-control {
					border: 1px solid $border-color-dark;
					border-radius: 5px;
					background-color: white;
					&:hover {
						box-shadow: none;
					}
				}
				&.Select.is-open > .Select-control {
					border-color: $mc-5;
				}
				&.Select.is-focused:not(.is-open) > .Select-control {
					border-color: $border-color-dark;
				}
			}
		}
	}
}

.date-filter-container {
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-bottom: 8px;
		@extend .font--semibold;
	}
	.type-selector {
		.at-dropdown {
			margin-right: 30px;
			width: 160px;
			display: inline-block;
			@include bp(768px) {
				margin-right: 20px;
			}
			.Select-control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				background-color: white;
				&:hover {
					box-shadow: none;
				}
			}
			&.Select.is-open > .Select-control {
				border-color: $mc-5;
			}
			&.Select.is-focused:not(.is-open) > .Select-control {
				border-color: $border-color-dark;
			}
		}
		.type-selected {
			display: inline-block;
			width: 150px;
			vertical-align: top;
		}
		.date-range-container {
			.range-first {
				display: inline-block;
				margin-left: 0px;
				margin-right: 10px;
			}
			.range-second {
				display: inline-block;
				margin-left: 10px;
			}
		}
		.inline-input-date.at-input-container {
			display: inline-block;
			width: 150px;
			input {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				font-size: $very-small-font-size;
			}
		}
		.inline-input.at-input-container {
			display: inline-block;
			width: 60px;
			margin-right: 10px;
			input {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
			}
		}
	}
	&.data-filter-horizontal {
		.meta-info {
			display: inline-block;
			vertical-align: top;
			margin: 10px 5px 0 0;
			@include bp(768px) {
				margin: 5px 5px 5px 0;
			}
		}
		.type-selector {
			display: inline-block;
			vertical-align: top;
		}
	}
}

.number-range-filter-container {
	.type-selector {
		.at-dropdown {
			margin-right: 15px;
			width: 150px;
			display: inline-block;
			.Select-control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				background-color: white;
				font-size: 12px;
				color: $mc-5;
				&:hover {
					box-shadow: none;
				}
			}
			&.Select.is-open > .Select-control {
				border-color: $mc-5;
			}
			&.Select.is-focused:not(.is-open) > .Select-control {
				border-color: $border-color-dark;
			}
		}
		.type-selected {
			display: inline-block;
			vertical-align: top;
		}
		.inline-input.at-input-container {
			display: inline-block;
			width: 60px;
			margin-right: 0;
			span.seperator {
				font-size: 12px;
				color: $mc-5;
				margin: 0 10px;
			}
			&.range-second {
				margin-left: 0;
			}
			input {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				font-size: $very-small-font-size;
			}
		}
	}
}

.fixed-range-filter-container {
	.type-selector {
		.at-dropdown {
			margin-right: 15px;
			width: 150px;
			display: inline-block;
			.Select-control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				background-color: white;
				&:hover {
					box-shadow: none;
				}
			}
			&.Select.is-open > .Select-control {
				border-color: $mc-5;
			}
			&.Select.is-focused:not(.is-open) > .Select-control {
				border-color: $border-color-dark;
			}
		}
		.type-selected {
			display: inline-block;
			vertical-align: top;
			.at-dropdown {
				width: 45px;
				display: inline-block;
				margin-right: 0;
				margin-bottom: 0;
			}
			.field-seperator {
				display: inline-block;
				height: 36px;
				line-height: 36px;
				vertical-align: top;
				font-size: 12px;
				margin-left: 10px;
				margin-right: 10px;
				color: $mc-5;
			}
		}
	}
}

.time-range-filter-container {
	.type-selector {
		.at-dropdown {
			margin-right: 15px;
			width: 150px;
			display: inline-block;
			.Select-control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				background-color: white;
				&:hover {
					box-shadow: none;
				}
			}
			&.Select.is-open > .Select-control {
				border-color: $mc-5;
			}
			&.Select.is-focused:not(.is-open) > .Select-control {
				border-color: $border-color-dark;
			}
		}
		.type-selected {
			display: inline-block;
			vertical-align: top;
			.time-range {
				display: inline-block;
				vertical-align: top;
				.time-input-container {
					display: inline-block;
					vertical-align: top;
					.time-input {
						width: 45px;
						margin-right: 0;
						margin-bottom: 0;
					}
					.time-identifier {
						text-align: center;
						font-size: 12px;
						color: $mc-5;
					}
				}

				.time-seperator {
					display: inline-block;
					height: 36px;
					line-height: 36px;
					vertical-align: top;
					font-size: 12px;
					margin-left: 5px;
					margin-right: 5px;
					color: $mc-5;
					&.clock {
						@extend .font--bold;
						color: #000;
						animation: blinkingText 1s infinite;
					}
					@keyframes blinkingText {
						0% {
							color: #000;
						}
						49% {
							color: transparent;
						}
						50% {
							color: transparent;
						}
						99% {
							color: transparent;
						}
						100% {
							color: #000;
						}
					}
				}
			}
		}
	}
}

.suggested-segments-container {
	.campaign-section-container {
		display: block;
		margin-right: 25px;
		margin-left: 25px;
	}
	.topbar-container {
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		margin-bottom: 10px;
		.at-top-bar {
			border-bottom: none;
			padding-left: 0;
			.topbar-column {
				padding-left: 0;
				padding-right: 0;
				margin-right: 10px;
				transition: 0.3s;
			}
		}
	}
	.topbar-data-container {
		overflow-y: auto;
		overflow-x: hidden;
		.stats {
			display: inline-block;
			vertical-align: top;
			text-align: center;
			margin-left: 10px;
			margin-right: 10px;
		}
		.meaning {
			display: inline-block;
			vertical-align: top;
			margin-left: 10px;
			margin-right: 10px;
			width: 225px;
			.text--title {
				font-size: 16px;
			}
		}
	}
}

.create-campaign-who-sidebar {
	.save-segment-button {
		display: block;
		margin: 10px 25px;
	}
	.back-to-segment-list {
		text-transform: uppercase;
		font-size: 12px;
		text-align: center;
		margin-top: 10px;
		a {
			text-decoration: none;
		}
	}
	.sidebar-item {
		line-height: 20px;
		.item-title,
		.item-filter-count {
			display: inline-block;
			vertical-align: top;
		}
		.item-title {
			width: 85%;
		}
		.item-filter-count {
			height: 20px;
			width: 20px;
			line-height: 20px;
			background-color: $mc-4;
			color: $mc-8;
			text-align: center;
			font-size: 12px;
			border-radius: 50%;
		}
	}
}

.segment-description {
	input {
		outline: 0;
		padding: 5px;
		padding-left: 0px;
		font-size: $very-small-font-size;
		border: 0px;
		border-bottom: 1px solid #111;
		width: 100%;
		max-width: 100%;
	}
}
