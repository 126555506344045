.at-dropdown {
	@extend .body-text1;
	.Select-control {
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		cursor: pointer;
		background-color: white;
		transition: 300ms;
	}
	&.Select.is-open > .Select-control {
		border-color: $color-blue;
		border-radius: $default-radius;
	}
	&.Select.is-focused:not(.is-open) > .Select-control {
		border-color: $border-color-dark;
		box-shadow: none;
	}
	.Select-menu-outer {
		border-radius: $default-radius;
		margin-top: 5px;
		border: 1px solid $border-color-dark;
		background-color: white;
		z-index: $zI-level-1 + 1;
		overflow-y: auto;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.12);
		.Select-menu {
			overflow-y: initial;
		}
		.Select-option {
			background-color: white;
			color: $mc-8;
			cursor: pointer;
			display: block;
			height: 40px;
			padding: 10px 8px 10px 8px;
			border-bottom: 1px solid $border-color-dark;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			line-height: initial;
			transition: 300ms;
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				background-color: $mc-3;
			}
		}
		.Select-option.is-focused {
			background-color: $mc-3;
			color: $mc-8;
			&:not(:hover) {
				background-color: white;
			}
		}
		.Select-option.is-selected {
			background-color: $mc-3;
			color: $mc-8;
			&.is-focused:not(:hover) {
				background-color: $mc-3;
			}
		}
		.Select-option.is-disabled {
			opacity: 0.3;
			&:hover {
				background-color: white;
			}
		}
	}

	&.at-dropdown--hover {
		border-color: $border-color;
	}

	&.at-dropdown--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
}
