.numerical-display {
	margin-bottom: 10px;
	.display {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&--value {
		color: rgba($mc-9, 0.2);
		min-height: 36px;
		min-width: 36px;
		font-size: 20px;
		display: grid;
		place-content: center;
		background-color: rgba($mc-9, 0.1);
		border: 2px solid transparent;
		border-radius: 50%;
		position: relative;
	}

	.selected {
		color: $color-blue;
		border: 2px solid $color-blue;
		background-color: #eaeefe;
		transition: 300ms;
	}

	.previous {
		border: 2px solid $color-blue;
		background-color: $color-blue;
		color: $mc-1;
	}

	&--divider {
		width: 90px;
		height: 2px;
		background-color: rgba($mc-9, 0.2);
		border-radius: $default-radius;
		margin: 0 4px;
		transition: width 0.3 ease-in-out;
	}

	.passed {
		background-color: $color-blue;
		transition: all 0.3s ease-in;
	}
	&.vertical {
		display: flex;
		padding: 0 25px;
		.display {
			flex-direction: column;
		}
		.numerical-display--divider {
			height: 90px;
			width: 2px;
			margin: 4px 0;
		}
	}
}
.progress-bar-display {
	background: $mc-1;
	width: 100%;
	.info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 30px 25px;
		.title {
			display: flex;
			align-items: center;
			color: $color-dark-grey;
			font-size: $medium-font-size;
			@extend .font--semibold;
		}
		.progress {
			color: $color-dark-grey;
			font-size: $very-small-font-size;
		}
	}
	.bars {
		height: 4px;
		display: flex;
		width: 100%;
	}
	.progress-bar {
		&--content {
			height: 100%;
			width: 100%;
			background-color: $mc-3;
			margin-right: 8px;
			&:last-child {
				margin-right: 0;
			}
		}
		&--loader {
			width: 0%;
			height: 100%;
			background-color: $color-blue-dark-2;
			transition: all 300ms ease-in-out;
		}
	}
}
