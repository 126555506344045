.onboarding-locations {
	// reset the existing browser styles for the heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
		margin: 0;
	}
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 0; // overriding padding from section-container class
	// overflow-y: auto;

	.upload-spreadsheet-container {
		padding: 24px;
		.upload-block {
			&__header {
				display: flex;
				gap: 16px;
				align-items: center;
			}
			&__footer {
				cursor: pointer;
				margin-top: 16px;
				display: grid;
				place-items: center;
				background-color: $color-blue-1;
				border: 1px dashed $color-blue-50;
				border-radius: 2px;
				height: 172px;
				&__content {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 8px;
				}
			}
		}
		.download-template-block {
			margin-top: 48px;

			&__header {
				display: flex;
				gap: 16px;
				align-items: center;
				.download-template__header__right {
					display: flex;
					flex-direction: column;
					gap: 16px;

					&__sub-text {
						margin: 0;
					}
				}
			}

			&__body {
				margin-top: 24px;
				padding: 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border: 1px solid #c6d2ff;
				border-radius: 2px;
				&__left {
					display: flex;
					gap: 8px;
					align-items: center;
					&__text {
						margin: 0;
					}
				}
			}
		}
	}

	.upload-spreadsheet-success {
		display: grid;
		place-items: center;
		margin-top: 10%;
		.success-icon {
			margin: 0 auto 24px;
			text-align: center;
		}
		.success-heading {
			margin-bottom: 24px;
		}
		.back-to-setup--cta,
		.view-locations--cta {
			margin-bottom: 24px;
			width: fit-content;
		}
		.redirection-message {
			margin-top: 24px;
			display: flex;
			gap: 12px;
			width: fit-content;
			align-items: center;
			padding: 8px 12px;
			border-left: 4px solid $color-blue-40;
			background-color: $color-blue-15;
		}
	}

	.configure-manually {
		flex-grow: 1;
		margin-bottom: 15px;
		&__title {
			margin: 24px;
			font-weight: 400;
		}
		.table-list {
			border-bottom: none;
			.multi-select-filter-container {
				.react-select__loading-indicator {
					display: none;
				}
			}
			.city {
				position: relative;
				.city-validation-message {
					position: absolute;
					bottom: -5px;
					color: $color-red;
					font-size: 12px;
				}
			}
		}

		&__add-more-rows {
			display: flex;
			align-items: flex-end;
			gap: 16px;
			margin: 16px;
			.add-more__button {
				height: 34px;
			}
		}

		.select-and-configure-platforms {
			.select-platforms {
				margin: 0 0 24px 24px;
				width: 300px;
				.custom-label {
					margin: 2px;
					gap: 8px;
					.platform-tag {
						height: 30px;
						padding: 4px 8px;
						border-radius: 2px;
						display: flex;
						background-color: $color-blue-15;
						align-items: center;
						gap: 8px;
						.platform-text {
							color: $color-blue-dark-2;
						}
						.platform-cross-icon {
							height: 8px;
							width: 8px;
							margin-top: 2px;
						}
					}
				}

				.option {
					position: relative;
					border: none;
					.option-icon {
						position: absolute;
						right: 0;
						img {
							border-radius: 2px;
						}
					}
					.at-checkbox {
						.checkmark {
							border: 2px solid $mc-3;
							background-color: $mc-1;
							opacity: 0.8;
						}
						input:checked ~ .checkmark {
							background-color: $color-blue;
							opacity: 1;
						}
						&.at-checkbox--disabled {
							opacity: 0.3;
						}
					}
				}
			}

			.configure-platforms__table {
				$location-width: 20%;
				$brands-width: 23%;
				$platforms-width: 12%;
				$platform-id-width: 25%;
				$platform-url-width: 40%;

				margin-bottom: 50px;
				z-index: 0;
				&.custom-table-container {
					.table-header {
						top: 57px;
						.locations {
							width: $location-width;
						}
						.brands {
							width: $brands-width;
						}
						.aggr-platforms {
							width: $platforms-width;
						}
						.platform-id {
							width: $platform-id-width;
						}
						.platform-url {
							width: $platform-url-width;
						}
					}
					.table-list {
						font-size: $very-small-font-size;
					}
				}
				.name {
					width: $location-width;
					.location-name {
						padding-top: 8px;
						display: flex;
						align-items: center;
						text-transform: capitalize;
					}
				}
				.brands {
					display: flex;
					width: $brands-width;
					padding-left: 25px;
					.brand-container {
						display: flex;
						align-items: center;
						margin-bottom: 20px;
						height: 55px;
						&:last-child {
							margin-bottom: 10px;
						}
						.brand-image,
						.monochrome-generator {
							min-width: 20px;
							min-height: 20px;
							width: 20px;
							height: 20px;
						}
					}
					.brand-name {
						margin-left: 10px;
						text-transform: capitalize;
					}
				}
				.aggr-platforms {
					width: $platforms-width;
					.plf-name {
						display: flex;
						align-items: center;
						height: 55px;
						margin-bottom: 25px;
						&:last-child {
							margin-bottom: 10px;
						}
						img {
							width: 24px;
							height: 24px;
							object-fit: cover;
							margin-right: 7px;
							border-radius: $default-radius;
						}
					}
				}
				.platform-id {
					width: $platform-id-width;
					.at-input-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						height: 55px;
						margin-bottom: 25px;
						.validation-message {
							top: 42px;
						}
						&:last-child {
							margin-bottom: 10px;
						}
						.at-input {
							border: 1px solid $color-neutral-black-10;
						}
					}
				}
				.platform-url {
					width: $platform-url-width;
					padding-right: 25px;
					.at-input-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						height: 55px;
						margin-bottom: 25px;
						.validation-message {
							top: 42px;
						}
						&:last-child {
							margin-bottom: 10px;
						}
						.at-input {
							border: 1px solid $color-neutral-black-10;
						}
					}
				}
				&.multi {
					.table-header {
						.brands,
						.aggr-platforms,
						.platform-id,
						.platform-url {
							padding: 10px 15px;
						}
					}
					.table-list {
						padding: 12px 0;
					}
					.brands {
						width: $brands-width;
						align-items: flex-start;
						flex-direction: column;
						padding: 7px 0;
						.brand-info {
							margin-bottom: 20px;
							border-bottom: 1px solid $border-color;
							width: 100%;
							padding: 0 15px;
							padding-bottom: 10px;
							.brand-container {
								margin-bottom: 20px;
								&:last-child {
									margin-bottom: 20px;
								}
							}
							&:last-child {
								margin-bottom: 0;
								border: none;
								.brand-container {
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
					}
					.aggr-platforms {
						padding: 7px 0;
						.brand-platforms {
							margin-bottom: 20px;
							border-bottom: 1px solid $border-color;
							padding: 0 15px;
							padding-bottom: 10px;
							.plf-name {
								margin-bottom: 20px;
								&:last-child {
									margin-bottom: 20px;
								}
							}
							&:last-child {
								margin-bottom: 0;
								border: none;
								.plf-name {
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
					}
					.platform-id {
						padding: 7px 0;
						.brand-platforms {
							margin-bottom: 20px;
							border-bottom: 1px solid $border-color;
							padding: 0 15px;
							padding-bottom: 10px;
							.at-input-container {
								margin-bottom: 20px;
								&:last-child {
									margin-bottom: 20px;
								}
							}
							&:last-child {
								margin-bottom: 0;
								border: none;
								.at-input-container {
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
					}
					.platform-url {
						padding: 7px 0;
						.brand-platforms {
							margin-bottom: 20px;
							border-bottom: 1px solid $border-color;
							padding: 0 25px 0 15px;
							padding-bottom: 10px;
							.at-input-container {
								margin-bottom: 20px;
								&:last-child {
									margin-bottom: 20px;
								}
							}
							&:last-child {
								margin-bottom: 0;
								border: none;
								.at-input-container {
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.location-creation-failure {
		display: grid;
		place-items: center;
		margin: 10% 0;
		.illustration {
			filter: grayscale(100%);
		}
		&__title {
			margin-top: 32px;
			display: flex;
			align-items: center;
			gap: 8px;
			color: $color-neutral-black-60;
		}
		&__sub-title {
			margin-top: 8px;
			text-align: center;
		}
		&__learn-more {
			margin-top: 8px;
		}
		&__try-again {
			margin-top: 32px;
		}
	}

	.onboarding-footer {
		box-shadow: $default-shadow;
		position: sticky;
		display: flex;
		bottom: 0;
		left: 0;
		right: 0;
		// top: 100%;

		flex-direction: row-reverse;
		gap: 12px;
		padding: 16px 24px;
		background-color: white;
	}
}
