.status-info-wrapper {
	padding: 16px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 2px;
	.details-wrapper {
		display: flex;
		gap: 12px;
	}
	.details {
		p {
			margin: 0;
		}
		.detail-header {
			color: $color-neutral-black-80;
			font-weight: $font--semibold;
			font-size: $medium-font-size;
		}
		.detail-description {
			margin-top: 4px;
			color: $color-neutral-black-60;
			font-size: $very-small-font-size;
		}
	}
	.icon {
		height: 20px;
		width: 20px;
	}
	.close-icon {
		cursor: pointer;
		height: 16px;
		width: 16px;
		padding: 4px;
		cursor: pointer;
		&:hover {
			background-color: $mc-2;
		}
	}
	&.alert {
		border-left: 6px solid $color-yellow;
		background-color: $color-yellow;
	}
	&.info {
		border-left: 6px solid $color-blue-40;
		background-color: $color-blue-15;
	}
	&.success {
		border-left: 6px solid $color-green-50;
		background-color: $color-green-15;
	}
	&.failed {
		border-left: 6px solid $color-red-50;
		background-color: $color-red-05;
	}
}
