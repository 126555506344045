@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.error-item {
	display: flex;
	padding: 25px;
	min-height: 550px;
	@include bp(768px) {
		flex-direction: column;
		text-align: center;
	}
	.error-img-container {
		width: 55%;
		margin: auto;
		text-align: center;
		padding: 7px 32px;
		position: relative;
		@include bp(768px) {
			width: 75%;
			padding: 20px;
		}
		.error-img {
			width: 90%;
			object-fit: cover;
		}
		.error-code {
			position: absolute;
			top: 10vw;
			right: 7vw;
			color: $color-blue;
			font-size: 8vw;
			transition: 180ms;
			animation: fadeIn 2s;
			transform: scaleY(1.2);
			@extend .font--semibold;
			@include bp(768px) {
				top: 18vw;
				right: 7vw;
				font-size: 15vw;
			}
		}
	}
	.error-msg-container {
		width: 45%;
		margin: auto;
		padding: 7px 32px;
		@include bp(768px) {
			width: 75%;
			padding: 20px;
		}
		.title {
			font-size: $large-font-size;
			margin-bottom: 15px;
			@extend .font--semibold;
		}
		.error-msg {
			font-size: 20px;
			margin-bottom: 25px;
		}
		.orders-btn {
			padding: 15px 0;
		}
		.dashboard-btn {
			padding: 15px 0;
		}
	}
}
