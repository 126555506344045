.archive-restore-container {
	.form-header-text {
		font-size: $medium-font-size;
		margin-bottom: 20px;
		@extend .font--semibold;
	}
	.alert {
		background-color: rgba(245, 166, 35, 0.05);
		border: 1px solid rgba(245, 166, 35, 0.4);
		padding: 15px;
		border-radius: $default-radius;
		margin-bottom: 20px;
		img {
			height: 18px;
			vertical-align: top;
			margin-right: 5px;
		}
		span {
			font-size: $very-small-font-size;
		}
	}
	.entity-title {
		font-weight: 600;
		margin-bottom: 20px;
	}
	.message-item-container {
		font-size: $small-font-size;
		margin-bottom: 20px;
		color: $mc-7;
		line-height: 20px;
		span {
			font-weight: 600;
		}
		ul {
			// padding-left: 16px;
			li {
				margin-bottom: 7px;
			}
		}
		.sub-header {
			font-size: $small-font-size;
			font-weight: 600;
			margin-bottom: 20px;
		}
		.disclaimer {
			margin-top: 20px;
		}
	}
	.at-checkbox {
		margin-top: 40px;
		.input-message {
			span {
				font-weight: 600;
			}
		}
	}
	.text-input-message {
		margin: 35px 0 5px 0;
		span {
			text-transform: uppercase;
			@extend .font--semibold;
		}
	}
	.replace-img-container {
		padding-top: 90px;
		display: flex;
		justify-content: space-between;
		position: relative;
		.compare-img {
			height: 170px;
			width: 280px;
			border-radius: $default-radius;
			border: 1px solid $border-color;
			transition: 300ms;
			object-fit: cover;
			box-shadow: $default-shadow;
			&:last-child {
				padding-right: 0;
			}
		}
	}
	&.in-modal {
		.modal-container {
			max-width: 600px;
			.modal-header {
				padding: 15px 25px;
				.title {
					text-align: left;
					font-size: $medium-font-size;
					margin-bottom: 0;
					color: $mc-9;
				}
			}
			.modal-content {
				padding: 25px;
				border-top: 1px solid $border-color;
				border-bottom: 1px solid $border-color;
				.loader-backdrop {
					.loading-indicator-container {
						margin-top: 0;
					}
				}
				.success-message {
					padding: 25px;
					background-color: rgba($color: $color-green, $alpha: 0.1);
					color: $color-green;
					text-align: center;
					border: 1px solid $color-green;
					border-radius: $default-radius;
				}
			}
			.modal-footer {
				padding: 15px 25px;
				text-align: right;
				.at-btn--danger {
					margin-right: 0;
				}
			}
		}
	}
}
