.catalogue-verification-container {
	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.credits-section-header.transactions-list-header {
		.search-input-container {
			display: flex;
			.search-filter {
				width: 170px;
				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;
						img {
							padding-left: 0;
						}
					}
					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}
				.cancel-search {
					right: 24px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.info-text {
		padding: 15px 25px;
		color: $mc-6;
		span {
			color: $mc-9;
			@extend .font--semibold;
		}
	}
	.at-top-bar-wrapper-v2 {
		&.wrapper-sticky-top {
			z-index: 100;
		}
	}
	.custom-topbar {
		.at-top-bar-v2 {
			height: 42px;
			.count {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				float: left;
				min-width: 10px;
				height: 23px;
				margin-right: 7px;
				font-size: $very-small-font-size;
				color: $mc-1;
				text-align: center;
				border-radius: 15px;
				opacity: 0.6;
				padding: 0 7px;
				&.error {
					background-color: $color-red;
				}
				&.warning {
					color: $mc-9;
					background-color: $color-yellow;
				}
			}
			.text {
				float: right;
				padding: 2px 0;
			}
			.selected {
				.count {
					opacity: 1;
				}
			}
		}
	}
	.wrapper {
		margin-bottom: 30px;
		.info-text {
			position: sticky;
			top: 0;
			padding: 0 25px 15px 25px;
			line-height: 1.3;
			height: 42px;
			background-color: $mc-1;
			z-index: 100;
		}
		.at-top-bar-wrapper-v2 {
			top: 0px;
			z-index: 1001 !important;
		}
		&.prism-enabled {
			.at-top-bar-wrapper-v2 {
				top: 57px !important;
			}
			.catalogue-verification-list-table-container {
				&.custom-table-container {
					.table-header {
						top: 100px !important;
					}
				}
			}
		}
		.at-top-bar-v2 {
			height: 45px !important;
		}
		.custom-filters-container {
			display: flex;
			align-items: flex-end;
			margin-bottom: 30px;
			padding: 0 25px;
			.select-filter-custom {
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				margin-right: 35px;
				.meta-info {
					font-weight: normal;
					color: $mc-5;
				}
			}
		}
		.filters-container {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: 30px;
			padding: 0 25px;
			.search-filter {
				width: 30%;
				margin: 0;
				.cancel-search {
					top: 31px;
					right: 16px;
				}
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-5;
				}
			}
		}
		.select-filter-custom {
			&.brands {
				width: 30%;
				.dropdown-component {
					z-index: 500;
				}
				.dropdown-custom {
					.container {
						height: 32px;
						padding: 1px 10px;
						border-radius: $border-radius-default;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $default-radius;
							background-color: rgba($color: $mc-5, $alpha: 0.1);
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $default-radius;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
	}
	.catalogue-verification-list-table-container {
		&.custom-table-container {
			.table-header {
				top: 44px !important;
				z-index: 70;
			}
			.table-list {
				font-size: $very-small-font-size;
				.type {
					text-transform: capitalize;
				}
			}
		}
		.name {
			width: 30%;
			padding-left: 25px;
			.link-text {
				display: inline-block;
				width: calc(100% - 20px);
			}
			.desc-text {
				margin-top: 5px;
			}
		}
		.at-cell-text {
			&.type {
				text-transform: capitalize;
			}
		}
		.type {
			width: 15%;
		}
		.platform {
			width: 15%;
		}
		.description {
			width: 40%;
			padding-right: 25px;
			span {
				margin-right: 10px;
			}
			.read-more {
				color: $color-blue;
				cursor: pointer;
				margin: 0;
			}
			div {
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 10px;
				}
			}
		}
	}
}
