.bulk-operations-container {
	.section-container-common {
		padding-top: 25px;
	}
}
.bulk-operation,
.bulk-operation-success,
.bulk-operation-failure {
	.back-navigation-arrow {
		margin: 0;
		padding: 0 25px;
	}
	.credits-section-header {
		padding: 10px 25px 15px 25px;
	}
	&.downloading {
		opacity: 0.5;
		pointer-events: none;
		cursor: default;
	}
	&.uploading {
		opacity: 0.5;
		pointer-events: none;
		cursor: default;
	}
	.transactions-list-header {
		margin-bottom: 20px;
		.at-btn--secondary {
			border: 1px solid $mc-4;
			color: $mc-9;
			&:hover {
				background: $mc-1;
			}
		}
	}
	.transactions-list-header {
		margin-bottom: 0;
	}
	.at-top-bar-wrapper-v2 {
		z-index: 100;
	}
	.header {
		.header-text {
			@extend .font--semibold;
			position: sticky;
			z-index: 1;
			padding: 10px 25px;
			margin-bottom: 10px;
			font-size: 12px;
			background-color: $mc-2;
			color: $mc-7;
			text-transform: uppercase;
			line-height: 15px;
			@include bp(768px) {
				position: initial;
			}
		}
		.subheader-text {
			padding: 10px 25px;
			margin-bottom: 10px;
		}
	}
	.download-template {
		margin-top: 20px;
		margin-bottom: 30px;
		.export-template-btn {
			display: flex;
			align-items: center;
			padding: 0 25px;
			.at-btn {
				margin: 22px 0 0 10px;
			}
			.at-btn--secondary {
				border: 1px solid $mc-4;
				color: $mc-9;
				&:hover {
					background: $mc-1;
				}
			}
			.at-input-container {
				width: 500px;
			}
		}
		.download-template-btn {
			padding-left: 25px;
		}
	}
	.fill-data {
		margin-bottom: 30px;
		.template-table-container {
			padding: 0 25px;
			.field {
				width: 65%;
				padding-left: 25px;
				.field-desc {
					@extend .font--semibold;
					display: inline-block;
					width: calc(100% - 70px);
					.desc-text {
						@extend .font--regular;
						margin-top: 5px;
					}
				}
			}
			.accepted-values {
				width: 20%;
				display: flex;
				flex-direction: column;
				text-align: right;
				padding-right: 25px;
				color: $mc-5;
				span:nth-child(1) {
					color: $mc-9;
					margin-bottom: 5px;
				}
			}
			.required {
				width: 15%;
				display: flex;
				flex-direction: column;
				.at-checkbox {
					padding: 10px;
					.checkmark {
						border: 1px solid $mc-7;
					}
					input:checked ~ .checkmark {
						background-color: $mc-7;
					}
					&.at-checkbox--disabled {
						opacity: 0.3;
					}
				}
			}
			.transaction-header-row {
				position: initial;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-header-text {
				color: $mc-7;
				line-height: 17px;
				opacity: 1;
			}
			.at-cell-text {
				font-size: $small-font-size;
				color: $mc-9;
			}
		}
	}
	.upload-filled-data {
		margin-bottom: 30px;
		.upload-form {
			padding: 0 25px;
			.form-row {
				display: flex;
				justify-content: flex-start;
				margin-bottom: 30px;
				max-width: 950px;
				@include bp(768px) {
					max-width: 100%;
					flex-direction: column;
					margin-bottom: 0px;
				}
				&.row-full > div {
					width: 64%;
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
					}
				}
				&.row-half > div {
					width: 30%;
					margin-right: 40px;
					&:nth-last-child(1) {
						margin-right: 0px;
					}
					@include bp(768px) {
						width: auto;
						margin-bottom: 30px;
						margin-right: 0;
					}
				}
				// override style for select filter component inside form
				.multi-select-filter-container {
					.at--dropdown {
						font-size: $very-small-font-size;
					}
					&.filter-section-item {
						opacity: 1;
						margin-bottom: 0;
						@include bp(768px) {
							margin-bottom: 30px;
						}
						.meta-info {
							font-weight: 400;
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
				}
			}
		}
		.upload-area {
			padding: 0 25px;
			margin-bottom: 15px;
			.upload-container {
				cursor: pointer;
				width: 420px;
				height: 140px;
				text-align: center;
				box-sizing: border-box;
				vertical-align: top;
				display: inline-block;
				border-radius: $default-radius;
				border: 2px dashed rgba(0, 0, 0, 0.1);
				box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
				transition: 300ms;
				@include bp(768px) {
					margin-left: 0% !important;
					margin-right: 0% !important;
					margin-bottom: 10px;
					width: 100%;
				}
				.upload-icon {
					margin: 14px auto 8px;
					img {
						width: 45px;
						height: 45px;
					}
				}
				.upload-desc {
					display: inline-block;
					width: calc(100% - 70px);
					.desc-text {
						margin-top: 5px;
					}
				}
			}
		}
	}
	.uploaded-file {
		margin-bottom: 20px;
		.uploaded-file-icon {
			margin-right: 5px;
			float: left;
			img {
				width: 18px;
			}
		}
		.subheader-text {
			margin-bottom: 0;
		}
	}
	.uploaded-file-status {
		padding: 5px 10px;
		float: right;
		border-radius: 2px;
		font-size: $very-small-font-size;
		user-select: none;
		transition: 180ms;
		text-align: center;
		box-sizing: border-box;
		&.success {
			color: $color-green;
			background: #efe;
			border: 1px solid $color-green;
		}
		&.failure {
			color: $color-red;
			background: #fee;
			border: 1px solid $color-red;
		}
	}
	.progress-bar {
		margin-left: 25px;
		width: 420px;
		-webkit-appearance: none;
		appearance: none;
		&[value]::-webkit-progress-bar {
			background-color: $mc-4;
			border-radius: calc(#{$default-radius * 4});
			height: 5px;
		}
		&[value]::-webkit-progress-value {
			background-color: $color-blue;
			border-radius: calc(#{$default-radius * 4});
		}
	}
	.sync-status-container {
		.sync-icon {
			padding: 0 30px;
			text-align: center;
			img {
				width: 250px;
				object-fit: cover;
			}
		}
		.message {
			max-width: 750px;
			margin: 0 auto;
			margin-bottom: 100px;
			padding: 0 30px;
			text-align: center;
			color: $mc-5;
			font-size: $medium-font-size;
			line-height: 1.5;
		}
	}
}

.bulk-operation-failure {
	.errors-found {
		.header {
			.subheader-text {
				padding: 10px 25px 0;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;
				.header-action-button {
					& > div {
						float: right;
						position: relative;
						margin: 0 10px;
						&:nth-child(1) {
							margin: 0;
						}
					}
				}
				.at-btn--secondary {
					border: 1px solid $mc-4;
					color: $mc-9;
					&:hover {
						background: $mc-1;
					}
				}
			}
		}
		.template-table-container {
			padding: 0 25px;
			margin-bottom: 100px;
			.title,
			.name,
			.reason,
			.invalid-headers,
			.errors {
				width: 50%;
				padding-left: 25px;
				padding-right: 25px;
			}
			.transaction-header-row {
				position: initial;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-header-text {
				color: $mc-7;
				line-height: 17px;
				opacity: 1;
			}
			.at-cell-text {
				font-size: $small-font-size;
				color: $mc-9;
			}
		}
	}
}

.bulk-operation-success {
	.import-files {
		.header {
			.subheader-text {
				padding: 10px 25px 0;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;
				.header-action-button {
					& > div {
						float: right;
						position: relative;
						margin: 0 10px;
						&:nth-child(1) {
							margin: 0;
						}
					}
				}
			}
		}
		.label-types {
			padding: 0 25px;
			display: flex;
			.label-types-container {
				padding: 12px 25px;
				width: 180px;
				border-radius: 2px;
				margin-right: 15px;
				display: flex;
				justify-content: space-between;
				& > span {
					margin: auto 0;
					&:nth-child(2) {
						font-size: $medium-large-font-size;
					}
				}
				&.will-create {
					border: 2px solid #91ffde;
					background: #e7fff8;
				}
				&.will-update {
					border: 2px solid #a7eaff;
					background: #edfbff;
				}
			}
		}
	}
	.template-table-container {
		padding: 0 25px;
		margin-bottom: 100px;
		.row-num {
			width: 20%;
			padding-left: 25px;
		}
		.row-identifier {
			width: 30%;
		}
		.type {
			width: 20%;
		}
		.message {
			width: 30%;
		}
		.transaction-header-row {
			position: initial;
		}
		.transaction-rows {
			min-height: initial;
		}
		.at-header-text {
			color: $mc-7;
			line-height: 17px;
			opacity: 1;
		}
		.at-cell-text {
			font-size: $small-font-size;
			color: $mc-9;
		}
	}
}
