.popover-wrapper-container {
	position: relative;
	.popover-container {
		position: absolute;
		top: 40px;
		right: 0;
		background-color: $mc-1;
		box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: $default-radius;
		min-width: 150px;
		min-height: 30px;
		box-sizing: border-box;
		z-index: 50;
		&::before {
			content: "";
			position: absolute;
			top: -10px;
			right: 15px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 14px 10px;
			border-color: transparent transparent #fff transparent;
			filter: drop-shadow(0 0 2px #cacaca);
		}
		&::after {
			content: "";
			position: absolute;
			top: -8px;
			right: 12px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 15px 18px 15px;
			border-color: transparent transparent #fff transparent;
		}
		.close-popover {
			position: absolute;
			top: 5px;
			right: 5px;
			padding: 5px 10px;
			cursor: pointer;
			img {
				width: 11px;
			}
		}
	}
	&.down-left {
		.popover-container {
			right: auto;
			left: -15px;
			&::before {
				right: auto;
				left: 16px;
			}
			&::after {
				right: auto;
				left: 11px;
			}
		}
	}
	&.down-right {
		.popover-container {
			right: 15px;
			left: auto;
			&::before {
				left: auto;
				right: -16px;
			}
			&::after {
				left: auto;
				right: -11px;
			}
		}
	}
	&.top-right {
		.popover-container {
			right: 30px;
			top: -6px;
			&::before {
				content: "";
				position: absolute;
				top: 10px;
				right: -8px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 7px 5px;
				transform: rotate(90deg);
				border-color: transparent transparent #fff transparent;
				filter: drop-shadow(0 0 2px #cacaca);
			}
			&::after {
				content: "";
				position: absolute;
				top: 10px;
				right: -8px;
				width: 0;
				height: 0;
				border-style: solid;
				transform: rotate(90deg);
				border-width: 0 8px 8px 6px;
				border-color: transparent transparent #fff transparent;
			}
		}
	}
	&.middle-right {
		.popover-container {
			top: -10px;
			right: auto;
			left: 66px;
			&::before {
				top: 12px;
				right: auto;
				left: -16px;
				rotate: -90deg;
			}
			&::after {
				top: 10px;
				right: auto;
				left: -16px;
				rotate: -90deg;
			}
		}
	}
	&.middle-left {
		.popover-container {
			top: -10px;
			right: 66px;
			left: auto;
			&::before {
				top: 12px;
				right: -16px;
				left: auto;
				rotate: 90deg;
			}
			&::after {
				top: 10px;
				right: -16px;
				left: auto;
				rotate: 90deg;
			}
		}
	}
}
