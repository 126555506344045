.periscope-container {
	.specific-table-div {
		.specific-table-container {
			padding: 24px 24px;
			border-radius: 4px;
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			background-color: #fff;
			border: solid 1px rgba(0, 0, 0, 0.05);
			margin-bottom: 15px;
			padding-bottom: 15px;

			.specificitem-table-head {
				padding: 0 0 16px 0;

				.specificitem-table-header {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					.text-dislplay-specificitem {
						display: flex;
						flex-direction: column;
						gap: 8px;

						.specificitem-header-display {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 5.67px;

							.specificitem-right-arrow {
								width: 5.05px;
								height: 8.09px;
							}
						}
					}

					.fetch-time {
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						color: #8a8a8a;
						margin-top: 10px;
					}

					.table-header-grey-text {
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #8a8a8a;
					}

					.specificitem-table-info {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 8px;

						.specificitem-btn-text {
							font-family: Source Sans Pro;
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
						}

						.specificitem-refresh-btn {
							cursor: pointer;
							padding: 8px 16px 8px 12px;
							border: 1px solid #eaeaea;
							gap: 8px;
							display: flex;
							align-items: center;

							.refresh-img {
								width: 12px;
								height: 16px;
							}

							.specificitem-btn-text {
								color: #363636;
							}
						}

						.specificitem-share-div {
							cursor: pointer;
							background: #3b5feb;
							display: flex;
							gap: 8px;
							align-items: center;
							padding: 8px 16px 8px 12px;

							.share-img {
								height: 13.33px;
								width: 10.67px;
							}

							.specificitem-btn-text {
								color: #ffffff;
							}
						}
					}
				}

				.specificitem-brand-item {
					font-family: Source Sans Pro;
					font-size: 28px;
					font-weight: 600;
					line-height: 36px;
					text-align: left;
					color: #363636;
				}
			}

			.specific-item-filters-div {
				display: flex;
				justify-content: space-between;
				gap: 12px;

				.specific-item-left-filters {
					display: flex;
					gap: 8px;
					flex-wrap: wrap;

					.specificitem-platform-filter .placeholder,
					.select-filter-custom .dropdown-custom .container .option,
					.item-availability-status,
					.item-availability-status {
						color: #363636;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
					}

					.city-filter {
						.react-select__single-value {
							color: #363636;
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 22px;
							text-align: left;
						}
					}

					.item-availability-status {
						margin: 0 8px;
					}
				}
			}

			.specific-item-table {
				padding: 16px 0;

				.selected-item-table-div {
					.selected-item-table-display {
						border: 1px solid #eaeaea;
						background: #ffffff;
						border-collapse: collapse;

						.selectedissue-thead {
							.selectedissue-firstrow {
								.location-div {
									padding: 16px 16px 16px 24px;
									border-right: 1px solid #eaeaea;
									border-bottom: 1px solid #eaeaea;
									min-width: 274px;
								}

								.platform-div {
									padding: 16px 24px 16px 24px;
									border-bottom: 1px solid #eaeaea;
								}
							}
						}

						.paginator-location {
							text-align: right;
							padding: 0 25px 10px 25px;
						}
						.info-popover {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							color: #363636;
							padding: 8px 12px;
							width: fit-content;
							text-wrap: nowrap;
						}
					}

					.selecteditem-location-display {
						padding: 20px 16px 24px 24px;
						min-width: 274px;

						.specificitem-location-cell {
							display: flex;
							align-items: center;
							gap: 8px;

							.specificitem-location-text {
								font-family: Source Sans Pro;
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								text-align: left;
								color: #363636;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								max-width: 215px;
							}

							.specificitem-rating-display {
								display: flex;
								align-items: center;
								gap: 4px;

								.specificitem-ratings-text {
									font-family: Source Sans Pro;
									font-size: 12px;
									font-weight: 600;
									line-height: 16px;
									text-align: left;
									color: #8a8a8a;
								}

								.star {
									width: 8.45px;
									height: 8.02px;
								}
							}
						}
					}

					.selecteditem-tabledata {
						.specific-item-offline-img {
							width: 16px;
							height: 16px;
						}

						.specific-item-partially-offline {
							width: 5px;
							height: 22px;
						}

						.online-image {
							width: 12.28px;
							height: 9.41px;
						}
					}

					.selecteditem-platform-display {
						padding: 20px 24px;
					}

					.specificitem-online {
						background: #ffffff;
					}

					.specificitem-partially-offline {
						background: #ffffff;
					}

					.specificitem-offline {
						background: #fff5f5;
					}

					.specificitem-offline:hover {
						background: #ffe5e5;
					}

					.specific-item-online-green-img {
						display: none;
						transform: translateX(0.5px);
					}

					.specificitem-online:hover .specific-item-online-img {
						display: none;
					}

					.specificitem-online:hover .specific-item-online-green-img {
						display: block;
					}

					.specificitem-online:hover {
						background: #f6fbf8;
					}

					.header-text-item {
						text-transform: capitalize;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #8a8a8a;
					}
				}
			}
		}
	}

	// Loading - SpecificItemTable
	.selecteditem-table-loader {
		.shimmer {
			border-radius: 0px;
		}
	}

	.specficitem-loading-state {
		border-bottom: 1px solid #eaeaea;
		border-right: 1px solid #eaeaea;
		border-left: 1px solid #eaeaea;
		border-collapse: collapse;

		.sample-table {
			border: none;
		}

		.specific-item-location-loader {
			padding: 20px 16px 24px 24px;
			min-width: 274px;
			border-bottom: 1px solid #eaeaea;
			border-right: 1px solid #eaeaea;
			border-left: 1px solid #eaeaea;

			.location-loader {
				width: 75%;
			}

			.platform-loader {
				width: 80%;
			}
		}

		.specific-platform-loader {
			padding: 20px 24px 20px 24px;
			border-bottom: 1px dashed #eaeaea;
		}
	}
}

.specific-item {
	.tooltip-item {
		position: relative;
		padding: 20px 0;
		// width: 100%;

		.tooltiptext-item {
			min-width: 145px;
			text-wrap: nowrap;
			visibility: hidden;
			background-color: white;
			color: black;
			text-align: center;
			border-radius: 6px;
			position: absolute;
			z-index: 1;
			right: 50%;
			transform: translateX(50%);
			display: flex;
			flex-direction: column;
			padding: 8px 12px;
			color: #363636;
			top: 110%;
			box-shadow: 0px 0px 12px 0px #00000029;
		}

		.all-offline-display {
			width: 175px;
		}
	}
	.tooltip-item .tooltiptext-item::after {
		content: "";
		position: absolute;
		bottom: 100%;
		transform: translateX(-50%);
		left: 50%;
		border-width: 7px;
		border-style: solid;
		border-color: transparent transparent white transparent;
	}

	.tooltip-item:hover .tooltiptext-item {
		visibility: visible;
	}

	.tooltiptext-item {
		.tooltip-description-item {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			text-align: center;
		}
	}
}
