.onboarding-menus {
	// reset the existing browser styles for the heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		padding: 0;
		margin: 0;
	}
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 0; // overriding padding from section-container class
	// overflow-y: auto;

	&__choose-menu-pull-source {
		flex-grow: 1;
		.selected-brand {
			padding: 24px;
			font-size: 18px;
			margin-top: 10px;
			.text-h4 {
				display: flex;
				align-items: center;
				gap: 5px;
			}
			&__display {
				display: flex;
				align-items: center;
				gap: 4px;
				// margin-top: 24px;
				padding: 8px 12px;
				border-radius: 4px;
				border: 1px solid var(--up-black-10);
				width: fit-content;
				.logo {
					width: 24px;
					height: 24px;
					border-radius: 1px;
				}
				.brand-title {
					text-transform: capitalize;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
		.food-delivery-platform {
			padding: 24px;
			&__heading {
				margin-bottom: 24px;
				font-size: 18px;
			}
			&__list {
				display: flex;
				gap: 30px;
				flex-wrap: wrap;
			}
			.dsp-platform-card {
				cursor: pointer;
				padding: 24px;
				border-radius: 4px;
				border: 1px solid var(--up-black-10);
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				gap: 12px;
				width: 20%;
				min-width: max-content;
				&__image {
					width: 58px;
					height: 58px;
					border-radius: 4px;
				}
				&__name {
					text-transform: capitalize;
				}
				.right-arrow-icon {
					margin-left: auto;
				}
			}
		}
		.hyperlink {
			text-decoration: underline;
			&::after {
				height: 0;
			}
		}
	}

	&__preview-menu-pull-source {
		display: grid;
		place-content: center;
		width: 50%;
		margin: 100px auto;
		border: 1px solid var(--up-black-10);
		border-radius: 4px;
		padding: 24px;
		text-align: center;
		max-width: 430px;
		.header {
			border-bottom: 1px solid var(--up-black-10);
			padding-bottom: 24px;
			.platform-logos {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				margin-bottom: 24px;
				.platform {
					img {
						width: 56px;
						height: 56px;
						border-radius: 4px;
					}
				}
			}
			&__title {
				margin-bottom: 8px;
				line-height: 1.6;
			}
			&__subtitle {
				line-height: 1.4;
			}
		}
		.body {
			padding-top: 24px;
			text-align: left;
			.attributes {
				display: flex;
				flex-direction: column;
				gap: 15px;
				margin-top: 25px;
				.attribute {
					display: flex;
					align-items: center;
					gap: 10px;
				}
			}
		}
		.footer {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			gap: 8px;
			margin-top: 24px;
		}
	}

	&__add-platform-url {
		padding: 24px;
		flex-grow: 1;
		.warning-card {
			background-color: $color-yellow-10;
			border-left: 6px solid $color-yellow-20;
			border-radius: 2px;
			padding: 16px;
			display: flex;
			gap: 8px;
			&__title {
				margin-bottom: 8px;
				font-size: 18px;
			}
			&__subtitle {
				color: $color-black-60;
			}
		}
		.integration-summary-logo {
			margin-top: 24px;
			padding: 8px 12px;
			border-radius: 4px;
			border: 1px solid var(--up-black-10);
			width: fit-content;
			display: flex;
			align-items: center;
			gap: 8px;

			.selected-brand,
			.selected-platform {
				display: flex;
				align-items: center;
				gap: 4px;
				.logo {
					width: 32px;
					height: 32px;
					border-radius: 1px;
				}
			}
			.selected-brand {
				padding-right: 8px;
				::after {
					content: " ";
					margin-left: 12px;
					border-right: 1px solid var(--up-black-10);
				}
			}
		}

		.title {
			margin: 24px 0;
			font-size: 18px;
		}
		.import-url-wrapper {
			position: relative;
			.at-input-label {
				margin-bottom: 12px;
			}
			.import-url-input {
				padding: 8px 24px 8px 8px;
				border-radius: 4px;
				border: 1px solid var(--up-black-10);
				outline: none;
				width: 100%;
				&--error {
					border: 1px solid $color-error;
				}
			}
			.validation-message {
				overflow: visible;
			}
			.open-link-icon {
				position: absolute;
				bottom: 15%;
				right: 1%;
				cursor: pointer;
			}
		}

		.how-it-works {
			margin-top: 32px;
			&__title {
				margin-bottom: 12px;
				font-size: 18px;
			}
			&__body {
				display: flex;
				flex-direction: column;
				gap: 8px;
				border: 1px solid var(--up-black-10);
				border-radius: 4px;
				.item {
					padding: 20px;
					font-weight: 400;
					font-size: 16px;
					&:not(:last-child) {
						border-bottom: 1px solid var(--up-black-10);
					}
				}
				.item-number {
					margin-right: 16px;
					background-color: #f2f5ff;
					color: $color-blue-40;
					padding: 4px 10px;
					border-radius: 50%;
				}
			}
		}
	}

	&__menu-creation-status {
		padding: 24px;
		display: grid;
		place-content: center;
		place-items: center;
		min-height: 60vh;
		width: 700px;
		margin: 50px auto;
		.success {
			place-items: center;
			display: grid;
			place-content: center;
			.success-icon {
				margin-bottom: 24px;
			}
			.success-text {
				color: $color-black-60;
				font-size: 18px;
			}
			.actions {
				margin-top: 24px;
				display: flex;
				align-items: center;
				gap: 24px;
			}
		}
		.failure {
			display: grid;
			place-content: center;
			place-items: center;
			.failure-icon {
				margin-bottom: 32px;
			}
			.body {
				text-align: center;
				margin-bottom: 32px;
				.title {
					margin-bottom: 12px;
					color: $color-black-60;
					font-size: 18px;
				}
				.subtitle {
					margin-bottom: 12px;
					color: $color-black-60;
				}
			}
			.actions {
				margin-top: 24px;
				display: flex;
				align-items: center;
				gap: 24px;
			}
		}
		.in-process {
			display: grid;
			place-content: center;
			place-items: center;
			.in-process-icon {
				margin-bottom: 32px;
			}
			.body {
				text-align: center;
				margin-bottom: 24px;
				.title {
					margin-bottom: 12px;
					color: $color-black-60;
					font-size: 18px;
				}
				.subtitle {
					margin-bottom: 12px;
					color: $color-black-60;
				}
				.warning {
					margin-top: 32px;
					display: flex;
					gap: 10px;
					align-items: center;
					background-color: $color-yellow-10;
					border-left: 6px solid $color-yellow-20;
					border-radius: 2px;
					padding: 12px 16px;
					.warning-text {
						color: $color-black-80;
					}
				}
			}
			.actions {
				display: flex;
				align-items: center;
				gap: 24px;
			}
		}
	}

	.onboarding-footer {
		box-shadow: $default-shadow;
		position: sticky;
		display: flex;
		bottom: 0;
		left: 0;
		right: 0;
		// top: 100%;

		flex-direction: row-reverse;
		gap: 12px;
		padding: 16px 24px;
		background-color: white;
	}
}
