.list-shimmer {
	display: flex;
	flex-flow: column;
	min-width: 200px;
	width: 700px;
	height: 400px;
	min-height: 200px;
	border-radius: 8px;
	overflow: hidden;

	&__body {
		flex: 1;
	}
}

.list-component {
	height: 100%;
}

.list-loader {
	--bg-color: white;
	--fg-color: #f2f2f2;
	--skeleton-item-height: clamp(30px, calc(30px + 15%), 70px);
	--gap: 1em;
	--diagonal-mask: linear-gradient(-45deg, black 46%, transparent 50%, black 55%);
	--bottom-fade-mask: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 0,
		rgba(0, 0, 0, 1) 80%,
		rgba(0, 0, 0, 0) 100%,
		rgba(0, 0, 0, 0) 0
	);

	margin: var(--gap);
	background:
		linear-gradient(90deg, var(--bg-color) 100%, transparent 50%),
		// vertical line
		linear-gradient(transparent calc(100% - var(--gap)), var(--bg-color) 0%),
		// horizontal lines
		var(--fg-color);
	background-size:
		var(--gap),
		100% var(--skeleton-item-height);
	background-position:
		clamp(60px, 20%, 100px),
		0 0;
	background-repeat: no-repeat, repeat-y;
	-webkit-mask-image: var(--bottom-fade-mask), var(--diagonal-mask);
	-webkit-mask-size:
		100% 100%,
		500%; // bloat the diagonal mask x5 in order to be able to change its position when animating
	-webkit-mask-position-x: 0, 100%;
	-webkit-mask-composite: source-in;
	animation: 2s animate-list-loader-mask infinite;

	@keyframes animate-list-loader-mask {
		100% {
			-webkit-mask-position-x: 0, 0%;
		}
	}
}
