@keyframes placeHolderShimmer {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	50% {
		transform: translateX(100%);
		opacity: 1;
	}
	100% {
		transform: translateX(-100%);
		opacity: 0;
	}
}
.reconciliation-container {
	.header-action-button {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		.action-buttons-container {
			margin-right: 0;
			display: flex;
			align-items: center;
			.at-btn {
				margin-left: 10px;
			}
		}
		.help-btn-container {
			margin-left: 10px;
			margin-right: 0;
		}
	}
	.uploader-section {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		.upload-button {
			width: 100%;
			margin: auto;
		}
		.loader-backdrop {
			background-color: transparent;
			position: static;
		}
		.upload-processing-message {
			text-align: center;
			color: $color-blue;
			font-weight: bold;
			margin-top: 24px;
		}
		.upload-processing-details {
			width: 100%;
			margin-top: 48px;
			.file-details {
				.single-file-detail {
					padding: 10px;
					margin-bottom: 12px;
					background-color: $mc-1;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					display: flex;
					height: 40px;
					flex-direction: column;
					justify-content: space-between;
					transition: 200ms;
					position: relative;
					.file-name {
						color: $mc-9;
						width: 300px;
						font-weight: 600;
						@include truncate_text;
					}
					.loader-backdrop {
						position: absolute;
						background-color: transparent;
						top: 44px;
						width: 98%;
						height: 10%;
						.loading-indicator-container {
							position: fixed;
							right: 4px;
							.loading-indicator {
								height: 24px;
								width: 24px;
							}
						}
					}
					.upload-info {
						width: 100%;
						display: flex;
						transition: 300ms;
						.info-block {
							width: 30%;
							height: 100px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							padding: 10px;
							.info {
								font-size: $very-small-font-size;
								.label {
									color: $mc-5;
								}
								.value {
									color: $mc-8;
									@include truncate_text;
								}
								.upload-status {
									img {
										height: 12px;
										width: 12px;
										margin-left: 4px;
										margin-top: 2px;
									}
									[aria-label][data-balloon-pos] {
										position: absolute;
										width: 100px;
										max-width: max-content;
										white-space: nowrap;
									}
								}
								.success-status {
									color: $color-green;
								}
								.failure-status {
									color: $color-red;
								}
							}
							.info-message {
								border: 1px solid $color-green;
								color: $color-green;
								padding: 10px;
								background-color: rgba(46, 204, 113, 0.1);
								border-radius: $default-radius;
								.message {
									font-weight: 600;
								}
								.adjoining-message {
									font-size: $very-small-font-size;
								}
							}
						}
						.info-block-failure {
							width: 100%;
							justify-content: space-between;
							display: flex;
							flex-direction: column;
							font-size: $very-small-font-size;
							gap: 24px;
							.basic-info {
								display: flex;
								flex-direction: row;
								width: 100%;
								.info {
									width: 50%;
									.label {
										color: $mc-5;
									}
									.value {
										color: $mc-8;
										@include truncate_text;
										font-weight: 600;
									}
									.failure {
										color: $color-red;
									}
								}
								.reconciliation-processed-status {
									border-radius: $default-radius;
									margin-top: 40px;
									.status-info-container {
										position: relative;
										.header {
											position: absolute;
											color: $mc-5;
											font-size: 12px;
											top: -15px;
											left: -0px;
										}
										.status-info-content {
											.row {
												.cell {
													margin: 5px 0;
													padding: 10px 15px;
													width: 180px;
													cursor: default;
													display: inline-block;
													border: 1px solid $border-color;
													margin-right: 20px;
													@include truncate_text;
												}
											}
											.reconciled {
												color: #0d7ea0;
											}
											.inconsistent {
												color: #b2596e;
											}
											.missing {
												color: #3498db;
											}
											.open {
												color: #ff7557;
											}
											.resolved {
												color: #3ec686;
											}
										}
									}
								}
							}
							.error-info {
								.label {
									color: $mc-5;
								}
								.value {
									color: $color-red;
									@include truncate_text;
								}
							}
						}
					}
					.view-upload-status {
						color: $color-blue;
						cursor: pointer;
						text-transform: initial;
						position: relative;
						&::after {
							content: "";
							position: absolute;
							width: 100%;
							transform: scaleX(0);
							height: 1px;
							bottom: 0;
							left: 0;
							background-color: $color-blue;
							transform-origin: bottom right;
							transition: transform 0.25s ease-out;
						}
						&:hover::after {
							transform: scaleX(1);
							transform-origin: bottom left;
						}
						transition: 300ms;
						&.disabled {
							opacity: 0.4;
						}
					}
				}
			}
		}
		.success-container {
			.success-message {
				padding: 20px;
				font-size: $small-font-size;
				color: $color-green;
				border: 1px solid $color-green;
				border-radius: $default-radius;
				background-color: rgba(46, 204, 113, 0.1);
			}
		}
		.error-container {
			.error-message {
				padding: 20px;
				font-size: $small-font-size;
				color: $color-red;
				border: 1px solid $color-red;
				border-radius: $default-radius;
				background-color: rgba($color-red, 0.1);
			}
		}
		.wobbling-bar-loader div {
			display: inline-block;
			position: absolute;
			margin-left: 44%;
			left: 8px;
			width: 16px;
			background: $color-blue-light;
			animation: wobbling-bar-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		}
		.wobbling-bar-loader div:nth-child(1) {
			left: 8px;
			animation-delay: -0.24s;
		}
		.wobbling-bar-loader div:nth-child(2) {
			left: 32px;
			animation-delay: -0.12s;
		}
		.wobbling-bar-loader div:nth-child(3) {
			left: 56px;
			animation-delay: 0;
		}
		@keyframes wobbling-bar-loader {
			0% {
				top: 8px;
				height: 64px;
			}
			50%,
			100% {
				top: 24px;
				height: 32px;
			}
		}
	}
	.recon-upload-success-info {
		.payout-amount-field {
			.at-input-field {
				input {
					padding: 0px 10px 0px 44px;
				}
			}
		}
	}
	.spinner {
		position: absolute;
		right: 59px;
		top: 6px;
		border: 2px solid #f3f3f3;
		border-radius: 50%;
		border-top: 2px solid #2f58f2;
		width: 20px;
		height: 20px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.csv-upload-container {
		margin-bottom: 24px;
		border-radius: $default-radius;
		box-sizing: border-box;
		.uploader-area-container {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			margin-bottom: 10px;
		}
		.csv-drop-area {
			height: 250px;
			background-color: rgba(47, 88, 242, 0.1);
			padding: 15px 5px;
			color: $mc-7;
			border: 1px dashed $color-blue;
			border-radius: $default-radius;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.upload-icon {
				height: 50px;
				width: 50px;
				margin: 20px;
			}
			.highlighted {
				color: $color-blue;
			}
			strong {
				.clip-icon-container {
					height: 10px;
					width: 10px;
					margin-top: 12px;
					padding-right: 4px;
					img {
						object-position: 2px 2px;
					}
				}
			}
		}
		.multi-select-filter-container {
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
				margin-bottom: 0;
				padding-bottom: 5px;
			}
		}
		.at-btn {
			width: 100%;
			margin-top: 10px;
		}
	}
	.welcomeboard {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.main-body {
			width: 80vw;
			display: flex;
			position: relative;
			justify-content: center;
			align-items: center;
			.section-container-size-adjust {
				position: relative;
				width: 80vw;
				height: 80vh;
				opacity: 1;
				z-index: 100;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				.info-display {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					.header {
						font-size: $medium-large-font-size;
						font-weight: 600;
						margin-bottom: 10px;
					}
					.sub-header {
						font-size: $medium-font-size;
						color: $mc-5;
					}
				}
				.info-graphics-container {
					height: 100vh;
					display: flex;
					width: 100%;
					.video-container {
						display: flex;
						width: 60%;
						height: auto;
						align-items: center;
						padding-left: 25px;
						.recon-intro-video {
							position: relative;
							width: 100%;
							height: 0;
							padding-bottom: 56.25%;
							position: relative;
							iframe {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								overflow: hidden;
								border-radius: 4px;
								border: 1px solid #d0dadd;
								z-index: 1;
							}
							.loader-backdrop {
								border-radius: $default-radius;
								position: absolute;
								width: 100%;
								height: 100%;
								background-color: transparent;
								z-index: 0;
								.loading-indicator-container {
									margin-top: 0px;
								}
							}
						}
						@include bp(768px) {
							width: 90%;
							padding: 25px;
						}
					}
					.info-details-section {
						width: 50%;
						display: flex;
						flex-direction: column;
						padding: 24px;
						justify-content: center;
						align-items: left;
						.header {
							font-size: $large-font-size;
							font-weight: 600;
							margin-bottom: 10px;
							text-align: left;
						}
						.write-up {
							font-size: $small-font-size;
							line-height: 32px;
							color: $mc-7;
							margin-bottom: 24px;
							text-align: left;
							padding-right: 24px;
						}
					}
					@media only screen and (max-width: 900px) {
						flex-direction: column;
						justify-content: center;
						overflow: scroll;
					}
				}
				.platforms {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;

					.specific-platform {
						height: 25vh;
						width: 19vw;
						min-width: 262px;
						min-height: 196px;
						border: 1px solid $border-color-dark;
						margin: 10px;
						border-radius: $moderate-border-radius;
						display: flex;
						flex-direction: column;
						justify-content: space-evenly;
						align-items: center;
						text-align: center;
						cursor: pointer;
						transition: 180ms;
						&:hover {
							box-shadow: $default-shadow;
						}
						.details {
							.header {
								font-size: $medium-font-size;
								font-weight: 600;
							}
							.sub-header {
								font-size: $body-text-font-size;
								font-weight: 400;
								color: $mc-5;
							}
						}
						img {
							height: 80px;
							width: 80px;
							border-radius: $moderate-border-radius;
						}
					}
				}
				.reconcilation-logo-wrapper {
					// border: 1px solid crimson;
					// height: 16rem;
					// width: 19rem;
					display: flex;
					justify-content: center;
					// align-items: center;
					overflow: hidden;
					.reconcilation-logo-container {
						height: 16rem;
						width: 16rem;
						border: 5px solid $color-blue;
						border-radius: 50%;
						background-color: rgba($color-blue, 0.2);
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						.reconcilation-logo-content {
							background-image: url("/assets/icons/icon-money-bag.png");
							background-size: 6rem 6rem;
							background-repeat: no-repeat;
							background-position: center;
							height: 12rem;
							width: 12rem;
							border-radius: 50%;
							filter: blur(0px);
							transition: 500ms;
							// animation-name: blurAndFocus;
							animation-duration: 2s;
							animation-iteration-count: infinite;
							@keyframes blurAndFocus {
								0% {
									filter: blur(8px);
								}
								25% {
									filter: blur(6px);
								}
								50% {
									filter: blur(4px);
								}
								100% {
									filter: blur(0px);
									background-size: 9rem 9rem;
								}
							}
						}
						.reconcilation-logo-zoom-lens-icon {
							position: absolute;
							top: 3px;
							left: -296px; //left: -52px;
							z-index: 1;
							transition: 500ms;
							img {
								height: 240px;
								height: 240px;
							}
							// animation-name: slideInZoomLens;
							animation-duration: 2s;
							animation-iteration-count: infinite;
							@keyframes slideInZoomLens {
								0% {
									left: -296px;
								}
								100% {
									left: -52px;
								}
							}
						}
					}
				}
				.shimmers {
					width: 70vw;
				}
				@media only screen and (max-width: 600px) {
					height: 95vh;
				}
			}
		}
		.graphics {
			position: absolute;
			height: 150px;
			width: 140px;
			&-astronaut {
				right: 6vw;
				top: 3vh;
			}
			&-planet {
				left: 4vw;
				bottom: 2vh;
			}
		}
	}
	.export-recon-reports {
		.report-success {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 90%;
			.illustration {
				img {
					width: 300px;
				}
			}
			.message {
				color: $color-dark-grey;
				font-size: $medium-font-size;
				margin-bottom: 20px;
				@extend .font--semibold;
			}
			.description {
				color: $color-dark-grey;
				font-size: $very-small-font-size;
				margin-bottom: 20px;
				span {
					@extend .font--semibold;
				}
			}
			.link-text {
				margin-top: 20px;
			}
		}
	}
	.filter-buttons {
		min-width: 500px;
		display: flex;
		flex-direction: row-reverse;
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}
		.platform-dropdown-container {
			align-items: center;
			position: relative;
			display: flex;
			margin-right: 10px;
			.multi-select-filter-container {
				margin: 0;
				width: 100%;
			}
			.dropdown-custom {
				color: #333;
				width: 210px;

				.container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 34px;
					padding: 1px 15px;
					width: auto;
				}

				.dropdown-component {
					width: inherit;
				}

				.dropdown-options {
					margin-bottom: 0;
					max-height: 199px;
					box-sizing: border-box;
					overflow-y: auto;
					// prevent scroll-chaining to parent elements
					overscroll-behavior: contain;

					.option {
						height: 37px;
						padding: 1px 15px;
						width: auto;
						border-bottom: 1px solid $border-color-dark;
						cursor: pointer;
						transition: 180ms;

						&.selected {
							background-color: $mc-3;
						}

						&:nth-last-child(1) {
							border-bottom: none;
						}

						&:hover {
							background-color: $mc-3;
						}

						&.no-hover {
							color: $mc-5;
							cursor: default;

							&:hover {
								background-color: transparent;
							}
						}
					}
				}

				.placeholder {
					color: $mc-5;
					font-size: 12px;
				}

				.platform-detail {
					display: flex;
					align-items: center;
					margin: auto;

					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						padding-right: 8px;

						img {
							width: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
					}
				}
			}
		}
	}
	.secondary-action-buttons-container {
		margin-right: 10px;
		.at-btn--secondary {
			img {
				width: 18px;
				vertical-align: text-top;
			}
		}
	}
	.payout-list-table-container {
		.transaction-rows {
			min-height: 100px;
		}
		.transaction-header-row {
			top: 130px;
			position: static;
			z-index: 0 !important;
		}
		.payout-period {
			padding-left: 25px;
			width: 20%;
		}
		.loc-info {
			width: 16%;
		}
		.order-count {
			width: 12%;
		}
		.verification {
			width: 24%;
			.queued {
				color: $color-blue;
			}
			&.passing {
				color: $color-green;
			}
			&.error {
				color: $color-red;
			}
			&.warning {
				color: $color-yellow;
			}
			.status-value {
				text-transform: uppercase;
				font-weight: 600;
				cursor: default;
				padding: 4px 8px;
				line-height: 26px;
				border-radius: 4px;
				font-size: 14px;
				@include truncate_text;
			}
			.status-processing {
				color: $color-yellow-dark;
				background-color: rgba($color-yellow-dark, 0.1);
			}
			.status-no-discrepancies-processed {
				color: $color-green-dark;
				background-color: rgba($color-green-dark, 0.1);
			}
			.status-discrepancies-processed {
				color: $color-brown;
				background-color: rgba($color-brown, 0.1);
			}
			.link-text {
				font-weight: normal;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.receivable {
			width: 12%;
		}
		.utr-transaction {
			width: 15%;
			padding-right: 25px;
		}
	}
	.basic-info {
		display: flex;
		flex-direction: column;
		font-size: $very-small-font-size;
		.payouts-info {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			&:first-child {
				margin-bottom: 20px;
			}
			&--blocks {
				width: 50%;
				flex-shrink: 0;
				&:last-child {
					text-align: right;
				}
				.sub-title {
					color: $mc-5;
					margin-bottom: 5px;
				}
				.value {
					font-size: 20px;
					font-weight: 600;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					.copy-icon {
						margin-left: 6px;
						svg {
							g {
								g {
									transition: 300ms;
								}
							}
							height: 16px;
							width: 16px;
						}
						&:hover {
							cursor: pointer;
							svg {
								g {
									g {
										fill: $color-blue;
									}
								}
							}
						}
					}
				}
			}
		}

		.reconciliation-processed-status {
			border-radius: $default-radius;
			margin-top: 40px;
			.status-info-container {
				position: relative;
				.header {
					position: absolute;
					color: $mc-5;
					font-size: $very-small-font-size;
					top: -24px;
					left: -0px;
				}
				.status-info-content {
					.row {
						.cell {
							margin: 5px 0;
							padding: 10px 15px;
							width: 180px;
							cursor: default;
							display: inline-block;
							border: 1px solid $border-color;
							margin-right: 20px;
							@include truncate_text;
							&:last-child {
								margin-right: 0px;
							}
						}
					}
					.reconciled {
						color: #0d7ea0;
					}
					.inconsistent {
						color: #b2596e;
					}
					.missing {
						color: #3498db;
					}
					.open {
						color: #ff7557;
					}
					.resolved {
						color: #3ec686;
					}
				}
			}
		}

		.payout-breakdown {
			border: 1px solid $border-color;
			box-shadow: $default-shadow;
			margin-top: 20px;
			.border-block {
				padding: 5px 15px;
				background-color: $mc-2;
				min-height: 20px;
				display: flex;
				justify-content: space-between;
				color: $mc-6;
				&--end {
					color: #000000;
				}
			}

			.breakdown-types-standalone {
				.breakdown-types-header-standalone-value {
					color: $mc-9;
					font-weight: bold;
				}
			}
			.breakdown-types {
				padding: 10px 15px;
				border-bottom: 1px solid $border-color-dark;
				&-header {
					font-weight: bold;
					&-standalone-value {
						font-weight: normal;
						color: $mc-5;
					}
				}
				&-header-with-value {
					font-weight: bold;
					display: flex;
					justify-content: space-between;
				}
				&-values {
					color: $mc-5;
					display: flex;
					justify-content: space-between;
					margin-top: 10px;
					margin-bottom: 10px;
				}
				&-standalone {
					display: flex;
					justify-content: space-between;
					padding: 10px 15px;
				}
			}
			.utr-details {
				display: flex;
				color: $mc-5;
				padding: 20px 15px;
				justify-content: center;
			}
		}
	}
	.overlay-sidebar-container {
		.form-sidebar-container {
			.header {
				.header-right-payout-details {
					min-width: 325px;
					display: flex;
					justify-content: left;
					gap: 10px;
					.help-btn-container {
						margin-right: 0px;
					}
				}
			}
			.footer {
				display: flex;
			}
		}
	}
	.filters {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 25px;
		.new-date-compare-filter {
			width: auto;
			margin-right: 10px;
			.preset-type {
				padding: 9px 10px;
				margin: 0;
				&.range {
					padding: 1px 0;
				}
			}
		}
		.dropdown-date-time-filter {
			margin-right: 10px;
		}
		.campaign-list-filter {
			.container {
				transition: 180ms;
				position: relative;
			}
			&.active {
				.container {
					border: 1px solid $color-blue;
				}
				.filter-icon {
					filter: invert(31%) sepia(47%) saturate(6605%) hue-rotate(225deg) brightness(95%) contrast(99%);
				}
				.filter-title {
					color: $color-blue;
				}
			}
			.filter-count {
				background: $color-blue;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
		.platform-dropdown-container {
			align-items: center;
			position: relative;
			display: flex;
			margin-right: 10px;
			.multi-select-filter-container {
				margin: 0;
				width: 100%;
			}
			.dropdown-custom {
				color: #333;
				width: 210px;
				.container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 34px;
					padding: 0px 10px;
					width: auto;
				}
				.dropdown-arrow {
					top: 7px;
					right: 10px;
				}
				.dropdown-component {
					width: inherit;
				}
				.dropdown-options {
					margin-bottom: 0;
					max-height: 199px;
					box-sizing: border-box;
					overflow-y: auto;
					// prevent scroll-chaining to parent elements
					overscroll-behavior: contain;
					.option {
						height: 37px;
						padding: 1px 10px;
						width: auto;
						border-bottom: 1px solid $border-color-dark;
						cursor: pointer;
						transition: 180ms;
						&.selected {
							background-color: $mc-3;
						}
						&:nth-last-child(1) {
							border-bottom: none;
						}
						&:hover {
							background-color: $mc-3;
						}
						&.no-hover {
							color: $mc-5;
							cursor: default;
							&:hover {
								background-color: transparent;
							}
						}
					}
				}
				.placeholder {
					color: $mc-5;
					font-size: 12px;
				}
				.platform-detail {
					display: flex;
					align-items: center;
					margin: auto;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						padding-right: 8px;
						img {
							width: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
					}
				}
			}
		}
	}
}

.payout-details-container {
	.order-details-tab {
		position: relative;
		.reconciliation-processed-status {
			border-radius: $default-radius;
			margin-top: 40px;
			.status-info-container {
				position: relative;
				.header {
					position: absolute;
					color: $mc-5;
					font-size: 12px;
					top: -15px;
					left: -0px;
				}
				.status-info-content {
					.row {
						font-size: $very-small-font-size;
						.cell {
							margin: 5px 0;
							padding: 10px 15px;
							width: 180px;
							cursor: default;
							display: inline-block;
							border: 1px solid $border-color;
							margin-right: 20px;
							@include truncate_text;
							&:last-child {
								margin-right: 0px;
							}
						}
					}
					.reconciled {
						color: #0d7ea0;
					}
					.inconsistent {
						color: #b2596e;
					}
					.missing {
						color: #3498db;
					}
					.open {
						color: #ff7557;
					}
					.resolved {
						color: #3ec686;
					}
				}
			}
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-top: 30px;
			height: 80px;
			.multi-select-filter-container {
				width: 300px;
			}
		}
		.items-edit-locations-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 70px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 40%;
				padding-left: 25px;
				.location-desc {
					display: flex;
					align-items: center;
					.availableAtLocation {
						padding: 1px 6px;
						margin: 0 5px;
						height: 12px;
						border-radius: 10px;
						text-align: center;
						font-size: 11px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 180ms;
						cursor: pointer;
						&[data-is-available="true"] {
							color: $color-green;
							border: 1px solid $color-green;
							background: rgba(46, 204, 113, 0.1);
						}
						&[data-is-available="false"] {
							color: $color-red;
							border: 1px solid $color-red;
							background: rgba(192, 57, 43, 0.1);
						}
						.loader {
							display: flex;
							position: relative;
							bottom: -3px;
							& > div {
								width: 3px;
								height: 3px;
								background: $mc-5;
								border-radius: 50%;
								margin: 3px;
								animation: loader 0.3s infinite alternate;
							}
							& > div:nth-child(2) {
								animation-delay: 0.1s;
							}
							& > div:nth-child(3) {
								animation-delay: 0.2s;
							}
							&.out {
								padding: 0 8px;
								& > div {
									background: $color-red;
								}
							}
							&.in {
								padding: 0 17px;
								& > div {
									background: $color-green;
								}
							}
						}
						@keyframes loader {
							to {
								transform: translate(0, -5px);
							}
						}
						.stock {
							display: flex;
							margin-left: 5px;
							cursor: pointer;
							padding: 1px 0;
							&.out {
								img {
									width: 10px;
									border: 1px solid $color-red;
									border-radius: 30px;
									background: rgba(192, 57, 43, 0.1);
								}
							}
							&.in {
								img {
									width: 10px;
									border: 1px solid $color-green;
									border-radius: 30px;
									background: rgba(46, 204, 113, 0.1);
								}
							}
						}
					}
				}
				.city-name {
					margin-top: 5px;
					color: $mc-7;
				}
				& > div {
					margin-bottom: 0;
				}
			}
			.stock-count {
				width: 17%;
			}
			.price {
				width: 25%;
			}
			.aggregator_price {
				width: 20%;
				display: flex;
				justify-content: space-between;
				.action-item {
					width: 12px;
					padding: 5px;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					cursor: pointer;
				}
			}
		}
		.items-edit-related-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: 90px;
				max-height: 90px;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.title {
				width: 25%;
				padding-left: 25px;
				display: flex;
				flex-direction: column;
				.order-id {
					display: flex;
					flex-direction: column;
					.link-text-non-clickable {
						cursor: default;
						font-size: 14px;
						font-weight: 700;
					}
					.external-id {
						color: $mc-5;
					}
				}
				.order-status {
					text-transform: capitalize;
				}
			}
			.category {
				width: 25%;
			}

			.price {
				width: 25%;
				padding-right: 25px;
			}
			.reconciliation-status {
				display: flex;
				flex-direction: column;
				.reconciled {
					color: $color-green;
				}
				.inconsistencies {
					color: $mc-5;
					line-height: 20px;
					.inconsistency {
						display: block;
						white-space: nowrap;
					}
				}
				.missing {
					color: #3498db;
				}
				.inconsistent {
					color: #b2596e;
				}
				.open {
					color: #ff7557;
				}
				.closed {
					// Closed and resolved are considered as same now - Insisted by product team
					color: #3ec686;
				}
				.resolved {
					color: #3ec686;
				}
				.reconciled {
					color: #0d7ea0;
				}
				.overflow-handler {
					transition: 300ms;
					.overflow-icon {
						cursor: default;
						display: inline-block;
						margin: 0;
						color: $color-blue;
						border-radius: 4px;
						font-size: 12px;
					}
					.overflow-content {
						position: absolute;
						bottom: 48px;
						right: 148px;
						border-radius: 8px;
						width: 200px;
						height: 120px;
						background-color: white;
						word-wrap: break-word;
						overflow-y: scroll;
						padding: 10px;
						box-shadow: 1px 0px 25px -5px rgba(0, 0, 0, 0.45);
					}
				}
			}
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
	.order-classification-component {
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		display: flex;
		.classification {
			padding: 16px;
			max-width: 130px;
			font-weight: 600;
			.title {
				font-size: $very-small-font-size;
				@include truncate_text;
			}
			.value {
				font-size: $medium-large-font-size;
				max-width: 120px;
				@include truncate_text;
			}
		}
		.inconsistent {
			color: #b2596e;
		}
		.missing {
			color: #3498db;
		}
		.resolved {
			color: #3ec686;
		}
		.general {
			.title {
				color: $mc-5;
				position: relative;
				width: 80px;
				.info-icon {
					position: absolute;
					height: 12px;
					width: 12px;
					top: 2.5px;
					right: 24px;
				}
			}
			.value {
				color: #222222;
			}
		}
		.order-total {
			border-right: 1px solid $border-color-dark;
		}
	}
	.form-container-non-sticky-top-bar {
		.at-top-bar-wrapper-v2 {
			position: static;
		}
	}
	.form-content {
		.recon-status-messages-container {
			height: 38px;
		}
	}
}

.reconciliation-stats {
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.filter-buttons {
			display: flex;
			flex-direction: row-reverse;
			margin-top: 10px;
			.campaign-list-filter {
				position: relative;
				margin-left: 10px;
				.filter-count {
					background: $color-green;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
			.multi-select-filter-container {
				width: 175px;
				margin: 0;
				.Select-value-label,
				.Select-option {
					text-transform: capitalize;
				}
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		z-index: $zI-level-1;
	}
	.recon-stats-filters {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 25px;
		padding: 0 25px;
		.multi-select-filter-container {
			width: 200px;
			margin-right: 10px;
		}
		.new-date-compare-filter {
			width: auto;
			margin-right: 10px;
			.preset-type {
				padding: 9px 10px;
				margin: 0;
				&.range {
					padding: 1px 0;
				}
			}
		}
		.dropdown-date-time-filter {
			margin-right: 10px;
		}
		.campaign-list-filter {
			.container {
				transition: 180ms;
				position: relative;
			}
			&.active {
				.container {
					border: 1px solid $color-blue;
				}
				.filter-icon {
					filter: invert(31%) sepia(47%) saturate(6605%) hue-rotate(225deg) brightness(95%) contrast(99%);
				}
				.filter-title {
					color: $color-blue;
				}
			}
			.filter-count {
				background: $color-blue;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
	}
	.recon-graphs {
		padding: 25px;
		.recharts-wrapper {
			margin-top: 20px;
			.recharts-surface {
				overflow: visible;
				.recharts-bar {
					.recharts-layer {
						path {
							&:hover {
								fill-opacity: 0.8;
							}
						}
					}
				}
			}
		}
		.recharts-rectangle {
			&.recharts-tooltip-cursor {
				// fill: transparent !important;
			}
		}
		.recharts-legend-wrapper {
			margin-top: -25px;
			li {
				display: flex;
				align-items: center;
				margin: 0 25px;
				&:first-child {
					margin-left: 0;
				}
				span {
					position: relative;
					top: 2px;
				}
			}
		}
		.sankey-tooltip {
			padding: 10px;
			border: 1px solid #cccccc;
			background-color: $mc-1;
		}
	}
}
.recon-status-messages-container {
	background-color: white;
	position: fixed;
	min-width: 150px;
	height: 100px;
	border-radius: 2px;
	padding: 15px;
	box-shadow: $default-shadow;
	border: 1px solid $border-color-dark;
	font-size: $very-small-font-size;
	div {
		margin-bottom: 4px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.reconciled {
		color: #0d7ea0;
	}
	.inconsistent {
		color: #b2596e;
	}
	.missing {
		color: #3498db;
	}
	.open {
		color: #ff7557;
	}
	.resolved {
		color: #3ec686;
	}
}
