.periscope-container {
	.specific-item-issue {
		padding: 24px 24px;
		border-radius: 4px;
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
		background-color: #fff;
		border: solid 1px rgba(0, 0, 0, 0.05);
		margin-bottom: 15px;
		padding-bottom: 15px;

		.specific-item-issue-table-head {
			.specific-item-issue-brand-item {
				font-family: Source Sans Pro;
				font-size: 28px;
				font-weight: 600;
				line-height: 36px;
				text-align: left;
				color: #363636;
			}

			.specific-item-issue-table-header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.specific-item-issue-text-display {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.specific-item-issue-header-display {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 5.67px;

						.specific-item-issue-right-arrow {
							width: 5.05px;
							height: 8.09px;
						}
					}
				}

				.table-header-grey-text {
					font-family: Source Sans Pro;
					font-size: 16px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #8a8a8a;
				}

				.fetch-time {
					margin-top: 10px;
					align-self: auto;
				}

				.specific-item-issue-table-info {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 8px;

					.specificitem-issue-btn-text {
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 600;
						line-height: 24px;
						text-align: left;
					}

					.specific-item-issue-refresh-btn {
						cursor: pointer;
						padding: 8px 16px 8px 12px;
						border: 1px solid #eaeaea;
						gap: 8px;
						display: flex;
						align-items: center;

						.refresh-img {
							width: 12px;
							height: 16px;
						}

						.specificitem-issue-btn-text {
							color: #363636;
						}
					}

					.specific-item-issue-share-div {
						cursor: pointer;
						background: #3b5feb;
						display: flex;
						gap: 8px;
						align-items: center;
						padding: 8px 16px 8px 12px;

						.share-img {
							height: 13.33px;
							width: 10.67px;
						}

						.specificitem-issue-btn-text {
							color: #ffffff;
						}
					}
				}
			}
		}

		.specific-issues-filters-div {
			display: flex;
			justify-content: space-between;

			.specific-item-issues-left-filters {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;

				.sortby {
					width: 180px;

					.sort-dropdown {
						display: flex;
						gap: 8px;
						align-items: center;

						.status-heading {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #8a8a8a;
						}
					}

					.sortby-status {
						color: #363636;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
					}
				}

				.campaign-list-filter .filter-title .filter-count {
					text-align: center;
				}

				.campaign-list-filter .container {
					border-radius: 3px;
					text-align: center;
					padding: 6px 16px;
					white-space: nowrap;
					position: relative;
					cursor: pointer;
				}

				.specificitemissue-platform-filter .placeholder,
				.select-filter-custom .dropdown-custom .container .option,
				.city-filter .react-select__single-value {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					color: #363636;
				}

				.container .filter-title {
					margin: 0 8px;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}
			}
		}

		.specific-item-issue-table-data {
			.specific-issue-main-table {
				border: 1px solid #eaeaea;
				background: #ffffff;
				border-collapse: collapse;

				.specificissue-table-body {
					border: 1px solid #eaeaea;

					.specificissue-location-cell {
						display: flex;
						align-items: baseline;
						gap: 8px;

						.specificissue-location-text {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							text-align: left;
							color: #363636;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 215px;
						}

						.specificissue-rating-display {
							display: flex;
							align-items: center;
							gap: 4px;

							.specificissue-ratings-text {
								font-family: Source Sans Pro;
								font-size: 12px;
								font-weight: 600;
								line-height: 16px;
								text-align: left;
								color: #8a8a8a;
							}

							.star {
								width: 8.45px;
								height: 8.02px;
							}
						}
					}

					.issue-hover-red {
						background-color: #fff5f5;
					}

					.issue-hover-red:hover {
						background: #ffe5e5;
					}

					.specificissue-platform-cell {
						display: flex;
						gap: 8px;
						align-items: center;
						padding: 0 24px 0 14px;

						.specific-missingitem-image {
							width: 15px;
							height: 13.25px;
						}

						.issue-description-display {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #363636;
							text-wrap: nowrap;
						}
					}
				}

				.selectedissue-thead {
					.selectedissue-firstrow {
						border: 1px solid #eaeaea;

						.selectedissue-table-header-text {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #8a8a8a;
							display: flex;
							gap: 8px;
							align-items: center;

							.platform-image-display {
								width: 20px;
								height: 20px;
							}

							.sorting-images {
								display: flex;
								flex-direction: column;

								img {
									width: 10.67px;
									height: 5.33px;
								}
							}
						}
					}
				}
				.info-popover {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #363636;
					padding: 8px 12px;
					width: fit-content;
					text-wrap: nowrap;
				}
			}
		}

		.item-platform-head {
			gap: 8px;
			display: flex;
			align-items: center;
			justify-content: right;
			padding: 16px 20px 16px 16px;
			text-transform: capitalize;

			.table-platform-img {
				width: 20px;
				height: 20px;
			}
		}

		.issues-tablehead-text {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			color: #646464;
		}

		.issue-show-cursor {
			cursor: pointer;
		}

		.item-table-loading {
			tr td:first-child {
				border-right: 1px solid #eaeaea;
			}

			.shimmer {
				border-radius: 0px;
			}
		}

		.intro-icon-loading {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 24px;
		}

		.star-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 12px;
		}

		.brand-image-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 11px;
		}

		.all-loading-span {
			display: flex;
		}

		.branditem-text-loading {
			width: 134px;
			height: 20px;
			padding: 20px 0px 20px 0px;
		}

		.platform-loading-item {
			padding: 16px 16px 16px 50px;
		}

		.shimmer {
			border-radius: 0px;
		}
	}
}
