.entity-groups {
	.action-buttons-container {
		margin-right: 10px;
	}
	.subtitle {
		vertical-align: top;
		line-height: normal;
		font-size: $very-small-font-size;
		color: $mc-7;
		span {
			@extend .font--semibold;
		}
	}
	.item-groups-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 30%;
			padding-left: 25px;
		}
		.description {
			width: 50%;
		}
		.items_associated {
			width: 20%;
			text-align: right;
			padding-right: 25px;
		}
	}
	.location-groups-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 30%;
			padding-left: 25px;
		}
		.description {
			width: 50%;
		}
		.locations_associated {
			width: 20%;
			text-align: right;
			padding-right: 25px;
		}
	}
	.timing-groups-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 40%;
			padding-left: 25px;
		}
		.description {
			width: 40%;
		}
		.slots_count {
			width: 20%;
			padding-right: 25px;
			text-align: right;
		}
	}
	.add-another-slot {
		margin-bottom: 30px;
		.at-btn--secondary {
			width: auto;
			border: none;
			color: #3b5feb;
			font-size: $very-small-font-size;
			font-weight: $font--semibold;
			padding-left: 0px;
		}
		.remaining-days {
			color: $color-light-grey;
			font-size: $very-small-font-size;
			margin: 0;
		}
	}
	.timings-section {
		.header-text {
			font-size: $small-font-size;
			@extend .font--semibold;
		}
		.no-items-placeholder {
			padding: 5% 0;
		}
		.delete-time-slot {
			display: flex;
			align-items: flex-end;
			flex-direction: row-reverse;
			.delete-icon {
				width: 15px;
				padding: 5px;
				border: 1px solid $color-red;
				border-radius: $default-radius;
				cursor: pointer;
			}
		}
		.timings-container {
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
			padding: 25px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-top: 10px;
			margin-bottom: 25px;
			&:nth-last-child(1) {
				margin-bottom: 0;
			}
			.form-row {
				&:nth-last-child(1) {
					margin-bottom: 0;
				}
			}
			.time-fields-container {
				display: flex;
				align-items: flex-end;
				& > div {
					width: 38px;
					.meta-info {
						white-space: nowrap;
					}
					.menu-wrapper {
						width: 53px !important;
						.react-select__option {
							padding: 10px;
						}
					}
					.react-select__dropdown-indicator {
						display: none;
					}
					.validation-message {
						width: 300px;
					}
				}
				.colon {
					margin: 10px 5px;
					@extend .font--semibold;
				}
			}
			.occurence,
			.select-days {
				display: flex;
				flex-direction: column;
				.meta-info {
					color: $mc-5;
					padding-bottom: 5px;
					font-size: 12px;
				}
				.checkmark {
					margin-top: 1px;
				}
				.at-checkbox {
					width: 25%;
				}
				.days-section {
					display: flex;
					.day-container {
						padding: 10px 5px;
						width: 35px;
						border: 1px solid $border-color-dark;
						border-radius: $default-radius;
						margin-right: 15px;
						text-align: center;
						transition: 180ms;
						cursor: pointer;
						&.selected {
							border: 1px solid $color-blue;
							background: rgba(47, 88, 242, 0.15);
						}
						&.read-only {
							cursor: default;
							pointer-events: none;
							border: 1px solid $border-color-dark;
							color: #777;
							background-color: #eaeaea;
						}
						&.read-only.selected {
							cursor: default;
							pointer-events: none;
							border: 1px solid rgba($color: $color-blue, $alpha: 0.4);
							background: rgba(47, 88, 242, 0.15);
						}
					}
				}
			}
		}
		.timing-group-wrapper:not(:first-child) {
			padding: 24px 0px;
		}
		.cancel-timeslot {
			padding: 10px;
			cursor: pointer;
		}
		.timing-group-wrapper:first-child {
			padding-bottom: 24px;
		}
		.timing-group-wrapper {
			border-bottom: 1px solid $divider-color;

			.add-slot {
				.at-btn--secondary {
					width: auto;
					border: none;
					color: #3b5feb;
					font-size: $exta-small-font-size;
					font-weight: $font--semibold;
					padding-left: 0px;
					padding-top: 8px;
				}
			}

			.timings-wrapper {
				display: flex;
				align-items: flex-end;
				gap: 32px;
				width: 100%;
				margin-bottom: 24px;

				.multi-select-filter-container {
					width: 50%;
				}
				.days-section {
					display: flex;
					gap: 8px;
					.day-container {
						width: 30px;
						height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid #eaeaea;
						border-radius: $border-radius-default;
						text-align: center;
						transition: 180ms;
						font-size: 14px;
						cursor: pointer;
						&.selected {
							color: #3b5feb;
							border: none;
							background: #f2f5ff;
						}
						&.read-only {
							cursor: default;
							pointer-events: none;
							border: 1px solid $border-color-dark;
							color: #777;
							background-color: #eaeaea;
						}
						&.read-only.selected {
							cursor: default;
							pointer-events: none;
							color: #3b5feb;
							border: none;
							background: #f2f5ff;
						}
						&.disabled-days {
							pointer-events: none;
							cursor: default;
							background-color: #f5f5f5;
							border: none;
							color: #8a8a8a;
						}
					}
				}
			}
			.timing-slot-wrapper {
				display: flex;
				align-items: flex-end;
				gap: 32px;
				.timing-slot-wrapper--slot {
					width: 45%;
					display: grid;
					align-items: flex-end;
					gap: 8px;
					grid-template-columns: repeat(3, 1fr);
				}
			}
			.timing-slot-wrapper:not(:first-child) {
				margin-top: 8px;
			}
		}
	}
}

.item-groups-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.crm-title-search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.item-group-items-table-container {
		.transaction-header-row {
			top: 38px;
		}
		.transaction-rows {
			min-height: 90px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.item-title {
			width: 60%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.item-desc {
				display: flex;
				.food-type {
					margin: auto 0;
				}
				.link-text {
					margin-left: 10px;
				}
			}
			.crm-title {
				color: $mc-5;
				padding-left: 22px;
			}
			.item_tags {
				padding-left: 23px;
				.tag-item {
					margin: 2px 5px 0px 0px;
				}
			}
		}
		.category {
			width: 20%;
		}
		.price {
			width: 20%;
			padding-right: 25px;
			text-align: right;
		}
	}
}

.location-groups-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.form-sidebar-container {
		.form-container {
			.form-content {
				padding: 0 25px 25px 25px;
			}
		}
	}
	.location-group-locations-table-container {
		.transaction-header-row {
			top: 38px;
		}
		.transaction-rows {
			min-height: 70px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 40%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
		}
		.location {
			width: 20%;
		}
		.address {
			width: 40%;
		}
	}

	.filters-container {
		display: flex;
		width: 100%;
	}

	.dropdown-custom {
		width: 190px;
		margin-bottom: 20px;
		.container {
			height: 32px;
			padding: 1px 10px;
			border: 0;
			.option {
				.custom-label {
					font-size: $medium-font-size;
				}
			}
		}
		.dropdown-options {
			.option {
				height: 37px;
				padding: 1px 10px;
			}
		}
		.custom-option,
		.custom-label {
			display: flex;
			align-items: center;
			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				width: 22px;
				height: 22px;
				text-transform: uppercase;
				font-size: $very-small-font-size;
				border-radius: $default-radius;
				@extend .font--semibold;
				img {
					width: 22px;
					height: 22px;
					object-fit: cover;
					border-radius: $default-radius;
				}
				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}
				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}
				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}
				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}
				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}
			}
		}
	}
}

.timing-groups-edit-container {
	[aria-label][data-balloon-pos]:after {
		left: -120px;
		width: 240px;
		font-size: $exta-small-font-size;
	}
	.wrapper-sticky-top {
		top: 0px;
	}
}
