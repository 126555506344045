.periscope-container {
	.header-item-availability {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.item-share-info {
			display: flex;
			gap: 12px;
			align-items: center;
			font-family: Source Sans Pro;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}

		.fetch-time {
			color: #8a8a8a;
		}

		.refresh-btn {
			border: 1px solid #eaeaea;
			padding: 8px 16px 8px 12px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			cursor: pointer;

			.refresh-img {
				margin: 0 5px 0 0;
			}

			.refresh-text {
				color: #363636;
			}
		}

		.share-report {
			padding: 8px 16px 8px 12px;
			display: flex;
			gap: 8px;
			background: #3b5feb;

			.share-text {
				color: white;
			}
		}
	}

	.align-center {
		display: flex;
		align-items: center;
	}

	.section-container-common-periscope {
		.item-availability-filters {
			.left-filters-item {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;

				.custom-label,
				.item-availability-status,
				.dropdown-custom span {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}

				span {
					.item-availability-status {
						padding: 0 8px;
					}
				}

				.city-filter {
					.multi-select-filter-container {
						.react-select__control {
							width: 200px;
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 22px;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.table-item-availability {
		border-collapse: collapse;
		border: 1px solid #eaeaea;
		background: #ffffff;
		.popover-wrapper-container .popover-container {
			position: absolute;
			top: 55px;
			right: 0;
			background-color: #fff;
			box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			min-width: 150px;
			min-height: 30px;
			box-sizing: border-box;
			z-index: 50;
			width: fit-content;
		}
		.popover-wrapper-container.down-left .popover-container {
			left: 75px;
		}

		.info-popover {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			color: #363636;
			padding: 8px 12px;
			width: fit-content;
			text-wrap: nowrap;
		}

		.intro-icon-loading {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 24px;
		}

		.all-loading-span {
			display: flex;
		}

		.star-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 12px;
		}

		.brand-image-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 11px;
		}

		.branditem-text-loading {
			width: 134px;
			height: 20px;
			padding: 20px 0px 20px 0px;
		}

		.platform-loading-item {
			padding: 16px 16px 16px 50px;
		}

		.all td:nth-child(2) {
			border-left: 1px solid #eaeaea;
		}

		.branditem-image-container {
			margin: 0px 0px 0px 20px;

			.brand-item-img-span {
				cursor: pointer;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 25px;
				margin-right: 6px;
				user-select: none;
			}

			.non-colored-branditem {
				width: 15.02px;
				height: 11.31px;
			}

			.colored-branditem {
				width: 14px;
				height: 11.5px;
			}

			.branditem-display {
				padding: 19px 0;

				.brand-image-display {
					width: 20px;
					height: 20px;
					border-radius: 2px;
					margin: 0 8px 0 16px;
				}

				.brand-text-styling {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #363636;
					margin-right: 8px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 180px;
				}

				.rating-text-styling {
					font-family: Source Sans Pro;
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					text-align: left;
					color: #8a8a8a;
				}

				.brand-display {
					user-select: none;
				}
			}
		}

		.item-platform-offline {
			height: 16px;
			width: 16px;
		}

		.item-percent-display {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
			color: #b02b2b;
		}

		.brand-item-text {
			border: 1px solid #eaeaea;
			padding: 0 0 0 68px;
			min-width: 340px;
			max-width: 308.1px;
		}

		.item-tablehead-text {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			color: #8a8a8a;

			.item-platform-head {
				text-transform: capitalize;
				gap: 8px;
				display: flex;
				align-items: center;
				justify-content: right;
				padding: 16px 20px 16px 16px;

				.table-platform-img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	// .table-display-item-availability
	.table-item-availability {
		.tooltip-item {
			position: relative;
			padding: 20px 0;
			width: 100%;

			.tooltiptext-item {
				visibility: hidden;
				background-color: white;
				color: black;
				text-align: center;
				border-radius: 6px;
				position: absolute;
				z-index: 1;
				right: 50%;
				transform: translateX(50%);
				display: flex;
				flex-direction: column;
				padding: 8px 12px;
				color: #363636;
			}

			.all-offline-display {
				min-width: 175px;
				text-wrap: nowrap;
			}
		}

		.tooltip-item .tooltiptext-item::after {
			content: "";
			position: absolute;
			bottom: 100%;
			transform: translateX(-50%);
			left: 50%;
			border-width: 7px;
			border-style: solid;
			border-color: transparent transparent white transparent;
		}

		.tooltip-item:hover .tooltiptext-item {
			visibility: visible;
		}

		.tooltiptext-item {
			.tooltip-description-item {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				text-align: center;
			}
		}
	}

	.item-availability-periscope {
		.filters-location {
			display: flex;
			justify-content: space-between;
		}

		.shimmer {
			border-radius: 0px;
		}

		.hover-red-min-offline {
			.tooltiptext-item {
				top: 112%;
			}
		}

		.hover-red-offline {
			.tooltiptext-item {
				top: 115%;
			}
		}

		.hover-partial-offline {
			.tooltiptext-item {
				top: 116%;
			}
		}

		.hover-green-online {
			.tooltiptext-item {
				top: 115%;
			}
		}

		.hover-green-online:hover .hover-online-images {
			color: green !important;
		}

		.hover-red-offline {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
			color: #b02b2b;
		}

		.hover-red-offline:hover,
		.hover-red-min-offline:hover {
			background-color: #ffe5e5;
		}

		.hover-green-offline {
			background-color: #ffffff;
		}

		.hover-green-online:hover {
			background: #f6fbf8;
		}

		.hover-red-offline,
		.hover-red-min-offline {
			background: #fff5f5;
		}

		.hover-red-min-offline {
			cursor: pointer;
		}

		.hover-partial-offline:hover {
			background: #f5f5f5;
		}

		.hover-online-images {
			display: flex;
			justify-content: right;

			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			color: #aaaaaa;
			height: 19px;
			align-items: center;
		}

		.online-color:hover .hover-online-images {
			color: #0b6e1b;
		}

		.correct-black-img {
			width: 12.28px;
			height: 9.41px;
		}

		.correct-green-img {
			display: none;
			right: 0;
			width: 12.28px;
			height: 9.4px;
		}

		.hover-online-images:hover .correct-black-img {
			display: none;
		}

		.hover-green-online:hover .correct-black-img,
		.online-color:hover .correct-black-img {
			display: none;
		}

		.hover-green-online:hover .correct-green-img,
		.online-color:hover .correct-green-img {
			display: block;
		}
	}

	.percent-table-display {
		.tooltip-item {
			position: relative;
			padding: 20px 0;
			width: 100%;

			.tooltiptext-item {
				visibility: hidden;
				background-color: white;
				color: black;
				text-align: center;
				border-radius: 6px;
				position: absolute;
				z-index: 1;
				right: 50%;
				transform: translateX(50%);
				display: flex;
				flex-direction: column;
				padding: 8px 12px;
				color: #363636;
			}

			.all-offline-display {
				min-width: 175px;
				text-wrap: nowrap;
			}
		}

		.tooltip-item .tooltiptext-item::after {
			content: "";
			position: absolute;
			bottom: 100%;
			transform: translateX(-50%);
			left: 50%;
			border-width: 7px;
			border-style: solid;
			border-color: transparent transparent white transparent;
		}

		.tooltip-item:hover .tooltiptext-item {
			visibility: visible;
		}

		.tooltiptext-item {
			.tooltip-description-item {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				text-align: center;
			}
		}
	}

	.partially-offline-color {
		.tooltip-item {
			.tooltiptext-item {
				top: 117%;
			}
		}
	}

	.offline-color {
		.tooltip-item {
			.tooltiptext-item {
				top: 113%;
			}
		}
	}

	.online-color {
		.tooltip-item {
			.tooltiptext-item {
				top: 115%;
			}
		}
	}

	.complete-offline-stores {
		.tooltip-item {
			.tooltiptext-item {
				top: 116%;
			}
		}
	}

	.item-availability-periscope {
		.item-main-graph {
			padding: 12px 16px 18px 16px;

			.item-status {
				height: 12px;
				margin: 12px 0 0 5px;
			}

			.item-status-div {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.item-info-status {
					display: flex;
					gap: 16px;

					.item-status-data {
						display: flex;
						flex-direction: column;

						.percent-display-item,
						.item-text-status {
							user-select: none;
						}
					}

					.item-status-present {
						display: flex;
						padding: 8px 10px;
						border-radius: 4px;

						.item-text-status {
							display: flex;
							align-items: center;
							justify-content: center;
							color: #646464;
							gap: 4px;
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							text-wrap: none;
							.popover-wrapper-container.down-left .popover-container {
								left: -21px;
							}
							.popover-wrapper-container .popover-container {
								position: absolute;
								top: 29px;
								.pop-info {
									padding: 12px;
									width: 265px;
									.main-title {
										font-size: 14px;
										font-weight: 600;
										line-height: 22px;
										text-align: left;
									}
									color: #363636;
									.info-desc {
										font-size: 14px;
										font-weight: 400;
										line-height: 22px;
										text-align: left;
									}
								}
							}
							.title-desc {
								display: flex;
								flex-direction: column;
								padding: 12px;
							}

							.item-graph-indicator {
								img {
									width: 10px;
									height: 10px;
								}
							}
						}

						.item-info-status-color {
							width: 16px;
							height: 16px;
							border-radius: 2px;
							margin: 2px 12px 0 0;
						}
					}

					.item-status-present:hover {
						background-color: #eaeaea;
						cursor: pointer;
					}

					.selected-status {
						background-color: #eaeaea;
					}
				}

				.item-display-select {
					display: flex;
					gap: 20px;

					.select-display-container {
						.link {
							text-decoration: none;

							.not-selected {
								color: #646464;
							}
						}

						display: flex;
						cursor: pointer;

						.location-border {
							border-top: 1px solid #eaeaea;
							border-right: 1px solid #eaeaea;
							border-bottom: 1px solid #eaeaea;
						}

						.item-border {
							border-top: 1px solid #eaeaea;
							border-left: 1px solid #eaeaea;
							border-bottom: 1px solid #eaeaea;
						}

						.item-border-radius {
							border-radius: 2px 0 0 2px;
						}

						.location-border {
							border-radius: 0px 2px 2px 0px;
						}

						.select-display {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 12px 16px;
							gap: 4px;

							.select-item-image {
								width: 11px;
								height: 13.75px;
							}

							.select-item-text {
								font-family: Source Sans Pro;
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								text-align: left;
							}
						}

						.selected-display {
							color: #3b5feb;
							border: 1px solid #3b5feb;
							background: #f2f5ff;
							font-weight: 600;
						}
					}
				}

				.percent-display-item {
					font-family: Source Sans Pro;
					font-size: 18px;
					font-weight: 600;
					line-height: 24px;
					text-align: left;
					color: #363636;
				}

				.item-total-display {
					display: flex;
					flex-direction: column;
					gap: 4px;
					padding-right: 20px;
					border-right: 1px solid #eaeaea;

					.item-text-total {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #646464;
						text-wrap: nowrap;
					}
				}
			}

			.brown {
				background: #b02b2b;
			}

			.orange {
				background: #fd9d53;
			}

			.blue {
				background: #c5d1fc;
			}

			.space-middle {
				margin: 0 6px;
			}

			.loading-stackedbar {
				width: 33%;

				.shimmer {
					height: 12px;
					width: 100.4%;
					border-radius: 0px;
				}
			}
		}

		.table-display-item-availability {
			.table-div-location {
				.popover-wrapper-container .popover-container {
					top: 35px;
					right: 0;
					width: fit-content;
				}
				.info-popover {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #363636;
					padding: 8px 12px;
					width: fit-content;
					text-wrap: nowrap;
				}
				.api-table-data {
					.offline-color {
						background: #fff5f5;
						cursor: pointer;
					}

					.offline-color:hover {
						background: #ffe5e5;
					}

					.complete-offline-stores {
						background: #fff5f5;
					}

					.complete-offline-stores:hover {
						background: #ffe5e5;
					}

					.partially-offline-color:hover {
						background: #f5f5f5;
					}

					.online-color {
						background: #ffffff;
					}

					.online-color {
						color: grey;
					}

					.online-color:hover {
						color: green !important;
					}

					.online-color:hover {
						background: #f6fbf8 !important;
					}

					.item-platform-offline {
						height: 16px;
						width: 16px;
					}

					.item-percent-display {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						color: #b02b2b;
					}
				}

				.location-cell-div {
					display: flex;
					gap: 8px;

					.rating-text-location {
						font-family: Source Sans Pro;
						margin-top: 1px;
						font-size: 12px;
						font-weight: 600;
						line-height: 16px;
						text-align: left;
						color: #8a8a8a;
						display: block;
						align-self: center;
						gap: 4px;
					}

					.location-cell-text {
						max-width: 200px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.tablehead-location-item-availability {
					.platforms-head-location {
						padding: 12px 16px;
						border: 1px solid #eaeaea;
					}
					.table-heading-platforms {
						text-transform: capitalize;
					}
				}

				.shimmer {
					border-radius: 0;
				}

				.display-location-loading {
					width: 80%;
					height: 100%;
				}

				.display-brand-loading {
					display: flex;
					gap: 16px;

					.brand-img-loading {
						width: 20px;
						height: 20px;
					}

					.brand-text-loading {
						width: 50%;
					}
				}

				.display-platform-loading {
					padding: 20px 25px;

					.shimmer-container {
						width: 65%;
						min-width: 80px;
						margin: 0 0 auto auto;
					}
				}
			}
		}

		.brand-cell {
			padding: 0 10px 0 0;
			.rating-text-location {
				display: flex;
				gap: 4px;
			}
			.inside-text-location {
				max-width: 120px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.brand-display {
		.text-logo {
			margin: -2px 8px 0 16px;
			border-radius: 2px;

			.brand-text-logo {
				padding: 0 1px;
				font-size: 15px;
				font-weight: 600;
			}

			.brand-text-logo {
				padding: 1.5px 2.5px 1.5px 2.5px;
				font-weight: 600;
			}
		}
	}

	.image-info,
	.text-logo {
		.brand-image-location {
			font-weight: 600;
		}

		&.brown {
			color: $color-brown-dark;
			background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
		}

		&.blue {
			color: $color-blue-dark;
			background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
		}

		&.green {
			color: $color-green-dark;
			background-color: rgba($color: $color-green-dark, $alpha: 0.1);
		}

		&.red {
			color: $color-red-dark;
			background-color: rgba($color: $color-red-dark, $alpha: 0.1);
		}

		&.purple {
			color: $color-violet;
			background-color: rgba($color: $color-violet, $alpha: 0.1);
		}
	}

	// coach mark
	body {
		.harsh-coach-mark {
			border-radius: 50%;
			border: 0;
		}
	}

	.hcm-tooltip-base {
		background-color: #ffffff !important;
		left: 332.325px !important;
		padding: 0 !important;
		margin: 0.7px 0 !important;
	}

	body {
		:root {
			--black-transparent: #eaeaea !important;
			--tooltip-move: 2px;
			--tooltip-height: 5px;
			--tooltip-shift: calc(-2 * var(--tooltip-height));
		}

		.coach-mark-hover-div {
			background-color: inherit !important;
			border-radius: 2px !important;
			box-shadow: 0px 0px 12px 0px #00000029 !important;

			.coach-mark-para1 {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
				text-align: left;
				color: #363636;
			}

			.coach-mark-para2 {
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				text-align: left;
				color: #646464;
			}
		}
	}

	.hcm-tooltip-base-left::before,
	.hcm-tooltip-base-bottom::before,
	.hcm-tooltip-base-right::before,
	.hcm-tooltip-base-top::before {
		border-color: red;
	}

	body {
		.harsh-coach-mark {
			opacity: 0.4;
			box-sizing: border-box;
			position: fixed;
			z-index: 9999998;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			transition: all 0.3s ease-out;
			border-radius: 50%;
		}

		.hcm-tooltip-base {
			box-sizing: content-box;
			position: fixed;
			z-index: 9999999;
			margin-top: -15px;
			border-radius: 0px;
			-webkit-transition: all 0.3s ease-out;
			-moz-transition: all 0.3s ease-out;
			-ms-transition: all 0.3s ease-out;
			-o-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
		}

		.hcm-tooltip-base-left::before,
		.hcm-tooltip-base-bottom::before,
		.hcm-tooltip-base-right::before,
		.hcm-tooltip-base-top::before {
			content: "";
			border-top-width: var(--tooltip-height);
			border-left-width: var(--tooltip-height);
			border-right-width: var(--tooltip-height);
			border-bottom-width: var(--tooltip-height);
			border-style: solid;
			background: transparent;
			border-color: #ffffff;
			position: absolute;
		}

		.hcm-tooltip-base-left::before {
			top: var(--tooltip-move);
			right: var(--tooltip-shift);
			border-top-color: transparent;
			border-bottom-color: transparent;
			border-right-color: transparent;
		}

		.hcm-tooltip-base-bottom::before {
			top: var(--tooltip-shift);
			left: var(--tooltip-move);
			border-top-color: transparent;
			border-right-color: transparent;
			border-left-color: transparent;
		}

		.hcm-tooltip-base-top::before {
			bottom: var(--tooltip-shift);
			left: var(--tooltip-move);
			border-bottom-color: transparent;
			border-right-color: transparent;
			border-left-color: transparent;
		}

		.hcm-tooltip-base-right::before {
			left: var(--tooltip-shift);
			top: var(--tooltip-move);
			border-top-color: transparent;
			border-left-color: transparent;
			border-bottom-color: transparent;
		}
	}

	.hcm-tooltip-base {
		border-radius: 2px !important;
	}

	.sample-table {
		.error-state {
			position: absolute;
			top: 10%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
			background-color: #ffffff;

			.load-chart-div {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 24px;
				gap: 16px;

				.load-chart-error-icon {
					width: 97.5px;
					height: 97.5px;
				}

				.load-chart-info {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.chart-info {
						display: flex;
						flex-direction: column;

						.chart-main-text {
							font-family: Source Sans Pro;
							font-size: 18px;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
							color: #666666;
						}

						.chart-para-info {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #979797;
						}
					}

					.try-again {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 4px;
						cursor: pointer;

						.load-again-img {
							width: 13.33px;
							height: 13.33px;
						}

						.try-again-text {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 20px;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.stop-moving .shimmer::after {
		background: #f4f4f4 !important;
	}

	.no-scroll {
		overflow: hidden;
	}
}
