@keyframes slideUp {
	from {
		transform: translateY(300px);
	}
	to {
		transform: translateY(0px);
	}
}
.form-sidebar-container {
	display: flex;
	flex-direction: column;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	& > .header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 25px;
		.header-left {
			display: flex;
			gap: 16px;
			.image-container {
				box-shadow: $default-shadow;
				border-radius: $default-radius;
				height: 56px;
				width: 56px;
				img {
					height: 56px;
					width: 56px;
					border-radius: $default-radius;
				}
			}
			.title-content {
				.at-breadcrumbs-container {
					padding: 0;
					margin-bottom: 8px;
				}
				.title {
					vertical-align: top;
					line-height: normal;
					font-size: $medium-large-font-size;
					@extend .font--semibold;
					margin-bottom: 5px;
				}
				.subtitle {
					vertical-align: top;
					line-height: normal;
					font-size: $very-small-font-size;
					color: $mc-7;
					span {
						@extend .font--semibold;
					}
				}
			}
		}
		.header-right {
			cursor: pointer;
			display: flex;
			flex-direction: row-reverse;
			position: relative;
			flex-shrink: 0;
			&:hover {
				.title {
					color: $mc-8;
				}
			}
			.at-btn {
				&.margin-right {
					margin-right: 10px;
				}
			}
			.help-btn-container {
				margin-right: 10px;
				.at-btn--secondary {
					padding: 0 14px;
					svg {
						position: relative;
					}
				}
			}
			.help-icon {
				position: relative;
				top: 3px;
				width: fit-content;
				height: fit-content;
				margin-right: 15px;
				padding: 3px;
				border-radius: 50%;
				background-color: rgba($color: #6c717a, $alpha: 0.1);
				cursor: pointer;
				img {
					height: 25px;
					width: 25px;
					opacity: 0.35;
					text-align: center;
					display: block;
				}
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		margin-bottom: 25px;
		.text {
			font-size: $very-small-font-size;
		}
		.at-top-bar-v2 .topbar-column.selected:after {
			height: 2px;
		}
		&.wrapper-sticky-top {
			top: 0px;
		}
	}
	.form-container {
		overflow-y: auto;
		overflow-x: hidden;
		height: 100%;
		// prevent scroll-chaining to parent elements
		overscroll-behavior: contain;
		scroll-behavior: smooth;
		will-change: height;
		position: relative;
		&.slide {
			transition: 300ms ease;
			// hide scroll bar
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		&.limited {
			overflow-y: hidden;
		}
		.form-content {
			padding: 0 25px 25px 25px;
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
					.react-select__option {
						line-height: 20px;
					}
					.menu-wrapper {
						z-index: 80 !important;
					}
				}
				.at-dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.form-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			&.row-full > div {
				width: 100%;
			}
			&.row-half > div {
				width: 47%;
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
					.react-select__option {
						line-height: 20px;
					}
				}
				.at-dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.form-row-mb-0 {
			margin-bottom: 0;
		}
		.dynamic-form-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&.row-full > div {
				width: 100%;
				margin-bottom: 30px;
			}
			&.row-half > div {
				width: 47%;
				margin-bottom: 30px;
			}
			// override style for select filter component inside form
			.multi-select-filter-container {
				.at--dropdown {
					font-size: $very-small-font-size;
					.react-select__option {
						line-height: 20px;
					}
				}
				.at-dropdown {
					font-size: $very-small-font-size;
				}
				&.filter-section-item {
					opacity: 1;
					margin-bottom: 0;
					.meta-info {
						font-weight: 400;
						margin-bottom: 0;
						padding-bottom: 5px;
					}
				}
			}
		}
		.form-row-action-tooltip {
			font-size: 12px;
			top: -25px;
			position: relative;
			color: $color-blue;
			cursor: pointer;
			font-weight: 600;
		}
		.form-description {
			margin-bottom: 30px;
			.header-text {
				font-size: $medium-font-size;
				margin-bottom: 15px;
			}
			.subtitle {
				font-size: $very-small-font-size;
				color: $mc-6;
				margin-bottom: 10px;
				.highlight {
					@extend .font--semibold;
					font-size: $small-font-size;
					color: $mc-9;
					margin-right: 12px;
				}
			}
		}
		.related-entities-header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.related-entities-search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.show-more-btn {
			display: flex;
			align-items: center;
			padding: 10px 25px;
			position: sticky;
			bottom: 0;
			text-transform: uppercase;
			font-size: 12px;
			color: $color-blue;
			background-color: $mc-1;
			transition: 300ms ease-out;
			box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.04);
			z-index: 100;
			cursor: pointer;
			@extend .font--semibold;
			.arrow {
				border: solid $color-blue;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 2px;
				margin-left: 10px;
				position: relative;
				transition: 300ms ease-out;
				will-change: top, transform;
				&.up {
					top: 2px;
					transform: rotate(-135deg);
					-webkit-transform: rotate(-135deg);
				}
				&.down {
					top: -1.5px;
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
				}
			}
		}
	}
	& > .footer {
		display: flex;
		flex-direction: row-reverse;
		position: sticky;
		bottom: 0;
		margin-top: auto;
		width: 100%;
		padding: 20px 25px;
		box-sizing: border-box;
		background: white;
		box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
		z-index: 200;
		animation: slideUp 180ms;
		transition: 180ms ease-in-out;
	}
	// .banner-message-adjusted & .footer {
	// 	bottom: 57px;
	// }
}
