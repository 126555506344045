.sales-analytics-container {
	.at-top-bar-wrapper-v2 {
		z-index: 5;
		margin-bottom: 25px;
	}
	.title {
		font-size: $large-font-size;
		margin-bottom: 5px;
		@extend .font--semibold;
	}
	.filter-buttons {
		& > div {
			float: right;
			position: relative;
			@include bp(768px) {
				float: none;
			}
		}
		.search-icon {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
		.dismiss-search {
			cursor: pointer;
			position: absolute;
			right: 31px;
			top: 44%;
			transform: translateY(-50%);
			height: 11px;
			width: 11px;
			img {
				width: 100%;
			}
		}
		.search-input {
			display: inline-block;
			vertical-align: top;
			outline: 0;
			padding: 0 0 0 30px;
			font-size: $very-small-font-size;
			border: 0;
			border-bottom: 1px solid $divider-color;
			width: 200px;
			height: 36px;
			box-sizing: border-box;
			margin-right: 10px;
			@include bp(768px) {
				margin-right: 0px;
				width: 100%;
			}
		}
		.search-input-holder {
			@include bp(768px) {
				margin-top: 10px;
				display: flex;
			}
		}
		.filter-button-container {
			display: inline-block;
			vertical-align: top;
			border: 1px solid $mc-4;
			padding: 8px 16px;
			cursor: pointer;
			text-align: center;
			img {
				display: inline-block;
				vertical-align: top;
			}
			.filter-button-title {
				display: inline-block;
				vertical-align: top;
			}
		}
		.filter-count {
			background: $color-blue;
			color: #fff;
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			border-radius: 100%;
			display: inline-block;
			position: absolute;
			right: -10px;
			top: -10px;
		}
	}
}

.sales-analytics-overview {
	// padding: 25px 25px 15px 25px;
	.transactions-list-header {
		.title {
			margin-bottom: 5px;
		}
		.subtitle {
			font-size: $small-font-size;
			color: $mc-6;
			margin-bottom: 0;
			.highlight {
				@extend .font--semibold;
				color: $mc-9;
			}
		}
	}
	.header-action-button {
		display: flex;
		flex-direction: row-reverse;
		.multi-select-filter-container {
			width: 150px;
			font-size: $very-small-font-size;
			margin-bottom: 15px;
		}
		.action-buttons {
			margin-right: 10px;
		}
	}
	.sales-analytics-filters {
		display: flex;
		align-items: center;
		padding: 10px 25px;
		margin-bottom: 25px;
		.select-filter-custom {
			margin-right: 10px;
			&.brand-filter {
				.dropdown-custom {
					width: 220px;
					.container {
						height: 32px;
						padding: 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $default-radius;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $default-radius;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
		.dropdown-date-time-filter {
			margin-right: 10px;
		}
		.multi-select-filter-container {
			width: 150px;
			font-size: $very-small-font-size;
			margin-right: 10px;
		}
	}
	.subtitle {
		font-size: $small-font-size;
		margin-bottom: 15px;
		color: $mc-6;
		.highlight {
			@extend .font--semibold;
			color: $mc-9;
		}
	}
	.overview-data-items {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0 25px;
		.overview-item {
			margin-right: 25px;
			margin-bottom: 20px;
			min-width: 100px;
			min-height: 105px;
			cursor: pointer;
			padding: 5px;
			border-radius: $default-radius;
			transition: 300ms;
			border: 1px solid $border-color;
			// box-shadow: 0px 5px 5px 0px rgba(0,0,0,.1);
			box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.08);
			&[data-active="true"] {
				box-shadow: none;
				background-color: $color-blue;
				color: white;
				.no-items-placeholder {
					color: white;
				}
				.percent-change {
					color: white;
					&.negative,
					&.positive {
						color: white;
					}
				}
				.comparison-value {
					color: white;
				}
			}
			.percent-change {
				font-size: 10px;
				margin-bottom: 5px;
				& > img {
					height: 10px;
				}
				& > span {
					font-size: 12px;
				}
				&.positive {
					color: $color-java;
				}
				&.negative {
					color: $color-red;
				}
			}
			.overview-item-title {
				margin-bottom: 5px;
				font-size: 12px;
				img {
					width: 12px;
					margin-left: 5px;
					vertical-align: top;
				}
			}
			.overview-item-value {
				font-size: $medium-font-size;
				@extend .font--semibold;
			}
			.comparison-value {
				margin-top: 25px;
				color: $mc-5;
				font-size: 12px;
				.highlight {
					@extend .font--semibold;
				}
			}
		}
	}
	.no-items-placeholder {
		text-align: left;
		font-size: $small-font-size;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.sales-analytics-trends {
	padding: 25px;
	padding-top: 0;
	.title {
		margin-bottom: 15px;
	}
	.trends-channels-container {
		margin-bottom: 15px;
		.no-items-placeholder {
			text-align: left;
			font-size: $small-font-size;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.sales-analytics-performance {
	& > .no-items-placeholder {
		text-align: left;
		font-size: $small-font-size;
		padding: 15px 25px;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		padding: 0px 25px 15px 25px;
		& > .title {
			margin-bottom: 15px;
		}
		.header-action-button {
			align-self: flex-end;
			margin-bottom: 10px;
			.filter-buttons {
				& > div {
					float: right;
					position: relative;
					@include bp(768px) {
						float: none;
					}
				}
				.dismiss-search {
					cursor: pointer;
					position: absolute;
					right: 15px;
					top: 42%;
					transform: translateY(-50%);
					height: 11px;
					width: 11px;
					img {
						width: 100%;
					}
				}
				.search-input {
					display: inline-block;
					vertical-align: top;
					outline: 0;
					padding: 0 0 0 10px;
					border-radius: 4px;
					font-size: $very-small-font-size;
					border: 1px solid $divider-color;
					height: 36px;
					box-sizing: border-box;
					margin-right: 0px;
					@include bp(768px) {
						margin-right: 0px;
						width: 100%;
					}
				}
				.search-filter {
					margin: 0;
				}
				.search-input-holder {
					@include bp(768px) {
						margin-top: 10px;
						display: flex;
					}
				}
				.multi-select-filter-container {
					width: 130px;
					margin-left: 10px;
					font-size: $very-small-font-size;
				}
			}
		}
	}
	.tags-container {
		.title {
			font-size: $small-font-size;
			line-height: 27px;
			margin-right: 5px;
			@extend .font--regular;
		}
		.list .tag-item {
			padding: 5px;
			background-color: $color-blue;
			color: white;
			font-size: $very-small-font-size;
			line-height: $very-small-font-size;
			border: 1px solid $color-blue;
			border-radius: $default-radius;
			transition: 180ms;
			max-width: 150px;
			opacity: 0.8;
			cursor: pointer;
			&:hover {
				background-color: white;
				color: $color-blue;
				.remove {
					border-right-color: $color-blue;
				}
			}
			.remove {
				position: relative;
				top: 1px;
				padding: 0 10px 0 5px;
				margin-right: 5px;
				border-right: 1px solid white;
				font-size: $medium-font-size;
			}
		}
	}
	.performance-container {
		display: flex;
		@include bp(768px) {
			flex-direction: column;
		}
	}
	.sidebar-container {
		width: 20%;
		min-width: 170px;
		border-right: 1px solid $border-color;
		margin-right: 25px;
		flex: 1;
		min-height: 200px;
		@include bp(768px) {
			border: 0px;
			width: 100%;
			min-height: 0px;
			margin-bottom: 20px;
			padding: 0 10px;
		}
		.sidebar-row {
			padding-bottom: 15px;
			padding-top: 15px;
			&.selected {
				background: rgba($color: $color-blue, $alpha: 0.1);
				&::after {
					left: 0;
				}
			}
		}
	}
	.data-container {
		width: 80%;
		padding-bottom: 25px;
		padding-right: 25px;
		@include bp(768px) {
			width: 100%;
			padding-right: 0px;
		}
		&.loading {
			opacity: 0.8;
			pointer-events: none;
			cursor: default;
		}
		g {
			.area {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				width: 100%;
				height: 100%;
				position: relative;
				img {
					position: absolute;
					opacity: 0.25;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.details-container {
					width: auto;
					height: auto;
					min-width: 50px;
					background-color: $mc-1;
					opacity: 0.8;
					border-radius: $default-radius;
					padding: 5px;
					font-size: $very-small-font-size;
					margin: 15px;
					overflow: hidden;
					.name {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						margin-bottom: 2px;
						@extend .font--semibold;
					}
					.sales {
						color: $mc-7;
						font-size: 12px;
						margin-bottom: 2px;
					}
					.percent {
						color: $mc-7;
						font-size: 12px;
					}
				}
			}
		}
	}
	.custom-treemap-tooltip {
		min-width: 50px;
		background-color: $mc-1;
		opacity: 0.9;
		border-radius: $default-radius;
		padding: 5px;
		font-size: $very-small-font-size;
		.name {
			@extend .font--semibold;
			margin-bottom: 2px;
		}
		.sales {
			color: $mc-7;
			font-size: 12px;
			margin-bottom: 2px;
		}
		.percent {
			color: $mc-7;
			font-size: 12px;
		}
	}
	.transactions-list-table {
		margin-top: 0px;
		margin-bottom: 20px;
		// min-height: 306px;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		.transaction-rows {
			height: auto;
			min-height: initial;
		}
		.transaction-header-row {
			top: 0px;
			border-top: none;
		}
		.at-table-row.sales-analytics-performance-table {
			.at-table-cell {
				width: 10%;
				text-align: right;
			}
			.name {
				width: 25%;
				padding-left: 25px;
				text-align: left;
				&.link-text {
					color: $color-blue;
					@extend .font--semibold;
				}
			}
			.brands {
				display: flex;
				justify-content: space-between;
				width: 20%;
				padding-right: 25px;
				.brands-list {
					display: flex;
					flex-wrap: wrap;
					height: fit-content;
					.brand-initials {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 24px;
						text-transform: uppercase;
						border-radius: $default-radius;
						margin-right: 15px;
						margin-bottom: 15px;
						cursor: default;
						@extend .font--semibold;
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
						img {
							width: 24px;
							height: 24px;
							object-fit: cover;
							border-radius: $default-radius;
							margin: 0;
						}
					}
				}
			}
			.comparison-value {
				margin-top: 5px;
				font-size: 12px;
				color: $mc-5;
			}
			.percentOfTotal {
				text-align: right;
				padding-right: 25px;
				.percent-bar {
					background-color: $color-blue;
					display: inline-block;
					height: 20px;
					border-radius: $default-radius;
					transition: 300ms;
				}
			}
			.percentChange {
				text-align: right;
				.val-container {
					background-position: left;
					background-repeat: no-repeat;
					background-size: 12px;
					padding-left: 15px;
				}
				.positive {
					color: $color-java;
					background-image: url("/assets/icons/up.png");
				}
				.negative {
					color: $color-red;
					background-image: url("/assets/icons/down-tiny.png");
				}
			}
		}
		@include bp(768px) {
			border: none;
			border-radius: 0;
			.transaction-header-row {
				top: 0px;
				border-top: 1px solid $border-color;
			}
			// .at-table-row.sales-analytics-performance-table {
			// 	.name {
			// 		width: 25%;
			// 		padding-left: 25px;
			// 	}
			// 	.count {
			// 		width: 4%;
			// 		text-align: right;
			// 	}
			// 	.sales {
			// 		width: 10%;
			// 		text-align: right;
			// 	}
			// 	.percentChange {
			// 		width: 5%;
			// 		text-align: right;
			// 	}
			// }
		}
	}
	.at-paginator {
		padding: 0;
		@include bp(768px) {
			padding: 0 25px;
		}
	}
}
