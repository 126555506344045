.periscope-container {
	.specificlocation-table-div {
		.specificlocation-table-container {
			padding: 24px 24px;
			border-radius: 4px;
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			background-color: #fff;
			border: solid 1px rgba(0, 0, 0, 0.05);
			margin-bottom: 15px;
			padding-bottom: 15px;

			.specificlocation-table-head {
				padding: 0 0 16px 0;

				.specificlocation-brand-item {
					font-family: Source Sans Pro;
					font-size: 28px;
					font-weight: 600;
					line-height: 36px;
					text-align: left;
					color: #363636;
				}

				.specificlocation-table-header {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					.specificlocation-text-display {
						display: flex;
						flex-direction: column;
						gap: 8px;

						.specificlocation-header-display {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 5.67px;

							.specificlocation-right-arrow {
								width: 5.05px;
								height: 8.09px;
							}
						}
					}

					.table-header-grey-text {
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #8a8a8a;
					}

					.specificlocation-table-info {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 8px;

						.specificlocation-btn-text {
							font-family: Source Sans Pro;
							font-size: 16px;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
						}

						.specificlocation-refresh-btn {
							cursor: pointer;
							padding: 8px 16px 8px 12px;
							border: 1px solid #eaeaea;
							gap: 8px;
							display: flex;
							align-items: center;

							.refresh-img {
								width: 12px;
								height: 16px;
							}

							.specificlocation-btn-text {
								color: #363636;
							}
						}

						.specificlocation-share-div {
							cursor: pointer;
							background: #3b5feb;
							display: flex;
							gap: 8px;
							align-items: center;
							padding: 8px 16px 8px 12px;

							.share-img {
								height: 13.33px;
								width: 10.67px;
							}

							.specificlocation-btn-text {
								color: #ffffff;
							}
						}
					}
				}

				.fetch-time {
					font-size: 16px;
					font-weight: 400;
					line-height: 22px;
					color: #8a8a8a;
					margin-top: 10px;
				}
			}

			.specific-location-filters-div {
				display: flex;
				justify-content: space-between;

				.specific-location-left-filters {
					display: flex;
					gap: 8px;

					.campaign-list-filter .filter-title .filter-count {
						text-align: center;
					}

					.campaign-list-filter .container {
						border-radius: 3px;
						text-align: center;
						padding: 6px 16px;
						white-space: nowrap;
						position: relative;
						cursor: pointer;
					}

					.specificlocation-platform-filter .placeholder,
					.select-filter-custom .dropdown-custom .container .option,
					.city-filter .react-select__single-value {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
					}

					.platform-status {
						width: 185px;
					}

					.specificitem-location-item {
						width: 180px;
					}

					.platform-status .item-availability-status,
					.specificlocation-sortby .item-availability-status,
					.specificitem-location-item .item-status {
						margin: 0 8px;
						color: #363636;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
					}

					.container .filter-title {
						margin: 0 8px;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
					}
				}
			}

			.specific-location-table {
				padding: 16px 0;
				.info-popover {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #363636;
					padding: 8px 12px;
					width: fit-content;
					text-wrap: nowrap;
				}
				.popover-wrapper-container.down-left .popover-container {
					right: auto;
					left: 85px;
					top: 58px;
				}
			}

			.selected-location-table-display {
				background: #ffffff;

				.specificlocation-thead {
					.brandItem {
						padding: 16px 23px 16px 72px;
						min-width: 340px;
						border-right: 1px solid #eaeaea;
					}

					.location-text {
						border: 1px solid #eaeaea;
						padding: 12px 16px;
					}

					.table-header-text {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #646464;
					}

					.tableheader-selectedlocation {
						padding: 16px;

						.platform-display-selectedLocation {
							text-transform: capitalize;
							display: flex;
							align-items: center;
							justify-content: right;
							gap: 8px;

							.plt-image {
								width: 20px;
								height: 20px;
							}
						}
					}
				}

				.specific-location-tbody {
					.specificitem-location-cell {
						display: flex;
						align-items: center;
						min-width: 340px;

						.select-star-div {
							padding: 20px 12px 16px 24px;
							display: flex;
							align-items: center;
							.star-div-width {
								height: 20px;
								width: 20px;
								cursor: pointer;
							}

							.select-star {
								width: 18.78px;
								height: 17.81px;
							}
						}

						.specificlocation-item-rating {
							padding: 20px 24px 20px 16px;
							display: flex;
							gap: 8px;
							justify-content: center;
							align-items: center;
							.logo {
								margin-right: unset;
							}

							.locationitem-text {
								font-family: Source Sans Pro;
								font-size: 14px;
								font-weight: 400;
								line-height: 22px;
								text-align: left;
								color: #363636;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								max-width: 236px;
							}

							.locationrating-div {
								display: flex;
								align-items: center;
								gap: 4px;

								.locationrating-text {
									font-family: Source Sans Pro;
									font-size: 12px;
									font-weight: 600;
									line-height: 16px;
									text-align: left;
									color: #8a8a8a;
								}

								.location-star-display {
									width: 8.45px;
									height: 8.02px;
								}
							}
						}
					}

					.selecteditem-branditem-display {
						border-right: 1px solid #eaeaea;
					}

					.specificitem-online {
						background: #ffffff;
					}

					.specificitem-partially-offline {
						background: #ffffff;
					}

					.specificitem-offline {
						background: #fff5f5;
					}

					.specificitem-offline:hover {
						background: #ffe5e5;
					}

					.specific-item-online-green-img {
						display: none;
						transform: translateX(0.5px);
					}

					.specificitem-online:hover .specific-item-online-img {
						display: none;
					}

					.specificitem-online:hover .specific-item-online-green-img {
						display: block;
					}

					.specificitem-online:hover {
						background: #f6fbf8;
					}
				}
			}
		}
	}

	.selected-location-table-display {
		.item-table-loading {
			tr td:first-child {
				border-right: 1px solid #eaeaea;
			}

			.shimmer {
				border-radius: 0px;
			}
		}

		.intro-icon-loading {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 24px;
		}

		.star-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 12px;
		}

		.brand-image-loading-item {
			width: 20px;
			height: 20px;
			padding: 20px 12px 20px 11px;
		}

		.all-loading-span {
			display: flex;
		}

		.branditem-text-loading {
			width: 134px;
			height: 20px;
			padding: 20px 0px 20px 0px;
		}

		.platform-loading-item {
			padding: 16px 16px 16px 50px;
		}
	}

	.text-logo {
		.brand-image-location {
			font-weight: 600;
		}

		&.brown {
			color: $color-brown-dark;
			background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
		}

		&.blue {
			color: $color-blue-dark;
			background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
		}

		&.green {
			color: $color-green-dark;
			background-color: rgba($color: $color-green-dark, $alpha: 0.1);
		}

		&.red {
			color: $color-red-dark;
			background-color: rgba($color: $color-red-dark, $alpha: 0.1);
		}

		&.purple {
			color: $color-violet;
			background-color: rgba($color: $color-violet, $alpha: 0.1);
		}
	}
}
