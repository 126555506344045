.dashboard-side-bar {
	position: sticky;
	top: 100px;

	@include bp(768px) {
		position: relative;
		top: 0px;
	}
	&.dashboard-side-bar--analytics {
		@include bp(768px) {
			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
		}
	}

	.sidebar-row {
		font-size: $medium-font-size;
		padding: 25px;
		padding-top: 0px;
		padding-bottom: 20px;
		padding-top: 5px;
		position: relative;

		@include bp(768px) {
			width: auto;
			display: inline-block;
			vertical-align: top;
			padding: 20px;
			padding-top: 0px;
			padding-bottom: 10px;
			padding-left: 5px;
		}
		&.selectable {
			cursor: pointer;
			user-select: none;
		}

		&:after {
			content: " ";
			position: absolute;
			height: 100%;
			top: 0;
			right: -2px;
			background: transparent;
			transition: 180ms background;
			width: 3px;

			@include bp(768px) {
				height: 2px;
				top: auto;
				bottom: 0px;
				background: #ddd;
				transition: 180ms background;
				width: 100%;
				left: 0px;
			}
		}

		&.selected {
			color: $color-blue;
			&:after {
				background: $color-blue;
			}
		}
	}
	.sidebar-row--analytics {
		.text--large {
			font-size: $large-font-size;
		}
		.text--small {
			font-size: $small-font-size;
		}
	}
}

.order-channel-block-container {
	min-height: 83px;
	overflow-x: auto;
	overflow-y: hidden;
	&::-webkit-scrollbar {
		display: none;
	}

	.scroll {
		position: absolute;
		color: white;
		z-index: $zI-level-1;
		cursor: pointer;
		height: 30px;
		width: 30px;
		top: 50%;
		transform: translateY(-50%);
		height: 30px;
		width: 30px;
		border-radius: 50%;
		background-color: #7f8c8d;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);

		&:after {
			content: "";
			height: 6px;
			width: 6px;
			border: solid white;
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
	.prev {
		left: 15px;
		&:after {
			border-width: 1px 0 0 1px;
			transform: translate(-30%, -50%) rotate(-45deg);
		}
	}
	.next {
		right: 15px;
		&:after {
			border-width: 1px 1px 0 0;
			transform: translate(-70%, -50%) rotate(45deg);
		}
	}
	.order-channels-wrapper {
		transition: 0.3s;
		display: inline-block;
		white-space: nowrap;
	}
	.order-channel-block {
		display: inline-block;
		margin-right: 15px;
	}
}
.order-channel-block {
	min-width: 128px;
	padding: 10px;
	background: $mc-2;
	border-radius: 4px;
	text-align: center;
	user-select: none;
	cursor: pointer;

	.percent-change {
		font-size: 12px;
		color: $color-java;
		background-image: url("/assets/icons/up.png");
		background-repeat: no-repeat;
		background-size: 8px;
		background-position: left center;
		display: inline-block;
		padding-left: 10px;

		&.negative {
			color: $color-red;
			background-image: url("/assets/icons/down-tiny.png");
		}
	}

	.comparison-value {
		font-size: 12px;
		margin-bottom: 5px;
	}

	.value {
		padding: 3px;
		font-size: 24px;
		margin-bottom: 4px;
	}

	.display-name {
		font-size: 12px;
		color: $mc-5;
	}

	&.order-channel-block--selected {
		color: #fff;
		background: $color-blue;

		.percent-change,
		.value,
		.display-name {
			color: #fff;
		}
	}
}

.ana-graph-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.custom-tooltip--analytics {
	background: #fff;
	border: 1px solid $color-blue;
	font-size: 14px;
	padding: 10px;
	.previous-value {
		font-size: 12px;
		color: $mc-5;
	}
}
