.search-filter {
	display: flex;
	align-items: center;
	width: 200px;
	margin-right: 10px;
	position: relative;
	.at-input-field {
		.at-label {
			height: 36px;
			border-top-left-radius: $border-radius-default;
			border-bottom-left-radius: $border-radius-default;
			border-right: 0;
			background-color: white;
			img {
				width: 16px;
				margin-top: 3px;
				// padding-left: 3px;
			}
		}
		.at-input {
			height: 36px;
			border-radius: $border-radius-default;
			&.at-input--label {
				padding: 0px 34px;
			}
		}
	}
	.cancel-search {
		cursor: pointer;
		position: absolute;
		// right: 16px;
		right: 13px;
		opacity: 0.7;
		img {
			width: 11px;
		}
		&:hover {
			opacity: 1;
		}
	}
}
