.common-sidebar-container {
	display: flex;
	justify-content: space-between;
	@include bp(768px) {
		flex-direction: column;
	}
	& > div {
		min-height: 300px;
	}
	.sidebar-tabs-container {
		width: 25%;
		margin-right: 25px;
		border-right: 1px solid $border-color;
		max-height: 500px;
		overflow-y: auto;
		position: sticky;
		top: 57px;
		@include bp(768px) {
			width: 100%;
			margin-right: 0;
			border-right: none;
			min-height: initial;
			overflow-y: initial;
		}
		.at-side-bar {
			background-color: white;
		}
		.at-side-bar .sidebar-row:after {
			right: 0px;
		}
		.text {
			font-size: $very-small-font-size;
			text-transform: capitalize;
			.loader-container {
				position: fixed;
				.loader {
					display: flex;
					position: relative;
					bottom: -3px;
					& > div {
						width: 3px;
						height: 3px;
						background: $mc-5;
						border-radius: 50%;
						margin: 3px;
						animation: loader 0.3s infinite alternate;
					}
					& > div:nth-child(2) {
						animation-delay: 0.1s;
					}
					& > div:nth-child(3) {
						animation-delay: 0.2s;
					}
					&.out {
						padding: 0 8px;
						& > div {
							background: $color-red;
						}
					}
					&.in {
						padding: 0 17px;
						& > div {
							background: $color-green;
						}
					}
				}
			}
		}
		.header {
			.header-text {
				padding: 10px;
				margin-top: 10px;
				font-size: $small-font-size;
				text-align: center;
				@extend .font--semibold;
			}
			.at-top-bar-wrapper-v2 {
				margin-bottom: 15px;
				.text {
					font-size: $very-small-font-size;
				}
				.at-top-bar-v2 .topbar-column.selected:after {
					height: 2px;
				}
			}
		}
	}
	.sidebar-content-container {
		width: 75%;
		@include bp(768px) {
			width: 100%;
			padding: 25px 0;
		}
	}
}
