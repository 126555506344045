.at-panels-cnt {
	font-size: $small-font-size;
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		padding-top: 3px;
	}
	.info {
		position: relative;
		top: 1px;
		width: 12px;
		margin-left: 5px;
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.panels-list-container {
		.panel-item {
			display: inline-block;
			box-sizing: border-box;
			height: 34px;
			width: 80px;
			text-align: center;
			line-height: 34px;
			padding: 0 15px;
			background-color: $mc-4;
			transition: 180ms;
			font-size: 12px;
			cursor: pointer;
			&[data-selected="true"] {
				background-color: $color-blue;
				color: white;
				font-weight: bold;
			}
			&:first-child {
				border-radius: 4px 0 0 4px;
			}
			&:last-child {
				border-radius: 0 4px 4px 0;
			}
		}
	}
	.validation-message {
		margin-top: 10px;
	}
}
