.operational-efficiency-container {
	justify-content: space-between;
	.transaction-section {
		.new-date-compare-filter {
			border: none;
			padding: 0 25px;
			margin: 25px 0;
		}
	}
	.store-and-date-selector {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin-bottom: 25px;
		@include bp(768px) {
			flex-direction: column;
			margin-bottom: 8px;
		}
		.duration-dropdown-selector {
			width: 170px;
			@include bp(768px) {
				width: auto;
				margin: 10px;
			}
		}
		.date-filter-container {
			margin-bottom: 0;
			min-height: 265px;
			box-sizing: border-box;
		}
		.date-filter-action-container {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			@include bp(768px) {
				padding: 5px;
			}
			.date-filter-action {
				text-align: right;
				position: absolute;
				bottom: 10px;
				right: 10px;
				cursor: pointer;
				font-size: 12px;
				text-transform: uppercase;
				@extend .font--semibold;
				.apply {
					color: $color-blue;
					margin-right: 25px;
					display: inline-block;
					vertical-align: top;
				}
				.cancel {
					display: inline-block;
					vertical-align: top;
					color: $mc-5;
					transition: 180ms;
					&:hover {
						color: $mc-7;
					}
				}
			}
		}
		.calender-range-first,
		.calender-range-second {
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			@extend .react-datepicker--inline;
		}
	}
	.operational-efficiency-header {
		.title {
			font-size: $large-font-size;
			margin-bottom: 5px;
			margin-right: 5px;
			@extend .font--semibold;
		}
		.subtitle {
			.highlight {
				@extend .font--semibold;
				color: $mc-9;
			}
		}
		.header-action-button {
			.filter-buttons {
				& > div {
					float: right;
					position: relative;
					@include bp(768px) {
						float: none;
					}
				}
				.search-icon {
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
				.dismiss-search {
					cursor: pointer;
					position: absolute;
					right: 15px;
					top: 42%;
					transform: translateY(-50%);
					height: 11px;
					width: 11px;
					img {
						width: 100%;
					}
				}
				.search-input {
					display: inline-block;
					vertical-align: top;
					outline: 0;
					padding: 0 10px 0 10px;
					border-radius: 4px;
					font-size: $very-small-font-size;
					border: 1px solid $divider-color;
					height: 36px;
					box-sizing: border-box;
					margin-right: 0px;
					@include bp(768px) {
						margin-right: 0px;
						width: 100%;
					}
				}
				.search-input-holder {
					@include bp(768px) {
						margin-top: 10px;
						display: flex;
					}
				}
				.filter-button-container {
					display: inline-block;
					vertical-align: top;
					border: 1px solid $mc-4;
					padding: 8px 16px;
					cursor: pointer;
					text-align: center;
					img {
						display: inline-block;
						vertical-align: top;
					}
					.filter-button-title {
						display: inline-block;
						vertical-align: top;
					}
				}
				.filter-count {
					background: $color-green;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
		}
	}
}

.operational-efficiency-overview {
	.transactions-list-header {
		.subtitle {
			margin-bottom: 10px;
			.highlight {
				@extend .font--semibold;
				color: $mc-9;
			}
		}
	}
	.title {
		font-size: $large-font-size;
		margin-bottom: 5px;
		@extend .font--semibold;
	}
	.subtitle {
		font-size: $small-font-size;
		margin-bottom: 15px;
		color: $mc-6;
		.highlight {
			@extend .font--semibold;
			color: $mc-9;
		}
	}
	.filter-buttons {
		& > div {
			float: right;
			position: relative;
			@include bp(768px) {
				float: none;
			}
		}

		.filter-button-container {
			display: inline-block;
			vertical-align: top;
			border: 1px solid $mc-4;
			padding: 8px 16px;
			cursor: pointer;
			text-align: center;
			img {
				display: inline-block;
				vertical-align: top;
			}
			.filter-button-title {
				display: inline-block;
				vertical-align: top;
			}
		}
		.filter-count {
			background: $color-blue;
			color: #fff;
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			border-radius: 100%;
			display: inline-block;
			position: absolute;
			right: -10px;
			top: -10px;
		}
	}
	.brands-date-filter-container {
		padding: 25px;
		padding-top: 0;
		display: flex;
		.select-filter-custom {
			margin-right: 10px;
			&.brand-selection {
				.dropdown-custom {
					width: 230px;
					.container {
						border-radius: $border-radius-default;
						height: 32px;
						padding: 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $border-radius-default;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $border-radius-default;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
		.dropdown-date-time-filter {
			margin-right: 10px;
		}
	}
	.no-items-placeholder {
		text-align: left;
		font-size: $small-font-size;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.operational-efficiency-block {
	display: inline-block;
	margin-right: 15px;
	padding: 20px 0px;
	background: $mc-2;
	width: 128px;
	background: $mc-2;
	border-radius: 2px;
	text-align: center;
	user-select: none;

	.value {
		padding: 5px;
		font-size: 24px;
		margin-bottom: 4px;
		padding-bottom: 0px;
	}
	.previous-value {
		padding: 1px;
		font-size: 12px;
		margin-bottom: 4px;
	}

	.display-name {
		font-size: 12px;
		color: $mc-8;
	}
}

.operational-efficiency-block-container {
	margin-bottom: 15px;
	padding: 0 25px;
}

.operational-efficiency-chart-container {
	margin-bottom: 15px;
	padding: 0 25px;
}

.operational-efficiency-chart {
	border: dashed 1px rgb(204, 204, 204);
	padding: 2px;
}

.at-table-row {
	.outlet,
	.order_id {
		padding-left: 25px;
	}
	.placed,
	.acknowledged,
	.food_ready,
	.dispatched,
	.completed,
	.cancelled {
		text-align: right;
		margin-right: 10px;
	}
}

.transaction-rows {
	border-bottom: 1px solid $divider-color;
	padding-top: 8px;
	padding-bottom: 8px;
	transition: 180ms;
	min-height: 80px;
	box-sizing: border-box;
	@include bp(768px) {
		min-height: initial;
		padding-top: 2px;
		padding-bottom: 2px;
	}
	&:last-child {
		border-bottom: 0px;
	}
	.at-table-cell {
		& > div {
			margin-bottom: 4px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.id {
		width: 9%;
		padding-left: 40px;
		position: relative;
		.ext-plt-icon {
			position: absolute;
			top: 10px;
			left: 10px;
			height: 20px;
		}
	}
	.outlet,
	.id {
		width: 9%;
	}
	.upiper-id {
		color: $color-blue;
		text-transform: capitalize;
		@extend .font--semibold;
	}
	.external-id {
		margin-top: 5px;
	}
	.list-first-line {
		font-weight: bold;
		font-size: 16;
	}

	.list-second-line {
		color: "#7b8a9a";
		font-size: 14;
	}
	.external-id,
	.date-time,
	.phone {
		color: $mc-5;
		font-size: $very-small-font-size;
		@include bp(768px) {
			font-size: 12px;
		}
	}
	.timestamp {
		width: 5%;
		text-align: right;
		margin-right: 10px;
		.sub-text {
			font-size: x-small;
		}
	}
}
