@keyframes blink {
	0% {
		outline-color: #fff;
	}
	50% {
		outline-color: $color-blue;
	}
	100% {
		outline-color: #fff;
	}
}
.multi-select-filter-container {
	position: relative;
	&.highlight {
		outline: 2px #fff solid;
		outline-offset: 6px;
		animation: blink 500ms;
		animation-iteration-count: 2;
		animation-delay: 1s;
		transition: none;
	}
	.info {
		position: relative;
		top: 1px;
		width: 12px;
		margin-left: 5px;
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.at-dropdown {
		margin-bottom: 0;
		width: 100%;
		.Select-placeholder {
			font-size: 12px;
			color: $mc-5;
		}
	}
	.selected-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.selected-item {
			background-color: $color-blue;
			color: white;
			margin-right: 5px;
			margin-bottom: 5px;
			font-size: 12px;
			padding: 5px 0;
			border: 1px solid $color-blue;
			border-radius: $default-radius;
			transition: 180ms;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: clip;
			width: auto;
			box-sizing: border-box;
			.item-remove,
			.item-title {
				display: inline-block;
				vertical-align: top;
				padding: 0 5px;
				line-height: 20px;
				height: 20px;
			}
			.item-remove {
				border-right: 1px solid white;
				text-align: center;
				cursor: pointer;
				padding: 0 7px;
				transform: translateY(-1px);
			}
			&:hover {
				background-color: white;
				color: $color-blue;
				.item-delete {
					border-right: 2px solid $color-blue;
				}
			}
		}
	}
}
