.campaign-list-filter {
	.container {
		border: 1px solid $mc-4;
		border-radius: $default-radius;
		text-align: center;
		padding: 7px 16px;
		white-space: nowrap;
		position: relative;
		cursor: pointer;
		img {
			width: 16px;
		}
	}
	&.active {
		.container {
			border-color: $color-blue;
			.filter-title {
				color: $color-blue;
			}
			img {
				filter: invert(23%) sepia(53%) saturate(4305%) hue-rotate(226deg) brightness(99%) contrast(92%);
			}
		}
	}
	.filter-icon,
	.filter-title {
		display: inline-block;
		vertical-align: middle;
	}
	.filter-title {
		font-size: 15px;
		color: $mc-9;
		line-height: normal;
		margin-left: 8px;
		transition: 0.3s;
		.filter-count {
			background: $color-blue;
			color: #fff;
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			border-radius: 100%;
			display: inline-block;
			position: absolute;
			right: -10px;
			top: -10px;
		}
	}
	&:hover {
		.filter-title {
			color: $mc-8;
		}
	}
}

.campaign-list-table {
	margin-bottom: 15px;
	.at-table-row {
		position: relative;
		max-height: 50px;
		overflow: hidden;
		padding-right: 25px;
	}
	.action-panel {
		position: absolute;
		right: 25px;
		background: #ddd;
		border: 1px solid #ddd;
		border-radius: 4px;
		width: 150px;
		top: 10px;
		overflow: hidden;
		transition: 180ms;
		will-change: transform;
		transform: translateX(200px);
		& > div {
			text-align: center;
			border-right: 1px solid #ccc;
			&:last-child {
				border-right: 0px;
			}
			display: inline-block;
			width: 50%;
			box-sizing: border-box;
			padding: 5px 5px;
			text-transform: uppercase;
			font-size: 12px;
			transition: 180ms;
			&:hover {
				background: #fff;
			}
		}
		&.clone-only {
			width: 75px;
			& > div {
				&:last-child {
					display: none;
				}
				width: 100%;
			}
		}
	}
	.at-table-row {
		&.hovered {
			.action-panel {
				transform: translateX(0px);
			}
		}
		&.deleted {
			max-height: 0;
			background: $color-red;
		}
	}
	.status {
		&.completed {
			color: $color-green;
			text-align: left;
		}
		&.cancelled {
			color: $color-red;
			text-align: left;
		}
		&.scheduled,
		&.recurring {
			color: $color-blue;
			text-align: left;
		}
		&.running {
			color: #e67e22;
			text-align: left;
		}
		&.inactive {
			color: $mc-5;
			text-align: left;
		}
	}
	.at-table-cell {
		margin: 0;
	}
	.at-cell-text {
		@extend .font--semibold;
		color: $mc-8;
		padding: 15px 25px;
		border-bottom: 1px solid $divider-color;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.at-table-header {
		padding: 15px 25px;
		border-bottom: 1px solid $divider-color;
		border-top: 1px solid $divider-color;
	}
	.active {
		color: $color-blue;
	}

	.at-cell-text.title {
		color: $color-blue;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// style to customize width of each column
	.at-header-text,
	.at-cell-text {
		&.title {
			width: 30%;
		}
		&.execution-time {
			width: 20%;
		}
		&.medium {
			width: 10%;
			text-align: center;
		}
		&.sent {
			width: 12%;
		}
		&.status {
			width: 13%;
		}
		&.conversion {
			width: 10%;
		}
		@include bp(768px) {
			&.title {
				width: 30%;
			}
			&.execution-time {
				width: 20%;
			}
			&.medium {
				width: 10%;
				text-align: center;
			}
			&.sent {
				width: 12%;
			}
			&.status {
				width: 13%;
				text-align: right;
			}
			&.conversion {
				width: 10%;
			}
		}
	}
	.no-items-placeholder {
		width: 100%;
		text-align: center;
		padding: 100px 0;
	}
	@include bp(768px) {
		.at-cell-text,
		.at-header-text {
			padding: 10px;
		}
	}
	.medium-icon {
		height: 18px;
		vertical-align: middle;
		padding: 0 10px 0 0;
	}
}

.campaign-filter-container {
	border-top: 1px solid #ddd;
}

.campaign-section--campaign-list {
	.filter-section-container {
		.filter-section-item {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			@include bp(768px) {
				width: 100%;
			}
			.checkbox-inline {
				width: 100%;
				box-sizing: border-box;
			}
		}
		.meta-info {
			margin-bottom: 15px !important;
		}
	}
}
