.transaction-details-container {
	.form-sidebar-container {
		.wrapper-sticky-top {
			top: 0px;
			z-index: 50;
		}
		.header-left {
			width: 100%;
			.title {
				margin: 0;
			}
		}
	}
	.transaction-details-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: relative;
		z-index: 100;
		width: 700px;
		@include bp(768px) {
			padding: 15px 5px;
		}
		.header-meta {
			.order-id {
				margin-bottom: 5px;
			}
			.order-update-time {
				font-size: $very-small-font-size;
				color: $mc-7;
				@extend .font--regular;
			}
		}
		.header-actions {
			cursor: pointer;
			display: flex;
			position: relative;
			@extend .font--regular;
			.action-title-container {
				box-sizing: border-box;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				padding: 9px 4px 3px 9px;
				height: 38px;
				transition: 180ms;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				&:hover {
					box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
				}
			}
			.action-title {
				font-size: $very-small-font-size;
				padding-right: 34px;
			}
			.header-actions-list {
				position: absolute;
				top: 81%;
				right: 1px;
				width: auto;
				min-width: 126px;
				white-space: nowrap;
				border-radius: $default-radius;
				background-color: white;
				box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
			}
			.action-item {
				padding: 9px;
				border-bottom: 1px solid $border-color-dark;
				font-size: $very-small-font-size;
				color: $color-blue;
				transition: 180ms;
				&:last-child {
					border-bottom: 0;
				}
				&:hover {
					background-color: $color-blue;
					color: white;
					border-bottom-color: $color-blue;
					&:first-child {
						border-radius: 4px 4px 0 0;
					}
					&:last-child {
						border-radius: 0 0 4px 4px;
					}
				}
			}
		}
	}

	.transaction-details-summary {
		// padding: 0 25px;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		.section-title {
			display: flex;
			align-items: center;
			padding: 15px;
			margin-bottom: 20px;
			font-size: $small-font-size;
			border-bottom: 1px solid $border-color-dark;
			// background-color: $mc-2;
			@extend .font--semibold;
			.icon-delivery {
				width: 30px;
			}
			.icon-customer {
				width: 20px;
			}
			img {
				margin-right: 10px;
			}
		}
		.order-details {
			margin-bottom: 30px;
			border: 1px solid $border-color-dark;
			border-radius: $border-radius-default;
		}
		.customer-details {
			margin: 0 25px;
		}
		@include bp(768px) {
			padding: 15px 5px;
			flex-direction: column;
			.order-details {
				width: auto;
				border-right: none;
				padding-right: initial;
				margin-bottom: 15px;
			}
			.customer-details {
				width: auto;
				padding-left: initial;
			}
		}

		.order-refund-details {
			border: 1px solid $border-color;
			border-radius: $default-radius;
			margin-bottom: 30px;
			.header-container {
				padding: 15px;
				display: flex;
				justify-content: space-between;
				.header-text {
					display: flex;
					align-items: center;
					img {
						height: 20px;
						width: 20px;
						margin-right: 10px;
					}
					.title {
						font-size: $medium-font-size;
						// border-bottom: 1px solid $border-color-dark;
						@extend .font--semibold;
					}
				}
				.header-actions {
					display: flex;
					align-items: center;
					.resolution-expiry-timestamp {
						margin-right: 20px;
						color: $color-red;
						font-size: $very-small-font-size;
						font-weight: 600;
					}
					.resolve-action-button {
						span {
							margin-right: 8px;
						}
					}
					.resolution-expired {
						font-size: $very-small-font-size;
						font-weight: 600;
						color: $mc-5;
						background-color: $mc-2;
						padding: 10px;
						border-radius: $default-radius;
					}
				}
			}
			.refund-reasons {
				padding: 25px 15px;
				background-color: $mc-2;
				font-size: $very-small-font-size;
				.title {
					font-size: $small-font-size;
					font-weight: 600;
					margin-bottom: 10px;
				}
			}
			.refund-details {
				margin-top: 15px;
			}
		}
	}
	.order-details {
		.order-details-header {
			font-size: $medium-font-size;
			padding: 15px;
			background-color: $color-lightest-grey;
			color: $color-dark-grey;
		}
		.order-details-bill-dump {
			font-size: $very-small-font-size;
			margin-bottom: 17px;
			white-space: pre;
			padding: 15px;
		}
		.order-item {
			border-top: 1px solid $mc-4;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px;
			.item-info,
			.item-quantity,
			.item-price {
				flex: 1 1 0;
			}
			.item-info {
				.name {
					font-size: $very-small-font-size;
					margin-bottom: 10px;
					font-weight: $font--semibold;
					color: $color-light-black;
				}
				.additions {
					font-size: 12px;
					color: $mc-5;
					margin-bottom: 5px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.brands {
					max-width: 250px;
					display: flex;
					flex-wrap: wrap;
					gap: 6px;
					align-items: center;
					font-size: $exta-small-font-size;
					color: $color-light-black;
					.brand-image {
						height: 20px;
						width: 20px;
					}
				}
				.instructions {
					font-size: 12px;
					color: $mc-5;
					margin-top: 10px;
					span {
						@extend .font--semibold;
					}
				}
			}
			.item-quantity {
				font-size: $very-small-font-size;
				padding-left: 50px;
			}
			.item-price {
				text-align: right;
				.total {
					font-size: $very-small-font-size;
					margin-bottom: 10px;
				}
				.tax {
					font-size: 12px;
					color: $mc-5;
					margin-bottom: 5px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.order-bill {
			.bill-breakup {
				border-top: 1px solid $mc-3;
				border-bottom: 1px solid $mc-3;
				padding: 15px;
				background-color: $mc-2;
			}
			.bill-total {
				padding: 15px;
			}
			.bill-component {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
				.dummy,
				.type,
				.value {
					flex: 1 1 0;
					font-size: $very-small-font-size;
					color: $mc-5;
				}
				.type {
					padding-left: 50px;
					&.total {
						color: black;
					}
				}
				.value {
					text-align: right;
					&.total {
						color: black;
					}
				}
			}
		}
		.order-instructions {
			padding: 15px;
			background-color: $mc-2;
			border-top: 1px solid $mc-4;
			.title {
				color: $mc-5;
				font-size: 12px;
				margin-bottom: 5px;
			}
			.value {
				font-size: $very-small-font-size;
			}
		}
	}
	.customer-details,
	.delivery-details,
	.payment-gateway-container,
	.status-update-container,
	.order-refund-details {
		border: 1px solid $border-color-dark;
		border-radius: $border-radius-default;
		margin-bottom: 30px;
		.payment-transaction-info,
		.status-updates-info {
			padding: 10px;
		}
		.detail-field {
			padding: 0 15px;
			margin-bottom: 20px;
			.type {
				font-size: 12px;
				color: $mc-5;
				margin-bottom: 5px;
			}
			a {
				text-decoration: none;
			}
			.value {
				font-size: $very-small-font-size;
				word-break: break-word;
				// unicode-bidi: bidi-override !important;
			}
			.value-status {
				padding: 4px 8px;
				border: 1px solid $mc-6;
				border-radius: $default-radius;
				display: inline-block;
				text-transform: uppercase;
				font-weight: 600;
				background-color: $mc-2;
			}
			.value-name {
				text-transform: capitalize;
				cursor: pointer;
				color: $color-blue;
				@extend .font--semibold;
			}
			.detail-icon {
				height: 20px;
				vertical-align: middle;
				margin-right: 10px;
				border-radius: $default-radius;
			}
			.copy-btn {
				display: inline;
				border: none;
				padding: 0 2px;
				margin: 0 7px;
				img {
					position: relative;
					width: 15px;
					top: 3px;
				}
			}
		}
		.details-field-grid {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			&.in-store {
				padding-top: 15px;
			}
			.detail-field {
				width: 45%;
				&.full-row {
					width: 95%;
				}
			}
		}
		.at-json-input-container {
			padding: 0 25px;
		}
	}
	.payment-gateway-container {
		border: none;
		margin: 0;
	}
	.status-update-container {
		border: none;
		margin: 0;
		.description {
			display: flex;
			align-items: center;
			margin-top: 5px;
			.status-code {
				padding-right: 10px;
				border-right: 1px solid $mc-5;
				span {
					display: inline-block;
					min-width: 20px;
					color: $mc-1;
					padding: 0px 6px;
					margin-left: 5px;
					border-radius: $default-radius;
					text-align: center;
					@extend .font--semibold;
				}
				&.success {
					span {
						background-color: rgba($color-green-dark, 0.1);
						color: $color-green-dark;
					}
				}
				&.error {
					span {
						background-color: rgba($color-red-dark, 0.1);
						color: $color-red-dark;
					}
				}
			}
			.elapsed-time {
				padding-left: 10px;
				border-right: 1px solid $mc-5;
				padding-right: 10px;
				span {
					margin-left: 5px;
				}
			}
			.logged-time {
				padding-left: 10px;
				span {
					margin-left: 5px;
				}
			}
		}
		.response {
			padding: 0 25px;
		}
		.label {
			color: $mc-5;
			font-size: 12px;
			margin-bottom: 5px;
		}
		.json-container {
			position: relative;
			overflow: auto;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			font-size: 13px;
			color: $mc-7;
			pre {
				margin: 0;
			}
			&.headers {
				min-height: 50px;
				max-height: 180px;
			}
			&.payload {
				min-height: 50px;
				max-height: 360px;
			}
		}
	}
	.transaction-details-status {
		padding: 0 25px;
		.status-item-container {
			display: flex;
			flex-direction: row;
			.timeline-container {
				position: relative;
				.timeline-circle {
					height: 25px;
					width: 25px;
					border-radius: 50%;
				}
				.timeline-bar {
					width: 10px;
					height: calc(100% - 23px);
					position: absolute;
					top: 24px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			.status-info {
				padding-left: 25px;
				min-height: 120px;
				.status-title {
					color: white;
					padding: 5px;
					display: inline-block;
					font-size: 12px;
					text-transform: uppercase;
					margin-bottom: 15px;
					border-radius: $default-radius;
				}
				.status-update-time {
					color: $mc-7;
					font-size: 12px;
					margin-bottom: 5px;
					span.highlight {
						background-color: $mc-3;
						color: black;
						padding: 2px 5px;
						border-radius: $default-radius;
					}
				}
				.status-message {
					font-size: 12px;
					color: $mc-5;
				}
			}
		}
	}
	.webhooks-container,
	.downstream-container {
		padding: 0 25px;
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.webhook-history-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.event {
				padding-left: 25px;
				width: 25%;
			}
			.desc-text {
				margin-top: 5px;
				color: $mc-5;
			}
			.delivery {
				width: 23%;
			}
			.url {
				width: 35%;
				text-overflow: ellipsis;
			}
			.at-header-text {
				&.status-code {
					padding-right: 25px;
					width: 17%;
					text-align: right;
				}
			}
			.at-cell-text {
				&.status-code {
					padding-right: 25px;
					width: 17%;
					text-align: right;
					span {
						display: inline-block;
						min-width: 20px;
						color: $mc-1;
						padding: 0px 6px;
						margin-left: 5px;
						border-radius: $default-radius;
						text-align: center;
						@extend .font--semibold;
					}
					&.success {
						span {
							background-color: rgba($color-green-dark, 0.1);
							color: $color-green-dark;
						}
					}
					&.error {
						span {
							background-color: rgba($color-red-dark, 0.1);
							color: $color-red-dark;
						}
					}
				}
			}
		}
		.downstream-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.purpose {
				padding-left: 25px;
				width: 30%;
			}
			.desc-text {
				margin-top: 5px;
				color: $mc-5;
			}
			.url {
				width: 50%;
				text-overflow: ellipsis;
			}
			.at-header-text {
				&.status-code {
					padding-right: 25px;
					width: 20%;
					text-align: right;
				}
			}
			.at-cell-text {
				&.status-code {
					padding-right: 25px;
					width: 20%;
					text-align: right;
					span {
						display: inline-block;
						min-width: 20px;
						color: $mc-1;
						padding: 0px 6px;
						margin-left: 5px;
						border-radius: $default-radius;
						text-align: center;
						@extend .font--semibold;
					}
					&.success {
						span {
							background-color: rgba($color-green-dark, 0.1);
							color: $color-green-dark;
						}
					}
					&.error {
						span {
							background-color: rgba($color-red-dark, 0.1);
							color: $color-red-dark;
						}
					}
				}
			}
		}
	}
	.upstream-container {
		padding: 0 25px;
		.title {
			font-size: $small-font-size;
			@extend .font--semibold;
			margin-bottom: 10px;
		}
		.json-container {
			position: relative;
			overflow: auto;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			font-size: 13px;
			color: $mc-7;
			pre {
				margin: 0;
			}
			&.headers {
				min-height: 50px;
				max-height: 180px;
			}
			&.payload {
				min-height: 50px;
				max-height: 360px;
			}
		}
		@keyframes fadeIn {
			to {
				opacity: 1;
			}
		}
		.copy-btn {
			position: absolute;
			opacity: 0;
			right: 0px;
			top: 5px;
			animation: fadeIn 150ms linear both;
		}
	}

	.order-payout-info {
		display: flex;
		flex-direction: column;
		padding: 0 25px;
		font-size: $very-small-font-size;
		.payouts-info {
			width: 100%;
			display: flex;
			justify-content: space-between;
			&--blocks {
				.sub-title {
					color: $mc-5;
					margin-bottom: 10px;
				}
				.value {
					font-size: $very-small-font-size;
				}
			}
		}
		.empty-data {
			font-size: $small-font-size;
			color: $mc-6;
			min-height: 120px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.payout-breakdown {
			border: 1px solid $border-color;
			box-shadow: $default-shadow;
			margin-top: 20px;
			.border-block {
				padding: 5px 15px;
				background-color: $mc-2;
				min-height: 20px;
				display: flex;
				justify-content: space-between;
				color: $mc-6;
				&--end {
					color: #000000;
				}
			}
			.breakdown-types {
				padding: 10px 15px;
				border-bottom: 1px solid $border-color-dark;
				&-header {
					font-weight: bold;
					&-standalone-value {
						font-weight: normal;
						color: $mc-5;
					}
				}
				&-header-with-value {
					font-weight: bold;
					display: flex;
					justify-content: space-between;
				}
				&-values {
					color: $mc-5;
					display: flex;
					justify-content: space-between;
					margin-top: 10px;
					margin-bottom: 10px;
				}
				&-standalone {
					display: flex;
					justify-content: space-between;
					padding: 10px 15px;
				}
			}
			.breakdown-types-standalone {
				.breakdown-types-header-standalone-value {
					color: $mc-9;
					font-weight: bold;
				}
			}
			.utr-details {
				color: $mc-5;
				padding: 20px 15px;
				justify-content: center;
				text-align: center;
			}
			.reconciliation-status-updates {
				color: $mc-5;
				padding: 20px 15px;
				height: auto;
				.status-item-container {
					display: flex;
					flex-direction: row;
					.timeline-container {
						position: relative;
						.timeline-circle {
							height: 25px;
							width: 25px;
							border-radius: 50%;
						}
						.timeline-bar {
							width: 10px;
							height: calc(100% - 23px);
							position: absolute;
							top: 24px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
					.status-info {
						padding-left: 25px;
						min-height: 120px;
						.status-title {
							color: white;
							padding: 5px;
							display: inline-block;
							font-size: 12px;
							text-transform: uppercase;
							margin-bottom: 15px;
							border-radius: $default-radius;
						}
						.status-update-time {
							color: $mc-7;
							font-size: 12px;
							margin-bottom: 5px;
							span.highlight {
								background-color: $mc-3;
								color: black;
								padding: 2px 5px;
								border-radius: $default-radius;
							}
						}
						.status-message {
							font-size: 12px;
							color: $mc-5;
						}
					}
				}
			}
		}
	}
	div {
		.at-modal {
			.modal-container {
				width: 70%;
				.modal-content {
					min-height: 50px;
				}
			}
			.modal-content {
				padding: 0 20px;
				color: $mc-5;
				display: flex;
				flex-direction: column;
				justify-content: center;
				.multi-select-filter-container {
					// border: 1px solid red;
					padding-bottom: 12px;
					.meta-info {
						.required-sign {
							font-size: $very-small-font-size;
							font-weight: 700;
						}
					}
				}
				.label {
					font-size: $very-small-font-size;
					font-weight: 700;
					padding-bottom: 8px;
				}
				.text-area {
					width: auto;
					height: 120px;
					resize: none;
					padding: 10px;
					border-color: $border-color-dark;
					border-radius: $default-radius;
					outline: none;
				}
				.update-message {
					text-align: center;
				}
				.wobbling-bar-loader {
					display: inline-block;
					position: relative;
					width: auto;
					height: 80px;
				}
				.wobbling-bar-loader div {
					display: inline-block;
					position: absolute;
					margin-left: 44%;
					left: 8px;
					width: 16px;
					background: $color-blue-light;
					animation: wobbling-bar-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
				}
				.wobbling-bar-loader div:nth-child(1) {
					left: 8px;
					animation-delay: -0.24s;
				}
				.wobbling-bar-loader div:nth-child(2) {
					left: 32px;
					animation-delay: -0.12s;
				}
				.wobbling-bar-loader div:nth-child(3) {
					left: 56px;
					animation-delay: 0;
				}
				@keyframes wobbling-bar-loader {
					0% {
						top: 8px;
						height: 64px;
					}
					50%,
					100% {
						top: 24px;
						height: 32px;
					}
				}
				.failure-message {
					color: $color-red;
					border: 1px solid $color-red;
					padding: 24px;
					border-radius: $default-radius;
					text-align: center;
					background-color: rgba($color-red, 0.1);
				}
				.success-message {
					color: $color-green;
					border: 1px solid $color-green;
					padding: 24px;
					border-radius: $default-radius;
					text-align: center;
					background-color: rgba($color-green, 0.1);
				}
			}
		}
	}
}
