.entity-accordion-container {
	border: 1px solid $border-color;
	border-radius: $border-radius-default;
	.accordion-title {
		position: relative;
		padding: 16px 24px 18px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title-expander-container {
			display: flex;
			align-items: center;
			.dropdown-arrow {
				display: flex;
				align-items: center;
				svg {
					transition: all 100ms ease-in-out;
					fill: #aaaaaa;
				}
			}
			.dropdown-arrow--invisible {
				visibility: hidden;
			}
		}
		.at-checkbox {
			padding-left: 0;
			height: 18px;
			width: 18px;
		}
	}
	.accordion-title[data-state="expanded"] {
		border-bottom: 1px solid $border-color;
	}
}
