.api-access-management {
	.credits-section-header {
		padding: 0 25px 10px 25px;
		.header-text {
			display: flex;
			align-items: center;
			flex-grow: 1;
			margin-top: 25px;
			.subtitle {
				color: $mc-9;
			}
			img {
				margin-right: 10px;
				width: 18px;
			}
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.search-filter {
		margin-right: 10px;
	}
	.api-accounts-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 30%;
			padding-left: 25px;
			display: flex;
			.profile-img {
				width: 40px;
				height: 40px;
				border-radius: 20px;
			}
			.name-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				background: rgba(47, 88, 242, 0.1);
				border-radius: 20px;
				span {
					color: $color-blue;
					font-size: $medium-font-size;
					@extend .font--semibold;
				}
			}
			.name-desc {
				display: inline-block;
				margin-left: 10px;
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.roles {
			width: 40%;
			.tag-item {
				text-overflow: clip;
				max-width: fit-content;
			}
		}
		.created {
			width: 30%;
			padding-right: 25px;
		}
	}
	.api-roles-list-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.name {
			width: 70%;
			padding-left: 25px;
		}
		.users {
			width: 30%;
			padding-right: 25px;
		}
	}
}
.api-account-create-container {
	.header-container {
		display: flex;
		align-items: center;
		img {
			object-fit: contain;
			height: 52px;
			margin-right: 10px;
		}
		.subtitle {
			margin-top: 5px;
			@extend .font--regular;
		}
	}
	.success-message {
		padding: 20px;
		margin-bottom: 15px;
		color: $color-green;
		font-size: $very-small-font-size;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		background: rgba(46, 204, 113, 0.1);
	}
}
.api-account-edit-container {
	.form-sidebar-container {
		.header {
			.header-right {
				position: static;
				height: auto;
				width: 100px;
				display: flex;
				.context-menu {
					z-index: 101;
					position: relative;
					top: 0;
					right: 0;
					.icon-dots {
						padding: 5px 10px;
						border: 1px solid $border-color;
					}
					.menu {
						top: 35px;
						right: 0;
					}
				}
			}
		}
	}
	.wrapper-sticky-top {
		top: 0px;
	}
}
.api-role-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		padding: 15px;
		margin-bottom: 25px;
		.header-text {
			font-size: $very-small-font-size;
			@extend .font--semibold;
		}
		.header-subtext {
			color: $mc-5;
			font-size: $very-small-font-size;
			padding-top: 5px;
		}
	}
	.role-users-tab {
		.search-container {
			display: flex;
			margin-bottom: 35px;
			.at-input-container {
				width: 30%;
				margin-right: 35px;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.associated-users-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.name {
				width: 35%;
				padding-left: 25px;
			}
			.username {
				width: 30%;
			}
			.email {
				width: 35%;
				padding-right: 25px;
			}
		}
	}
}
