.layout-container {
	padding-left: $sidebar-width;
	transition: padding 300ms ease-in-out;
	@include bp(768px) {
		padding-left: 0px;
	}
	&.minimized {
		padding-left: $sidebar-width-minimized;
	}
}

.page-container {
	width: 100%;
	box-sizing: border-box;
	width: 100%;
	margin: auto;
	padding-top: 57px;
	&:before {
		content: " ";
		display: table;
	}
	& > .content {
		display: block;
		margin: 0px 30px;
	}
	@include bp(768px) {
		padding-top: 70px;
		& > .content {
			margin: 0px 10px;
		}
	}
	.top-nav-bar {
		margin-top: -20px;
		margin-bottom: 20px;
		width: 100%;
		max-height: 47px;
		background-color: #fff;
		.tab-container {
			max-width: 95%;
			margin: auto;
			display: flex;
			justify-content: center;
		}
		.tab {
			border-radius: 4px;
			color: $mc-9;
			opacity: 0.6;
			text-decoration: none;
			max-height: 30px;
			max-width: 15%;
			background-color: inherit;
			float: left;
			border: none;
			outline: none;
			cursor: pointer;
			padding: 5px;
			transition: 0.3s;
			font-family: Source Sans Pro;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			margin: 5px 10px;
			padding-left: 20px;
			padding-right: 20px;
			border-radius: 4px;
			&.active {
				background: rgba(47, 88, 242, 0.1);
				color: #2f58f2;
				opacity: 1;
			}
			.tab-desc {
				display: flex;
				align-items: center;
				img {
					margin-left: 5px;
					width: 15px;
				}
			}
		}
	}
	.content-container {
		max-width: 100%;
		margin: auto;
	}
}
