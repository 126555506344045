.drag-drop-wrapper {
	position: relative;
	.drag-drop-central-node {
		height: 2px;
		background-color: $color-blue;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		animation: expand 400ms ease-out forwards;

		@keyframes expand {
			0% {
				width: 0;
				left: 50%;
			}
			50% {
				width: 100%;
				left: 0;
			}
			100% {
				width: 100%;
				left: 0;
			}
		}
	}
}
