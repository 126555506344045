// common css related to dashboard
// component specific css will be in their file
.dashboard-section {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.05);
	margin-bottom: 25px;
	.transactions-list-header {
		.subtitle {
			.highlight {
				@extend .font--semibold;
				color: $mc-9;
			}
		}
	}
	// need to remove in next release
	.deprecate-dashboard-banner {
		border-radius: 2px;
		margin: 15px 25px;
		padding: 25px 25px;
		border-left: 6px solid $color-yellow-20;
		background-color: $color-yellow-10;
		display: flex;
		gap: 12px;
		.deprecate-warning-icon {
			height: 24px;
			width: 24px;
			margin-top: -2px;
		}
		.deprecate-dashboard-message {
			margin-top: 16px;
			color: $color-neutral-black-80;
			font-size: 14px;
		}
		.deprecate-warning-cta {
			margin-top: 16px;
			display: flex;
			gap: 8px;
			align-items: center;
			cursor: pointer;
			.deprecate-warning-cta-text {
				font-size: 14px;
				@extend .font--semibold;
				color: $color-blue-40;
			}
		}
	}

	.dashboard-filters {
		display: flex;
		align-items: center;
		padding: 15px 25px;
		.multi-select-filter-container {
			width: 150px;
			font-size: $very-small-font-size;
			margin-right: 10px;
		}
		.dropdown-date-time-filter {
			margin-right: 10px;
		}
		.select-filter-custom {
			margin-right: 10px;
			&.brand-filter {
				.dropdown-custom {
					width: 220px;
					.container {
						height: 32px;
						padding: 1px 10px;
					}
					.dropdown-options {
						.option {
							height: 37px;
							padding: 1px 10px;
						}
					}
					.custom-option,
					.custom-label {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							width: 22px;
							height: 22px;
							text-transform: uppercase;
							font-size: $very-small-font-size;
							border-radius: $default-radius;
							@extend .font--semibold;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $default-radius;
							}
							&.brown {
								color: $color-brown-dark;
								background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
							}
							&.blue {
								color: $color-blue-dark;
								background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
							}
							&.green {
								color: $color-green-dark;
								background-color: rgba($color: $color-green-dark, $alpha: 0.1);
							}
							&.red {
								color: $color-red-dark;
								background-color: rgba($color: $color-red-dark, $alpha: 0.1);
							}
							&.purple {
								color: $color-violet;
								background-color: rgba($color: $color-violet, $alpha: 0.1);
							}
						}
					}
				}
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		z-index: 10;
	}
	.header-action-button {
		display: flex;
		align-items: center;
	}
	// .new-date-compare-filter {
	// 	padding: 0 25px;
	// 	margin-bottom: 25px;
	// }
	.dashboard-header-container {
		padding: 25px;
		.header-text {
			font-size: $large-font-size;
			float: left;
			margin-bottom: 5px;
			@extend .font--semibold;
			@include bp(768px) {
				float: none;
			}
		}
		.subheader-text {
			font-size: $small-font-size;
			float: left;
			clear: both;
			margin-top: 5px;
			color: $mc-6;
			.highlight {
				color: $mc-9;
			}
		}
		.filter-in-header {
			float: right;
			margin-top: -20px;
		}
		.filter-in-header-btn-holder {
			@include bp(768px) {
				display: none;
			}
		}
		.filter-in-header-btn-holder--mobile {
			display: block;
		}
		@include bp(768px) {
			padding: 10px;
			.subheader-text,
			.filter-in-header {
				float: none;
				margin-top: 0px;
				color: $mc-6;
			}
			.filter-in-header {
				margin-top: 10px;
			}
		}
		.campaign-list-filter {
			.container {
				transition: 0.3s;
				&:hover {
					&.filter-title {
						color: $color-blue;
					}
				}
				position: relative;
			}
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}
	.sidebar-container {
		width: 20%;
		min-width: 170px;
		border-right: 1px solid $border-color;
		margin-right: 25px;
		flex: 1;
		min-height: 300px;
		padding: 25px 0;
		@include bp(768px) {
			border: 0px;
			width: 100%;
			min-height: 0px;
			margin-bottom: 20px;
		}
		.sidebar-row {
			padding-bottom: 15px;
			padding-top: 15px;
			&.selected {
				background: rgba($color: $color-blue, $alpha: 0.1);
				&::after {
					left: 0;
				}
			}
		}
	}
	.data-container {
		width: 80%;
		overflow: hidden;
		padding: 25px 25px 25px 0;
		@include bp(768px) {
			width: 100%;
			padding-right: 0px;
		}
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.dashboard-section-container {
	display: flex;
	@include bp(768px) {
		flex-direction: column;
		padding: 10px;
		padding-top: 0px;
	}
}

.dashboard-welcome-message {
	.modal-container {
		background-repeat: no-repeat;
		background-position: center;
		background-color: transparent;
		width: 60%;
		.modal-content {
			min-height: 0;
			position: relative;
			.whats-app-support-btn {
				position: absolute;
				bottom: -350px;
				left: 33px;
				width: 150px;
				display: flex;
				justify-content: space-between;
				font-size: $medium-font-size;
				font-weight: 600;
				border-radius: 15px;
				border-width: 0;
				color: rgb(99, 17, 244);
				.right-arrow-icon {
					padding-top: 7px;
					padding-right: 0;
					object-fit: cover;
					height: 20px;
					width: 20px;
				}
				box-shadow: $box-shadow;
			}
		}
		.modal-close {
			top: 10px;
			right: 10px;
			width: 20px;
			height: 20px;
			padding: 0;
			background-color: rgba(#fff, 0.3);
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				height: 10px;
				width: 10px;
				opacity: 1;
				filter: brightness(1%);
			}
		}
		@include bp(1172px) {
			background-size: contain;
			max-height: 100vh;
			.modal-content {
				max-height: 580px;
			}
			.feature-container {
				flex-direction: column;
				align-items: center;
				img {
					margin-bottom: 15px;
				}
				.content {
					margin: 0;
				}
			}
		}
	}
	.feature-container {
		padding: 0 50px;
		display: flex;
		justify-content: space-around;
		img {
			width: 380px;
			height: 260px;
			padding: 10px;
			border-radius: $default-radius;
		}
		a {
			text-decoration: none;
		}
		.content {
			margin-left: 25px;
			margin-top: 10px;
			padding-top: 22px;
			.header {
				color: $color-blue;
				font-weight: 700;
				margin-top: -15px;
				font-size: $medium-large-font-size;
			}
			.tag-line {
				font-size: $medium-large-font-size;
				color: $mc-7;
				color: $color-java;
				&.striked {
					color: $mc-5;
					text-decoration: line-through;
					text-decoration-color: $mc-9;
				}
				@extend .font--semibold;
			}
			.button-div {
				padding-top: 8px;
				display: flex;
			}
			.intro {
				font-size: $medium-font-size;
				margin-top: 24px;
				color: $mc-7;
				text-align: left;
				line-height: 1.25;
				span {
					@extend .font--semibold;
				}
				a {
					color: $color-blue;
					cursor: pointer;
					text-transform: initial;
					position: relative;
					&::after {
						content: "";
						position: absolute;
						width: 100%;
						transform: scaleX(0);
						height: 1px;
						bottom: 0;
						left: 0;
						background-color: $color-blue;
						transform-origin: bottom right;
						transition: transform 0.25s ease-out;
					}
					&:hover::after {
						transform: scaleX(1);
						transform-origin: bottom left;
					}
				}
			}
			.start-here {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 17px;
				padding: 8px 15px;
				margin-top: 30px;
				width: fit-content;
				border-radius: $default-radius;
				border: 1px solid $color-blue;
				background-color: rgba(47, 88, 242, 0.1);
				font-size: $small-font-size;
				color: $color-blue;
				cursor: pointer;
				@extend .font--semibold;
			}
			.secondary {
				color: $color-java;
				background-color: rgba(24, 208, 178, 0.1);
				border: 1px solid $color-java;
			}
		}
	}
}
