.infinite-spinner-loader {
	width: 24px;
	height: 24px;
	border: 2px solid #3b5feb;
	border-bottom-color: #f2f5ff;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
