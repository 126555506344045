$small-size: 8px;
$base-size: 10px;

@mixin padding($value) {
	padding: round($value * $base-size);
}

@mixin margin($value) {
	margin: round($value * $base-size);
}

@mixin marginTop($value) {
	margin-top: round($value * $base-size);
}

@mixin marginBottom($value) {
	margin-bottom: round($value * $base-size);
}

@mixin marginVertical($value) {
	margin: round($value * $base-size) 0;
}

@mixin gap($value) {
	gap: round($value * $base-size);
}

@mixin borderRadius($value) {
	border-radius: round($value * $base-size);
}

.base-padding-small {
	@include padding(0.75);
}

.base-padding-smaller {
	@include padding(0.5);
}

.base-padding-normal {
	@include padding(1);
}

.base-padding-large {
	@include padding(2);
}

.base-padding-big {
	@include padding(3);
}

.base-padding-bigger {
	@include padding(4);
}

.base-margin-small {
	@include margin(0.75);
}

.base-margin-smaller {
	@include margin(0.5);
}

.base-margin-normal {
	@include margin(1);
}

.base-margin-large {
	@include margin(2);
}

.base-margin-big {
	@include margin(3);
}

.base-margin-bigger {
	@include margin(4);
}

.base-margin-top-small {
	@include marginTop(0.75);
}

.base-margin-top-smaller {
	@include marginTop(0.5);
}

.base-margin-top-normal {
	@include marginTop(1);
}

.base-margin-top-large {
	@include marginTop(2);
}

.base-margin-top-big {
	@include marginTop(3);
}

.base-margin-top-bigger {
	@include marginTop(4);
}

.base-margin-bottom-small {
	@include marginBottom(0.75);
}

.base-margin-bottom-smaller {
	@include marginBottom(0.5);
}

.base-margin-bottom-normal {
	@include marginBottom(1);
}

.base-margin-bottom-large {
	@include marginBottom(2);
}

.base-margin-bottom-big {
	@include marginBottom(3);
}

.base-margin-bottom-bigger {
	@include marginBottom(4);
}

.base-margin-vertical-small {
	@include marginVertical(0.75);
}

.base-margin-vertical-smaller {
	@include marginVertical(0.5);
}

.base-margin-vertical-normal {
	@include marginVertical(1);
}

.base-margin-vertical-large {
	@include marginVertical(2);
}

.base-margin-vertical-big {
	@include marginVertical(3);
}

.base-margin-vertical-bigger {
	@include marginVertical(4);
}

.base-shadow-normal {
	box-shadow: $default-shadow;
}

.base-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.base-center-all {
	align-items: center;
	justify-content: center;
}

.base-row-direction {
	display: flex;
	flex-direction: row;
}

.base-column-direction {
	display: flex;
	flex-direction: column;
}

.base-align-items-flex-start {
	align-items: flex-start;
}

.base-align-items-center {
	align-items: center;
}

.base-align-items-flex-end {
	align-items: flex-end;
}

.base-justify-content-flex-start {
	justify-content: flex-start;
}

.base-justify-content-center {
	justify-content: center;
}

.base-justify-content-flex-end {
	justify-content: flex-end;
}

.base-gap-normal {
	@include gap(1);
}

.base-gap-large {
	@include gap(2);
}

.base-gap-big {
	@include gap(3);
}

.base-gap-bigger {
	@include gap(4);
}

.base-width-15 {
	width: 15%;
}

.base-width-25 {
	width: 25%;
}

.base-width-half {
	width: 50%;
}

.base-width-75 {
	width: 75%;
}

.base-width-full {
	width: 100%;
}

.base-height-full {
	height: 100%;
}

.base-size-full {
	width: 100%;
	height: 100%;
}

.base-flex-1 {
	flex: 1;
}

.base-flex-full {
	flex: 1;
	width: 100%;
}

.base-capitalize {
	text-transform: capitalize;
}

.base-color-black-60 {
	color: $color-black-60;
}

.base-color-blue {
	color: $color-blue;
}

.base-border-small {
	border: 1px solid $color-blue;
}

.base-border-normal {
	border: 2px solid $color-blue;
}

.base-border-color-white {
	border-color: $mc-1;
}

.base-border-color-blue {
	border-color: $color-blue;
}

.base-border-color-black-20 {
	border-color: $color-black-20;
}

.base-border-radius-smallest {
	@include borderRadius(0.25);
}

.base-border-radius-smaller {
	@include borderRadius(0.5);
}

.base-border-radius-small {
	@include borderRadius(0.75);
}

.base-border-radius-normal {
	@include borderRadius(1);
}

.base-background-color-blue {
	background-color: $color-blue;
}

.base-background-color-blue-10 {
	background-color: $color-blue-10;
}

.base-cursor-pointer {
	cursor: pointer;
}

.base-font-size-smaller {
	font-size: $exta-small-font-size;
}

.base-font-size-small {
	font-size: $very-small-font-size;
}

.base-font-size-normal {
	font-size: $small-font-size;
}

.base-font-size-large {
	font-size: $medium-font-size;
}

.base-font-size-big {
	font-size: $medium-large-font-size;
}

.base-font-size-bigger {
	font-size: $large-font-size;
}

.base-divider-large {
	height: $small-size;
	@extend .base-width-full;
	background-color: $color-divider;
}

.base-overflow-hidden {
	overflow: hidden;
}

.base-transition {
	transition: 0.3s ease-in-out;
}

.base-position-relative {
	position: relative;
}

.base-position-absolute {
	position: absolute;
}

.base-link {
	color: $mc-8;
	text-decoration: none;
	font-size: 14px;
}

.base-button-disabled {
	cursor: default !important;
	opacity: 0.6;
}

.base-font-14 {
	font-size: 14px;
}
