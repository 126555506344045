.at-breadcrumbs-container {
	display: flex;
	align-items: center;
	gap: 8px;
	.at-breadcrumb-link {
		color: $mc-6;
		text-decoration: none;
		.hyperlink {
			color: $mc-6;
			font-weight: 400;
			&::after {
				background-color: $mc-6;
			}
		}
		.right-arrow-icon {
			width: 20px;
			height: 10px;
		}
	}
}
