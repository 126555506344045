@mixin bp($point) {
	@media screen and (max-width: $point) {
		@content;
	}
}

@mixin truncate_text() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
