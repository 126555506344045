// brands list styles
.brandslist-section {
	.bulk-actions-container {
		.bulk-actions {
			display: flex;
			justify-content: space-between;
			padding: 0 25px;
			color: $color-dark-grey;
			font-size: $very-small-font-size;
			.archive {
				color: $color-red;
				&::after {
					background-color: $color-red;
				}
			}
			.restore {
				color: $color-green;
				&::after {
					background-color: $color-green;
				}
			}
		}
	}
	.brands-list-header {
		padding: 15px 25px 25px 25px;
		.action-buttons-container {
			.at-btn--secondary {
				margin-right: 12px;
				svg {
					// top: 3.5px;
				}
			}
		}
		.header-text {
			.title {
				font-size: $large-font-size;
				margin-bottom: 5px;
				@extend .font--semibold;
			}
			.subtitle {
				font-size: $small-font-size;
				color: $mc-6;
			}
		}
	}
	.brand-filters {
		padding: 0 25px;
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
		.multi-select-filter-container {
			margin-bottom: 0px;
			width: 210px;
		}
		.search-filter {
			width: 300px;
			margin-right: 0;
			.at-input-container {
				width: 100%;
			}
			.at-input {
				border-radius: $border-radius-default;
			}
			.at-label {
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
		}

		.Select-control {
			border-radius: $border-radius-default;
		}
	}
	.brands-list-table-container {
		.transaction-rows {
			min-height: 100px;
			.context-menu {
				.menu {
					padding-left: 0;
				}
			}
		}
		.at-header-text {
			&.brand {
				padding-left: 25px;
				width: 25%;
				display: flex;
				align-items: center;
				.at-checkbox {
					height: 16px;
					// position: static;
					// .checkmark {
					// 	top: 9px;
					// 	left: 9px;
					// }
				}
			}
			&.assoc_locations {
				width: 25%;
				display: flex;
				align-items: center;
			}
			&.assoc_items {
				width: 40%;
				display: flex;
				align-items: center;
			}
		}
		.at-cell-text {
			&.brand {
				padding-left: 25px;
				width: 25%;
				.at-checkbox {
					height: 16px;
					width: fit-content;
					// position: static;
					// .checkmark {
					// 	top: 15px;
					// 	left: 9px;
					// }
				}
				.location-title {
					display: flex;
				}
				.title-image-container {
					display: flex;
					gap: 10px;
					.link-text {
						width: 70%;
						@extend .hyperlink;
						@extend .hyperlink--black-color;
						@include truncate_text;
					}
					.brand-image {
						height: 32px;
						width: 32px;
						object-fit: cover;
						border-radius: 2px;
					}
				}
			}
			&.assoc_locations {
				width: 25%;
				position: relative;
				padding-right: 25px;
				.dotted-dropdown {
					position: absolute;
					top: 7px;
					cursor: pointer;
					right: 25px;
				}
			}
			&.assoc_items {
				width: 40%;
			}
		}
	}
}
// brands details styles
.brand-details-form-content {
	.basic-info {
		margin: 0 24px 24px 24px;
		display: flex;
		gap: 40px;
		.biz-logo-container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			justify-content: center;
			.logo {
				margin-top: 2px;
				display: flex;
				flex-direction: column;
				gap: 5px;
				.logo-header {
					margin-bottom: 2px;
					font-size: $exta-small-font-size;
					color: $mc-5;
				}
				.empty-logo-container {
					height: 160px;
					width: 160px;
					background-color: #f5f5f5;
					display: flex;
					justify-content: center;
				}
				.biz-logo {
					box-sizing: content-box;
					height: 160px;
					width: 160px;
					border: 1px solid $border-color;
					border-radius: 2px;
				}
			}
			.no-border {
				border-width: 0;
				color: $color-red;
			}
		}
		.biz-name {
			flex-grow: 1;
		}
	}

	.brand-locations {
		position: relative;
		.header-container {
			padding: 0 24px 24px 24px;
			display: flex;
			justify-content: space-between;
			.title {
				color: $color-light-black;
				font-size: $very-small-font-size;
				font-weight: 600;
			}
			.sub-title {
				color: $color-dark-grey;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.filters {
			display: flex;
			justify-content: space-between;
			padding: 0 24px 44px 24px;
			.dropdown-filters {
				width: 50%;
				display: flex;
				gap: 12px;
				.multi-select-filter-container {
					width: 150px;
				}
				.Select {
					.Select-control {
						border-radius: 2px;
					}
				}
			}
			.search-filter {
				margin-right: 0;
				display: block;
				width: 256px;
				.at-label {
					border-top-left-radius: 2px;
					border-bottom-left-radius: 2px;
				}
				.at-input {
					border-radius: 2px;
				}
				.cancel-search {
					top: 10px;
				}
			}
		}
		.bulk-actions-container {
			position: absolute;
			width: 700px;
			top: 110px;
			padding: 0 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.selected-entity-counter {
				color: $color-dark-grey;
				font-size: $very-small-font-size;
			}
			.actions-container {
				display: flex;
				align-items: center;
				gap: 12px;
				font-size: $very-small-font-size;
				.remove-loc {
					color: $color-red;
					&::after {
						background-color: $color-red;
					}
				}
				.multi-select-filter-container {
					width: 110px;
					// margin-left: 20px;
					.react-select__control {
						border: none;
						min-height: auto;
					}
					.react-select__value-container {
						overflow: initial;
						padding: 0;
						width: fit-content;
					}
					.react-select__single-value {
						width: fit-content;
						overflow: initial;
						white-space: initial;
						text-overflow: initial;
						font-size: $very-small-font-size;
						color: $color-blue;
						@extend .font--semibold;
					}
					.react-select__dropdown-indicator {
						color: $color-blue;
						padding: 2px 0 2px 3px;
					}
				}
			}
		}
		&--table {
			.name {
				width: 25%;
				.name--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.ilaCount {
				width: 25%;
				.assocItems--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.associatedPlatforms {
				width: 50%;
				.assocPlatforms--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.form-table--list {
				.loc-details {
					width: 100%;
					display: flex;
					flex-direction: column;
					line-height: 1.5;
					.name {
						width: 100%;
						@include truncate_text;
						.name--underline {
							@extend .font--semibold;
							@extend .hyperlink;
							@extend .hyperlink--black-color !optional;
						}
					}
					.city {
						font-size: $exta-small-font-size;
						text-transform: uppercase;
						color: $mc-6;
						font-weight: $font--semibold;
					}
				}
				.associatedPlatforms {
					position: relative;
					overflow: visible;
					// border: 1px solid red;
					padding-right: 40px;
					.platforms-list {
						display: flex;
						flex-wrap: wrap;
						gap: 10px;
						width: 100%;
						.context-menu {
							right: 15px;
						}
						.actions-list--selected {
							background-color: $color-lightest-grey;
							svg {
								path {
									fill: $color-light-black;
								}
							}
						}
					}

					.popover-wrapper-container {
						.popover-wrapper--content {
							.platform {
								position: relative;
								padding-top: 2px;
								.platform-img {
									height: 30px;
									width: 28px;
									border-radius: 2px;
									object-fit: cover;
									border: 1px solid $mc-2;
								}
								.platform-status-indicator {
									height: 8px;
									width: 8px;
									background-color: $color-neutral-grey;
									position: absolute;
									top: -4px;
									right: -4px;
									border-radius: 50%;
									border: 4px solid $mc-1;
								}
							}

							.platform--enabled {
								.platform-status-indicator {
									background-color: $color-green;
								}
							}

							[aria-label][data-balloon-pos]:after {
								right: 1px;
								top: 80%;
							}
							[aria-label][data-balloon-pos]:before {
								right: 8px;
								top: 80%;
							}
						}
						.popover-container {
							top: 45px;
							right: -11px;
							&::before {
								right: 17px;
							}
							.lpa-logs-container {
								position: relative;
								.header {
									position: sticky;
									top: 0;
									padding: 10px 15px;
									font-size: 12px;
									border-bottom: 1px solid $border-color;
									background-color: $mc-1;
									color: rgba($color: $mc-9, $alpha: 0.6);
									border-radius: 4px 4px 0 0;
									text-transform: uppercase;
									box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
									@extend .font--semibold;
								}
								.logs {
									width: 400px;
									min-height: 120px;
									max-height: 300px;
									overflow-y: auto;
									.log-container {
										display: flex;
										align-items: center;
										justify-content: space-between;
										border-bottom: 1px solid $border-color;
										padding: 10px 15px;
										.brand-container {
											display: flex;
											align-items: center;
											.brand-initials {
												display: flex;
												align-items: center;
												justify-content: center;
												width: 32px;
												height: 32px;
												text-transform: uppercase;
												border-radius: $default-radius;
												margin-right: 10px;
												@extend .font--semibold;
												&.brown {
													color: $color-brown-dark;
													background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
												}
												&.blue {
													color: $color-blue-dark;
													background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
												}
												&.green {
													color: $color-green-dark;
													background-color: rgba($color: $color-green-dark, $alpha: 0.1);
												}
												&.red {
													color: $color-red-dark;
													background-color: rgba($color: $color-red-dark, $alpha: 0.1);
												}
												&.purple {
													color: $color-violet;
													background-color: rgba($color: $color-violet, $alpha: 0.1);
												}
												img {
													width: 32px;
													height: 32px;
													object-fit: cover;
													border-radius: $default-radius;
													margin: 0;
												}
											}
											.brand-info {
												display: flex;
												flex-direction: column;
												.brand-name {
													text-transform: capitalize;
													margin-bottom: 3px;
													@extend .font--semibold;
												}
												.brand-status {
													display: flex;
													align-items: center;
													.platform-status {
														font-size: 12px;
														margin-right: 5px;
														color: $mc-7;
														&.offline {
															color: $color-red;
														}
														&.online {
															color: $color-green;
														}
													}
													.ext-link {
														position: relative;
														top: 1px;
														cursor: pointer;
														img {
															margin: 0;
															width: 15px;
															height: 15px;
															opacity: 0.6;
														}
													}
												}
											}
										}
										.action-container {
											display: flex;
											flex-direction: column;
											.action {
												width: auto;
												display: flex;
												flex-direction: row;
												align-items: center;
												justify-content: flex-end;
												color: $mc-7;
												margin-bottom: 3px;
												@extend .font--semibold;
												img {
													position: relative;
													top: -1px;
													width: 14px;
													height: 14px;
													margin: 0;
													margin-right: 5px;
													object-fit: contain;
													filter: grayscale(1) brightness(0.8);
												}
											}
											.date-time {
												text-align: right;
												font-size: 12px;
												color: $mc-5;
											}
										}
									}
									.no-items-placeholder {
										border-bottom: none;
									}
								}
								.actions {
									padding: 10px 15px;
									text-align: right;
									box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
									div {
										color: $color-blue;
										cursor: pointer;
										@extend .font--semibold;
									}
								}
							}
						}
					}
				}
			}
		}
		.table--tooltip {
			.brand-loc-tooltip-data-container {
				padding: 20px;
				width: 100px;
				.brand-loc-info {
					// padding: 24px;
					font-size: $exta-small-font-size;
					color: $color-dark-grey;
					@extend .font--semibold;
					border-bottom: 1px solid $border-color;
					.brand-verification-info {
						background-color: red;
					}
					.action-tab {
						display: flex;
						justify-content: center;
						// .link-text {
						// 	border: 1px solid crimson;
						// }
					}
				}
			}
		}
		.at-paginator {
			padding: 24px;
		}
	}
}
// brand details generic modal styles
.remove-locations-modal {
	.at-modal {
		.modal-header {
			.header-left {
				.title {
					color: $color-light-black;
					text-align: left;
					font-size: 18px;
				}
			}
		}
		.modal-container {
			width: 30%;
			min-width: 500px;
		}
		.modal-footer {
			text-align: right;
		}
		.remove-location-details {
			padding: 0 25px;
			.brand-platform-info {
				font-size: 18px;
				@extend .font--semibold;
				color: $color-light-black;
			}
			.item-assoc-count {
				margin-top: 8px;
			}
			.warning {
				margin: 24px 0;
			}
			.confirm-process {
				display: flex;
				gap: 13px;
				.at-checkbox {
					position: relative;
					padding-left: 0;
					.checkmark {
						position: relative;
						display: block;
						height: 14px;
						width: 14px;
					}
				}
			}
			.end-result {
				display: flex;
				padding: 25px 0;
				border-radius: $border-radius-default;
				justify-content: center;
				&.success {
					background-color: rgba($color-green, 0.1);
					color: $color-green;
					border: 1px solid $color-green;
				}
				&.failure {
					background-color: rgba($color-red, 0.1);
					color: $color-red;
					border: 1px solid $color-red;
				}
			}
		}
	}
}

// brands create style
.brand-creation-flow {
	min-height: 80vh;
	padding-bottom: 0;
	&--container {
		min-height: 80vh;
		position: relative;
		.title {
			font-size: $medium-large-font-size;
			color: $color-light-black;
			padding-bottom: 24px;
			@extend .font--semibold;
		}
	}
	&--header {
		font-size: $medium-font-size;
		@extend .font--semibold;
		color: $color-dark-grey;
		padding: 32px 32px 24px 32px;
		display: flex;
		gap: 16px;
		.brand-name {
			display: flex;
			gap: 12px;
			.logo-image-container {
				height: 24px;
				width: 24px;
				background-color: $color-lightest-grey;
				border-radius: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				.img-preview {
					height: 24px;
					width: 24px;
					object-fit: cover;
				}
				.logo-img {
					height: 24px;
					width: 24px;
					object-fit: cover;
				}
			}
		}
	}
	&--details-entry {
		padding: 32px 32px 24px 32px;
		.brand-details-container {
			display: flex;
			gap: 40px;
			flex-wrap: wrap;
			.brand-logo-selector-container {
				.info {
					position: relative;
					top: 1px;
					width: 12px;
					margin-left: 5px;
				}
				.header {
					font-size: $very-small-font-size;
					@extend .font--semibold;
					margin-bottom: 4px;
					color: $color-dark-grey;
					line-height: 22px;
				}
				.logo-image-container {
					height: 240px;
					width: 240px;
					background-color: $color-lightest-grey;
					border-radius: 2px;
					margin-bottom: 12px;
					display: flex;
					justify-content: center;
					align-items: center;
					.img-preview {
						height: 240px;
						width: 240px;
						object-fit: cover;
					}
				}
				.at-btn {
					width: 240px;
				}
				.remove-logo-text {
					color: $color-red;
					font-size: $very-small-font-size;
					cursor: pointer;
					text-align: center;
					margin-top: 12px;
					@extend .font--semibold;
				}
			}
			.brand-name-entry {
				.at-input-container {
					.at-input-label {
						font-size: $very-small-font-size;
						@extend .font--semibold;
						padding-bottom: 0;
						margin-bottom: 4px;
						color: $color-dark-grey;
						line-height: 22px;
					}
					.at-input-field {
						.at-input {
							border-radius: 2px;
							height: 54px;
							width: 420px;
						}
					}
				}
			}
		}
	}
	&--locations-association {
		.title {
			padding: 32px 32px 24px 32px;
		}
		.filters {
			padding: 32px 32px 24px 32px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.section-1 {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				.at-btn {
					border-radius: $border-radius-default;
					&:hover {
						svg {
							path {
								fill: $color-blue;
							}
						}
					}
				}
				.Select-control {
					border-radius: $border-radius-default;
				}
				.multi-select-filter-container {
					width: 200px;
				}
			}
			.section-2 {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				.search-filter {
					margin-right: 0;
					display: block;
					width: 327px;
					.at-label {
						border-top-left-radius: 2px;
						border-bottom-left-radius: 2px;
					}
					.at-input {
						// border: 1px solid yellow;
						border-radius: $border-radius-default;
					}
					.cancel-search {
						top: 10px;
					}
				}
			}
		}
		.brand-creation-loc-assoc-table {
			.name {
				width: 20%;
				.name--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.id {
				width: 20%;
				.id--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.city {
				width: 20%;
				.city--container {
					width: 100%;
					@include truncate_text;
				}
			}
			.address {
				width: 40%;
				.address--container {
					width: 100%;
					@include truncate_text;
				}
			}
		}
		.at-paginator {
			padding-bottom: 15px;
		}
	}
	&--modal {
		.modal-container {
			min-height: 440px;
			min-width: 524px;
			.modal-header {
				text-align: left;
				.header-left {
					.title {
						font-size: $medium-font-size;
						color: $color-light-black;
					}
				}
			}
			.modal-content {
				max-height: max-content;
				background-color: $color-light-black;
				margin: auto;
			}
			.modal-footer {
				text-align: right;
			}
		}
	}
	&--footer {
		box-shadow: $default-shadow;
		position: sticky;
		display: flex;
		bottom: 0;
		left: 0;
		right: 0;

		flex-direction: row-reverse;
		gap: 12px;
		padding: 16px 24px;
		background-color: white;
	}
	&--end-result {
		width: 100%;
		height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 60px;
		position: relative;
		.icon-text-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 32px;
			.text {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;
				.main-text {
					font-size: $large-font-size;
					color: $color-light-black;
					@extend .font--semibold;
				}
				.sub-text {
					font-size: $medium-font-size;
					color: $color-dark-grey;
					@extend .font--semibold;
				}
			}
		}
		.btn-container {
			display: flex;
			flex-direction: column;
			gap: 12px;
			z-index: 1;
		}
	}
	.global-loader {
		display: flex;
		height: 80vh;
		justify-content: center;
		align-items: center;
		.loader-text {
			font-size: $medium-font-size;
			font-weight: 600;
		}
	}
	&--progress {
		height: 80vh;
		display: flex;
		gap: 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: $medium-large-font-size;
		color: $color-light-black;
		font-weight: 600;
		.progress-bar-container {
			width: 50%;
		}
	}

	.shimmer-container {
		margin-top: 25%;
	}
}

.brand-loc-association {
	.name {
		width: 50%;
	}
	.city {
		width: 50%;
	}

	.at-paginator {
		padding: 25px;
	}

	&--filters {
		display: flex;
		padding: 25px;
		justify-content: space-between;
		.section-1 {
			display: flex;
			gap: 12px;
			.multi-select-filter-container {
				width: 100px;
			}
			.Select-control {
				border-radius: $border-radius-default;
			}
		}
		.section-2 {
			.search-filter {
				.at-input {
					border-radius: $border-radius-default;
				}
				.at-label {
					border-top-left-radius: $border-radius-default;
					border-bottom-left-radius: $border-radius-default;
				}
			}
		}
	}

	&--progress {
		height: 80%;
		width: 100%;
		font-size: $medium-large-font-size;
		font-weight: 600;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		gap: 24px;
		.progress-bar-container {
			width: 80%;
		}
	}
}

.item-ilpa-ids {
	.at-paginator {
		padding: 25px;
	}
	&--filters {
		display: flex;
		padding: 25px;
		justify-content: space-between;
		.section-1 {
			display: flex;
			gap: 12px;
			.multi-select-filter-container {
				width: 100px;
			}
			.Select-control {
				border-radius: $border-radius-default;
			}
		}
		.section-2 {
			.search-filter {
				.at-input {
					border-radius: $border-radius-default;
				}
				.at-label {
					border-top-left-radius: $border-radius-default;
					border-bottom-left-radius: $border-radius-default;
				}
			}
		}
	}

	.random-table {
		color: $color-light-black;
		.table-header {
			display: flex;
			background-color: $color-lightest-grey;
			text-transform: uppercase;
			font-weight: 600;
			font-size: $exta-small-font-size;
			color: $color-dark-grey;
			padding: 16px;
			.locationName {
				width: 50%;
				@include truncate_text;
			}
			.platform {
				width: 25%;
				@include truncate_text;
			}
			.ilpaId {
				width: 25%;
				@include truncate_text;
			}
		}
		.table-list {
			min-height: 70px;
			border-bottom: 1px solid $border-color;
			padding: 0 0 0 16px;
			&:hover {
				background-color: rgba(47, 88, 242, 0.05);
			}
			display: flex;
			.locationName {
				padding-top: 10px;
			}
		}
		.locationName {
			width: 47%;
			@include truncate_text;
		}
		.value-set-container {
			width: 53%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			line-height: 1.5;
			padding-top: 10px;
			.values-container {
				width: 100%;
				padding: 10px;
				display: flex;
				border-bottom: 1px solid $border-color;
				.platform {
					text-align: left;
					width: 47%;
					@include truncate_text;
				}
				.ilpaId {
					text-align: left;
					width: 53%;
					@include truncate_text;
				}
				&:last-child {
					border-bottom: 0;
				}
				&:first-child {
					padding-top: 0;
				}
			}
		}
	}
}

// temp style for rectangular progress-bar
.rectangular-progress-bar {
	&--container {
		height: 4px;
		display: flex;
		width: 100%;
		position: relative;
	}
	&--content {
		height: 100%;
		width: 100%;
		background-color: $color-neutral-grey;
		margin-right: 8px;
		&:last-child {
			margin-right: 0;
		}
	}
	&--content > &--loader {
		width: 0%;
		height: 100%;
		background-color: $color-blue-dark-2;
		transition: all 500ms ease-in-out;
	}
	&--numeric-display {
		position: absolute;
		right: 10px;
		bottom: 24px;
		color: $color-dark-grey;
	}
}

.table--tooltip {
	background-color: white;
	box-shadow: $default-shadow;
	// height: 118px;
	width: 360px;
	border-radius: 2px;
	position: fixed;
	&::after {
		position: absolute;
		content: "";
		height: 0px;
		width: 0px;
		content: "";
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 20px solid $mc-1;
		top: -14px;
		right: 6px;
	}
}

.brand-edit-crop--modal {
	.modal-container {
		min-height: 440px;
		min-width: 524px;
		.modal-header {
			text-align: left;
			.header-left {
				.title {
					font-size: $medium-font-size;
					color: $color-light-black;
				}
			}
		}
		.modal-content {
			max-height: max-content;
			background-color: $color-light-black;
			margin: auto;
		}
		.modal-footer {
			text-align: right;
		}
	}
}

.brand-catalogue-tab {
	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 25px;
		margin-bottom: 30px;
		.header-text {
			font-size: $very-small-font-size;
			@extend .font--semibold;
		}
		.header-subtext {
			color: $mc-5;
			font-size: $very-small-font-size;
			padding-right: 15px;
			padding-top: 5px;
			&:nth-child(3) {
				@extend .font--semibold;
			}
		}
		.action-buttons {
			.button {
				display: inline-block;
				&:last-child {
					margin-left: 10px;
				}
			}
		}
		.popover-container {
			top: 54px;
			left: -10px !important;
			width: 400px;
			&::before {
				top: -12px;
			}
			&::after {
				top: -10px;
			}
			.popover-info {
				padding: 12px;
				color: $mc-7;
				font-size: $very-small-font-size;
				.popover-header {
					font-weight: 600;
				}
				.popover-message {
					margin-top: 7px;
				}
			}
		}
		.select-filter-custom {
			margin-top: 0;
			&.brand-selector {
				margin: 0;
				.container {
					padding-top: 3px;
				}
				.dropdown-component {
					width: 329px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}
			}
		}
	}
	.filters {
		display: flex;
		justify-content: space-between;
		padding: 0 24px 24px 24px;
		.dropdown-filters {
			width: 60%;
			display: flex;
			gap: 12px;
			.multi-select-filter-container {
				width: 120px;
			}
			.Select {
				width: 100px;
			}
			.select-filter-custom {
				margin: 0;
				.container {
					padding-top: 3px;
				}
				.dropdown-component {
					width: 329px;
					right: auto;
					left: 0;
				}
			}
			.popover-container {
				top: 54px;
			}
			.status-selector {
				margin-right: 12px;
			}
		}
		.platform-selector-container {
			width: 55%;
			// padding: 0 24px 24px 24px;
			.select-filter-custom {
				width: 100%;
				margin-top: 0;
				.dropdown-custom {
					.dropdown-component {
						width: 100%;
					}
				}
				.container {
					padding: 0 10px;
					border: 1px solid $border-color-dark;
					justify-content: space-between;
					height: 35px;
					.option {
						.custom-label {
							font-size: $very-small-font-size;
							@extend .font--regular;
							div {
								max-width: 80px;
								@include truncate_text;
							}
							.logo {
								width: 25px;
								height: 25px;
								img {
									width: 25px;
									height: 25px;
									object-fit: cover;
									border-radius: 4px;
								}
							}
						}
					}
				}
			}
		}
		.search-inspect-filter {
			.search-container {
				display: flex;
				.search-type-selector {
					width: 120px;
					.react-select__control {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.at-input-field {
					.at-label {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}
				}
			}
			display: flex;
			gap: 12px;
			.search-filter {
				margin-right: 0;
				display: block;
				width: 220px;
				.cancel-search {
					top: 10px;
				}
			}
			.at-btn-inspect {
				svg {
					padding: 0;
				}

				&:hover {
					svg {
						path {
							fill: $color-blue;
						}
					}
				}
			}
			.inspect-mode {
				border-color: $color-blue;
				svg {
					path {
						fill: $color-blue;
					}
				}
			}
		}
	}
	.platform-selector-container-out {
		width: 25%;
		padding: 0 24px 24px 24px;
		.select-filter-custom {
			width: 100%;
			margin-top: 0;
			.container {
				padding: 0 5px;
				border: 1px solid $border-color;
				justify-content: space-between;
				.option {
					.custom-label {
						font-size: $very-small-font-size;
						.logo {
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: 4px;
							}
						}
					}
				}
			}
		}
	}

	.categories-container {
		.categories-title {
			padding-left: 24px;
			padding-bottom: 24px;
			.title {
				font-size: $very-small-font-size;
				color: $color-light-black;
				@extend .font--semibold;
			}
			.items-count {
				color: $color-dark-grey;
				font-size: $exta-small-font-size;
			}
		}
	}
	.brand-catalogue--table {
		padding-bottom: 24px;
		.itemInfo {
			width: 40%;
			.itemInfo--container {
				width: 100%;
			}
			.item-info-container {
				display: flex;
				gap: 12px;
				.item-name {
					width: 60%;
				}
				.item-image {
					width: 64px;
					height: 64px;
					border-radius: 2px;
					object-fit: cover;
				}
				.foodtype {
					height: 12px;
					width: 12px;
					position: relative;
				}
				.veg {
					border: 2px solid #388e3c;
					&::after {
						position: absolute;
						height: 8px;
						width: 8px;
						content: "";
						top: 50%;
						left: 50%;
						border-radius: 50%;
						background-color: #388e3c;
						transform: translate(-50%, -50%);
					}
				}
				.non-veg {
					border: 2px solid #bf360c;
					&::after {
						position: absolute;
						height: 0px;
						width: 0px;
						content: "";
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						border-bottom: 8px solid #bf360c;
						top: 15%;
						left: 15%;
					}
				}
			}
		}
		.assocLocations {
			width: 15%;
		}
		.locationPlatforms {
			width: 30%;
			.platforms-list {
				display: flex;
				gap: 10px;
				position: relative;
				flex-wrap: wrap;
				width: 100%;
				.platform {
					position: relative;
					.platform-img {
						height: 28px;
						width: 28px;
						border-radius: 2px;
						object-fit: cover;
						border: 1px solid $mc-2;
					}
				}
				.actions-list--selected {
					background-color: $color-lightest-grey;
					svg {
						path {
							fill: $color-light-black;
						}
					}
				}
			}
		}
		.ilpaIds {
			width: 10%;
			padding-left: 0px;
			padding-right: 24px;
			.prices-link {
				margin-bottom: 10px;
			}
			.customise-link {
				width: 63px;
			}
			&--container {
				display: flex;
				flex-direction: column;
			}
		}
	}
	.single-plf-mode {
		.itemInfo {
			width: 90%;
		}
	}
	.category-navigation {
		position: fixed;
		bottom: 50px;
		right: 36%;
		transform: translateX(-50%);
		padding: 7px 20px;
		background-color: $color-green;
		border: 1px solid $color-green;
		border-radius: $default-radius;
		box-shadow: $default-shadow;
		text-transform: capitalize;
		z-index: 10;
		cursor: pointer;

		span {
			color: #fff;
			font-size: $very-small-font-size;
			font-weight: 600;
		}

		img {
			width: 15px;
			margin-right: 5px;
			vertical-align: bottom;
		}

		.categories-list {
			position: absolute;
			bottom: 125%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			box-shadow: $default-shadow;
			min-height: 150px;
			max-height: 300px;
			width: 150px;
			overflow-y: auto;
			color: $mc-7;
			padding: 10px 15px;
			font-size: $very-small-font-size;
			transition: 180ms;

			.category-list-item {
				position: relative;
				margin-bottom: 5px;

				&:hover {
					color: $mc-8;
				}

				&[data-active="true"]:before {
					content: " ";
					height: 6px;
					width: 6px;
					border-radius: 50%;
					background-color: $color-green;
					position: absolute;
					top: 50%;
					left: -7%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.brand-logs-tab {
	padding-bottom: 30px;
	.search-container {
		margin: 20px 0 30px 0;
		padding: 0 25px;
		.row {
			display: flex;
			align-items: center;
			gap: 12px;
			margin-bottom: 25px;
		}
		.at-input-container {
			width: 30%;
			margin-right: 35px;
		}
		.multi-select-filter-container {
			width: 38%;
			opacity: 1;
			&.status {
				width: 140px;
				.at--dropdown {
					width: 140px;
				}
			}
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
			.Select {
				.Select-control {
					border-radius: $border-radius-default;
				}
			}
			.custom-value {
				color: $mc-6;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				span {
					color: $color-neutral-black-90;
					margin-left: 2px;
				}
			}
		}
		.select-filter-custom {
			&.platform-filter,
			&.action-filter {
				width: 130px;
				.container {
					height: 35px;
				}
				.dropdown-custom {
					width: 130px;
				}
				.dropdown-component {
					width: 250px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}
			}
			&.brand-selector {
				margin: 0;
				.container {
					padding-top: 3px;
				}
				.dropdown-component {
					width: 329px;
					right: auto;
					left: 0;
					z-index: 160 !important;
				}
			}
		}
		.popover-container {
			top: 54px;
		}
		.dropdown-date-time-filter {
			width: 370px;
			.dropdown-custom {
				width: auto;
			}
			.actions-container {
				.at-btn {
					width: 67px;
				}
			}
		}
	}
	.custom-table-container {
		.table-header {
			top: 37px;
		}
		.actions {
			display: flex;
			align-items: flex-start;
			width: 30%;
			padding-left: 25px;
			img {
				height: 20px;
				margin-right: 10px;
				border-radius: $default-radius;
			}
			.date-time {
				color: $color-neutral-black-40;
				font-size: $exta-small-font-size;
				margin-top: 5px;
				text-transform: uppercase;
				@extend .font--semibold;
			}
		}
		.performed-by {
			width: 26%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.message {
			width: 30%;
			display: flex;
			align-items: flex-start !important;
			flex-direction: column;
			gap: 5px;
			.fix-errors {
				color: $color-blue;
				cursor: pointer;
				@extend .font--semibold;
			}
		}
		.status {
			flex-direction: row;
			width: 14%;
			padding-right: 25px;
			.log-status {
				width: fit-content;
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: $very-small-font-size;
				color: $mc-8;
				padding: 2px 8px;
				border-radius: $border-radius-default;
				&.ACK_SUCCESS {
					color: $color-green-70;
					background-color: $color-green-10;
				}
				&.ACK_FAILED {
					color: $color-red-7;
					background-color: $color-red-1;
				}
				&.VALIDATION_FAILED {
					color: $color-red-7;
					background-color: $color-red-1;
				}
				&.SUCCESS {
					color: $color-green-70;
					background-color: $color-green-10;
				}
				&.FAILED {
					color: $color-red-7;
					background-color: $color-red-1;
				}
				&.NA {
					color: $color-neutral-grey;
					background-color: rgba($color-neutral-grey, 0.1);
				}
			}
		}
	}
	.at-paginator {
		padding: 0 25px;
	}
	.publish-log-item {
		border: 1px solid $border-color;
		border-radius: $default-radius;
		font-size: 12px;
		color: $mc-7;
		margin-bottom: 25px;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

		.highlight {
			font-weight: 600;
		}
		.log-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px 24px;
			.log-action {
				display: flex;
				align-items: center;
				img {
					height: 20px;
					margin-right: 10px;
					border-radius: $default-radius;
				}
			}
			.log-status {
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: $very-small-font-size;
				color: $mc-8;
				padding: 2px 8px;
				border-radius: $border-radius-default;
				&.ACK_SUCCESS {
					color: $color-green;
					background-color: rgba($color-green, 0.1);
				}
				&.ACK_FAILED {
					color: $color-red;
					background-color: rgba($color-red, 0.1);
				}
				&.SUCCESS {
					color: $color-green;
					background-color: rgba($color-green, 0.1);
				}
				&.FAILED {
					color: $color-red;
					background-color: rgba($color-red, 0.1);
				}
				&.NA {
					color: $color-neutral-grey;
					background-color: rgba($color-neutral-grey, 0.1);
				}
			}
		}
		.log-result {
			border-top: 1px solid $border-color;
			padding: 16px 24px;
			.result-text {
				margin-bottom: 5px;
			}
			.ref-id {
				font-size: $exta-small-font-size;
				font-weight: $font--semibold;
				color: $mc-6;
				text-transform: uppercase;
			}
		}
		.log-timeline {
			padding: 10px;
			display: flex;
			align-items: center;
			img {
				height: 20px;
				margin-right: 10px;
			}
		}
	}
}
