.login-form-container {
	@include bp(768px) {
		& > .text {
			text-align: center;
		}
	}
	.subtitle {
		color: $mc-5;
		margin-top: 8px;
	}
	.login-form {
		margin-top: 30px;
		.required-label {
			color: $color-red;
			font-size: 12px;
		}
		.validation-message {
			margin-top: -1px;
		}
		.at-input-container {
			margin-bottom: 15px;
		}
		label.login-text-input {
			font-size: 15px;
			color: $mc-7;
			letter-spacing: 0px;
			padding-bottom: 8px;
		}
		input.login-text-input {
			margin-bottom: 5px;
			&::placeholder {
				color: #c3c3c3;
			}
		}
		.remember-me {
			margin-top: 10px;
			font-size: 13px;
			line-height: 17px;
			color: $mc-7;
			@extend .font--regular;
			.checkmark {
				height: 16px;
				width: 16px;
			}
			input:checked ~ .checkmark {
				background-color: $color-violet;
			}
		}
		.login-submit {
			display: block;
			margin-top: 16px;
			padding-left: 0;
			padding-right: 0;
		}
		.message {
			margin-top: 10px;
			color: $color-blue;
			text-align: center;
			cursor: pointer;
		}
		.login-modal-nav {
			color: $mc-6;
			.left-arrow {
				height: 10px;
				transition: 0.3s;
			}
			&:hover {
				.left-arrow {
					transform: translateX(-10px);
				}
			}
		}
		.forgot-pass {
			@include font-line-eq(12px);
			@extend .font--regular;
			text-align: right;
			color: $color-blue;
			cursor: pointer;
			position: absolute;
			top: 34px;
			right: 10px;
			z-index: 1;
			text-decoration: underline;
		}

		.resend-token {
			@extend .forgot-pass;
		}
		.divider {
			display: flex;
			width: 100%;
			text-align: center;
			margin: 10px 0;
			white-space: nowrap;
			box-sizing: border-box;
			span {
				font-size: $very-small-font-size;
				color: $mc-7;
				padding: 0 10px;
			}
			&::before {
				content: "";
				position: relative;
				top: 50%;
				width: 50%;
				border-top: 1px solid $border-color-dark;
				border-bottom: 0;
				transform: translateY(50%);
			}
			&::after {
				content: "";
				position: relative;
				top: 50%;
				width: 50%;
				border-top: 1px solid $border-color-dark;
				border-bottom: 0;
				transform: translateY(50%);
			}
		}
		.try-new-login {
			width: 100%;
		}
	}
	.reset-success {
		margin-top: 13px;
		background-color: white;
		padding: 15px;
		padding-left: 40px;
		border: 1px solid $mc-4;
		position: relative;
		.icon,
		.msg {
			display: inline-block;
			vertical-align: top;
		}
		.icon {
			width: 25px;
			position: absolute;
			top: 18px;
			left: 10px;
		}
		.msg {
			width: 210px;
			padding-left: 8px;
			font-size: 15px;
			@extend .font--regular;
			color: $mc-7;
		}
	}
	.login--warning {
		// border: 1px dashed $color-yellow;
		margin-top: 16px;
		color: #595334;
		padding: 15px;
		background-color: #f6e58d;
		font-size: $very-small-font-size;
		line-height: 1.5;
	}
}
