.dropdown-custom {
	position: relative;
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 8px;
		@extend .font--semibold;
	}
	.container {
		position: relative;
		border: 1px solid $border-color-dark;
		transition: 300ms;
		cursor: pointer;
		padding: 8px 15px;
		width: 250px;
		font-size: $small-font-size;
		background-color: $mc-1;
		border-radius: $default-radius;
		@include bp(768px) {
			width: auto;
			margin: 10px;
			padding: 8px 10px;
		}
		.dropdown-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: sticky;
			top: 0;
			right: 0px;
			svg {
				fill: hsl(0, 0%, 70%);
				width: 16px;
			}
			&:hover {
				svg {
					fill: hsl(0, 0%, 46%);
				}
			}
			&.is-open {
				svg {
					transform: rotate(180deg);
				}
			}
		}
		.dropdown-clear {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: sticky;
			top: 0;
			right: 0;
			margin-right: 7px;
			margin-left: auto;
			svg {
				fill: hsl(0, 0%, 70%);
				width: 16px;
			}
			&:hover {
				svg {
					fill: #d62a34;
				}
			}
		}
		.dropdown-arrow-zone {
			position: absolute;
			right: 15px;
			top: 53%;
			transform: translateY(-50%);
			position: absolute;
			border-color: #999 transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
		}
		&.is-open {
			border-color: $color-blue;
			.dropdown-arrow-zone {
				top: 47%;
				border-color: transparent transparent #999;
				border-width: 0 5px 5px;
			}
		}
	}
	.dropdown-component {
		position: absolute;
		right: 0;
		top: 120%;
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		box-shadow: 0 0 15px 0 rgba(51, 51, 51, 0.15);
		white-space: nowrap;
		background-color: white;
		z-index: $zI-level-1;
		box-sizing: border-box;
		@include bp(768px) {
			white-space: normal;
			margin: 0 10px;
		}
		// &:before {
		// 	content: "";
		// 	position: absolute;
		// 	top: -5px;
		// 	right: 130px;
		// 	border-color: transparent transparent #fff;
		// 	border-style: solid;
		// 	border-width: 0 5px 5px;
		// }
		& span {
			font-size: 12px;
		}
	}
}
