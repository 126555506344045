.array-of-object {
	.field-label {
		font-size: 12px;
		padding-top: 3px;
		padding-bottom: 5px;
		color: $mc-5;
	}
	.array-of-object-area {
		width: 100%;
		padding: 15px 5px;
		box-sizing: border-box;
		background-color: #f8f8f8;
		border-radius: $default-radius;
		border: 1px solid $border-color;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
		.object-fields-container {
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 5px;
			background-color: white;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: $default-radius;
			.object-field-item {
				margin-bottom: 10px;
			}
			.object-action-buttons {
				display: flex;
				justify-content: space-between;
				& > .at-btn {
					width: 45%;
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
}
