.periscope-container {
	.section-container-common-periscope {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px 24px 40px 24px;

		.header-location-availability {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title-location {
				font-weight: 600;
				font-size: 28px;
				display: flex;
				align-items: center;
			}

			.item-share-info {
				display: flex;
				gap: 12px;
				align-items: center;
				font-family: Source Sans Pro;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;

				.fetch-time {
					color: #8a8a8a;
				}

				.refresh-btn {
					border: 1px solid #eaeaea;
					padding: 8px 16px 8px 12px;
					border-radius: 2px;
					display: flex;
					align-items: center;
					cursor: pointer;

					.refresh-img {
						margin: 0 5px 0 0;
					}

					.refresh-text {
						color: #363636;
					}
				}

				.share-report {
					padding: 8px 16px 8px 12px;
					display: flex;
					gap: 8px;
					background: #3b5feb;

					.share-text {
						color: white;
					}
				}
			}
		}

		.filters-location {
			display: flex;
			justify-content: space-between;

			.left-filters-location {
				display: flex;
				gap: 8px;

				.city-filter {
					span {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						text-align: left;
						color: #363636;
					}
				}
			}
		}

		.location-platforms-table {
			.location-data-icons {
				text-align: right;
				padding: 16px 24px;
				text-align: right;
			}

			.offline-table-icon {
				width: 16px;
				height: 16px;
			}

			.partiallyOffline-table-icon {
				width: 5px;
				height: 22px;
			}

			.online-table-icon {
				width: 12.28px;
				height: 9.41px;
			}
		}

		.align-center {
			display: flex;
			align-items: center;
		}

		.inside-text-location {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}

		.locationcell-display {
			max-width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.location-display-main {
			display: flex;
			gap: 8px;
		}

		.icon-platforms {
			margin: 0px 8px 0px 0px;
		}

		.sort-arrows-location {
			display: flex;
			flex-direction: column;
			margin: 0px 8px;
			justify-content: center;
		}

		.header-text-location {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			color: #8a8a8a;
		}

		.brand-cell {
			text-align: left;
			display: flex;
			align-items: center;

			.brand-text-width {
				max-width: 120px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.image-info {
				margin: 20px 12px 20px 16px;
			}
		}

		.brand-image-location,
		.icon-platforms {
			height: 20px;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.rating-text-location {
			font-family: Source Sans Pro;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			text-align: left;
			color: #8a8a8a;
			display: flex;
			align-items: center;
			margin-left: 8px;
			margin-top: 1px;
		}

		.star-image-location {
			width: 8.45px;
			height: 8.02px;
		}

		.paginator-location {
			padding: 0px 25px 10px 25px;
		}
	}

	.border-black {
		background-color: #ffffff;
		border: 1px solid #eaeaea;
		border-radius: 2px;
	}

	.item-availability-filters {
		.custom-label,
		.item-status {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
		}

		span {
			.item-status {
				padding: 0 8px;
			}
		}
	}

	.text-right {
		text-align: right;
	}

	.location-main-graph {
		padding: 12px 16px 18px 16px;

		.location-status {
			height: 12px;
			margin: 12px 0 0 5px;
		}

		.location-status-div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.shimmer {
				border-radius: 0px;
			}

			.location-info-status {
				display: flex;
				gap: 16px;

				.loc-status-data {
					display: flex;
					flex-direction: column;

					.percent-display-loc,
					.text-status {
						user-select: none;
					}
				}

				.location-status-percent {
					display: flex;
					padding: 8px 10px;
					border-radius: 4px;

					.text-status {
						display: flex;
						align-items: center;
						justify-content: center;
						color: #646464;
						gap: 4px;
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						.popover-wrapper-container.down-left .popover-container {
							left: -21px;
						}
						.popover-wrapper-container .popover-container {
							position: absolute;
							top: 29px;
							.pop-info {
								padding: 12px;
								width: 270px;
								.main-title {
									font-size: 14px;
									font-weight: 600;
									line-height: 22px;
									text-align: left;
								}
								color: #363636;
								.info-desc {
									font-size: 14px;
									font-weight: 400;
									line-height: 22px;
									text-align: left;
								}
							}
						}
						.title-desc {
							display: flex;
							flex-direction: column;
							padding: 12px;
						}

						.graph-image-indicator {
							img {
								width: 10px;
								height: 10px;
							}
						}
					}

					.info-status-color {
						width: 16px;
						height: 16px;
						border-radius: 2px;
						margin: 2px 12px 0 0;
					}
				}

				.location-status-percent:hover {
					background-color: #eaeaea;
					cursor: pointer;
				}

				.selected-status {
					background-color: #eaeaea;
				}
			}

			.percent-display-loc {
				font-family: Source Sans Pro;
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				text-align: left;
				color: #363636;
			}

			.locations-total {
				display: flex;
				flex-direction: column;
				gap: 4px;

				.total-locations {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #646464;
				}
			}
		}

		.brown {
			background: #b02b2b;
		}

		.orange {
			background: #fd9d53;
		}

		.blue {
			background: #c5d1fc;
		}

		.space-middle {
			margin: 0 5px;
		}

		.loading-stackedbar {
			width: 33%;

			.shimmer {
				height: 12px;
				width: 100%;
				border-radius: 0px;
			}
		}
	}

	.tooltip-location {
		position: relative;
		display: inline-block;
		width: 100%;

		.tooltiptext-location {
			visibility: hidden;
			background-color: white;
			color: black;
			text-align: center;
			border-radius: 6px;
			position: absolute;
			z-index: 1;
			right: 50%;
			transform: translateX(50%);
			display: flex;
			flex-direction: column;
			padding: 8px 12px;
		}
	}

	.tooltip-location .tooltiptext-location::after {
		content: "";
		position: absolute;
		bottom: 100%;
		transform: translateX(-50%);
		left: 50%;
		border-width: 7px;
		border-style: solid;
		border-color: transparent transparent white transparent;
	}

	.tooltip-location:hover .tooltiptext-location {
		visibility: visible;
	}

	.tooltiptext-location {
		.tooltip-description-location {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
		}
	}

	.offline-color {
		background: #fff5f5;
	}

	.offline-color:hover {
		background: #ffe5e5;
	}

	.online-color {
		background: #ffffff;
	}

	.online-color:hover {
		background: #f6fbf8;
	}

	.table-div-locations {
		.info-popover {
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			color: #363636;
			padding: 8px 12px;
			width: fit-content;
			text-wrap: nowrap;
		}
		.online-black-image {
			display: flex;
		}

		.online-color:hover .online-black-image {
			display: none;
		}

		.online-green-image {
			width: 12.28px;
			height: 9.41px;
			display: none;
		}

		.online-color:hover .online-green-image {
			display: flex;
			transform: translate(0.5px, 0px);
		}
	}

	.partial-color:hover {
		background: #f5f5f5;
	}

	.sample-table {
		border: 1px solid #eaeaea;
	}

	.table-div-locations {
		.location-availability-thead {
			.zero-index {
				padding: 16px 16px 16px 24px;
				min-width: 260px;
			}

			.brand-location-availability {
				min-width: 192px;
				max-width: 193px;
				border-right: 1px solid #eaeaea;
				padding: 16px;
				text-align: left;
			}

			.location-cell {
				text-align: left;
			}
			.table-heading-platforms {
				text-transform: capitalize;
			}
		}

		.location-availability-tbody {
			border-bottom: 1px solid #eaeaea;
			.brand-cell {
				padding: 0 16px 0 0;
			}
		}

		.star-image-location {
			margin: 0 4px;
		}

		.brand-image-location {
			padding: 0px 1.5px 1.5px 1.5px;
			border-radius: 2px;
		}

		.shimmer {
			border-radius: 0;
		}

		.display-location-loading {
			width: 80%;
			height: 100%;
		}

		.display-brand-loading {
			display: flex;
			gap: 16px;

			.brand-img-loading {
				width: 20px;
				height: 20px;
			}

			.brand-text-loading {
				width: 50%;
			}
		}

		.display-platform-loading {
			padding: 16px 24px;

			.shimmer-container {
				width: 65%;
				min-width: 80px;
				margin: 0 0 auto auto;
			}
		}
	}

	.sample-table {
		.error-state {
			position: absolute;
			top: 10%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
			background-color: #ffffff;

			.load-chart-div {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 24px;
				gap: 16px;

				.load-chart-error-icon {
					width: 97.5px;
					height: 97.5px;
				}

				.load-chart-info {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.chart-info {
						display: flex;
						flex-direction: column;

						.chart-main-text {
							font-family: Source Sans Pro;
							font-size: 18px;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
							color: #666666;
						}

						.chart-para-info {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #979797;
						}
					}

					.try-again {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 4px;
						cursor: pointer;

						.load-again-img {
							width: 13.33px;
							height: 13.33px;
						}

						.try-again-text {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 600;
							line-height: 20px;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.location-availability-page {
		.stop-moving .shimmer::after {
			background: #f4f4f4 !important;
		}
		.location {
			.popover-wrapper-container.down-left .popover-container {
				right: auto;
				left: -19px;
			}

			.popover-wrapper-container .popover-container {
				position: absolute;
				top: 34px;
			}
		}
		.brand {
			.popover-wrapper-container.down-left .popover-container {
				right: auto;
				left: 32px;
			}
			.popover-wrapper-container .popover-container {
				position: absolute;
				top: 52px;
				right: 0;
				background-color: #fff;
				box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
				border-radius: 4px;
				min-width: 150px;
				min-height: 30px;
				box-sizing: border-box;
				z-index: 50;
			}
		}
	}

	.search-filter {
		margin-right: 0;
	}
}
