.settings-section {
	background-color: #fff;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

.settings-header {
	padding: 15px 25px 15px 25px;
	border-bottom: 1px solid $border-color;
	&.no-border {
		border: none;
	}
	@include bp(768px) {
		padding: 10px;
	}
	.header-text {
		font-size: $large-font-size;
		margin-bottom: 5px;
		@extend .font--semibold;
	}
	.subheader-text {
		font-size: $small-font-size;
		color: $mc-6;
	}
}

.settings-types-container {
	padding: 25px;
	@include bp(768px) {
		padding: 15px 10px;
	}
	.meta-info {
		font-size: $very-small-font-size;
		color: $mc-7;
	}
	.settings-types {
		.settings-types-item {
			display: inline-block;
			vertical-align: top;
			width: 60px;
			margin-right: 25px;
			a {
				text-decoration: none;
			}
			img {
				display: block;
				margin: 0 auto 5px auto;
				height: 48px;
			}
			.title {
				font-size: $very-small-font-size;
				color: $mc-7;
				text-align: center;
				transition: 300ms;
			}
			&:hover {
				.title {
					color: $mc-9;
				}
			}
		}
	}
}

.billing-section,
.user-management-section,
.unified-user-management-section,
.api-access-management-section {
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.actions {
			display: flex;
			.at-btn {
				margin-left: 10px;
			}
			.help-btn-container {
				.at-btn--secondary {
					padding: 0 14px;
					svg {
						position: relative;
						// top: 3px;
					}
				}
			}
		}
	}
	.form-sidebar-container {
		.drawer-container {
			.settings-header {
				display: unset;
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		&.wrapper-sticky-top {
			z-index: 100;
		}
	}
}

.api-access-management-section {
	.transactions-list-table {
		margin-top: 15px;
	}
	.header-actions--api-list {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
		.archive-active-filter-container {
			width: 200px;
		}
		.filter-buttons--api-list {
			display: flex;
		}
	}
}
