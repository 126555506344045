.important-items {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 24px 84px 24px;

	gap: 16px;
	.image-painter {
		width: 191px;
		height: 215px;
		margin-top: 60px;
	}
	.important-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		color: #666666;
		.imp-description-info {
			display: flex;
			align-items: baseline;
		}
		.label-img {
			height: 11.17px;
			width: 14.83px;
			margin: 0 4px;
		}
		.imp-items-data {
			font-family: Source Sans Pro;
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;
			text-align: left;
		}
	}
	.clickable-buttons {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.btn-all-items {
			padding: 6px 16px 6px 16px;
			border: 1px solid #eaeaea;
			text-align: center;
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			color: #363636;
			margin: 16px 0;
			cursor: pointer;
		}
		.end-tag {
			display: flex;
			gap: 4px;
			justify-content: center;
			align-items: center;

			.questions {
				width: 13px;
				height: 13px;
			}
			.learn-more-imp {
				font-family: Source Sans Pro;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				text-align: center;
				color: #2f58f2;
			}
		}
	}
}
