.dsp-integration-container {
	&.section-container-common {
		padding-bottom: 0;
	}

	.credits-section-header {
		.header-text {
			width: 50%;
		}

		.header-action-button {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 10px;
			width: 50%;
		}
	}

	.select-filter-custom {
		flex-grow: 1;
		max-width: 250px;

		.dropdown-custom {
			width: 100%;
			max-width: 250px;

			.container {
				height: 32px;
				padding: 1px 10px;
			}

			.dropdown-component {
				z-index: 200;
			}

			.dropdown-options {
				.option {
					height: 37px;
					padding: 1px 10px;
				}
			}

			.custom-option,
			.custom-label {
				display: flex;
				align-items: center;

				.logo {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 8px;
					width: 22px;
					height: 22px;
					text-transform: uppercase;
					font-size: $very-small-font-size;
					border-radius: $default-radius;
					@extend .font--semibold;

					img {
						width: 22px;
						height: 22px;
						object-fit: cover;
						border-radius: $default-radius;
					}

					&.brown {
						color: $color-brown-dark;
						background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
					}

					&.blue {
						color: $color-blue-dark;
						background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
					}

					&.green {
						color: $color-green-dark;
						background-color: rgba($color: $color-green-dark, $alpha: 0.1);
					}

					&.red {
						color: $color-red-dark;
						background-color: rgba($color: $color-red-dark, $alpha: 0.1);
					}

					&.purple {
						color: $color-violet;
						background-color: rgba($color: $color-violet, $alpha: 0.1);
					}
				}
			}
		}
	}

	.dsp-authorization {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 95vh;
		padding: 15px;
		box-sizing: border-box;

		.card-container {
			padding: 25px;
			max-width: 370px;
			width: 100%;
			border: 1px solid $border-color;
			border-radius: $border-radius-default;

			.header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.platforms {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-bottom: 25px;

					.platform {
						display: flex;
						align-items: center;

						img {
							width: 56px;
							border-radius: $border-radius-default;
						}
					}

					.integrate {
						img {
							width: 20px;
						}
					}
				}

				.status-icon {
					margin-bottom: 15px;

					img {
						width: 60px;
					}
				}

				.loader-backdrop {
					position: relative;
					background: none;
				}

				.header-text {
					font-size: $medium-large-font-size;
					color: $color-neutral-black-90;
					margin-bottom: 10px;
					@extend .font--semibold;
				}

				.header-sub-text {
					font-size: $very-small-font-size;
					color: $color-dark-grey;
					margin-bottom: 25px;
					text-align: center;
					line-height: 1.6;
				}
			}

			.content {
				padding: 25px 0;
				border-top: 1px solid $border-color;

				.description {
					color: $color-black-60;
					font-size: $very-small-font-size;
					margin-bottom: 20px;
					line-height: 1.6;
				}

				.benifits {
					display: flex;
					flex-direction: column;
					gap: 20px;

					.point {
						display: flex;
						align-items: flex-start;
						gap: 10px;

						.text {
							color: $color-black-80;
							font-size: $very-small-font-size;
						}
					}
				}

				span {
					text-transform: capitalize;
				}
			}

			.footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 10px;

				&.right {
					justify-content: flex-end;
				}

				&.center {
					justify-content: center;
				}

				.footer-sub-text {
					width: 100%;
					font-size: $very-small-font-size;
					color: $color-dark-grey;
					text-align: center;
				}
				.disabled {
					opacity: 0.5;
					pointer-events: none;
					cursor: default;
				}
			}
		}

		&.card-status-success,
		&.card-status-failed,
		&.card-status-loading {
			.card-container {
				.header {
					.header-text {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.modal-container {
		width: 80%;
		max-width: 550px;

		.modal-header {
			padding: 15px 25px;
			border-bottom: 1px solid $border-color;

			.header-left {
				flex-grow: 0;

				.title {
					margin-bottom: 0;
					font-size: $medium-font-size;
					color: $color-black-80;
				}
			}
		}

		.modal-content {
			padding: 25px;
			box-sizing: border-box;
			min-height: 60px;
			color: $color-black-80;
			.urlModal {
				.status-info-wrapper {
					margin-bottom: 15px;
				}
				.status-alert-container {
					margin-top: 0px;
					.main-desc {
						font-family: Source Sans Pro;
						font-size: $very-small-font-size;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
					}
				}
				.modal-desc {
					font-family: Source Sans Pro;
					font-size: $very-small-font-size;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
				}
			}
		}

		.modal-footer {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px 25px;
			border-top: 1px solid $border-color;
		}
	}

	.dsp-brand-selection {
		min-height: 95vh;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 25px;
			margin-bottom: 15px;

			.header-text {
				color: $color-black-60;
				font-size: $medium-font-size;
				@extend .font--semibold;
			}

			.search-filter {
				width: 250px;
				margin-right: 0;

				.at-input-container {
					width: 100%;
				}
			}
		}

		.brands {
			padding: 0 25px;
			display: flex;
			// justify-content: center;
			flex-wrap: wrap;
			gap: 30px;
			margin-bottom: 30px;

			.no-items-placeholder {
				width: 100%;
			}
		}

		.brand-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 140px;
			height: 170px;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			// margin-right: 40px;
			// margin-bottom: 30px;
			font-size: $very-small-font-size;
			transition: 180ms;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				scale: 1.05;
			}

			&.selected {
				border-color: $color-blue;
				scale: 1.05;
				box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.2);

				.brand-name {
					color: $color-blue;
				}
			}

			.at-radiobutton {
				width: 20px;
				height: 20px;
				padding: 0;
				z-index: 10;
				position: absolute;
				top: 14px;
				right: 14px;

				.checkmark {
					width: 18px;
					height: 18px;
					border-radius: 50%;
					background-color: rgba($color: $mc-1, $alpha: 0.5);

					&::after {
						top: 2px;
						left: 2px;
						width: 10px;
						height: 10px;
					}
				}

				input:checked ~ .checkmark {
					background-color: $mc-1;
				}
			}

			.brand-initials {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 100px;
				text-transform: uppercase;
				font-size: 42px;
				border-radius: $default-radius;
				@extend .font--semibold;

				&.brown {
					color: $color-brown-dark;
					background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
				}

				&.blue {
					color: $color-blue-dark;
					background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
				}

				&.green {
					color: $color-green-dark;
					background-color: rgba($color: $color-green-dark, $alpha: 0.1);
				}

				&.red {
					color: $color-red-dark;
					background-color: rgba($color: $color-red-dark, $alpha: 0.1);
				}

				&.purple {
					color: $color-violet;
					background-color: rgba($color: $color-violet, $alpha: 0.1);
				}

				img {
					width: 100px;
					height: 100px;
					border-radius: $default-radius;
					object-fit: cover;
				}
			}

			.brand-name {
				text-align: center;
				margin-top: 15px;
				text-transform: capitalize;
				width: 100px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	.wrapper-dsp {
		display: flex;
		justify-content: space-around;
		height: auto;
		min-height: 70vh;

		.dsp-locations-none {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			width: 350px;
			height: 344px;
			overflow: visible;
			padding: 0px 24px 0px 24px;

			.header-text {
				font-family: Source Sans Pro;
				font-size: $medium-font-size;
				font-weight: 600;
				line-height: 24px;
				text-align: left;
			}
			.no-items-placeholder {
				width: Fill (352px) px;
				height: Hug (76px) px;
				gap: 8px;
				padding: 0px;
				font-family: Source Sans Pro;
				font-size: $very-small-font-size;
				font-weight: 400;
				line-height: 22px;
				text-align: center;
			}
		}
	}

	.dsp-locations {
		.action {
			font-size: $very-small-font-size;
			color: $color-blue;
			padding: 8px 16px;
			cursor: pointer;
			@extend .font--semibold;
		}

		.status-alert-container {
			display: flex;
			align-items: center;
			margin: 25px 0;

			.error-icon {
				height: 24px;
			}
			svg {
				width: 22px;
				height: 22px;
			}

			.main-error-desc {
				margin: 0;
			}
		}

		.publish-store-activation-status {
			min-height: 95vh;
			max-width: 600px;
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 25px;

			.header {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.status-icon {
					margin-bottom: 15px;
				}
				.status-icon-dsp {
					margin-bottom: 15px;
					img,
					svg {
						width: 85px;
						height: 85px;
					}
				}

				.header-text {
					font-size: $medium-large-font-size;
					color: $color-neutral-black-90;
					margin-bottom: 15px;
					@extend .font--semibold;
				}

				.header-sub-text {
					max-width: 522px;
					font-size: $very-small-font-size;
					color: $color-dark-grey;
					margin-bottom: 20px;
					text-align: left;
					line-height: 1.3;
				}
				.benifits {
					display: flex;
					flex-direction: column;
					gap: 20px;
					text-align: left;
					max-width: 522px;
					font-size: $very-small-font-size;
					color: $color-dark-grey;
					margin-bottom: 20px;
					line-height: 1.3;

					.point {
						display: flex;
						align-items: center;
						gap: 10px;
						.img {
							width: 16px;
							display: flex;
							align-items: center;
							justify-content: space-around;
						}
						.text {
							color: $color-black-80;
							font-size: $very-small-font-size;
						}
					}
				}
			}
			.footer {
				width: Hug (211px) px;
				height: Hug (40px) px;
				padding: 8px 16px 8px 16px;
				gap: 8px;
				border-radius: 2px;
			}
			.redirect {
				img {
					color: #363636;
					padding: 0px 5px;
				}
				margin-top: 5px;
				font-family: Source Sans Pro;
				font-size: $exta-small-font-size;
				font-weight: 600;
				line-height: 16px;
				text-align: left;
				color: #363636;
			}
			.status-alert-container {
				padding: 15px;
				margin: 24px 0;
				display: flex;
				align-items: center;
				border: 1px solid $border-color;
				border-radius: $border-radius-default;
				gap: 12px;
				.error-text-link {
					color: $color-dark-grey;
					.main-error-header {
						font-size: $medium-font-size;
						font-weight: $font--semibold;
						margin-bottom: 4px;
					}
					.main-error-desc {
						font-size: $very-small-font-size;
						margin-bottom: 12px;
					}
					.hyperlink {
						display: flex;
						align-items: center;
						gap: 4px;
						img {
							width: 16px;
							height: 16px;
						}
					}
				}
			}

			.activation-status {
				width: 100%;
				max-width: 600px;
				border: 1px solid $mc-3;
				margin-bottom: 30px;

				.title {
					padding: 24px;
					font-size: $small-font-size;
					color: $color-neutral-black-80;
					background-color: $color-neutral-black-05;
					border-bottom: 1px solid $mc-3;
					@extend .font--semibold;
				}

				.status-wrapper {
					padding: 24px;
					display: flex;
					flex-direction: column;
					gap: 24px;

					.status {
						display: flex;

						& > svg {
							position: relative;
							// top: 1px;
							width: 16px;
							height: 16px;
						}

						.loader-circular {
							position: relative;
							top: 1px;
							width: 16px;
							height: 16px;
						}

						.type {
							font-size: $very-small-font-size;
							margin-left: 10px;

							.name {
								color: $color-neutral-black-80;
								@extend .font--semibold;
							}

							.count {
								width: fit-content;
								color: $color-neutral-black-50;
								margin-top: 5px;

								&.link {
									position: relative;
									color: $color-blue-40;
									cursor: pointer;

									&::after {
										content: "";
										position: absolute;
										width: 100%;
										transform: scaleX(0);
										height: 1px;
										bottom: 0;
										left: 0;
										background-color: $color-blue-40;
										transform-origin: bottom right;
										transition: transform 0.25s ease-out;
									}

									&:hover::after {
										transform: scaleX(1);
										transform-origin: bottom left;
									}
								}
							}
						}
					}
				}
			}

			.footer {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
			}
		}

		.dsp-location-container {
			display: flex;
			flex-direction: column;
			min-height: 95vh;
			padding-top: 24px;
			.at-breadcrumbs-container {
				padding: 0 24px;
			}
			.form-content {
				padding: 0 25px 30px 25px;
				margin-bottom: auto;

				@include bp(768px) {
					padding: 0 15px 20px 15px;
				}
				.locations-status {
					margin: 25px 0px;
					color: $color-dark-grey;
					font-size: $small-font-size;
					line-height: 20px;
					@extend .font--semibold;
				}

				.locations-table-container {
					margin-top: 25px;
					margin-bottom: 15px;
					.text-light {
						opacity: 0.4;
					}

					.table-header {
						span {
							opacity: 1;
						}

						.platform {
							display: flex;
							gap: 5px;
						}

						.logo {
							width: 20px;
							margin: 0;
							border-radius: $border-radius-default;
						}

						.name {
							opacity: 0.6;
						}

						.table-cell {
							display: flex;
						}
					}
					.table-list {
						.table-cell {
							display: flex;
						}
						.menu-img {
							height: 16px;
							width: 26px;
							left: -2px;
							gap: 2.34px;
							border-radius: 0.59px;
							box-sizing: border-box;
						}
					}
					.popover-container {
						max-width: 300px;
						width: max-content;
						top: 55px;
						right: 0;
						&::before {
							right: 17px;
						}
						&::after {
							right: 13px;
						}
						.popover-info {
							padding: 12px;
							color: $mc-7;
							font-size: $very-small-font-size;
							.popover-header {
								font-weight: 600;
							}
							.popover-message {
								margin-top: 7px;
							}
						}
						.popover-id {
							padding: 12px;
							color: $mc-7;
							font-size: $very-small-font-size;
							display: flex;
							flex-direction: row;
							align-items: center;
							.popover-header {
								font-weight: 600;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.popover-message {
								margin-top: 7px;
							}
						}
					}
				}
				.failure-alert-box {
					padding: 15px;
					margin: 10px 0 20px 0;
					display: flex;
					align-items: flex-start;
					border: 1px solid $border-color;
					border-radius: $border-radius-default;
					gap: 12px;
					.error-text-link {
						color: $color-dark-grey;
						.main-error-header {
							font-size: $medium-font-size;
							font-weight: $font--semibold;
							margin-bottom: 4px;
						}
						.main-error-desc {
							font-size: $very-small-font-size;
						}
						.hyperlink {
							display: flex;
							align-items: center;
							gap: 4px;
							img {
								width: 16px;
								height: 16px;
							}
						}
					}
				}

				.menu-edit-container {
					.item-edit-container {
						.form-container {
							.at-top-bar-wrapper-v2 {
								overflow: hidden;
							}
						}
					}
					.footer {
						border-top: 1px solid $border-color;
						border-bottom-left-radius: $default-radius;
						border-bottom-right-radius: $default-radius;
						padding: 20px 25px;
						background: white;
						box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
						z-index: 10;
						justify-content: flex-start;
						animation: slideUp 180ms;
						transition: 180ms ease-in;
						position: sticky;
						bottom: 0;
						&.exception {
							margin-left: 25%;
							border-left: 1px solid $border-color;
						}
					}
				}
				.locations-table-container.no-top-margin {
					margin-top: 0;
				}
			}
			.form-content.menuFix {
				height: 100%;
				margin-bottom: auto;
				.status-alert-container {
					margin-top: 0px;
				}
				.status-info-wrapper {
					margin-bottom: 20px;
				}
				.locations-table-container {
					margin-top: 0;
					.table-list {
						align-items: center;
						display: flex;
						.chevron {
							top: 25px;
						}
					}
					.menu-display {
						display: flex;
						justify-content: flex-start;

						align-items: center;

						.menu-detail {
							display: flex;

							width: 60%;
							.menu-name {
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							.text-light {
								opacity: 0.55;
							}
							img {
								padding-right: 10px;
							}

							&.with-actions {
								padding-right: 75px;
							}
						}
					}
				}
				.at-btn-secondry {
					margin: 0;
				}
			}

			.footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				border-top: 1px solid $border-color;
				border-bottom-left-radius: $default-radius;
				border-bottom-right-radius: $default-radius;
				padding: 20px 25px;
				background: white;
				box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
				z-index: 10;
				animation: slideUp 180ms;
				transition: 180ms ease-in;
				position: sticky;
				bottom: 0;
			}
		}

		.progress-bar-display {
			.info {
				padding: 10px 25px;

				.title {
					color: $color-neutral-black-80;
					font-size: $large-font-size;
				}

				.actions {
					display: flex;
					align-items: center;
					gap: 10px;
				}
			}
		}

		.header-action-button {
			.at-btn {
				img {
					top: 3px;
				}
			}
		}
		.filters-container {
			margin-top: 10px;
			margin-bottom: 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 10px;

			.filters-left {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 10px;

				.select-filter-custom {
					width: 250px;
				}
			}

			.multi-select-filter-container {
				width: 200px;

				.custom-value {
					color: $color-neutral-black-light;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					span {
						color: $color-neutral-black-dark;
						margin-left: 2px;
						@extend .font--semibold;
					}
				}
			}

			.search-filter {
				width: 250px;
				margin-right: 0;

				.at-input-container {
					width: 100%;
				}
			}
		}

		.locations-status-count {
			margin-top: 25px;
			color: $color-dark-grey;
			font-size: $small-font-size;
			line-height: 20px;
			@extend .font--semibold;
			.container {
				width: 100%;
				height: auto;
				box-sizing: border-box;
			}
			.header {
				color: #666;
				margin-bottom: 10px;
			}
			.status-overview {
				display: flex;
				justify-content: space-between;
				border-top: 1px solid #e0e0e0;
				width: auto;
				height: 72px;
				border-radius: 4px;
			}

			.status-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 24px 0px 24px 0px;
				gap: 0px;
				border-radius: 2px;
				flex: 1;
				padding: 10px 10px;
			}
			.status-item:not(:last-child) {
				border-right: 1px solid #e0e0e0;
			}
			.status-title {
				color: #666;
			}
			.status-value {
				color: #666;
			}
		}

		.locations-table-container {
			margin-top: 25px;
			margin-bottom: 15px;
			.popover-container {
				max-width: 300px;
				width: max-content;
				top: 55px;
				right: 0;
				&::before {
					right: 17px;
				}
				&::after {
					right: 13px;
				}
				.popover-info {
					padding: 12px;
					color: $mc-7;
					font-size: $very-small-font-size;
					.popover-header {
						font-weight: 600;
					}
					.popover-message {
						margin-top: 7px;
					}
				}
				.popover-id {
					padding: 12px;
					color: $mc-7;
					font-size: $very-small-font-size;
					display: flex;
					flex-direction: row;
					align-items: center;
					.popover-header {
						font-weight: 600;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.popover-message {
						margin-top: 7px;
					}
				}
			}
			.table-header {
				span {
					opacity: 1;
				}

				.platform {
					display: flex;
					align-items: center;
					gap: 5px;
				}

				.logo {
					width: 20px;
					margin: 0;
					border-radius: $border-radius-default;
				}

				.name {
					opacity: 0.6;
				}

				.table-cell {
					display: flex;
					align-items: center;
				}
			}

			.table-list {
				align-items: center;
				display: flex;
				.table-cell {
					display: flex;
					align-items: center;

					.multi-select-filter-container {
						width: 100%;
					}

					&.up-stores {
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						gap: 5px;
					}
					&.dsp-stores {
						width: 25%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						.dsp-address {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							padding-top: 5px;
							line-clamp: 2;
							color: #979797;
							overflow: hidden;
							font-family: Source Sans Pro;
							white-space: wrap;
							text-overflow: ellipsis;
							font-size: $exta-small-font-size;
							line-height: 1.2;
							max-height: 3em;
						}
						.dsp-ssioId {
							color: #979797;
							font-family: Source Sans Pro;
							font-size: 11px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						// padding-left: 25px;
					}
				}
			}

			.dsp-stores {
				width: 30%;
			}

			.up-stores {
				width: 30%;

				.description {
					color: $mc-6;
				}

				.multi-select-filter-container {
					.react-select__option {
						padding: 0;
						cursor: default;

						.custom-option {
							padding: 12px 8px;
							cursor: pointer;

							&.disabled {
								opacity: 1;
								color: #cccccc;
								background-color: $mc-1;
								pointer-events: all;
								cursor: default;
							}
						}

						&.react-select__option--is-selected {
							.custom-option {
								&.disabled {
									color: #333333;
									background-color: $mc-3;
									cursor: pointer;
								}
							}
						}
					}

					&.null {
						.react-select__control {
							background-color: $mc-1;

							&:hover {
								background-color: $mc-1;
							}
						}
					}

					.validation-message {
						position: relative;
					}
				}
			}

			.menu {
				width: 25%;

				&.with-actions {
					padding-right: 75px;
				}

				.multi-select-filter-container {
					.create-menu {
						padding: 12px 10px;
						display: flex;
						align-items: center;
						gap: 6px;
						color: $color-blue;
						cursor: pointer;
						@extend .font--semibold;
					}
				}
			}

			.menu-display {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 20%;
				div.menu-detail {
					display: flex;
					align-items: center;
					width: 80%;
					div.menu-name {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.text-light {
						opacity: 0.55;
					}
					img {
						padding-right: 10px;
					}

					&.with-actions {
						padding-right: 75px;
					}
				}
			}

			.up-stores-non-dsp {
				width: 25%;
				.store {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.store-platform-url {
				width: 50%;
				display: flex;
				align-items: center;

				.at-input-container {
					width: 100%;
					.at-input {
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
						border-right: 0px;
					}
				}
				a img {
					width: 30px;
					height: 34px;
					box-sizing: border-box;
					padding: 10px 5px;
					opacity: 0.7;
					display: flex;
					align-items: center;
					cursor: pointer;
					background-color: $mc-1;
					border: $mc-4 1px solid;
					border-radius: 0px 2px 2px 0px;
					opacity: 1;
					margin: auto 0;
				}
			}

			.store-platform-url-display {
				width: 23%;

				.url {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
			.store-platform-id-display {
				width: 17%;

				.id {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.store-platform-id {
				width: 25%;
			}

			.status {
				width: 15%;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				// padding-right: 25px;
				.tag {
					padding: 5px 10px;
					width: fit-content;
					font-size: $very-small-font-size;
					border-radius: $border-radius-default;
					text-transform: capitalize;
					@extend .font--semibold;

					&.unmapped {
						color: $color-neutral-black-90;
						background-color: $color-neutral-black-10;
					}

					&.mapped {
						color: $color-yellow-dark;
						background-color: $color-yellow-10;
					}

					&.active,
					&.success {
						color: $color-green-dark;
						background-color: $color-green-10;
					}

					&.failed {
						color: $color-red-dark;
						background-color: $color-red-1;
					}
				}
			}

			.context-menu {
				padding: 5px 1px;
				border-radius: $border-radius-default;

				// &:hover, &.open {
				// 	background: $color-neutral-black-05;
				// 	outline: 1px solid $mc-3;
				// 	svg {
				// 		path {
				// 			fill: $color-neutral-black-90;
				// 		}
				// 	}
				// }
				.menu {
					top: 28px;
				}
			}
		}

		.at-paginator {
			padding: 0;
		}

		.overlay-sidebar-container {
			.form-sidebar-container {
				.form-container {
					.filters-container {
						margin-top: 0;
						margin-bottom: 10px;
					}

					.retry-all {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						margin-top: 25px;
						color: $color-blue;
						font-size: $very-small-font-size;
						cursor: pointer;
						@extend .font--semibold;

						img {
							width: 20px;
							height: 20px;
							margin-right: 5px;
						}
					}

					.custom-table-container {
						margin-top: 0%;

						.table-header {
							top: 0px;
							z-index: 50;
						}

						.status {
							width: 5%;
						}

						.multi-select-filter-container {
							max-width: 200px;
						}

						.popover-wrapper-container {
							margin-left: 10px;

							&.down-right {
								.popover-container {
									top: 30px;
									left: auto;
									right: -22px;
									width: max-content;
									max-width: 400px;
									z-index: 60;
									box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);

									&::before {
										right: 20px;
										z-index: -10;
									}

									&::after {
										right: 16px;
										display: none;
									}
									.popover-info {
										display: flex;
										flex-direction: row;
									}
								}
							}

							.popover-container {
								.popover-header {
									border-radius: $default-radius;
									box-sizing: border-box;
									font-size: $exta-small-font-size;
									color: $color-neutral-black-dark;
									line-height: 1.2;
									z-index: 10;
									width: 80%;
								}
								.at-copy-to-clipboard {
									position: absolute;
									right: 0;
									width: 20%;
									.copy-btn {
										display: flex;
										align-items: center;
										height: 34px;
										margin: 0;
										padding: 0 8px;
										border-top-left-radius: 0;
										border-top-right-radius: $default-radius;
										border-bottom-left-radius: 0;
										border-bottom-right-radius: $default-radius;
										box-sizing: border-box;
									}
								}
								.error-message {
									background-color: $mc-1;
									border-radius: $default-radius;
									box-sizing: border-box;
									font-size: $exta-small-font-size;
									color: $color-neutral-black-dark;
									line-height: 1.2;
									z-index: 10;
									.title-text {
										text-transform: uppercase;
										color: $color-dark-grey;
										border-bottom: 1px solid $mc-3;
										padding: 8px 12px;
										@extend .font--semibold;
									}
									.message {
										color: $color-red-50;
										padding: 8px 12px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.form-sidebar-container {
		.form-container {
			.fix-error-drawer-table-wrapper {
				padding: 0 24px;

				.fix-error-msg-wrapper {
					border-radius: 2px;
					padding: 0 16px;
					margin-bottom: 12px;
					display: flex;
					gap: 12px;
					align-items: center;

					p {
						color: $color-neutral-black-80;
						font-size: $very-small-font-size;
					}
				}

				.form-table {
					border: 1px solid $border-color;

					.form-table--header {
						.item {
							width: 30%;
						}

						.issue {
							width: 45%;
						}

						.platforms {
							width: 25%;
						}
					}

					.form-table--list {
						&:last-child {
							border-bottom: none;
						}

						.item {
							width: 30%;

							.fix-error-item-list-data {
								.name {
									font-size: $very-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-80;
								}

								.type {
									text-transform: uppercase;
									font-size: $exta-small-font-size;
									font-weight: 600;
									color: $color-neutral-black-40;
								}
							}
						}

						.issue {
							width: 45%;
							white-space: unset;

							.fix-error-issue-list-data {
								display: flex;
								gap: 8px;
							}
						}

						.platforms {
							width: 25%;
							min-height: inherit;

							&--container {
								display: flex;
								align-items: center;
							}

							.fix-error-issue-list-data {
								display: flex;
								align-items: center;
								gap: 8px;

								img {
									width: 28px;
									height: 28px;
									object-fit: cover;
									border-radius: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
}
