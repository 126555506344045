.filter-sidebar-container {
	padding: 25px;
	@include bp(768px) {
		padding: 10px;
	}
	.header {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			height: 14px;
		}
		.title {
			display: inline-block;
			vertical-align: top;
			line-height: normal;
			font-size: $small-font-size;
			color: $mc-5;
			transition: 180ms;
		}
		.header-right {
			cursor: pointer;
			&:hover {
				.title {
					color: $mc-8;
				}
			}
		}
	}
	.filter-container {
		padding-top: 25px;
		border-top: 1px solid $border-color;
		.multi-select-filter-container,
		.number-range-filter-container {
			font-size: $very-small-font-size;
		}
		.dropdown-custom {
			margin-bottom: 15px;
			.container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: auto;
				color: #333;
				padding: 7px 8px;
				.dropdown-arrow-zone {
					right: 10px;
				}
				.dropdown-arrow {
					position: relative;
				}
			}
		}
		.dropdown-component {
			top: 110%;
		}
		.duration-dropdown-selector {
			width: 170px;
			@include bp(768px) {
				width: auto;
				margin: 10px;
			}
		}
		.date-filter-container {
			margin-bottom: 0;
			min-height: 265px;
			box-sizing: border-box;
		}
		.date-filter-action-container {
			display: inline-block;
			vertical-align: top;
			padding: 10px 12px;
			@include bp(768px) {
				padding: 5px;
			}
			.meta-info {
				margin: 5px 5px 5px 0;
			}
			.type-selector {
				display: flex;
				justify-content: space-between;
				.at--dropdown {
					margin-bottom: 5px;
					margin-right: 25px;
					width: 150px;
					display: inline-block;
					.react-select__control {
						border: none;
						border-bottom: 2px dashed $border-color-dark;
						border-radius: 0;
						background-color: white;
						font-size: $very-small-font-size;
						color: $mc-5;
						&:hover {
							box-shadow: none;
						}
					}
					.react-select__control--menu-is-open {
						border-color: $color-blue;
					}
				}
			}
			.date-filter-action {
				text-align: right;
				position: absolute;
				bottom: 10px;
				right: 10px;
				cursor: pointer;
				font-size: 12px;
				text-transform: uppercase;
				@extend .font--semibold;
				.apply {
					color: $color-blue;
					margin-right: 25px;
					display: inline-block;
					vertical-align: top;
				}
				.cancel {
					display: inline-block;
					vertical-align: top;
					color: $mc-5;
					transition: 180ms;
					&:hover {
						color: $mc-7;
					}
				}
			}
		}
		.calender-range-first,
		.calender-range-second {
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			@extend .react-datepicker--inline;
		}
	}
	.footer {
		margin-top: 50px;
	}
}
