.close-drawer-modal {
	position: fixed;
	background: rgba(000, 000, 000, 0.4);
	bottom: 49vh;
	width: 192px;
	min-height: 36px;
	left: 30%;
	margin-left: -226px;
	padding: 10px;
	border-radius: 2px;
	z-index: 1003;
	box-sizing: border-box;
	@include bp(768px) {
		width: 100%;
		margin-left: 0px;
		border-radius: 0px;
		bottom: 0px;
		left: 0px;
		text-align: center;
	}
	.message {
		font-size: 14px;
		color: #fff;
		button {
			margin: 0 3px;
			border-radius: 2px;
			border: 1px solid $mc-2;
			outline: none;
		}
	}
	animation: slideRight 180ms;
	box-shadow: 1px 1px 5px rgba(45, 62, 79, 0.6);
}

@keyframes slideRight {
	from {
		transform: translateX(-300px);
	}
	to {
		transform: translateX(0px);
	}
}
