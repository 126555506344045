.menu-sections-container {
	.menu-section-list-container {
		padding-bottom: 30px;
		.header-container {
			padding: 21px 25px;
			display: flex;
			// margin-bottom: 35px;
			justify-content: space-between;
			align-items: flex-start;
			.header-text {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				gap: 8px;
				.title {
					font-size: $large-font-size;
					@extend .font--semibold;
					line-height: 0.7;
				}

				.subtitle {
					color: $mc-5;
					padding-top: 5px;
				}
			}
			.search-filter {
				position: relative;
				margin: 10px 0 0 0;
				width: 250px;
				.at-input-container {
					width: 100%;
				}
			}
		}
		.menu-section-list-table-container {
			.transactions-list-table {
				margin-top: 5px;
			}
			.name {
				padding-left: 25px;
			}
		}
		.at-paginator {
			padding: 0 25px;
		}
	}
}
