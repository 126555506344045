.context-menu {
	position: absolute;
	top: 15px;
	right: 15px;
	.menu {
		min-width: 150px;
		position: absolute;
		width: fit-content;
		top: 25px;
		right: -5px;
		background-color: $mc-1;
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
		border-radius: $border-radius-default;
		z-index: 50;
		.action-item {
			width: auto;
			padding: 10px 25px 10px 15px;
			font-size: $very-small-font-size;
			white-space: nowrap;
			background-color: $mc-1;
			transition: 180ms;
			cursor: pointer;
			&:hover {
				background-color: #f5f5f5;
			}
			&:first-child {
				border-radius: 2px 2px 0 0;
			}
			&:last-child {
				border-radius: 0 0 2px 2px;
			}
			&:only-child {
				border-radius: $border-radius-default !important;
			}
			&.danger {
				color: $color-red;
			}
		}
		.at-accordion-container {
			.header {
				padding: 10px 15px;
				.title {
					padding: 0;
					font-size: $very-small-font-size;
					width: 100%;
					@extend .font--regular;
				}
			}
			.contents {
				box-shadow:
					inset 0 12px 15px -16px rgba(0, 0, 0, 0.25),
					inset 0 -12px 15px -16px rgba(0, 0, 0, 0.25);
				.action {
					padding: 10px 15px;
					width: 100%;
					font-size: $very-small-font-size;
					transition: 180ms;
					cursor: pointer;
					&:hover {
						background-color: rgba(75, 139, 216, 0.05);
					}
				}
			}
		}
	}
	.icon-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		cursor: pointer;
		svg {
			path {
				transition: 50ms;
			}
		}
	}
	&.open {
		.icon-dots {
			svg {
				path {
					fill: $color-light-black;
				}
			}
		}
	}
}
