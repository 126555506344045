.at-modal {
	.modal-container {
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
		min-height: 20vh;
		max-height: 80vh;
		background-color: white;
		z-index: ($zI-top + 1);
		transition: 180ms;
		border-radius: $default-radius;
		box-shadow: 0px 2px 20px 3px rgba(0, 0, 0, 0.15);
		.modal-close {
			text-align: right;
			padding: 5px;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 10;
			img {
				width: 14px;
				cursor: pointer;
				padding: 15px;
				opacity: 0.7;
				&:hover {
					opacity: 1;
				}
			}
		}
		.modal-header {
			padding: 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			text-align: center;
			.header-left {
				flex-grow: 1;
				.title {
					vertical-align: top;
					line-height: normal;
					font-size: $medium-large-font-size;
					@extend .font--semibold;
					margin-bottom: 5px;
					color: $mc-7;
				}
				.subtitle {
					vertical-align: top;
					line-height: normal;
					opacity: 0.4;
				}
			}
			.header-right {
				cursor: pointer;
				&:hover {
					.title {
						color: $mc-8;
					}
				}
				.at-btn {
					&.margin-right {
						margin-right: 10px;
					}
				}
			}
		}
		.modal-content {
			overflow-y: auto;
			min-height: 80px;
			max-height: 380px;
			// prevent scroll-chaining to parent elements
			overscroll-behavior: contain;
			.loader-backdrop {
				background-color: transparent;
				.loading-indicator-container {
					margin-top: 15px;
				}
			}
			.iframe-container {
				display: flex;
				align-items: center;
				justify-content: center;
				iframe {
					width: 560px;
					height: 315px;
					border-radius: $default-radius;
				}
			}
		}
		.modal-footer {
			width: 100%;
			padding: 20px 25px;
			box-sizing: border-box;
			background: white;
			text-align: center;
			border-bottom-left-radius: $default-radius;
			border-bottom-right-radius: $default-radius;
			z-index: 10;
		}
	}
}
