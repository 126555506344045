.location-entity-association-container {
	.search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.locations-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 70px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 50%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.checkbox-sort {
				position: relative;
			}
			.sort-button {
				position: absolute;
				height: 8px;
				margin-bottom: 4px;
				cursor: pointer;
				padding: 5px;
				left: -18px;
				top: -1px;
			}
			.at-checkbox {
				font-size: $very-small-font-size;
			}
			.tags {
				padding-left: 25px;
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.city {
			width: 40%;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}

.item-entity-association-container {
	.search-filter-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		&:nth-child(2) {
			margin-bottom: 35px;
		}
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.items-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 90px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.title,
		.name {
			width: 60%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.checkbox-sort {
				position: relative;
			}
			.sort-button {
				position: absolute;
				height: 8px;
				margin-bottom: 4px;
				cursor: pointer;
				padding: 5px;
				left: -18px;
				top: -1px;
			}
			.at-checkbox {
				font-size: $very-small-font-size;
				display: flex;
				& > span {
					margin: auto 10px auto 0;
				}
				.food-type {
					margin-left: 5px;
				}
			}
			.crm-title {
				color: $mc-5;
				padding-left: 52px;
			}
			.item_tags {
				padding-left: 53px;
				.tag-item {
					margin: 2px 5px 0px 0px;
				}
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.category {
			width: 20%;
		}
		.price {
			width: 20%;
			padding-right: 25px;
			text-align: right;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}

.user-entity-association-container {
	.search-container {
		display: flex;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
			margin-right: 35px;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.modifiers-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 65px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 60%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.checkbox-sort {
				position: relative;
			}
			.sort-button {
				position: absolute;
				height: 8px;
				margin-bottom: 4px;
				cursor: pointer;
				top: -1px;
				left: -18px;
				padding: 5px;
			}
			.at-checkbox {
				font-size: $very-small-font-size;
				display: flex;
				& > span {
					margin: auto 10px auto 0;
				}
				.food-type {
					margin-left: 5px;
				}
			}
			.handle {
				color: $mc-5;
				padding-left: 52px;
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.price {
			width: 40%;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			position: relative;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
	.users-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: initial;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 35%;
			padding-left: 25px;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
		}
		.username {
			width: 30%;
		}
		.email {
			width: 35%;
			padding-right: 25px;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}

.modifier-entity-association-container {
	.search-container {
		display: flex;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
			margin-right: 35px;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.modifiers-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 65px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 50%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.checkbox-sort {
				position: relative;
			}
			.sort-button {
				position: absolute;
				height: 8px;
				margin-bottom: 4px;
				cursor: pointer;
				top: -1px;
				left: -18px;
				padding: 5px;
			}
			.at-checkbox {
				font-size: $very-small-font-size;
				display: flex;
				& > span {
					margin: auto 10px auto 0;
				}
				.food-type {
					margin-left: 5px;
				}
			}
			.handle {
				color: $mc-5;
				padding-left: 52px;
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.crm-title {
			width: 30%;
		}
		.price {
			width: 20%;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			position: relative;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
	.users-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: initial;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 35%;
			padding-left: 25px;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
		}
		.username {
			width: 30%;
		}
		.email {
			width: 35%;
			padding-right: 25px;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}

.item-tag-entity-association-container {
	.search-filter-container {
		display: flex;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
			margin-right: 35px;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.item-tags-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 60px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.title {
			width: 50%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
			.crm-title {
				color: $mc-5;
				padding-left: 25px;
			}
		}
		.tag-group {
			width: 25%;
		}
		.num-items {
			width: 25%;
			padding-right: 25px;
			text-align: right;
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}

.location-group-entity-association-container {
	.form-container {
		.form-content {
			padding: 0 25px 25px 25px !important;
		}
	}
	.search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.location-group-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 70px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 100%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}
