.down-arrow-global {
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0 0 0 10px;
	padding: 4px 10px 0 10px;
	transition: 300ms ease-out;
	will-change: transform;
	&:hover {
		transform: translateY(2px);
	}
	&.no-hover {
		transform: translateY(2px);
	}
	.edge-1,
	.edge-2 {
		display: inline-block;
		vertical-align: top;
		border-right: 1px solid;
		border-radius: 4px;
		height: 8px;
		transition: 300ms ease-out;
	}
	.edge-1 {
		transform: rotate(-45deg);
		margin-right: 4px;
	}
	.edge-2 {
		transform: rotate(45deg);
	}
	&.up {
		// the below transform on hover is to keep the dropdown in its location if its already expanded
		&:hover {
			transform: translateY(0);
		}
		.edge-1 {
			transform: rotate(-135deg);
		}
		.edge-2 {
			transform: rotate(135deg);
		}
	}
}
