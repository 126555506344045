.at-radiobutton {
	display: block;
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	font-size: 12px;
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: $radio-btn-radius;
		border: 1px solid $btn-grey-color;
		box-sizing: border-box;
		height: 18px;
		width: 18px;
		&:after {
			content: "";
			position: absolute;
			left: 3px;
			top: 3px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $color-blue;
			display: none;
		}
	}
	input:checked ~ .checkmark {
		border: 2px solid $color-blue;
	}
	input:checked ~ .checkmark:after {
		display: block;
	}
	&.at-radiobutton--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
}
// .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
// }
