.at-accordion-container {
	position: relative;
	width: 100%;
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		cursor: pointer;
		.title {
			font-size: $small-font-size;
			@extend .font--semibold;
		}
		.down-arrow-global {
			width: 10px;
			position: relative;
			top: 5px;
			padding: 0px;
			filter: brightness(0%);
		}
		.dropdown-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: sticky;
			top: 0;
			right: 0px;
			svg {
				fill: hsl(0, 0%, 70%);
				width: 16px;
			}
			&:hover {
				svg {
					fill: hsl(0, 0%, 46%);
				}
			}
			&.is-open {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
	.accordion-row {
		display: flex;
		border-bottom: 1px solid $border-color;
		padding: 8px 0;
		cursor: pointer;
		.dropdown-arrow {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			border-radius: $default-radius;
			border: 1px solid $border-color-dark;
			width: 15px;
			height: 15px;
			margin-right: 10px;
			background-color: $mc-1;
			svg {
				width: 15px;
				opacity: 0.6;
				transition: 180ms;
				cursor: pointer;
			}
			&.hidden {
				visibility: hidden;
			}
		}
		&.expanded {
			.dropdown-arrow {
				svg {
					transform: rotate(180deg);
				}
			}
		}
		.down-arrow-global {
			position: absolute;
			right: 15px;
			height: 8px;
			padding: 5px;
			margin: auto 0;
			filter: brightness(0%);
		}
	}
	.contents {
		overflow: hidden;
		height: 0;
		&.expanded {
			height: fit-content;
		}
	}
}
