.low-credits-alert {
	.alert-icon {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px;
	}
	.alert-area {
		display: inline-block;
		vertical-align: top;
	}
	.alert-title {
		font-size: $large-font-size;
		color: $mc-9;
		line-height: 22px;
		@extend .font--semibold;
		margin-bottom: 10px;
	}
	.alert-subtitle {
		font-size: $very-small-font-size;
		color: $mc-7;
		margin-bottom: 24px;
	}
	.alert-actions {
		.buy-button,
		.cancel-button {
			display: inline-block;
			vertical-align: top;
			text-transform: uppercase;
			margin-right: 10px;
			cursor: pointer;
		}
		.buy-button {
			color: $color-blue;
			font-size: $very-small-font-size;
			@extend .font--semibold;
		}
		.cancel-button {
			color: $mc-7;
			font-size: $very-small-font-size;
		}
	}
}
