.onboarding-setup-header {
	// reset the existing browser styles for the heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
		margin: 0;
	}
	position: relative;
	padding: 24px;
	border-bottom: 1px solid var(--up-black-10);
	.text-b3 {
		margin-top: 10px;
	}
	.at-breadcrumbs-container {
		margin-bottom: 8px;
		.hyperlink {
			color: var(--up-black-50);
			font-weight: 400;
			cursor: pointer;
		}
	}
	.header-container {
		display: flex;
		justify-content: space-between;
	}
	.onboarding-steps {
		position: absolute;
		bottom: -1%;
		right: 0;
		left: 0;
		background-color: #fff;
		height: 4px;
		display: flex;
		justify-content: space-between;
		gap: 8px;
		flex-wrap: nowrap;
		width: 100%;
		border-radius: 4px;
		&__step {
			flex: 1;
			height: 4px;
			background-color: $color-neutral-black-10;
			border-radius: 4px;
			&--active {
				background-color: $color-blue-50;
			}
		}
	}
	.infinite-load-bar {
		bottom: -1%;
		width: 100%;
		height: 4px;
		background-color: $color-neutral-black-10;
	}
}
