.refresh-failed-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0px 22px 0px 25px;
	border-radius: 4px;
	padding: 24px;
	.refresh-failed-main-div {
		display: flex;
		flex-direction: row;
		.refresh-failed-div {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
		.refresh-failed {
			width: 22.5px;
			height: 19.88px;
			top: 1.13px;
			left: 0.75px;
			gap: 0px;
			opacity: 0px;
		}
		.failed-info-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			.main-info-test {
				display: flex;
				flex-direction: column;
				gap: 2px;
				.refresh-header {
					font-family: Source Sans Pro;
					font-size: 18px;
					font-weight: 600;
					line-height: 24px;
					text-align: left;
				}
				.details-info {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #646464;
				}
			}
		}
		.refresh-failed-contact {
			display: flex;
			flex-direction: row;
			gap: 8px;
			align-items: center;
			.right-arrow-contact {
				width: 15.05px;
				height: 14.43px;
			}
			.contact-info {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				color: #3b5feb;
			}
		}
	}
	.close-refresh {
		align-self: flex-start;
	}
}
