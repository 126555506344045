.select-filter-custom-group {
	z-index: 11111;
	user-select: none;
	.meta-info {
		font-size: 12px;
		font-weight: 400;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 5px;
	}
	.dropdown-custom {
		position: relative;
		width: 100%;

		.dropdown-component {
			left: 0;
			z-index: 1111111;

			.dropdown-options {
				position: relative;
				max-height: 450px;
				overflow-y: auto;
				.grouped-options {
					.view-all-btn {
						color: #3b5feb;
						font-size: 14px;
						padding: 9px 10px;
						&:hover {
							background-color: $mc-3;
						}
					}
				}
				.max-selectable-message {
					position: sticky;
					top: 0;
					z-index: 1;

					font-weight: bold;
					color: $mc-7;
					padding: 10px 15px;
					font-size: 12px;
					background-color: #f8f8f8;
				}
				.max-selection-reached-color {
					color: $color-red-dark;
				}
				.search-input {
					input {
						width: 100%;
						border: none;
						outline: none;
						height: 40px;
						border-bottom: 1px solid #d0dadd;
						border-top: 1px solid #d0dadd;
						padding: 0px 10px;
						border-radius: 4px 4px 0 0;
					}
				}
				.group-name {
					font-size: $very-small-font-size;
					font-weight: 600;
				}
				.empty-info {
					white-space: normal;
					color: #7b8a9a;
					font-size: 14px;
					padding: 0 10px;
					font-weight: 400;
				}
				.name-on-top {
					padding: 7px 10px 7px 10px;
				}
				.name-below {
					padding: 10px 10px 5px 10px;
				}
				.option {
					display: flex;
					align-items: center;
					margin: auto;
					padding: 0px 10px;
					height: 37px;
					border-bottom: 1px solid $border-color-dark;
					cursor: pointer;
					transition: 180ms;
					font-size: $very-small-font-size;
					.option-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						padding-right: 8px;
						width: fit-content;
						img {
							width: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
					}
					.initials {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						min-width: 22px;
						height: 22px;
						border-radius: $default-radius;
						background-color: $mc-3;
						font-size: 12px;
						@extend .font--semibold;
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
					.at-checkbox {
						display: flex;
						align-items: center;
						font-size: inherit;
						width: 100%;
						padding: 10px 25px;
						.checkmark {
							margin-top: 11px;
						}
					}
					&.search-input {
						padding: 0;
					}
					&.selected {
						background-color: $mc-3;
					}
					&:hover {
						background-color: $mc-3;
					}
					&.no-hover {
						color: $mc-5;
						cursor: default;
						&:hover {
							background-color: transparent;
						}
					}
				}

				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
			}
		}
	}
	.dropdown-custom .container {
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 34px;
		padding: 0px 10px;
		width: 100%;
		border-radius: 2px;
	}
}
