.taxes-container {
	.action-buttons-container {
		margin-right: 10px;
	}
	.taxes-list-table-container {
		.name {
			width: 40%;
			padding-left: 25px;
			.name-desc {
				display: inline-block;
				width: calc(100% - 70px);
				.desc-text {
					margin-top: 5px;
				}
			}
		}
		.applicable-on {
			width: 15%;
		}
		.merchant-ref-id {
			width: 15%;
		}
		.items {
			width: 15%;
			text-align: right;
		}
		.locations {
			width: 15%;
			padding-right: 25px;
			text-align: right;
		}
	}
}
.tax-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
}
.items-and-locations-container {
	.items-and-locations-table-container {
		.transaction-rows {
			min-height: initial;
		}
		.transaction-header-row {
			top: 38px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.item-group {
			width: 50%;
		}
		.location-group {
			width: 50%;
			display: flex;
			justify-content: space-between;
			.link-text {
				width: 80%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.delete-icon {
				width: 15px;
				border: 1px solid $color-red;
				border-radius: $default-radius;
				cursor: pointer;
				padding: 5px;
			}
		}
	}
}
