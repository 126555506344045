.at-switch-cnt {
	font-size: $small-font-size;
	position: relative;
	.meta-info {
		font-size: 12px;
		color: $mc-5;
	}
	.info {
		position: relative;
		top: 1px;
		width: 12px;
		margin-left: 5px;
	}
	[aria-label][data-balloon-pos]:after {
		z-index: 1000;
		width: 350px;
		max-width: max-content;
		white-space: normal;
	}
	.at-switch {
		width: 45px;
		min-height: 26px;
		position: relative;
		display: inline-block;
		cursor: pointer;
		&.read-only {
			cursor: default;
			pointer-events: none;
			.switch-rail {
				background-color: rgba($color: $btn-grey-color, $alpha: 0.8);
			}
		}
		.switch-rail {
			width: 100%;
			margin: 10px 0;
			height: 26px;
			background-color: $btn-grey-color;
			border-radius: 14px;
			transition: 300ms;
		}
		.switch-ball {
			border-radius: 50%;
			width: 22px;
			height: 22px;
			top: 12px;
			left: 3px;
			background-color: $mc-2;
			transition: 0.3s;
			box-shadow: 1px 1px 5px rgba(86, 84, 86, 0.3);
			-webkit-box-shadow: 1px 1px 5px rgba(86, 84, 86, 0.3);
			position: absolute;
		}
		&.active {
			.switch-rail {
				background-color: $color-blue;
			}
			.switch-ball {
				left: 20px;
			}
		}
		&.read-only.active {
			.switch-rail {
				background-color: rgba($color: $color-blue, $alpha: 0.6);
			}
		}
	}
	.leftlabel {
		padding-right: 10px;
	}
	.rightlabel {
		padding-left: 10px;
	}
	&.at-switch--disabled {
		opacity: 0.2;
		pointer-events: none;
		cursor: default;
	}
	.validation-message {
		margin-top: 10px;
	}
}
