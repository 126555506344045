.ilgps-entity-association-container {
	.pair-input-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 35px;
		.multi-select-filter-container {
			width: 45%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
			.at--dropdown {
				font-size: $very-small-font-size;
			}
		}
		.action-icon {
			img {
				width: 25px;
				padding: 2px 0;
			}
			cursor: pointer;
		}
	}
}
