.multi-select-filter-container {
	font-size: $very-small-font-size;
	.meta-info {
		font-size: 12px;
		color: $mc-5;
		margin-top: 3px;
		margin-bottom: 0;
		padding-bottom: 5px;
	}
	&.ellipsized {
		.react-select__value-container--is-multi {
			&.react-select__value-container--has-value {
				& > div {
					max-width: 130px;
				}
			}
		}
	}
	&.archived {
		.react-select__control {
			border: 1px solid $color-red;
			background-color: rgba(192, 57, 43, 0.1);
			&:hover {
				border: 1px solid $color-red;
				background-color: rgba(192, 57, 43, 0.1);
			}
		}
		.react-select__control--menu-is-open {
			border: 1px solid $color-red;
			background-color: rgba(192, 57, 43, 0.1);
			&:hover {
				border: 1px solid $color-red;
				background-color: rgba(192, 57, 43, 0.1);
			}
		}
	}
	&.null {
		.react-select__control {
			border: 1px solid $color-red;
			background-color: rgba(192, 57, 43, 0.1);
			&:hover {
				border: 1px solid $color-red;
				background-color: rgba(192, 57, 43, 0.1);
			}
		}
		.react-select__control--menu-is-open {
			border: 1px solid $color-blue;
			background-color: $mc-1;
			&:hover {
				border: 1px solid $color-blue;
				background-color: $mc-1;
			}
		}
	}
}
.at--dropdown {
	// select dropdown
	.react-select__control {
		border: 1px solid $border-color-dark;
		min-height: 36px;
		cursor: pointer;
		&:hover {
			border: 1px solid $border-color-dark;
		}
	}
	.react-select__control--is-focused {
		box-shadow: none;
		border: 1px solid $border-color-dark;
	}
	.react-select__control--menu-is-open {
		border: 1px solid $color-blue;
		&:hover {
			border: 1px solid $color-blue;
		}
		.react-select__dropdown-indicator {
			svg {
				transform: rotate(180deg);
			}
		}
	}
	.react-select__placeholder {
		font-size: $very-small-font-size;
		color: #d2d2d2;
	}
	// select dropdown disabled
	&.react-select--is-disabled {
		.react-select__control--is-disabled {
			background-color: #eaeaea;
			.react-select__single-value--is-disabled {
				color: #777;
			}
		}
	}
	// single value
	.react-select__single-value {
		color: $mc-9;
	}
	// multi value
	.react-select__value-container--is-multi {
		&.react-select__value-container--has-value {
			padding-left: 2px;
		}
	}
	.react-select__multi-value {
		color: rgba($color: #007eff, $alpha: 0.8);
		background-color: rgba($color: #007eff, $alpha: 0.08);
		border: 1px solid rgba($color: #007eff, $alpha: 0.24);
		border-radius: 100px;
		.react-select__multi-value__label {
			color: #007eff;
			padding: 4px 6px;
			cursor: default;
		}
		.react-select__multi-value__remove {
			border-radius: 0 100px 100px 0;
			transition: 180ms;
			&:hover {
				background-color: rgba($color: #007eff, $alpha: 0.1);
				color: rgba($color: #007eff, $alpha: 0.8);
			}
		}
	}
	// loading, clear and arrow indicators
	.react-select__indicators {
		.react-select__indicator {
			color: hsl(0, 0%, 70%);
			padding: 6px 0;
			cursor: pointer;
			svg {
				width: 16px;
			}
		}
		.react-select__clear-indicator {
			padding-left: 3px;
			padding-right: 3px;
			svg {
				width: 15px;
				path {
					transition: 150ms;
				}
			}
			&:hover {
				svg {
					path {
						fill: #d62a34;
					}
				}
			}
		}
		.react-select__loading-indicator {
			margin-right: 6px;
			cursor: default;
			span {
				background-color: hsl(0, 0%, 70%);
			}
		}
		.react-select__indicator-separator {
			display: none;
		}
		.react-select__dropdown-indicator {
			padding-left: 3px;
			padding-right: 8px;
			&:hover {
				color: hsl(0, 0%, 46%);
			}
		}
	}
	// dropdown options menu
	.menu-wrapper {
		z-index: 1000 !important;
	}
	.react-select__menu {
		margin-top: 6px;
		margin-bottom: 20px;
		.react-select__menu-list {
			padding: 0;
			max-height: 200px;
		}
		.react-select__option {
			height: 40px;
			padding: 10px 8px;
			border-bottom: 1px solid $border-color-dark;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			transition: 180ms;
			cursor: pointer;
			&:last-child {
				border: none;
			}
			&:hover {
				background-color: $mc-3;
			}
		}
		.react-select__option--is-focused {
			background-color: white;
		}
		.react-select__option--is-selected {
			color: #333;
			background-color: $mc-3;
		}
		.react-select__menu-notice--no-options,
		.react-select__menu-notice--loading {
			display: flex;
			align-items: center;
			padding-top: 10px;
			padding-bottom: 10px;
			text-align: left;
		}
	}
}

.number-range-filter-container {
	.type-selector {
		display: flex;
		align-items: flex-end;
		.at--dropdown {
			margin-bottom: 5px;
			margin-right: 15px;
			width: 150px;
			display: inline-block;
			.react-select__control {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				background-color: white;
				font-size: $very-small-font-size;
				color: $mc-5;
				&:hover {
					box-shadow: none;
				}
			}
			.react-select__control--menu-is-open {
				border-color: $color-blue;
			}
		}
		.type-selected {
			display: inline-block;
			vertical-align: top;
			margin-bottom: 5px;
		}
		.inline-input.at-input-container {
			display: inline-block;
			width: 60px;
			margin-right: 0;
			span.seperator {
				font-size: 12px;
				color: $mc-5;
				margin: 0 10px;
			}
			&.range-second {
				margin-left: 0;
			}
			input {
				border: none;
				border-bottom: 2px dashed $border-color-dark;
				border-radius: 0;
				font-size: $very-small-font-size;
			}
		}
	}
}
.select-description {
	font-size: 10px;
	color: $mc-5;
}
