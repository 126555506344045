.custom-table-container {
	position: relative;
	margin-bottom: 10px;
	.table-header {
		display: flex;
		flex-wrap: nowrap;
		background-color: #f8f8f8;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		top: 57px;
		position: sticky;
		box-sizing: border-box;
		z-index: 150;
		.table-cell {
			width: 100%;
			padding: 10px 15px;
			font-size: 12px;
			color: $mc-9;
			text-transform: uppercase;
			box-sizing: border-box;
			flex-grow: 1;
			overflow: hidden;
			list-style: none;
			@extend .font--semibold;
			span {
				opacity: 0.6;
			}
			.required-sign {
				&::after {
					top: 0;
					filter: contrast(100);
				}
			}
			.sort-icons {
				position: relative;
				top: 1px;
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-left: 5px;
			}
			&.clickable {
				display: flex;
				align-items: center;
				cursor: pointer;
				&:hover {
					background-color: $mc-2;
				}
				.sort-icons {
					top: 0px;
					opacity: 1;
				}
			}
			&.active {
				color: $color-blue;
				&.dark {
					color: $mc-9;
				}
			}
			&.archived {
				color: $color-red;
			}
			img {
				margin-left: 5px;
			}
			.at-checkbox {
				height: 16px;
				span {
					opacity: 1;
				}
				&.read-only {
					cursor: default;
					span {
						opacity: 0.6;
					}
				}
			}
		}
		&.analytics {
			background-color: $mc-1;
			position: relative;
			top: 0 !important;
			z-index: 50;
			.table-cell {
				padding: 15px;
				font-size: $very-small-font-size;
				text-transform: none;
				color: $color-neutral-black-light;
				transition: 180ms;
				font-weight: normal;
				span {
					opacity: 1;
				}
				&.clickable {
					display: flex;
					align-items: center;
					&:hover {
						background-color: #f5f5f5;
					}
				}
				&.is-row-clickable {
					&:last-child {
						padding-right: 70px;
					}
				}
				&.active {
					color: $color-neutral-black-medium;
				}
			}
			.sub-name {
				margin-top: 3px;
			}
		}
	}
	.table-list {
		display: flex;
		flex-wrap: nowrap;
		border-bottom: 1px solid $border-color;
		padding: 8px 0;
		transition: 180ms;
		min-height: 70px;
		box-sizing: border-box;
		position: relative;
		// &:nth-of-type(odd) {
		// 	background-color: $mc-2;
		// }
		&.clickable {
			cursor: pointer;
			&:hover {
				background-color: $mc-2;
			}
			.table-cell {
				&:last-of-type {
					padding-right: 70px;
				}
			}
		}
		&:hover {
			// background-color: rgba(47, 88, 242, 0.05);
			background-color: $mc-2;
		}
		.table-cell {
			width: 100%;
			text-overflow: ellipsis;
			box-sizing: border-box;
			flex-grow: 1;
			list-style: none;
			padding: 7px 15px;
			font-size: $very-small-font-size;
			// overflow: hidden;
			.link-text {
				color: $color-blue;
				text-transform: capitalize;
				@extend .font--semibold;
				&.archived {
					color: $color-red;
				}
			}
			.at-checkbox {
				height: 17px;
				&.read-only {
					cursor: default;
					span {
						opacity: 0.6;
					}
				}
			}
		}
		&.analytics {
			&:hover {
				background-color: #f5f5f5;
			}
			&.clickable {
				cursor: pointer;
				&:hover {
					background-color: $mc-2;
				}
				.table-cell {
					&:last-of-type {
						padding-right: 70px;
					}
				}
			}
			align-items: center;
			border-bottom: none;
			// for custom dashed border
			&:after {
				content: "";
				width: 100%;
				height: 1px;
				position: absolute;
				left: 0;
				bottom: 0;
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23EAEAEAFF' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='1' stroke-linecap='butt'/%3e%3c/svg%3e");
			}
			.table-cell {
				color: $color-neutral-black-medium;
				.hyperlink {
					&--black-color {
						color: $color-neutral-black-dark;
						&::after {
							background-color: $color-neutral-black-dark;
						}
					}
				}
			}
			&:last-child {
				&:after {
					background-image: none;
				}
			}
		}
		.context-menu {
			position: absolute;
			top: 15px;
			right: 15px;
			.menu {
				min-width: 150px;
				position: absolute;
				width: fit-content;
				top: 25px;
				right: -5px;
				background-color: $mc-1;
				box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
				border-radius: $default-radius;
				z-index: 50;
				.action-item {
					width: auto;
					padding: 10px 25px 10px 15px;
					font-size: $very-small-font-size;
					white-space: nowrap;
					background-color: $mc-1;
					transition: 180ms;
					cursor: pointer;
					&:hover {
						background-color: #f5f5f5;
					}
					&:first-child {
						border-radius: 4px 4px 0 0;
					}
					&:last-child {
						border-radius: 0 0 4px 4px;
					}
				}
			}
			.icon-dots {
				padding: 0 10px;
				cursor: pointer;
				svg {
					path {
						transition: 50ms;
					}
				}
			}
			&.open {
				.icon-dots {
					svg {
						path {
							fill: $color-light-black;
						}
					}
				}
			}
		}
		.chevron {
			position: absolute;
			// top: 22px;
			right: 25px;
			padding: 2px;
			cursor: pointer;
			border-radius: $border-radius-default;
			&:hover {
				background-color: $mc-1;
				path {
					fill: $color-neutral-black-dark;
				}
			}
		}
	}
	.no-items-placeholder {
		border-bottom: 1px solid $divider-color;
	}
	.custom-placeholder {
		text-align: center;
		padding: 10% 0;
		box-sizing: border-box;
		font-size: $small-font-size;
		color: $mc-6;
		img {
			width: 300px;
			opacity: 0.9;
		}
		&.placeholder-img {
			padding: 2% 0;
		}
		.placeholder-text {
			margin-top: 10px;
		}
	}
	.view-action-row {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 25px 30px;
		box-sizing: border-box;
	}
	&.bordered {
		border: 1px solid $border-color;
		.table-header {
			border-top: none;
			border-radius: 4px 4px 0 0;
			.table-cell {
				&:first-child {
					border-radius: 4px 0 0 0;
				}
				&:last-child {
					border-radius: 0 4px 0 0;
				}
			}
		}
		.table-list {
			&:last-child {
				border-bottom: none;
				border-radius: 0 0 4px 4px;
				&:after {
					background-image: none;
				}
			}
		}
		.no-items-placeholder {
			border-bottom: none;
		}
	}
	.table-loader {
		display: flex;
		flex-direction: column;
		width: 100%;
		.table-list {
			display: flex;
			&:hover {
				background-color: $mc-1;
			}
		}
		.table-cell {
			display: flex;
			align-items: center;
			justify-content: center;
			.shimmer {
				border-radius: 0;
				&:after {
					// background: linear-gradient(to right, rgba(228, 226, 226, 0) 0%, #979797 50%, rgba(228, 226, 226, 0) 100%);
					background: linear-gradient(
						to right,
						rgba(245, 245, 245, 0) 0%,
						#cccccc 50%,
						rgba(245, 245, 245, 0) 100%
					);
				}
			}
		}
		.small {
			width: 7%;
		}
		.medium {
			width: 13%;
		}
		.large {
			width: 25%;
			display: flex;
			gap: 10px;
		}
	}
	.loading-time-alert {
		position: absolute;
		top: 21%;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		gap: 15px;
		width: max-content;
		margin: auto;
		padding: 30px;
		z-index: 50;
		background-color: $mc-1;
		.clock-icon {
			padding: 10px;
			svg {
				width: 100px;
				height: 100px;
			}
		}
		.alert {
			display: flex;
			flex-direction: column;
			gap: 5px;
			.header {
				font-size: $medium-font-size;
				color: $color-dark-grey;
				@extend .font--semibold;
			}
			.sub-header {
				font-size: $very-small-font-size;
				color: $mc-6;
			}
		}
	}
}
