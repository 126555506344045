.timeline-trail-container {
	display: flex;
	.trail-pointer-container {
		.head-pointer-container {
			background-color: white;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			.parent-circle {
				height: 30px;
				width: 30px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				.child-circle {
					height: 16px;
					width: 16px;
					border-radius: 50%;
				}
			}
		}
		.animated-circles {
			.parent-circle {
				animation: scale-up-down 1s linear 0s infinite;
				.child-circle {
					animation: scale-up-down 1s linear 0s infinite;
				}
			}
		}
		.trail-container {
			height: 100px;
			width: 30px;
			.trail {
				width: 4px;
				height: 100px;
				margin: auto;
				background-color: #efefef;
			}
		}
	}
	.info-section {
		padding-left: 15px;
		width: 700px;
		height: 100px;
		.status-tag {
			height: 20px;
			width: fit-content;
			padding: 4px 8px;
			border-radius: $default-radius;
			font-size: $very-small-font-size;
			text-transform: uppercase;
			font-weight: 600;
			line-height: 20px;
			margin-bottom: 12px;
		}
		.status-update-info {
			font-size: $very-small-font-size;
			font-weight: 600;
			color: #222222;
			margin-bottom: 4px;
			width: 90%;
			@include truncate_text;
			.highlight {
				background-color: $mc-3;
				padding: 0px 4px;
				border-radius: 4px;
				font-family: monospace;
				font-weight: 400;
			}
		}
		.status-update-message-comments {
			font-size: $very-small-font-size;
			color: $mc-6;
			width: 90%;
			@include truncate_text;
		}
		.modified-order {
			margin-top: 5px;
			color: $mc-6;
			font-size: $very-small-font-size;
		}
	}
}
