.carousel-container {
	position: relative;
	overflow: hidden;
	border-radius: $default-radius;
	.inner {
		position: relative;
		white-space: nowrap;
		transition: transform 0.3s;
		border-radius: $default-radius;
	}
	.btn-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		min-height: 400px;
		height: auto;
		transition: 300ms;
		z-index: 20;
		cursor: pointer;
		img {
			width: 20px;
			opacity: 0.4;
			transition: 180ms;
			filter: invert(1) brightness(100);
		}
		&.prev {
			padding: 0 30px 0 10px;
			left: 0;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0));
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: opacity 180ms;
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
			}
			&:hover {
				img {
					opacity: 0.6;
				}
				&::before {
					opacity: 1;
				}
			}
		}
		&.next {
			padding: 0 10px 0 30px;
			right: 0;
			background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0));
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: opacity 180ms;
				background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
			}
			&:hover {
				img {
					opacity: 0.6;
				}
				&::before {
					opacity: 1;
				}
			}
		}
	}
	.carousel-indicator-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		.indicator {
			padding: 4px;
			margin: 5px 6px;
			background: $mc-4;
			border-radius: 50%;
			transition: 300ms;
			box-sizing: border-box;
			cursor: pointer;
			&.active {
				background-color: $color-blue;
			}
		}
	}
}
.carousel-item-container {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 400px;
	height: auto;
	background-color: $mc-1;
	border-radius: $default-radius;
}
