.coupons-container {
	.action-buttons-container {
		margin-right: 10px;
	}
	.overlay-sidebar-container {
		.content {
			overflow: visible;
			overflow-x: visible;
		}
	}
	.coupons-list-table-container {
		.title {
			width: 40%;
			padding-left: 25px;
			.title-desc {
				display: inline-block;
				width: calc(100% - 30px);
				.badge {
					padding: 1px 6px;
					margin: 0 5px;
					border-radius: 10px;
					text-align: center;
					font-size: 11px;
					display: inline-block;
					color: $color-red;
					border: 1px solid $color-red;
					background: rgba(192, 57, 43, 0.1);
				}
			}
		}
		.type {
			width: 20%;
		}
		.starts {
			width: 20%;
		}
		.expires {
			width: 20%;
			padding-right: 25px;
		}
	}
}
.coupon-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.coupon-rules-tab {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.rules-section {
			.rule-container {
				border: 1px solid $border-color;
				border-radius: $default-radius;
				box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
				margin-bottom: 25px;
				.rule-header {
					padding: 15px 25px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background-color: rgba($color: $mc-2, $alpha: 0.6);
					.header-text {
						font-size: $small-font-size;
						@extend .font--semibold;
					}
					.delete-rule-icon {
						width: 15px;
						padding: 5px;
						border: 1px solid $color-red;
						border-radius: $default-radius;
						cursor: pointer;
						background-color: $mc-1;
					}
					.multi-select-filter-container {
						width: 250px;
						font-size: $very-small-font-size;
					}
				}
				.parameters {
					padding: 15px 25px 0 25px;
					border-top: 1px solid $border-color;
					.form-row {
						display: flex;
						justify-content: space-between;
						margin-bottom: 5px;
						flex-wrap: wrap;
						&.row-half > div {
							width: 47%;
							margin-bottom: 20px;
						}
						.at-checkbox {
							display: flex;
							align-items: center;
							top: 5px;
							padding-left: 0;
							.checkmark {
								position: relative;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
	.coupon-actions-tab {
		.parameters {
			&.form-row {
				display: flex;
				justify-content: space-between;
				margin-bottom: 5px;
				flex-wrap: wrap;
				& > div {
					width: 47%;
					margin-bottom: 30px;
				}
				.multi-select-filter-container {
					&[order="true"] {
						order: 2;
					}
					margin-bottom: 30px;
				}
				.at-textarea-container {
					width: 100%;
					margin-bottom: 30px;
					order: 1;
				}
				.header-container {
					display: flex;
					justify-content: space-between;
					border: 1px solid $border-color-dark;
					border-radius: $default-radius;
					width: 100%;
					padding: 15px;
					margin-bottom: 30px;
					.header-text {
						font-size: $very-small-font-size;
						@extend .font--semibold;
					}
					.header-subtext {
						color: $mc-5;
						font-size: $very-small-font-size;
						padding-top: 5px;
					}
				}
			}
		}
		.associated-entities-container {
			overflow: hidden;
			.header-container {
				display: flex;
				justify-content: space-between;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				padding: 15px;
				margin: 25px 0;
				.header-text {
					font-size: $small-font-size;
					@extend .font--semibold;
				}
				.header-subtext {
					color: $mc-5;
					font-size: $very-small-font-size;
					padding-top: 5px;
				}
			}
			.search-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 15px;
				.at-input-container {
					width: 30%;
				}
				.multi-select-filter-container {
					width: 30%;
					opacity: 1;
					.meta-info {
						font-weight: normal;
						color: $mc-6;
					}
				}
			}
			.crm-title-search-container {
				display: flex;
				justify-content: space-between;
				margin-bottom: 35px;
				.at-input-container {
					width: 30%;
				}
				.multi-select-filter-container {
					width: 30%;
					opacity: 1;
					.meta-info {
						font-weight: normal;
						color: $mc-6;
					}
				}
			}
			.associated-entities-list-table-container {
				.table-row-header {
					top: 0px;
				}
				.table-row-list {
					min-height: 90px;
					.table-cell {
						font-size: $very-small-font-size;
					}
				}
				.name {
					width: 55%;
					padding-left: 25px;
					display: flex;
					flex-direction: column;
					.item-desc {
						display: flex;
						.food-type {
							margin: auto 10px auto 0;
						}
					}
					.crm-title {
						color: $mc-5;
						padding-left: 22px;
					}
					.item_tags {
						padding-left: 23px;
						.tag-item {
							margin: 2px 5px 0px 0px;
						}
					}
				}
				.category {
					width: 25%;
				}
				.price {
					width: 20%;
					padding-right: 25px;
					text-align: right;
				}
				&.bxgy {
					.name {
						width: 35%;
						padding-left: 15px;
					}
					.category {
						width: 20%;
					}
					.price {
						width: 15%;
						text-align: left;
						padding-right: 15px;
					}
					.modifiers {
						width: 30%;
						padding-right: 15px;
					}
				}
			}
			.items-list-table-container {
				.transaction-header-row {
					top: 0px;
				}
				.transaction-rows {
					min-height: 90px;
				}
				.at-cell-text {
					font-size: $very-small-font-size;
				}
				.name {
					width: 55%;
					padding-left: 25px;
					display: flex;
					flex-direction: column;
					.item-desc {
						display: flex;
						.food-type {
							margin: auto 10px auto 0;
						}
					}
					.crm-title {
						color: $mc-5;
						padding-left: 22px;
					}
					.item_tags {
						padding-left: 23px;
						.tag-item {
							margin: 2px 5px 0px 0px;
						}
					}
				}
				.category {
					width: 25%;
				}
				.price {
					width: 20%;
					padding-right: 25px;
					text-align: right;
				}
			}
		}
	}
	.bulk-coupons-tab {
		.header-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
			.link-text {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				img {
					margin-bottom: 5px;
				}
			}
			.loader {
				margin-bottom: 5px;
				border: 2px solid #f3f3f3;
				border-radius: 50%;
				border-top: 2px solid #2f58f2;
				width: 20px;
				height: 20px;
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
			}
			/* Safari */
			@-webkit-keyframes spin {
				0% {
					-webkit-transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
}
