.credits-counter {
	margin-top: 15px;
	text-align: right;
	padding: 0 25px;
	@include bp(768px) {
		margin-right: 5px;
	}
	.inline-text {
		display: inline-block;
		vertical-align: top;
		color: $mc-6;
		font-size: 12px;
		@extend .font--semibold;
		.count {
			color: $mc-7;
			&.blue {
				color: $color-blue;
			}
			&.green {
				color: $color-green;
			}
			&.orange {
				color: $color-orange;
			}
			&.red {
				color: $color-red;
			}
		}
		&.seperator {
			margin-left: 3px;
			margin-right: 3px;
		}
		&.buy-button {
			color: $color-blue;
			cursor: pointer;
		}
	}
}

.campaign-section {
	background-color: #fff;
	padding-bottom: 15px;
	.at-top-bar {
		position: sticky;
		position: -webkit-sticky;
		top: 57px;
		background: #fff;
		z-index: 9;
		@include bp(768px) {
			position: relative;
			top: 0px;
		}
	}
	.filter-sidebar-container > .header {
		padding: 0;
		&:after {
			content: none;
			display: initial;
			clear: initial;
		}
	}
	.header {
		padding: 25px;
		.header-text {
			font-size: $large-font-size;
			float: left;
			margin-bottom: 5px;
			@extend .font--semibold;
			@include bp(768px) {
				float: none;
			}
		}
		.subheader-text {
			font-size: $small-font-size;
			float: left;
			clear: both;
			margin-top: 5px;
			color: $mc-6;
		}
		.filter-in-header {
			float: right;
			margin-top: -20px;
		}
		.filter-in-header-btn-holder {
			@include bp(768px) {
				display: none;
			}
		}
		.filter-in-header-btn-holder--mobile {
			display: block;
		}
		@include bp(768px) {
			padding: 10px;
			.subheader-text,
			.filter-in-header {
				float: none;
				margin-top: 0px;
				color: $mc-6;
			}
			.filter-in-header {
				margin-top: 10px;
			}
		}
		.campaign-list-filter {
			.container {
				transition: 0.3s;
				&:hover {
					&.filter-title {
						color: $color-blue;
					}
				}
				position: relative;
			}
			.filter-count {
				background: $color-green;
				color: #fff;
				width: 20px;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				border-radius: 100%;
				display: inline-block;
				position: absolute;
				right: -10px;
				top: -10px;
			}
		}
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
	}

	.filter-in-header-btn-holder {
		text-align: right;
		margin-bottom: 10px;
		& > div {
			display: inline-block;
			vertical-align: middle;
			box-sizing: border-box;
			@include bp(768px) {
				width: 50%;

				&:first-child {
					text-align: left;
				}
				&:last-child {
					text-align: right;
				}
			}
		}

		.discard {
			text-transform: uppercase;
			color: $color-red;
			padding: 10px 25px;
			cursor: pointer;

			@include bp(768px) {
				padding-left: 0px;
				// border: 1px solid $color-red;
			}
		}
	}
}

.campaign-filter-container {
	padding-top: 25px;
}

.top-border-blue {
	border-top: 2px solid $color-blue;
}

.campaign-section-container {
	display: flex;
	margin-right: 25px;
	@include bp(768px) {
		margin: 10px;
		flex-direction: column;
	}
	// margin-bottom: 35px;

	.sidebar-container {
		float: left;
		width: 25%;
		border-right: 1px solid #eaeaea;
		margin-right: 25px;
		flex: 1;
		min-height: 200px;
		@include bp(768px) {
			float: none;
			min-height: 0px;
			border-right: 0px;
			margin-right: 0px;
			width: 100%;

			.at-side-bar {
				position: relative;
				top: 0;
				white-space: nowrap;
				overflow-x: auto;
				.sidebar-row {
					text-align: center;
					display: inline-block;
					border-right: 0px;
					padding: 10px;
					min-width: 50%;
					&:after {
						height: 1px;
						width: 100%;
						bottom: 0px;
						left: 0px;
						top: auto;
						background: #ddd;
					}

					&.selected {
						&:after {
							background: $color-blue;
						}
					}
				}
			}
		}
	}
	.data-container {
		float: left;
		width: 75%;
		overflow: hidden;
		box-sizing: border-box;
		@include bp(768px) {
			padding: 5px 0;
			float: none;
			width: 100%;
		}
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.campaign-block-footer-text-container {
	display: inline-block;
	vertical-align: middle;
	font-size: 12px;
	color: #2f58f2;
	cursor: pointer;
}

.last-saved-helper-text {
	text-align: right;
	color: $mc-5;
	font-size: $small-font-size;
	@include bp(768px) {
		text-align: left;
	}
}
.last-saved-helper-text--mobile {
	text-align: center;
}
.helper-text-in-filters {
	font-size: 12px;
	color: #a2aab2;
	margin-right: 10px;
}

.discard-confirm-modal {
	overflow-x: hidden;
	overflow-y: auto;
	.title {
		font-size: $large-font-size;
		font-weight: 600;
		margin-bottom: 15px;
	}
	.modal-text {
		margin-bottom: 10px;
	}
}

.save-campaign-title {
	input {
		outline: 0;
		padding: 5px;
		padding-left: 0px;
		font-size: $large-font-size;
		border: 0px;
		border-bottom: 1px solid #111;
		width: 50%;
		max-width: 100%;
	}
}
.associated-items-options-selectors {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	.multi-select-filter-container {
		width: 49%;
	}
}
.selectors-header {
	margin-top: 25px;
	color: $mc-6;
}
.segment-gallery {
	.inactive-segment-container {
		border-top: 1px solid $mc-2;
		.header {
			padding: 25px 25px 10px 25px;
			font-size: 24px;
			margin-bottom: 5px;
		}
	}
	.segment-container {
		padding: 20px;
		@include bp(768px) {
			padding: 10px;
		}
	}
	.segment-block-container {
		cursor: pointer;
		width: 47%;
		box-sizing: border-box;
		vertical-align: top;
		margin-bottom: 50px;
		display: inline-block;
		border-radius: $default-radius;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		transition: 300ms;
		&:hover {
			box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.1);
		}
		&:nth-child(odd) {
			margin-right: 2%;
			margin-left: 0%;
		}
		&:nth-child(even) {
			margin-right: 0%;
			margin-left: 4%;
		}
		@include bp(768px) {
			margin-left: 0% !important;
			margin-right: 0% !important;
			margin-bottom: 10px;
			width: 100%;
		}

		.in-active {
			filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
			opacity: 0.7;
		}

		.top-section {
			background-color: #f5f5f5;
			color: $mc-9;
			padding: 20px;
			height: 90px;
			box-sizing: border-box;
			overflow: hidden;
			border-radius: $default-radius $default-radius 0 0;
			@include bp(768px) {
				padding: 10px;
			}
			.title {
				font-size: 20px;
				margin-bottom: 7px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.description {
				font-size: 14px;
				color: $mc-5;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.bottom-section {
			font-size: 14px;
			padding: 15px 20px 5px 20px;
			color: $mc-5;

			@include bp(768px) {
				padding: 10px;
			}

			.row {
				margin-bottom: 15px;
				& > div {
					display: inline-block;
					&:first-child() {
						width: 80%;
					}
					&:last-child() {
						color: #000;
						font-weight: 800;
						width: 20%;
						text-align: right;
					}
				}
			}
		}
		.footer {
			// border-top: 1px solid #ddd;
			font-size: 12px;
			padding: 10px 20px;

			@include bp(768px) {
				padding: 10px;
			}
			& > div {
				display: inline-block;
				vertical-align: middle;

				&:first-child {
					width: 60%;
				}
				&:last-child {
					width: 40%;
				}
			}
			.edit-clone-btn {
				position: relative;
				color: $color-blue;
				span {
					display: inline-block;
					margin-right: 10px;
					text-transform: uppercase;
					cursor: pointer;

					&:last-child {
						margin-right: 0px;
					}
				}
				.edit-clone-btn--floating {
					position: absolute;
					width: 100px;
					background: #fff;
					// border: 1px solid $mc-4;
					top: 20px;
					border-radius: 4px;
					overflow: hidden;
					transition: 180ms;
					transform-origin: left top;
					box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
					will-change: height;
					height: 0px;
					overflow: hidden;
					transition: 180ms ease-in-out;

					& > div {
						text-transform: uppercase;
						padding: 10px;
						box-sizing: border-box;
						border-bottom: 1px solid $divider-color;
						width: 100%;
						&:last-child {
							border: 0px;
						}
						&:hover {
							cursor: pointer;
							background: #f2f2f2;
						}
					}
				}

				.menu-expand {
					position: absolute;
					right: 0px;
					top: 1px;
					background: #fff;
					user-select: none;
					img {
						height: 10px;
					}
				}
			}
			.start-campaign-btn {
				text-transform: uppercase;
				box-sizing: border-box;
				text-align: right;
				.btn-text {
					color: $color-blue;
					cursor: pointer;
					transition: 180ms;
					display: inline-block;
					margin-right: 5px;
				}
				.btn-icon {
					display: inline-block;
					vertical-align: middle;
					height: 10px;
					transition: 300ms;
				}
				&:hover {
					.btn-icon {
						transform: translateX(2px);
					}
				}
			}
		}
	}
}

.campaign-email,
.campaign-app {
	.subject-input {
		label {
			@extend .meta-info;
			padding-bottom: 0;
			vertical-align: top;
		}
		input {
			margin-bottom: 15px;
		}
	}
}
