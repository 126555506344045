.item-group-entity-association-container {
	.form-container {
		.form-content {
			padding: 0 25px 25px 25px !important;
		}
	}
	.search-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		.at-input-container {
			width: 30%;
		}
		.multi-select-filter-container {
			width: 30%;
			opacity: 1;
			.meta-info {
				font-weight: normal;
				color: $mc-6;
			}
		}
	}
	.item-group-table-container {
		.transaction-header-row {
			top: -0.2px;
		}
		.transaction-rows {
			min-height: 70px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.name {
			width: 100%;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			.at-checkbox {
				font-size: $very-small-font-size;
			}
			.selected {
				.at-checkbox {
					.checkmark {
						border: 1px solid $color-blue;
					}
				}
			}
		}
		.at-header-text {
			line-height: 17px;
			opacity: 1;
			.at-checkbox {
				font-size: 12px;
			}
		}
	}
}
