.referrals-section {
	.at-top-bar-wrapper-v2 {
		margin-bottom: 15px;
		z-index: 50;
	}
	.settings-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.header-actions {
			display: flex;
			align-items: center;
			.campaign-list-filter {
				margin-top: 10px;
				position: relative;
				.filter-count {
					background: $color-green;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
			.search-filter {
				position: relative;
				margin: 10px 10px 0 0;
			}
			.search-input-container {
				display: flex;
				.search-filter {
					width: 170px;
					margin: 10px 0 0 0;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 4px 4px 0;
						}
					}
					.cancel-search {
						right: 14px;
					}
				}
				.multi-select-filter-container {
					margin: 10px 0 0 0;
					width: 100px;
					.Select-control {
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
					}
				}
			}
		}
	}
	.date-filter {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: 25px;
		.date-filter-container {
			margin-bottom: 0;
			min-height: 265px;
			box-sizing: border-box;
		}
		.date-filter-action-container {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			@include bp(768px) {
				padding: 5px;
			}
			.date-filter-action {
				text-align: right;
				position: absolute;
				bottom: 10px;
				right: 10px;
				cursor: pointer;
				font-size: 12px;
				text-transform: uppercase;
				@extend .font--semibold;
				.apply {
					color: $color-blue;
					margin-right: 25px;
					display: inline-block;
					vertical-align: top;
				}
				.cancel {
					display: inline-block;
					vertical-align: top;
					color: $mc-5;
					transition: 180ms;
					&:hover {
						color: $mc-7;
					}
				}
			}
		}
		.calender-range-first,
		.calender-range-second {
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			@extend .react-datepicker--inline;
		}
	}
	.referrals-container {
		.referrals-list-container {
			.filters {
				display: flex;
				align-items: center;
				padding: 10px 25px;
				.dropdown-date-time-filter {
					margin-right: 10px;
				}
				.header-actions {
					margin-left: auto;
					.search-filter {
						margin: 0;
					}
				}
			}
		}
		.referrals-list-table-container {
			.transaction-header-row {
				top: 95px;
			}
			.referrer {
				width: 15%;
				padding-left: 25px;
				.referrer-desc {
					display: inline-block;
					width: calc(100% - 20px);
					.desc-text {
						margin-top: 5px;
					}
				}
			}
			.referred {
				width: 15%;
				padding-left: 25px;
				.referred-desc {
					display: inline-block;
					width: calc(100% - 20px);
					.desc-text {
						margin-top: 5px;
					}
				}
			}
			.sharedOn {
				width: 20%;
			}
			.codeOrLink {
				display: flex;
				align-items: flex-start;
				width: 15%;
				.text {
					color: $mc-6;
				}
				img {
					width: 18px;
					opacity: 0.6;
					cursor: pointer;
					margin-left: 5px;
				}
			}
			.platform {
				width: 10%;
			}
			.channel {
				width: 10%;
				padding-right: 25px;
			}
			.status {
				width: 10%;
				padding-right: 25px;
				display: flex;
				justify-content: center;
				.text {
					color: white;
					.status-block {
						border-radius: 5px;
						padding: 5px;
					}
					.claimed {
						background-color: #a47c81;
					}
					.unclaimed {
						background-color: #8e8686;
					}
				}
			}
		}
		.referrals-nps-container {
			padding: 10px 25px;
			.dropdown-date-time-filter {
				margin-bottom: 25px;
			}
			.header-container {
				margin-bottom: 25px;
			}
			.title {
				font-size: $medium-large-font-size;
				margin-bottom: 5px;
				@extend .font--semibold;
			}
			.sub-title {
				font-size: $small-font-size;
				margin-bottom: 10px;
				color: $mc-6;
				.highlight {
					@extend .font--semibold;
					color: $mc-9;
				}
			}
			.no-items-placeholder {
				text-align: left;
				font-size: $small-font-size;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			.trends {
				margin-bottom: 30px;
			}
		}
	}
}
