.analytics-container {
	.section-container-common {
		z-index: $zI-level-1;
		max-width: 1500px;
		margin: 0 auto;
		transition: all 300ms ease-out;
		&.scrolled {
			padding-bottom: 30px;
		}
	}
	.analytics-header {
		position: sticky;
		top: 58px;
		background-color: $mc-1;
		z-index: 100;
		border-radius: 4px 4px 0 0;
		.no-scroll {
			top: 0;
		}
	}
	.at-breadcrumbs-container {
		padding: 0 25px;
		padding-top: 15px;
	}
	.analytics-section-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5px;
		padding: 15px 25px;
		transition: all 300ms ease-out;
		.section-header-left {
			line-height: 36px;
			font-size: $large-font-size;
			margin-bottom: 5px;
			text-transform: capitalize;
			transition: all 300ms ease-out;
			display: flex;
			gap: 25px;
			@extend .font--semibold;
			.new-btn {
				padding: 2px 8px 2px 8px;
				gap: 4px;
				border-radius: 2px;
				background: #0da125;
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
				text-align: left;
				color: #ffffff;
				align-self: center;
				position: relative;
				overflow: hidden;
			}
			.diagonal-animation {
				width: 7px;
				height: 100px;
				opacity: 0.6;
				background: linear-gradient(90deg, #c3faa2 0%, #c0faa2 35.5%);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%) rotate(135deg);
				animation: move-horizontal 2s linear infinite;
			}
			@keyframes move-horizontal {
				0% {
					left: -50%;
				}
				100% {
					left: 130%;
				}
			}
		}
		.section-header-right {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			margin-left: 10px;
			.help-btn-container {
				margin-left: 10px;
				svg {
					// top: 3px;
				}
			}
			.analytics-share-report {
				margin-left: 10px;
			}
			.search-filter {
				margin-left: 10px;
			}
		}
		&.scrolled {
			padding: 15px 25px 12px 25px;
			.section-header-left {
				font-size: $medium-font-size;
				color: $color-neutral-black-medium;
			}
		}
		.context-menu {
			right: 25px;
			&.custom {
				.at-btn {
					transition: all 300ms ease-out;
					&.dots {
						background-color: $mc-1;
						border: 1px solid $border-color-dark;
						padding: 0 12px;
						height: 28px;
						line-height: 28px;
						svg {
							height: 14px;
							padding: 0;
						}
						&:hover {
							opacity: 1;
							svg {
								path {
									fill: $mc-9;
								}
							}
						}
					}
				}
				.menu {
					top: 40px;
					right: 0px;
					min-width: 130px;
					transition: all 300ms ease-out;
					.action-item {
						display: flex;
						align-items: center;
						padding: 10px 10px 10px 10px;
						.icon {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 15px;
							margin-right: 7px;
						}
						svg,
						img {
							width: 15px;
							height: 15px;
						}
					}
				}
				&.with-dots {
					.menu {
						top: 32px;
					}
				}
			}
		}
	}
	.search-filter {
		position: relative;
		width: 250px;
		margin: 0;
		.at-input-container {
			width: 100%;
		}
		.cancel-search {
			right: 12px;
		}
	}
	.analytics-filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
		margin: 0 25px;
		margin-bottom: 15px;
		.filters-left,
		.filters-right {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		.filters-left {
			.dropdown-custom {
				width: 200px;
				.container {
					width: auto;
					height: 32px;
					padding: 1px 10px;
				}
				.dropdown-component {
					z-index: 150 !important;
				}
				.dropdown-options {
					.option {
						height: 37px;
					}
				}
				.custom-option,
				.custom-label {
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						width: 22px;
						height: 22px;
						text-transform: uppercase;
						font-size: $very-small-font-size;
						border-radius: $default-radius;
						@extend .font--semibold;
						img {
							width: 22px;
							height: 22px;
							object-fit: cover;
							border-radius: $default-radius;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
				}
				&.platform-filter {
					.container {
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #333;
						font-size: $very-small-font-size;
						border-radius: $border-radius-default;
					}
					.option {
						display: flex;
						align-items: center;
						.logo {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 8px;
							img {
								width: 22px;
								height: 22px;
								object-fit: cover;
								border-radius: $default-radius;
							}
						}
					}
					.dropdown-component {
						width: 100%;
						z-index: 150 !important;
						.platforms-list-container {
							max-height: 238px;
							overflow-y: auto;
							// prevent scroll-chaining to parent elements
							overscroll-behavior: contain;
							.option {
								padding: 1px 10px;
								height: 37px;
								border-bottom: 1px solid $border-color-dark;
								color: #333;
								font-size: $very-small-font-size;
								transition: 180ms;
								cursor: pointer;
								&.selected {
									background-color: $mc-3;
								}
								&:hover {
									background-color: $mc-3;
								}
								&:last-child {
									border-bottom: none;
								}
							}
							.group-title {
								margin-top: 15px;
								padding: 5px 10px;
								font-size: 12px;
								color: $color-neutral-black-light;
								text-transform: uppercase;
								@extend .font--semibold;
							}
						}
					}
				}
			}
			.multi-select-filter-container {
				width: 400px;
				.menu-wrapper {
					z-index: 150 !important;
				}
			}
			.filter-in-header {
				position: relative;
				margin-bottom: 10px;
				.filter-count {
					background: $color-blue;
					color: #fff;
					width: 20px;
					height: 20px;
					line-height: 20px;
					font-size: 12px;
					border-radius: 100%;
					display: inline-block;
					position: absolute;
					right: -10px;
					top: -10px;
				}
			}
		}
		.location-and-group-analytics {
			width: 230px;
			.dropdown-custom {
				width: 100%;
			}
		}
	}
	.analytics-filters-wrap {
		.overlapping-groups {
			position: relative;
			background: #fff5f5;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0px 22px 0px 25px;
			border-radius: 4px;
			padding: 16px;
			border-left: 5px solid #e5b917;
			.info-main-div {
				display: flex;
				flex-direction: row;
				gap: 12px;
				flex-basis: 100%;
				.overlapping-info {
					display: flex;
					flex-direction: column;
					gap: 8px;
					align-items: flex-start;
					.overlap-content {
						display: flex;
						flex-direction: column;
						.overlap-header {
							font-family: Source Sans Pro;
							font-size: 18px;
							font-weight: 600;
							line-height: 24px;
							text-align: left;
						}
						.overlap-details {
							font-family: Source Sans Pro;
							font-size: 14px;
							font-weight: 400;
							line-height: 22px;
							text-align: left;
							color: #646464;
						}
					}
				}
				.hide-header-text {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					flex-grow: 1;
				}
				.overlap-contact {
					display: flex;
					flex-direction: row;
					gap: 8px;
					margin-right: 16px;
					align-items: center;
					.right-arrow-contact {
						width: 15.05px;
						height: 14.43px;
					}
					.contact-info {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 600;
						line-height: 21px;
						text-align: center;
						color: #3b5feb;
						white-space: nowrap;
						cursor: pointer;
					}
				}
			}
			.close-btn {
				align-self: flex-start;
				height: fit-content;
				margin-top: 2px;
				margin-right: 4px;
				cursor: pointer;
				.img {
					height: 12px;
					width: 12px;
				}
			}
		}
	}
	.at-top-bar-wrapper-v2 {
		margin-bottom: 25px;
		font-size: $very-small-font-size;
		// z-index: $zI-level-1;
		.topbar-column {
			&::after {
				height: 3px;
			}
		}
	}
	.shimmer {
		background-color: #fafafa;
		&:after {
			background: linear-gradient(to right, rgba(228, 226, 226, 0) 0%, #c0c0c0 50%, rgba(228, 226, 226, 0) 100%);
		}
	}
	.empty-search-placeholder {
		width: 100%;
		height: 500px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $color-dark-grey;
		.message {
			margin-bottom: 10px;
			font-size: $medium-large-font-size;
			@extend .font--semibold;
		}
		.info {
			margin-bottom: 50px;
		}
		.learn-more {
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}
	.metric-card {
		margin: 0 25px;
		margin-bottom: 30px;
		border: 1px solid $border-color;
		border-radius: $default-radius;
		.metric-header {
			display: flex;
			justify-content: space-between;
			padding: 16px 25px;
			.header-right {
				display: flex;
				gap: 10px;
			}
		}
		.multi-select-filter-container {
			font-size: $very-small-font-size;
			width: 205px;
			.custom-value {
				color: $color-neutral-black-light;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				span {
					color: $color-neutral-black-dark;
					margin-left: 2px;
					@extend .font--semibold;
				}
			}
			.react-select__single-value--is-disabled {
				.custom-value {
					opacity: 0.6;
				}
			}
		}
		.show-comparison {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 25px;
			margin-bottom: 10px;
			.at-switch-cnt {
				display: flex;
				align-items: center;
				gap: 8px;
				flex-direction: row-reverse;
				.meta-info {
					font-size: $very-small-font-size;
					color: $color-neutral-black-medium;
					@extend .font--semibold;
				}
				.at-switch {
					width: 34px;
					min-height: 20px;
					top: 2px;
					.switch-rail {
						margin: 0;
						height: 20px;
						background-color: $color-neutral-black-40;
					}
					.switch-ball {
						top: 2px;
						width: 16px;
						height: 16px;
					}
					&.active {
						.switch-rail {
							background-color: $color-green-50;
						}
						.switch-ball {
							left: 15px;
						}
					}
				}
			}
		}
		.custom-table-container {
			margin-bottom: 0;
			.table-cell {
				&:first-child {
					padding-left: 25px;
				}
				&:last-child {
					padding-right: 25px;
				}
				&:last-of-type {
					padding-right: 25px;
				}
				b {
					text-transform: capitalize;
				}
				&.align-right {
					justify-content: flex-end;
				}
				.primary {
					width: fit-content;
				}
			}
			.table-header {
				.table-cell {
					display: flex;
					align-items: center;
				}
			}
			.secondary-below {
				margin-top: 5px;
				.sub-text {
					font-size: $exta-small-font-size;
					color: $color-black-40;
					text-transform: uppercase;
					@extend .font--semibold;
				}
			}
			.popover-wrapper-container {
				&.middle-right {
					.popover-container {
						top: -23px;
						left: 82px;
						width: max-content;
						min-width: 50px;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						cursor: initial;
						&::before {
							top: 23px;
							z-index: -10;
						}
						&::after {
							display: none;
						}
					}
				}
				&.middle-left {
					.popover-container {
						top: -23px;
						right: 70px;
						width: max-content;
						min-width: 50px;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						cursor: initial;
						&::before {
							top: 23px;
							z-index: -10;
						}
						&::after {
							display: none;
						}
					}
				}
				.popover-container {
					.compare-info {
						background-color: $mc-1;
						border-radius: $default-radius;
						padding: 12px;
						box-sizing: border-box;
						line-height: 1.2;
						z-index: 10;
						.compare-date {
							font-size: $exta-small-font-size;
							color: $color-neutral-black-light;
							margin-bottom: 5px;
						}
						.compare-value {
							font-size: $very-small-font-size;
							color: $color-neutral-black-medium;
							@extend .font--semibold;
						}
					}
				}
			}
			.rate {
				display: flex;
				width: 60px;
				justify-content: flex-end;
				cursor: pointer;
				&.up {
					color: $color-green-50;
				}
				&.down {
					color: $color-red-50;
				}
				.icon {
					position: relative;
					top: 2px;
				}
			}
		}
	}
	.chart-metric-container {
		.label-container,
		.value-container,
		.compare-value-container {
			display: flex;
			align-items: center;
		}
		.label-container {
			align-items: baseline;
			.popover-wrapper-container {
				&.down-left {
					.popover-container {
						top: 28px;
						width: max-content;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						&::before {
							left: 20px;
							z-index: -10;
						}
						&::after {
							left: 16px;
							display: none;
						}
					}
				}
				&.down-right {
					.popover-container {
						top: 28px;
						left: auto;
						right: -24px;
						width: max-content;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						&::before {
							right: 20px;
							z-index: -10;
						}
						&::after {
							right: 16px;
							display: none;
						}
					}
				}
				.popover-container {
					.description {
						background-color: $mc-1;
						border-radius: $default-radius;
						padding: 12px;
						box-sizing: border-box;
						font-size: $very-small-font-size;
						color: $color-neutral-black-dark;
						line-height: 1.2;
						z-index: 10;
					}
				}
			}
		}
		.value-container {
			align-items: baseline;
			.popover-wrapper-container {
				.popover-container {
					top: -25px;
					left: 78px;
					width: max-content;
					min-width: 50px;
					max-width: 400px;
					z-index: 60;
					box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
					&::before {
						top: 26px;
						z-index: -10;
					}
					&::after {
						display: none;
					}
					.compare-info {
						background-color: $mc-1;
						border-radius: $default-radius;
						padding: 12px;
						box-sizing: border-box;
						line-height: 1.2;
						z-index: 10;
						.compare-date {
							font-size: $very-small-font-size;
							color: $color-neutral-black-light;
							margin-bottom: 5px;
						}
						.compare-value {
							font-size: $small-font-size;
							color: $color-neutral-black-medium;
							@extend .font--semibold;
						}
					}
				}
				&.pve {
					.popover-container {
						left: 78px;
					}
				}
				&.nve {
					.popover-container {
						left: 82px;
					}
				}
			}
		}
		&.large {
			.label-container {
				.label {
					font-size: $medium-font-size;
					color: $color-neutral-black-medium;
					@extend .font--semibold;
					line-height: 24px;
				}
				.popover-container {
					left: -15px;
				}
				.popover-wrapper--content {
					display: flex;
				}
				.info {
					margin-left: 8px;
					width: 13px;
					height: 13px;
					position: relative;
					top: 1px;
					cursor: pointer;
				}
			}
			.value-container {
				.value {
					font-size: $large-font-size;
					color: $color-neutral-black-dark;
					line-height: 36px;
					@extend .font--semibold;
				}
			}
		}
		&.small {
			.label-container {
				.label {
					font-size: $very-small-font-size;
					color: $color-neutral-black-medium;
					line-height: 22px;
				}
				.popover-container {
					top: 26px;
					left: -18px;
				}
				.info {
					margin-left: 6px;
					width: 11px;
					height: 11px;
					cursor: pointer;
				}
			}
			.value-container {
				.value {
					font-size: $medium-font-size;
					color: $color-neutral-black-medium;
					@extend .font--semibold;
					line-height: 24px;
				}
				.popover-container {
					left: 81px;
				}
			}
		}
		.compare-value-container {
			margin-left: 10px;
			cursor: pointer;
			.arrow {
				margin-right: 2px;
				width: 12px;
				height: 12px;
			}
			&.up {
				color: $color-green-50;
			}
			&.down {
				color: $color-red-50;
			}
			.rate {
				font-size: $very-small-font-size;
			}
		}
	}
	.multi-chart-metrics {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0 25px;
		gap: 50px;
		margin-bottom: 24px;
	}
	.chart-selector {
		display: flex;
		align-items: center;
		height: fit-content;
		.chart {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $border-color-dark;
			box-sizing: border-box;
			cursor: pointer;
			img {
				padding: 7px 10px;
			}
			&.selected {
				border: 1px solid $color-blue;
				background-color: rgba($color: $color-blue, $alpha: 0.1);
			}
			&:first-child {
				border-radius: 2px 0 0 2px;
			}
			&:last-child {
				border-radius: 0 2px 2px 0;
			}
		}
	}
}
.with-banner {
	.analytics-container {
		.analytics-header {
			top: 114px;
			&.no-scroll {
				top: 0;
			}
		}
	}
}
.action-btn {
	position: relative;
	color: $color-blue-40;
	height: fit-content;
	font-size: $very-small-font-size;
	cursor: pointer;
	@extend .font--semibold;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 1px;
		bottom: 0;
		left: 0;
		background-color: $color-blue-40;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	a {
		color: inherit;
		font-size: inherit;
		text-decoration: none;
	}
	.arrow-right {
		margin-left: 6px;
		transition: 250ms transform;
	}
	&:hover {
		&::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
		.arrow-right {
			transform: translateX(2px);
		}
	}
}

// analytics home
.analytics-home {
	.search-results {
		padding: 10px 25px;
		font-size: $very-small-font-size;
		color: $color-neutral-black-medium;
	}
	.topics {
		padding: 10px 25px;
		box-sizing: border-box;
		min-height: 500px;
		.topic-container {
			margin-bottom: 50px;
			.topic-header {
				color: $color-neutral-black-medium;
				font-size: $medium-font-size;
				margin-bottom: 25px;
				@extend .font--semibold;
				.coming-soon {
					font-size: $very-small-font-size;
					margin-left: 10px;
					padding: 5px 10px;
					color: #753675;
					background-color: rgba($color: #753675, $alpha: 0.1);
					border-radius: $border-radius-default;
				}
			}
			.tabs {
				display: flex;
				flex-wrap: wrap;
				gap: 25px;
				a {
					text-decoration: none;
				}
				.tab-container {
					position: relative;
					display: flex;
					flex-grow: 1;
					gap: 15px;
					width: max-content;
					max-width: 350px;
					padding: 20px 25px;
					border: 1px solid $border-color;
					border-radius: $border-radius-default;
					box-sizing: border-box;
					cursor: pointer;
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 10px;
						height: 100%;
						border-radius: 1px 0 0 1px;
						background-color: #f2f5ff;
					}
					.icon {
						img {
							width: 30px;
							height: 30px;
						}
					}
					.tab-info {
						.tab-header {
							margin-bottom: 8px;
							.hyperlink {
								color: $color-neutral-black-dark;
								&::after {
									background-color: $color-neutral-black-dark;
								}
							}
						}
						.tab-description {
							font-size: $very-small-font-size;
							color: $color-neutral-black-medium;
						}
					}
				}
				&.disabled {
					opacity: 0.8;
					filter: grayscale(1);
					.icon {
						opacity: 0.6;
					}
				}
			}
		}
	}
}

// revenue analytics
.analytics-revenue {
	.metric-card {
		&.net-revenue {
			.nivo-chart-line {
				margin-bottom: 25px;
			}
		}
		.gross_revenue {
			.nivo-chart-line {
				margin-bottom: 25px;
			}
		}
		&.revenue-breakdown {
			.center-metric-pie {
				.metric-label {
					font-size: $medium-font-size;
				}
				.metric-value {
					font-size: $extra-large-font-size;
				}
			}
			.revenue-breakdown-table-container {
				margin-top: 30px;
				.platform,
				.name {
					.primary {
						display: flex;
						align-items: center;
						gap: 10px;
						.color {
							width: 10px;
							height: 10px;
							border-radius: $border-radius-default;
						}
					}
				}
				.table-list {
					.order_revenue,
					.order_completed_orders,
					.order_avg_order_value {
						display: flex;
						gap: 10px;
					}
					.platform {
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.avg-order-value {
			.nivo-chart-line {
				margin-bottom: 25px;
			}
		}
		&.revenue-by-location {
			.revenue-by-location-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.revenue-by-item {
			.revenue-by-item-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.lost-revenue {
			.metric-header {
				padding: 25px 25px 15px 25px;
			}
			.lost-revenue-table-container {
				margin-top: 30px;
				.order_type {
					width: 50%;
					.chart-metric-container {
						.label-container {
							margin-bottom: 0;
							.label {
								@extend .font--semibold;
							}
						}
						.info {
							width: 13px;
							height: 13px;
							position: relative;
							top: 2px;
						}
					}
				}
				.lost_revenue,
				.lost_orders {
					width: 25%;
				}
				.table-list {
					.lost_revenue,
					.lost_orders {
						display: flex;
						gap: 10px;
					}
					.label {
						color: #363636;
					}
				}
			}
		}
		&.lost-revenue-breakdown {
			.lost-revenue-breakdown-table-container {
				margin-top: 30px;
				.platform,
				.name {
					display: flex;
					align-items: center;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.platform {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
	}
}

// order analytics
.analytics-orders {
	.metric-card {
		&.orders-received {
			.nivo-chart-line {
				margin-bottom: 25px;
			}
		}
		&.order-performance {
			.chart-time-period {
				padding: 25px;
				font-size: $very-small-font-size;
				color: $color-neutral-black-medium;
				span {
					@extend .font--semibold;
				}
			}
		}
		&.orders-breakdown {
			.center-metric-pie {
				.metric-label {
					font-size: $medium-font-size;
				}
				.metric-value {
					font-size: $extra-large-font-size;
				}
			}
			.orders-breakdown-table-container {
				margin-top: 30px;
				.platform,
				.name {
					.primary {
						display: flex;
						align-items: center;
						gap: 10px;
						.color {
							width: 10px;
							height: 10px;
							border-radius: $border-radius-default;
						}
					}
				}
				.table-list {
					.order_revenue,
					.order_completed_orders,
					.order_avg_order_value {
						display: flex;
						gap: 10px;
					}
					.primary {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
		&.orders-by-location {
			.orders-by-location-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.order-frequency {
			.heatmap-filters {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;
				.multi-select-filter-container {
					padding: 0 25px;
				}
				.show-comparison {
					margin-bottom: 0;
				}
			}
			.nivo-chart-heatmap {
				margin-bottom: 25px;
			}
		}
		&.orders-by-item {
			.orders-by-item-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.lost-orders {
			.metric-header {
				padding: 25px 25px 15px 25px;
			}
			.lost-orders-table-container {
				margin-top: 30px;
				.order_type {
					width: 50%;
					.chart-metric-container {
						.label-container {
							margin-bottom: 0;
							.label {
								@extend .font--semibold;
							}
						}
						.info {
							width: 13px;
							height: 13px;
							position: relative;
							top: 2px;
						}
					}
				}
				.lost_orders,
				.lost_revenue {
					width: 25%;
				}
				.table-list {
					.lost_orders,
					.lost_revenue {
						display: flex;
						gap: 10px;
					}
					.label {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
		&.lost-orders-breakdown {
			.lost-orders-breakdown-table-container {
				margin-top: 30px;
				.platform,
				.name {
					display: flex;
					align-items: center;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.platform {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
	}
}

// operations analytics
.analytics-operations {
	.metric-card {
		&.operations-summary {
			.multi-chart-metrics {
				margin-top: 25px;
				gap: 100px;
			}
		}
		&.order-completion-time {
			.nivo-chart-bar {
				margin-bottom: 25px;
				svg {
					overflow: visible;
				}
				.compare-percent {
					display: flex;
					align-items: center;
					font-size: $very-small-font-size;
					padding: 32px 0;
					box-sizing: border-box;
					.time {
						margin-right: 10px;
						@extend .font--semibold;
					}
					.cent-change {
						margin-left: 2px;
					}
				}
				.popover-wrapper-container {
					.popover-wrapper--content {
						display: flex;
						align-items: center;
						cursor: pointer;
					}
					.popover-container {
						top: -22px;
						left: 70px;
						width: max-content;
						min-width: 50px;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						&::before {
							top: 23px;
							z-index: -10;
						}
						&::after {
							display: none;
						}
						.compare-info {
							background-color: $mc-1;
							border-radius: $default-radius;
							padding: 8px 12px;
							box-sizing: border-box;
							line-height: 1.2;
							z-index: 10;
							.compare-date {
								font-size: $very-small-font-size;
								color: $color-neutral-black-light;
								margin-bottom: 5px;
							}
							.compare-value {
								font-size: $small-font-size;
								color: $color-neutral-black-medium;
								@extend .font--semibold;
							}
						}
					}
					&.pve {
						.popover-container {
							left: 70px;
						}
					}
					&.nve {
						.popover-container {
							left: 74px;
						}
					}
				}
			}
		}
		&.order-completion-funnel {
			.chart-time-period {
				padding: 25px;
				font-size: $very-small-font-size;
				color: $color-neutral-black-medium;
				span {
					@extend .font--semibold;
				}
			}
		}
		&.restaurant-availability {
			.heatmap-filters {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;
				.multi-select-filter-container {
					padding: 0 25px;
				}
				.show-comparison {
					margin-bottom: 0;
				}
			}
			.nivo-chart-heatmap {
				margin-bottom: 25px;
			}
		}
		&.lost-orders {
			.metric-header {
				padding: 25px 25px 15px 25px;
			}
			.lost-orders-table-container {
				margin-top: 30px;
				.order_type {
					width: 50%;
					.chart-metric-container {
						.label-container {
							margin-bottom: 0;
							.label {
								@extend .font--semibold;
							}
						}
						.info {
							width: 13px;
							height: 13px;
							position: relative;
							top: 2px;
						}
					}
				}
				.lost_orders,
				.lost_revenue {
					width: 25%;
				}
				.table-list {
					.lost_orders,
					.lost_revenue {
						display: flex;
						gap: 10px;
					}
					.label {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
		&.lost-orders-breakdown {
			.lost-orders-breakdown-table-container {
				margin-top: 30px;
				.platform,
				.name {
					display: flex;
					align-items: center;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.primary {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
	}
	.restaurant-availability-detail {
		.form-sidebar-container {
			.header {
				.subtitle {
					font-size: $small-font-size;
					color: $color-neutral-black-50;
					@extend .font--semibold;
				}
			}
		}
		.info-card-container {
			display: flex;
			gap: 10px;
			padding: 16px;
			margin-bottom: 30px;
			background: $color-blue-05;
			border-radius: $border-radius-default;
			position: relative;
			.title {
				font-size: $exta-small-font-size;
				color: $color-black-80;
				margin-bottom: 8px;
				@extend .font--semibold;
			}
			.description {
				font-size: $exta-small-font-size;
				color: $color-neutral-black-60;
				margin-bottom: 6px;
				&:last-of-type {
					margin-bottom: 0;
				}
				span {
					@extend .font--semibold;
				}
			}
			.close {
				position: absolute;
				top: 2px;
				right: 4px;
				padding: 3px 8px;
				cursor: pointer;
				img {
					width: 9px;
					height: 9px;
				}
			}
		}
		.custom-table-container {
			.table-header {
				top: 0px;
			}
			.table-list {
				color: $color-neutral-black-60;
				align-items: center;
				.table-cell {
					&:last-of-type {
						padding-right: 55px;
					}
				}
				.brand,
				.platform {
					text-transform: capitalize;
				}
				.primary,
				.label {
					color: #363636;
				}
			}
			&.availability-table-container {
				.location {
					width: 36%;
				}
				.brand {
					width: 12%;
				}
				.platform {
					width: 12%;
				}
				.offline-time {
					width: 23%;
				}
				.availability {
					width: 17%;
				}
				.chevron {
					right: 15px;
				}
			}
			&.location-table-container {
				.brand {
					width: 24%;
					display: flex;
					align-items: center;
					.logo {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 20px;
						height: 20px;
						border-radius: $border-radius-default;
						margin-right: 10px;
						text-transform: uppercase;
						font-size: $exta-small-font-size;
						flex-shrink: 0;
						@extend .font--semibold;
						img {
							width: 20px;
							height: 20px;
							object-fit: cover;
							border-radius: $border-radius-default;
						}
						&.brown {
							color: $color-brown-dark;
							background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
						}
						&.blue {
							color: $color-blue-dark;
							background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
						}
						&.green {
							color: $color-green-dark;
							background-color: rgba($color: $color-green-dark, $alpha: 0.1);
						}
						&.red {
							color: $color-red-dark;
							background-color: rgba($color: $color-red-dark, $alpha: 0.1);
						}
						&.purple {
							color: $color-violet;
							background-color: rgba($color: $color-violet, $alpha: 0.1);
						}
					}
					// .name {
					// 	overflow: hidden;
					// 	text-overflow: ellipsis;
					// 	white-space: nowrap;
					// }
				}
				.platform {
					width: 20%;
					display: flex;
					align-items: center;
					.logo {
						width: 20px;
						border-radius: $border-radius-default;
						margin-right: 10px;
						flex-shrink: 0;
					}
				}
				.online-time {
					width: 22%;
				}
				.offline-time {
					width: 20%;
				}
				.availability {
					width: 14%;
				}
			}
		}
	}
}

// catalogue analytics
.analytics-catalogue {
	.metric-card {
		&.catalogue-metrics {
			.multi-chart-metrics {
				margin-top: 25px;
				gap: 100px;
			}
		}
		&.category-performance {
			.category-performance-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
		&.item-performance {
			.metric-header {
				align-items: baseline;
			}
			.item-performance-table-container {
				.name {
					width: 35%;
					flex-shrink: 0;
				}
				.table-list {
					.table-cell {
						display: flex;
						gap: 10px;
					}
					.name {
						flex-direction: column;
						gap: 0;
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
}

// analytics entity list
.analytics-entity-list {
	.analytics-header {
		position: relative;
		top: auto;
		padding-bottom: 15px;
	}
	&.scrolled {
		.analytics-header {
			border-bottom: 1px solid $border-color;
		}
	}
	.entity-filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 25px 0 25px;
		.filters-left,
		.filters-right {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		.multi-select-filter-container {
			font-size: $very-small-font-size;
			width: 200px;
			.custom-value {
				color: $color-neutral-black-light;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				span {
					color: $color-neutral-black-dark;
					margin-left: 2px;
					@extend .font--semibold;
				}
			}
			.react-select__single-value--is-disabled {
				.custom-value {
					opacity: 0.6;
				}
			}
		}
		.search-input-container {
			display: flex;
			align-items: center;
			.search-filter {
				position: relative;
				width: 250px;
				margin: 0;
				.at-input-container {
					width: 100%;
					.at-input-field {
						.at-label {
							border-radius: 0;
							border-left: 0;
							img {
								padding-left: 0;
							}
						}
						.at-input {
							border-left: 0;
							border-radius: 0 2px 2px 0;
						}
					}
				}
				.cancel-search {
					right: 12px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 120px;
				.react-select__control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.entity-list-table-container {
		margin: 15px 25px;
		.table-cell {
			&:first-child {
				padding-left: 25px;
			}
			&:last-child {
				padding-right: 25px;
			}
			&:last-of-type {
				padding-right: 25px;
			}
			&.align-right {
				justify-content: flex-end;
			}
			.primary {
				width: fit-content;
			}
		}
		.table-header {
			.table-cell {
				display: flex;
				align-items: center;
			}
		}
		.table-list {
			.align-right {
				.primary {
					font-size: 16px;
				}
			}
			.secondary-below {
				margin-top: 5px;
				.sub-text {
					font-size: $exta-small-font-size;
					color: $color-black-40;
					text-transform: uppercase;
					@extend .font--semibold;
				}
			}
			.popover-wrapper-container {
				&.middle-right {
					.popover-container {
						top: -23px;
						left: 82px;
						width: max-content;
						min-width: 50px;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						cursor: initial;
						&::before {
							top: 23px;
							z-index: -10;
						}
						&::after {
							display: none;
						}
					}
				}
				&.middle-left {
					.popover-container {
						top: -23px;
						right: 70px;
						width: max-content;
						min-width: 50px;
						max-width: 400px;
						z-index: 60;
						box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
						cursor: initial;
						&::before {
							top: 23px;
							z-index: -10;
						}
						&::after {
							display: none;
						}
					}
				}
				.popover-container {
					.compare-info {
						background-color: $mc-1;
						border-radius: $default-radius;
						padding: 12px;
						box-sizing: border-box;
						line-height: 1.2;
						z-index: 10;
						.compare-date {
							font-size: $exta-small-font-size;
							color: $color-neutral-black-light;
							margin-bottom: 5px;
						}
						.compare-value {
							font-size: $very-small-font-size;
							color: $color-neutral-black-medium;
							@extend .font--semibold;
						}
					}
				}
			}
			.rate {
				display: flex;
				width: 60px;
				justify-content: flex-end;
				cursor: pointer;
				&.up {
					color: $color-green-50;
				}
				&.down {
					color: $color-red-50;
				}
				.icon {
					position: relative;
					top: 2px;
				}
			}
		}
	}
	.at-paginator {
		margin-bottom: 10px;
	}
	&.revenue-by-location,
	&.revenue-by-item,
	&.orders-by-location,
	&.orders-by-item,
	&.category-performance,
	&.item-performance,
	&.popular-add-ons,
	&.location-performance {
		.entity-list-table-container {
			.name {
				width: 35%;
				flex-shrink: 0;
			}
			.table-list {
				.table-cell {
					display: flex;
					gap: 10px;
				}
				.name {
					flex-direction: column;
					gap: 0;
					.primary {
						color: #363636;
						line-height: 20px;
					}
				}
			}
		}
	}
}

// analytics entity detail
.analytics-entity-detail {
	.item-performance {
		.metric-card {
			&.revenue-trend {
				.nivo-chart-line {
					margin-bottom: 25px;
				}
			}
			&.units-sold {
				.nivo-chart-line {
					margin-bottom: 25px;
				}
			}
			&.order-frequency {
				.heatmap-filters {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 15px;
					.multi-select-filter-container {
						padding: 0 25px;
					}
					.show-comparison {
						margin-bottom: 0;
					}
				}
				.nivo-chart-heatmap {
					margin-bottom: 25px;
				}
			}
			&.location-performance {
				.location-performance-table-container {
					.name {
						width: 35%;
						flex-shrink: 0;
					}
					.table-list {
						.table-cell {
							display: flex;
							gap: 10px;
						}
						.name {
							flex-direction: column;
							gap: 0;
							.primary {
								color: #363636;
								line-height: 20px;
							}
						}
					}
				}
			}
			&.popular-add-ons {
				.popular-add-ons-table-container {
					.name {
						width: 35%;
						flex-shrink: 0;
					}
					.table-list {
						.table-cell {
							display: flex;
							gap: 10px;
						}
						.name {
							flex-direction: column;
							gap: 0;
							.primary {
								color: #363636;
								line-height: 20px;
							}
						}
					}
				}
			}
			&.variant-performance {
				.center-metric-pie {
					.metric-label {
						font-size: $medium-font-size;
					}
					.metric-value {
						font-size: $extra-large-font-size;
					}
				}
				.variant-performance-table-container {
					margin-top: 30px;
					.name {
						width: 35%;
						flex-shrink: 0;
						.primary {
							display: flex;
							align-items: center;
							gap: 10px;
							.color {
								width: 10px;
								height: 10px;
								border-radius: $border-radius-default;
							}
						}
					}
					.table-list {
						.option_order_net_revenue,
						.option_completed_orders,
						.option_order_avg_value {
							display: flex;
							gap: 10px;
						}
						.primary,
						.label {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
			&.offline-count {
				.nivo-chart-line {
					margin-bottom: 25px;
				}
			}
			&.item-availability {
				.heatmap-filters {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 15px;
					.multi-select-filter-container {
						padding: 0 25px;
					}
					.show-comparison {
						margin-bottom: 0;
					}
				}
				.nivo-chart-heatmap {
					margin-bottom: 25px;
				}
			}
			&.lost-orders {
				.metric-header {
					padding: 25px 25px 15px 25px;
				}
				.lost-orders-table-container {
					margin-top: 30px;
					.order_type {
						width: 50%;
						.chart-metric-container {
							.label-container {
								margin-bottom: 0;
								.label {
									@extend .font--semibold;
								}
							}
							.info {
								width: 13px;
								height: 13px;
								position: relative;
								top: 2px;
							}
						}
					}
					.lost_orders,
					.lost_revenue {
						width: 25%;
					}
					.table-list {
						.lost_orders,
						.lost_revenue {
							display: flex;
							gap: 10px;
						}
						.label {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
			&.lost-orders-breakdown {
				.lost-orders-breakdown-table-container {
					margin-top: 30px;
					.platform,
					.name {
						display: flex;
						align-items: center;
					}
					.table-list {
						.table-cell {
							display: flex;
							gap: 10px;
						}
						.primary {
							color: #363636;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
}

// compare
.compare {
	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}
	.clickable-images {
		padding: 0 24px;
		display: flex;
		grid-gap: 24px;
		gap: 24px;
		box-sizing: border-box;
	}

	.compare-divs {
		flex-basis: 25%;
		border: 1px solid #eaeaea;
		overflow: hidden;
		.cards-img {
			object-fit: cover;
			width: 100%;
			min-height: 132px;
		}
		.inner-cards {
			position: relative;
			padding: 24px 16px;
			display: flex;
			flex-direction: column;
			gap: 24px;
			.compare-btn {
				cursor: pointer;
				height: 32px;
				padding: 6px 16px 6px 16px;
				border-radius: 2px;
				border: 1px solid #eaeaea;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				text-align: left;
				color: #363636;
				text-decoration: none;
			}
			Link,
			a {
				color: #363636;
				text-decoration: none;
			}

			.card-header {
				font-family: Source Sans Pro;
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				text-align: left;
			}
			.card-text {
				font-family: Source Sans Pro;
				font-size: 14px;
				line-height: 22px;
				text-align: left;
				width: 208px;
				text-wrap: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				text-wrap: nowrap;
				overflow: hidden;
				width: 100%;
				text-overflow: ellipsis;
				.like {
					color: #aaaaaa;
					font-weight: 800;
				}
				.info {
					color: #8a8a8a;
					font-weight: 600;
				}
			}
		}
		.inner-cards::before {
			content: "v/s";
			color: #ffffff;
			background: #8a8a8a;
			position: absolute;
			top: -18px;
			border-radius: 50%;
			padding: 1px 4px 3px 3px;
			font-weight: 600;
			text-align: center;
			letter-spacing: -1px;
			vertical-align: middle;
		}
	}
	.topbar-compare {
		margin-top: 32px;
		.label-heading {
			display: flex;
			gap: 6px;
			align-items: center;
			.data-count {
				border-radius: 50%;
				background-color: #2543b6;
				color: white;
				padding: 0 3px;
			}
		}
	}
	.empty-state-compare {
		display: flex;
		margin-bottom: 40px;
		align-items: center;
		color: #666666;
		flex-direction: column;
		.no-saved {
			font-family: Source Sans Pro;
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;
		}
		.description {
			font-family: Source Sans Pro;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			text-align: center;
			margin-top: 8px;
		}
	}
	.compare-intro {
		background: linear-gradient(180deg, rgba(135, 154, 255, 0.1) 0%, rgba(229, 56, 201, 0.053) 94.51%);
		position: relative;
		overflow: hidden;
		margin-bottom: 24px;
		.main-close-icon {
			z-index: 23;
			position: absolute;
			right: 50px;
			top: 25px;
			cursor: pointer;
			cursor: pointer;
			svg {
				width: 20.41px;
				height: 20.41px;
				rotate: 45deg;
			}
		}
		.star-img {
			position: absolute;
			top: -18px;
			left: 0;
			width: 100%;
			z-index: 12;
		}
		.ellipse_left {
			position: absolute;
			top: 0;
			left: 0;
		}

		.ellipse_right {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 59%;
			height: 82%;
		}
		padding: 60px 0 40px 55px;
		.all-content {
			display: flex;
			justify-content: space-between;
			.main-info {
				display: flex;
				flex-basis: 55%;
				gap: 32px;
				flex-direction: column;
				.heading {
					font-family: Source Sans Pro;
					font-size: 24px;
					font-weight: 600;
					line-height: 32px;
					text-align: left;
					color: #171717;
				}
				.key-points {
					display: flex;
					flex-direction: column;
					gap: 12px;
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #363636;
					.key-info {
						display: flex;
						gap: 8px;
						.img-span {
							display: flex;
							align-items: center;
							img {
								width: 16px;
								height: 16px;
								align-self: center;
							}
						}
					}
				}
			}
		}
		.sty-img {
			flex-basis: 40%;
			z-index: 111;
			display: flex;
			gap: 20px;
			.border-background {
				background: #ffffff;
				border-right: 0.47px solid #eaeaea;
				box-shadow: 0px 3.39px 10.16px 0px #00000014;
				border-radius: 6.78px;
			}
			.left-bin {
				flex-basis: 60%;
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding: 2px 0 15px 0;
				.top-items {
					padding: 11.4px 11.4px 0 11.4px;
					margin-left: 15px;
					margin-right: 22px;
					.star-items {
						display: flex;
						gap: 3.51px;
						align-items: center;
						.best-selling {
							font-family: Source Sans Pro;
							font-size: 7.46px;
							font-weight: 600;
							line-height: 10.1px;
							text-align: left;
							color: #646464;
						}
					}
					.item {
						font-family: Source Sans Pro;
						font-size: 6.65px;
						font-weight: 600;
						line-height: 10.45px;
						text-align: left;
						color: #363636;
						padding: 7.01px;
						border-radius: 3.51px;
						background: #fef6e9;
						margin-top: 9.5px;
					}
					.empty-item {
						margin-top: 9.5px;
						height: 7.01px;
						border-radius: 3.51px 3.51px 0 0;
						background: #fef6e9;
					}
				}
				.zoma-bng {
					display: flex;
					.bangalore {
						padding: 12.65px 12px 12.65px 8px;
						gap: 3.82px;
						border-radius: 7.01px;
						border: 0.48px;
						height: fit-content;
						display: flex;
						align-self: flex-end;
						margin-left: 10px;
						align-items: center;
						.location-img {
							width: 15.29px;
							height: 15.29px;
						}
						.bng {
							font-family: Source Sans Pro;
							font-size: 10.53px;
							font-weight: 600;
							line-height: 9.56px;
							text-align: left;
							color: #363636;
						}
					}
					.zomato {
						background: white;
						border-radius: 50%;
						width: fit-content;
						width: 58px;
						height: 58px;
						display: flex;
						justify-content: center;
						.zomato-img {
							align-self: center;
							width: 35.1px;
							height: 35.1px;
						}
					}
				}
				.total-revenue {
					padding: 11.41px;
					gap: 3.8px;
					border-radius: 7.02px;
					border: 0.48px;
					background: #ffffff;
					border-right: 0.48px solid #eaeaea;
					box-shadow: 0px 3.51px 10.53px 0px #00000014;
					margin-left: 15px;
					display: flex;
					justify-content: space-between;

					.total {
						font-family: Source Sans Pro;
						font-size: 10.53px;
						font-weight: 600;
						line-height: 9.56px;
						text-align: left;
						color: #363636;
					}
					.rupeee {
						font-family: Source Sans Pro;
						font-size: 12.28px;
						font-weight: 600;
						line-height: 10.46px;
						text-align: left;
						color: #646464;
					}
				}
			}
			.right-bin {
				flex-basis: 40%;
				display: flex;
				gap: 10px;
				flex-direction: column;
				margin-right: 20px;
				justify-content: flex-end;

				.swiggy {
					display: flex;
					justify-content: center;
				}
				.swiggy-img {
					width: 51px;
					height: 51px;

					padding: 10.25px;

					border-radius: 174.72px;
				}
			}
			.london {
				display: flex;
				align-items: center;
				padding: 7.65px 12px 7.65px 8px;
				gap: 3.82px;
				border-radius: 7.01px;
				width: fit-content;
				margin-left: 15px;
				.location-img {
					width: 15.29px;
					height: 15.29px;
				}
				.text {
					font-family: Source Sans Pro;
					font-size: 10.53px;
					font-weight: 600;
					line-height: 9.56px;
				}
			}
			.locations-available {
				padding: 12px;
				display: flex;
				flex-direction: column;
				gap: 5.49px;
				.bar {
					display: flex;
					height: 10.97px;
					width: 100%;
					justify-content: space-between;
					.more {
						flex-basis: 85%;
						background: #c6d2ff;
					}
					.small {
						flex-basis: 10%;
						background: #f5f5f5;
						width: fit-content;
					}
				}
				.available {
					font-family: Source Sans Pro;
					font-size: 9.6px;
					font-weight: 600;
					line-height: 15.09px;
					color: #646464;
				}
			}
		}
	}
	.comparison {
		margin: 4px 24px 0px 24px;
		border-radius: 4px;
		.custom-table-container {
			border-left: 1px solid #eaeaea;
			border-right: 1px solid #eaeaea;
			border-radius: 4px;

			.table-header {
				border-radius: 4px 4px 0 0;
			}
		}
		.table-header {
			display: flex;
			flex-wrap: nowrap;
			.comparing {
				flex-basis: 70%;
				padding: 8px 24px 8px 16px;
			}
			.created {
				flex-basis: 30%;
				align-self: center;
			}
		}
		.created {
			flex-basis: 30%;
			padding: 4px 0 4px 14px;

			.date-text {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				color: #363636;
			}
			.createdby {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				color: #8a8a8a;
			}
		}
		.comparing-div {
			flex-basis: 70%;
			padding: 4px 24px 4px 12px;
			display: flex;
			gap: 12px;
			align-items: center;
			.name-text {
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				text-align: left;
				color: #363636;
				margin-bottom: 5px;
				.popover-wrapper-container .popover-container::after {
					top: -10px;
				}
			}
		}
	}
	.analytics-compare {
		padding: 25px;
		.info-text {
			margin: 0px 0 20px 0;
		}
		.warning {
			font-weight: $font--semibold;
			color: $color-light-black;
			margin-bottom: 24px;
		}
		.confirm-process {
			display: flex;
			gap: 13px;
			align-items: center;
			.at-checkbox {
				position: relative;
				padding-left: 0;
				.checkmark {
					position: relative;
					display: block;
					height: 14px;
					width: 14px;
				}
			}
		}
		.end-result {
			display: flex;
			padding: 25px 0;
			border-radius: $border-radius-default;
			justify-content: center;
			&.success {
				background-color: rgba($color-green, 0.1);
				color: $color-green;
				border: 1px solid $color-green;
			}
			&.failure {
				background-color: rgba($color-red, 0.1);
				color: $color-red;
				border: 1px solid $color-red;
			}
		}
	}
	.loading-circle {
		position: relative;
		width: 100%;
	}
}

.compare-metrics {
	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}
	margin-bottom: -45px !important;
	.analytics-header {
		.specific-header-compare {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 24px;
			.compare-top-head {
				svg {
					fill: #aaaaaa !important;
					opacity: 0.4;
				}
			}
			.analytics-section-header,
			.section-header-left {
				margin-bottom: 0px;
			}

			.small-heading {
				width: 100%;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				color: #8a8a8a;
				width: fit-content;
				display: flex;
				align-items: center;
				Link,
				a {
					text-decoration: none;
					color: inherit;
				}
			}
			.analytics-section-header {
				padding: 0px;
				.comparison-btn {
					color: #ffffff;
					padding: 8px 16px 8px 16px;
					border-radius: 2px;
					background: #d0d0d0;
					cursor: pointer;
					display: flex;
					align-items: center;
					gap: 10px;
					.down-arrow-global {
						margin: 0;
						padding: 0;
						height: 10px;
					}
				}
				.blue {
					background: #3b5feb;
				}
			}
		}
		.tablehead-scroll::-webkit-scrollbar {
			display: none;
		}
		.tablehead-scroll {
			scrollbar-width: none;
		}
		.overlapping-groups {
			margin-bottom: 15px;
		}
	}
	.shimmer {
		border-radius: 0 !important;
	}

	.tablehead-scroll {
		overflow-x: auto;
		box-sizing: border-box;
		border-spacing: 0;
		border-collapse: separate;
		th {
			border-right: 1px solid #eaeaea;
			border-spacing: 0;
			border-bottom: 1px solid #eaeaea;
			border-collapse: separate;
		}
	}

	.head {
		border-bottom: 1px solid #eaeaea;
		border-top: 1px solid #eaeaea;
		th {
			border-right: 1px solid #eaeaea;
			border-bottom: 1px solid #eaeaea;
		}
	}
	.comparison-metrics-table {
		overflow: auto;
		box-sizing: border-box;
		border-spacing: 0;
		border-top: 1px solid #eaeaea;
		scrollbar-width: none;
		table {
			border-spacing: 0;
		}
		tr,
		td,
		th {
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			vertical-align: top;
		}

		tr {
			border: 1px solid #eaeaea;
			border-spacing: 0;
			border-collapse: separate;
		}
		td {
			border-right: 1px solid #eaeaea;
		}
		tr,
		td {
			border-bottom: 1px dashed #eaeaea;
		}

		.metric-card {
			all: unset !important;
			margin: 0 0px !important;
			margin-bottom: 0px !important;
			border: none !important;
			border-radius: none !important;
		}
	}

	.table::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	.table-location-header {
		font-family: Source Sans Pro;
		box-sizing: border-box;

		.main-text {
			height: 48px !important;
			cursor: pointer;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			text-align: left;
			color: #363636;
			display: flex;
			flex-grow: 1;
			align-items: center;
			height: 100%;
			justify-content: space-between;
			padding: 0 16px;
			text-transform: capitalize;
			.text-img {
				display: flex;
				gap: 8px;
				align-items: center;
				.img {
					display: flex;
				}
			}
			.close-icon {
				display: flex;
				cursor: pointer;
			}
		}
		.adding-data {
			background: #f5f5f5;
			border: 1px dashed #aaaaaa;
			padding: 8px 16px;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			text-align: left;
			height: 48px;
			color: #8a8a8a;
			min-height: 48px;
			max-height: 48.1px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			text-transform: capitalize;

			.menus-icon-data {
				img {
					width: 32px;
					height: 32px;
				}
			}
			.menus-icon-div {
				display: flex;
			}
			.create {
				display: flex;
				gap: 6px;
				align-items: baseline;
			}
		}
		.flex {
			display: flex;
		}
		.all-words {
			overflow-y: scroll;
			background: white;
			position: relative;
			border-radius: 2px;
			box-shadow: 0px 0px 12px 0px #00000014;
			margin-top: 6px;
			display: flex;
			flex-direction: column;
			max-height: 386px;
			overflow-y: scroll;
			background: white;
			z-index: 1;
			transition: max-height 300ms ease-in;
			padding-bottom: 8px;
			.search-box {
				position: sticky;
				top: 0;
				z-index: 1111;
			}

			input {
				border: none;
			}
			.at-label {
				border: none;
			}
			.search-filter {
				width: 98%;
			}

			.mini-header {
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 600;
				line-height: 18px;
				text-align: left;
				color: #aaaaaa;
				padding: 4px 12px 0px 12px;
				z-index: 11;
				position: sticky;
				top: 35px;
				background: white;
			}

			.view-more {
				display: flex;
				align-items: center;
				border: none;
				background-color: transparent;
				cursor: pointer;
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 600;
				line-height: 22px;
				text-align: left;
				color: #3b5feb;
				gap: 6px;
				padding: 8px 10px 0px 10px;
			}

			.group {
				display: flex;
				flex-direction: column;
				margin-bottom: 8px;
				.mini-header,
				.results {
					display: flex;
					flex-direction: column;
				}
				.word-text {
					display: flex;
					justify-content: space-between;
					margin-top: 8px;
					padding: 4px 12px;
					color: #363636;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					cursor: pointer;
					text-transform: capitalize;
					gap: 15px;
					.side-head {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-style: italic;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #8a8a8a;
					}
				}
				.selected-btn {
					background: #f5f5f5;
				}
				.loading-viewmore {
					padding: 8px 12px 10px 12px;
					color: #8a8a8a;
					font-weight: 200 !important;
				}
			}

			.all-words::-webkit-scrollbar {
				width: 8px;
				border-radius: 4px;
				background-color: transparent;
			}

			.all-words::-webkit-scrollbar-thumb {
				background: #eaeaea;
				border-radius: 2px;
				height: 45px;
			}

			.scroll {
				overflow-y: scroll;
			}
			.nivo-chart-bar {
				padding: 0;
			}
		}
		.header-visible {
			max-height: 265px;
		}
		.all-words::-webkit-scrollbar {
			width: 8px;
			border-radius: 4px;
			background-color: transparent;
		}

		.all-words::-webkit-scrollbar-thumb {
			background: #eaeaea;
			border-radius: 2px;
			height: 45px;
		}

		.scroll {
			overflow-y: scroll;
		}
	}

	.comparison-metrics-table {
		box-sizing: border-box;
		.header-div {
			display: flex;
			position: sticky;
			top: 0;
			height: 80px;
			overflow: visible;
			background: white;
			z-index: 2;
		}
		.highlight {
			background: linear-gradient(-90deg, #ecf7f0 0%, rgba(255, 255, 255, 0) 100%);
		}
		.row-col-zero {
			position: sticky;
			left: 0;
			z-index: 1;
			background: white;
			width: 100%;
			min-width: 208px;
			max-width: 208.01px;
			box-sizing: border-box;
			padding: 24px 24px 24px 32px;
			border-right: 1px solid #eaeaea;
			border-bottom: 1px dashed #eaeaea;
			box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.0784313725);
		}
		.head-zero {
			position: sticky;
			left: 0;
			z-index: 4;
			background: white;
			width: 100%;
			min-width: 208px;
			max-width: 208.01px;
			box-sizing: border-box;
			padding: 16px;
			border-bottom: 1px solid #eaeaea;
			padding: 24px 24px 24px 32px;
			align-content: center;
			border-right: 1px solid #eaeaea;
			box-shadow: 0px 0px 12px 0px #00000014;
		}
		.head-row-col {
			min-width: 36%;
			align-content: center;
			padding: 16px;
			background: white;
			border-bottom: 1px solid #eaeaea;
			border-right: 1px solid #eaeaea;
			box-shadow: 0px 0px 12px 0px #00000014;
		}

		.row-col {
			min-width: 36%;
			max-width: 36.01%;
			box-sizing: border-box;
			padding: 24px 24px 24px 32px;
			border-right: 1px solid #eaeaea;
			border-bottom: 1px dashed #eaeaea;
			.text-end {
				text-align: end;
			}
		}
		.location {
			text-align: left;
			align-content: center;
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			text-align: left;
			text-transform: capitalize;
		}
		.highlight-data {
			background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
				linear-gradient(90deg, #ecf7f0 0%, rgba(255, 255, 255, 0) 100%);
		}
		.mini-header {
			position: sticky;
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			text-align: left;
			color: #646464;
		}
		.empty-info {
			padding: 4px 12px;
			color: #8a8a8a;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			text-align: left;
			text-transform: capitalize;
		}
		.row-title {
			.breakdown {
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				text-align: left;
				color: #3b5feb;
				width: fit-content;
				cursor: pointer;
			}
		}
		.flex {
			display: flex;
		}
	}

	.table-location-header {
		.main-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 8px;
			width: 20px;
			height: 20px;
			text-transform: uppercase;
			font-size: $very-small-font-size;
			border-radius: 1px;
			@extend .font--semibold;

			img {
				width: 22px;
				height: 22px;
				object-fit: cover;
				border-radius: $default-radius;
			}

			&.brown {
				color: $color-brown-dark;
				background-color: rgba($color: $color-brown-dark, $alpha: 0.1);
			}

			&.blue {
				color: $color-blue-dark;
				background-color: rgba($color: $color-blue-dark, $alpha: 0.1);
			}

			&.green {
				color: $color-green-dark;
				background-color: rgba($color: $color-green-dark, $alpha: 0.1);
			}

			&.red {
				color: $color-red-dark;
				background-color: rgba($color: $color-red-dark, $alpha: 0.1);
			}

			&.purple {
				color: $color-violet;
				background-color: rgba($color: $color-violet, $alpha: 0.1);
			}
		}
	}

	.comparison-metrics-table {
		.nivo-chart-bar {
			padding: 0;
		}
		.item-stats {
			display: flex;
			flex-direction: column;
			gap: 12px;
			.loading-div {
				height: 22px;
			}
			.item-stats-loading {
				display: flex;
				flex-direction: column;
				gap: 12px;
				position: relative;
			}

			.item-display {
				display: flex;
				gap: 8px;
				.item-val {
					display: flex;
					flex-basis: 73%;
					align-items: center;
					flex-grow: 1;
					gap: 8px;
					.img-item {
						width: 16px;
						height: 16px;
						margin-top: 2px;
						align-self: flex-start;
					}
					.item-title {
						font-family: Source Sans Pro;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
						color: #646464;
					}
				}
				.items-sold {
					align-self: center;
					font-family: Source Sans Pro;
					font-size: 12px;
					font-weight: 600;
					line-height: 22px;
					text-align: left;
					color: #646464;
					align-self: flex-start;
				}
			}
		}

		.mini-title {
			display: flex;
			gap: 4px;
			font-family: Source Sans Pro;
			font-size: 14px;
			font-weight: 600;
			line-height: 16px;
			text-align: left;
			color: #646464;
			align-self: flex-end;
		}
		.title-dim {
			color: #646464;
		}
		.highlight {
			.title-dark {
				color: #363636;
			}
		}

		.compare-bar {
			width: 100%;
			.graph-breakdown,
			.single-line-graph {
				.percent {
					font-family: Source Sans Pro;
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
					text-align: left;
					color: #646464;
				}
				.value,
				.index .indexValue,
				.blocks .indexValue {
					text-transform: capitalize;
					font-family: Source Sans Pro;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					text-align: left;
					color: #646464;
				}
				.align-right {
					align-self: flex-end;
				}
			}

			.graph-breakdown {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.index {
					align-items: center;
					display: flex;
					gap: 8px;
				}
			}
			.single-line-graph {
				display: flex;
				justify-content: space-between;
				.blocks {
					display: flex;
					flex-direction: column;
				}
			}
		}
		.breakdown-container {
			display: flex;
			gap: 10px;
			flex-direction: column;
			.title-dark {
				.title-text {
					margin-right: 5px;
				}
				.space-btw {
					display: flex;
					gap: 2px;
				}
			}
		}
		.graph-breakdown {
			.space-btw {
				display: flex;
				gap: 2px;
				.min {
					text-transform: lowercase;
				}
			}
		}
		.not-enough-state,
		.error-info {
			display: flex;
			gap: 16px;
			.text-div {
				display: flex;
				flex-direction: column;
				align-content: space-between;
				gap: 4px;
				justify-content: space-evenly;
				.main-text {
					font-family: Source Sans Pro;
					font-size: 18px;
					font-weight: 600;
					line-height: 24px;
					text-align: left;
					color: #666666;
				}
				.para {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					text-align: left;
					color: #979797;
				}
			}
		}

		.compare-loading {
			.error-info {
				position: absolute;
				transform: translate(-50%, -50%);
				top: 50%;
				width: 80%;
				padding: 16px;
				left: 50%;
				box-sizing: content-box;
				background: #ffffff;
				max-height: 145px;
				overflow: hidden;
				.exclamatory-div {
					width: 72px;
					height: 72px;
				}
			}
			.single-bar {
				position: relative;
				left: 70%;
				width: 30%;
				height: 22px;
			}
			.small-box {
				width: 27%;
				height: 22px;
			}
			.error {
				background: #f5f5f5;
			}
			.single-line-graph {
				display: flex;
				justify-content: space-between;
				margin-top: 8px;
				.box {
					height: 32px;
					width: 30%;
				}
			}

			.breakdown-loader {
				.title {
					margin-bottom: 20px;
				}
				.text {
					margin-bottom: 8px;
				}
			}
		}
		.option-loading {
			.option-loader {
				margin: 8px 12px 8px 12px;
			}
		}
		.progress-bar {
			.bar {
				display: flex;
				gap: 8px;
				.individual-bar {
					height: 16px;
					flex-grow: 1;
				}
				.color-fill {
					background: #2543b6;
				}
			}
			.color-grey {
				background: #f5f5f5;
			}
			.percent-display {
				margin-top: 8px;
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				color: #646464;
			}
		}
		.percent-available {
			height: 22px;
			margin-top: 8px;
			width: 70px;
		}
	}

	.comparison-sidebar {
		padding: 24px;
		border-top: 1px solid #eaeaea;
		.input-text {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.text {
				font-family: Source Sans Pro;
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				text-align: left;
				color: #646464;
			}
			input {
				padding: 8px;
				gap: 8px;
				border-radius: 2px;
				border: 1px solid #eaeaea;
				outline: none;
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
			}
		}
		.radio-buttons {
			margin-top: 20px;
			display: flex;
			gap: 36px;
			.radio-box {
				display: flex;
				gap: 6px;
				.checkmark {
					width: 16px;
					height: 16px;
				}
				.checkmark::after {
					left: 2px;
					top: 2px;
				}
				label {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 400;
					text-align: left;
					color: #646464;
				}
			}
		}
		.info-container {
			position: relative;
			background: #f2f5ff;
			border-radius: 3px 0 0 3px;
			.colored-div {
				position: absolute;
				top: 0;
				left: 0;
				width: 6px;
				height: 100%;
				border-radius: 3px 0 0 3px;
				background: #3b5feb;
			}
			.info-div {
				padding: 8px 16px;
				display: flex;
				gap: 12px;
				margin-top: 20px;
				.info-msg {
					font-size: 14px;
					color: #363636;
					line-height: 22px;
				}
			}
		}
	}

	.save-btn {
		display: flex;
		user-select: none;
		.container {
			background: #d0d0d0;
			color: #ffffff;
			height: auto;
			padding: 8px 12px 8px 16px;
		}

		.dropdown-custom {
			svg {
				fill: #ffffff;
			}
			.placeholder {
				color: #ffffff;
				font-size: 16px;
				margin-right: 8px;
			}
		}
	}
	.clickable {
		.container {
			background: #3b5feb;
		}
	}
	.btn-disabled {
		.container {
			background: #d0d0d0 !important;
			border-color: #d0d0d0;
		}
	}
	.remove-btn {
		.dropdown-custom {
			svg {
				display: none;
			}
		}
		.container.is-open {
			border-color: inherit;
			border: 1px solid #d0d0d0;
		}
		.dropdown-component {
			display: none;
		}
	}
	.view-btn {
		.dropdown-custom {
			svg {
				display: block;
				height: 12px;
			}
			svg:hover {
				fill: #ffffff;
			}
		}
		.dropdown-component {
			display: block;
		}
		.container.is-open {
			background: #2543b6;
		}
	}
}
