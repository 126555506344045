.tags-input-container {
	.react-tagsinput {
		min-height: 34px;
		width: 100%;
		padding: 0px 5px 5px 0;
		box-sizing: border-box;
		font-size: 14px;
		border-radius: $border-radius-default;
		outline: none;
		border: 1px solid #d0dadd;
		overflow: auto;
	}
	.react-tagsinput--focused {
		border: 1px solid #d0dadd;
	}
	.react-tagsinput-input {
		border: none;
		width: 30%;
		font-size: 14px;
		margin: 5px 0 0 5px;
		padding: 5px;
		font-family: inherit;
		color: $mc-9;
		&::placeholder {
			color: #d2d2d2;
		}
	}
	.react-tagsinput-tag {
		display: inline-block;
		color: #007eff;
		background-color: #ebf5ff;
		border: 1px solid rgba(0, 126, 255, 0.24);
		border-radius: 100px;
		margin: 5px 0 0 5px;
		.react-tagsinput-remove {
			position: relative;
			right: -5px;
			margin: 0;
			padding: 5px 7px 5px 2px;
			border-radius: 0 100px 100px 0;
			&:hover {
				background-color: rgba(0, 126, 255, 0.08);
			}
		}
	}
	&.read-only {
		pointer-events: none;
		cursor: default;
		.react-tagsinput {
			background-color: #eaeaea;
		}
		.react-tagsinput-tag {
			background-color: rgba(0, 126, 255, 0.08);
		}
	}
}
.at-input-container {
	.dropdown-component {
		width: 100%;
		position: absolute;
		top: 110%;
		border: 1px solid $border-color-dark;
		border-radius: $default-radius;
		box-shadow: 0px 2px 4px 0 rgba(60, 60, 60, 0.1);
		white-space: nowrap;
		background-color: white;
		z-index: $zI-level-1;
		box-sizing: border-box;
		font-size: $very-small-font-size;
		color: #333;
		.option {
			display: flex;
			align-items: center;
			margin: auto;
			padding: 0px 10px;
			height: 38px;
			cursor: pointer;
			transition: 180ms;
			&:hover {
				background-color: $mc-3;
			}
		}
	}
}
