.free-trial {
	.main-header {
		font-family: Source Sans Pro;
		font-size: 28px;
		font-weight: 600;
		line-height: 36px;
		padding: 24px 24px 0 24px;
	}
	.free-trail-plan-wrap {
		height: 80vh;
		.free-trial-intro {
			width: 572px;
			border: 1px solid #eaeaea;
			margin-top: 10vh;
			margin-left: auto;
			margin-right: auto;
			background: #ffffff;
			.heading {
				align-items: center;
				display: flex;
				gap: 8px;
				font-size: 18px;
				font-weight: 600;
				text-align: left;
				color: #363636;
				padding: 16px 24px;
				border-bottom: 1px solid #eaeaea;
			}
			.info-div {
				border-left: 4px solid #3b5feb;
				border-radius: 4px;
				display: flex;
				gap: 12px;
				padding-left: 12px;
				margin: 16px 24px;
				padding: 12px 16px;
				background: #f2f5ff;
				font-family: Source Sans Pro;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
			}
			.btns {
				display: flex;
				justify-content: right;
				gap: 16px;
				padding: 12px 16px;
				border-top: 1px solid #eaeaea;
				.back {
					padding: 8px 16px 8px 16px;
					gap: 8px;
					border-radius: 2px;
					background: #ffffff;
					border: 1px solid #eaeaea;
				}
			}
		}
		.modal-plan {
			margin: 16px 24px;
			border: 1px solid #eaeaea;
			padding: 16px;
			display: flex;
			gap: 16px;
			flex-direction: column;
			.free-trail {
				display: flex;
				gap: 8px;
				padding-bottom: 16px;
				border-bottom: 1px solid #eaeaea;
				font-family: Source Sans Pro;
				font-size: 18px;
				font-weight: 600;
				text-align: left;
				color: #646464;
				svg {
					scale: 1.2;
					align-self: center;
				}
				.your-plan {
					color: #363636;
				}
			}
			.all-plans {
				display: flex;
				flex-direction: column;
				gap: 8px;
				.plans {
					display: flex;
					gap: 8px;
					.svg {
						height: 20px;
						width: 20px;
						display: flex;
						align-items: center;
					}
					.text {
						font-family: Source Sans Pro;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-align: left;
					}
					.text-bold {
						font-weight: 600;
					}
				}

				.plan-benefits {
					font-family: Source Sans Pro;
					font-size: 14px;
					font-weight: 700;
					line-height: 22px;
					text-align: left;
					color: #7a7a7a;
				}
				.plans-info {
					display: flex;
					flex-direction: column;
					gap: 6px;
				}
			}
		}
	}
}
