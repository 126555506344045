.at-paginator {
	min-height: 70px;
	max-height: 250px;
	display: flex;
	@include bp(768px) {
		flex-direction: column-reverse;
	}
	.page-size-container {
		width: 50%;
		display: flex;
		align-items: center;
		@include bp(768px) {
			width: 100%;
			float: none;
			margin-top: 10px;
		}
		& > span {
			color: $mc-5;
			font-size: $very-small-font-size;
			// @include bp(768px) {
			// 	margin-left: auto;
			// }
		}
		.multi-select-filter-container {
			padding: 0 5px;
			width: 65px;
			margin-bottom: 0;
		}
	}
	.page-items-container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		width: 50%;
		float: right;
		@include bp(768px) {
			width: 100%;
			float: none;
		}
		&.restrict {
			justify-content: center;
		}
		.page-items {
			display: flex;
			justify-content: center;
			.at-paginator-item {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
				min-width: 30px;
				margin-right: 10px;
				text-align: center;
				box-sizing: border-box;
				transition: 180ms;
				&:last-child {
					margin-right: 0;
				}
			}
			.paginator-number {
				cursor: pointer;
				border: 1px solid $border-color-dark;
				border-radius: $default-radius;
				svg {
					width: 12px;
					height: 12px;
				}
				&.active,
				&:hover {
					border: 1px solid $color-blue;
					color: $color-blue;
					svg {
						fill: $color-blue;
					}
				}
				&.first {
					svg {
						transition: 300ms ease-out;
					}
					&:hover {
						svg {
							transform: translateX(-2px);
						}
					}
				}
				&.next {
					width: 60px;
					svg {
						transition: 300ms ease-out;
						margin-left: 5px;
					}
					&:hover {
						svg {
							transform: translateX(2px);
						}
					}
				}
				&.previous {
					width: 60px;
					svg {
						transition: 300ms ease-out;
						margin-right: 5px;
					}
					&:hover {
						svg {
							transform: translateX(-2px);
						}
					}
				}
			}
			.info {
				display: flex;
				align-items: center;
				margin-right: 10px;
				color: $mc-5;
				font-size: $very-small-font-size;
			}
		}
		.pagination-info {
			color: $mc-5;
			font-size: $very-small-font-size;
			text-align: center;
			float: right;
			clear: both;
			margin: 10px 0 0 0;
			.number {
				@extend .font--semibold;
				color: $mc-7;
			}
		}
	}
}
