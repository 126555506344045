.periscope-container {
	.filter-show-locations {
		.show-locations-container {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.info-display {
				padding: 12px 16px 12px 16px;
				border-radius: 2px;
				border-left: 4px solid #3b5feb;
				background: #f2f5ff;
				gap: 12px;
				display: flex;
				align-items: flex-start;

				.icon-image {
					width: 20px;
					height: 20px;
				}

				.info-text {
					display: flex;
					gap: 4px;
					flex-direction: column;
					align-items: flex-start;
				}

				.location-note {
					font-size: 14px;
					font-weight: 600;
					line-height: 22px;
				}

				.experience-note {
					font-size: 12px;
					font-weight: 400 !important;
					line-height: 16px;
					color: #646464;
				}
			}
		}

		.show-locations-table {
			border-right: 1px solid #eaeaea;
			border-left: 1px solid #eaeaea;

			.table-list .table-cell .at-checkbox {
				display: flex;
			}

			.title-display {
				display: flex;
				align-items: center;
			}

			.table-header .location_id {
				display: flex;
				align-items: center;
			}

			.table-header .location_id,
			.table-header .city {
				font-size: 12px;
				font-weight: 600;
				line-height: 18px;
				text-align: left;
				color: #8a8a8a;
			}

			.table-list .location_id,
			.table-list .city,
			.table-list div {
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
				text-align: left;
				color: #363636;
			}

			.location_id {
				width: 70%;
			}

			.city {
				width: 30%;
			}

			.at-checkbox--partial input:checked ~ .checkmark:after {
				left: 2px;
				top: 6px;
			}
		}

		.table-div {
			margin: 0px 24px 0 24px;
		}

		.custom-table-container .table-header {
			top: 30px;
		}

		.custom-table-container {
			margin-bottom: 0px;
		}

		.footer div:first-child {
			width: 160px;
		}

		.at-paginator {
			border-bottom: 1px solid #eaeaea;
			border-right: 1px solid #eaeaea;
			border-left: 1px solid #eaeaea;
			padding: 15px 25px;
		}

		.text-top .bulk-actions {
			padding: 0px 24px 8px 24px;
			color: #646464;
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			display: flex;
			justify-content: space-between;
			user-select: none;
			background: #ffffff;

			.title-display {
				font-size: 14px;
			}

			.at-checkbox {
				display: flex;

				.checkmark {
					top: 2px;
				}
			}
		}

		.text-top {
			opacity: 1;
			height: auto;
			height: 30px;
			position: sticky;
			top: 0px;
			z-index: 3;
			background: white;
		}

		.header-left {
			width: 100%;

			.title-content {
				width: 100%;
			}
		}

		.filters-div {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;

			.cities {
				width: 200px;
			}
		}

		.form-sidebar-container > .header {
			padding: 25px 25px 16px 25px;
		}
	}

	.waiting-page {
		padding: 15px 24px 30px 24px;

		.loading {
			width: 418px;
			height: 368px;
		}

		.heading-periscope {
			font-size: 28px;
			font-weight: 600;
			line-height: 36px;
			color: #363636;
			text-align: left;
		}

		.waiting-page-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;

			.graphics-loading {
				width: 418px;
				height: 368px;
			}

			.waiting-text-div {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 24px;

				.text-div {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.header-text {
						font-size: 24px;
						font-weight: 600;
						line-height: 32px;
						text-align: center;
						color: #646464;
					}

					.description {
						font-size: 18px;
						font-weight: 400;
						line-height: 24px;
						text-align: center;
						color: #646464;
					}
				}

				.back-to-home {
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					text-align: left;
					color: #363636;
					border: 1px solid #eaeaea;
					padding: 8px 16px;
					cursor: pointer;
					background-color: white;
				}

				.enquiry {
					display: flex;
					align-items: center;
					gap: 4px;

					.about {
						color: #3b5feb;
					}

					.questions {
						color: #646464;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-align: center;
					}

					.question-img {
						width: 13px;
						height: 13px;
					}
				}
			}
		}
	}
}
