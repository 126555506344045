.webhooks-container {
	.filter-buttons {
		@include bp(768px) {
			display: flex;
			flex-direction: row-reverse;
			float: left;
			position: relative;
		}
	}
	.action-buttons-container {
		margin-right: 10px;
	}
	.overlay-sidebar-container {
		.content {
			overflow: visible;
			overflow-x: visible;
		}
	}
	.credits-section-header.transactions-list-header {
		.search-input-container {
			display: flex;
			.search-filter {
				width: 170px;
				.at-input-field {
					.at-label {
						border-radius: 0;
						border-left: 0;
						img {
							padding-left: 0;
						}
					}
					.at-input {
						border-left: 0;
						border-radius: 0 4px 4px 0;
					}
				}
				.cancel-search {
					right: 24px;
				}
			}
			.multi-select-filter-container {
				margin: 0;
				width: 100px;
				.Select-control {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
	.webhooks-list-table-container {
		.transaction-rows {
			min-height: 80px;
		}
		.url {
			width: 50%;
			padding-left: 25px;
			.link-text {
				text-transform: none;
			}
		}
		.event {
			width: 30%;
		}
		.created_at {
			width: 20%;
			padding-right: 25px;
		}
	}
}

.webhook-edit-container {
	.wrapper-sticky-top {
		top: 0px;
	}
	.headers-container {
		.key-value-pair {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 30px;
			.key.at-input-container {
				width: 35%;
			}
			.value.at-input-container {
				width: 55%;
			}
			.multi-select-filter-container {
				width: 41%;
			}
		}
		.delete-icon {
			width: 15px;
			padding: 5px;
			margin-top: 22px;
			border: 1px solid $color-red;
			border-radius: $default-radius;
			cursor: pointer;
		}
		.at-btn--secondary {
			width: auto;
			border: 1px solid $mc-4;
			color: $mc-9;
			margin: 20px 0;
			&:hover {
				background: $mc-2;
			}
		}
	}
	.history-container {
		.header-container {
			display: flex;
			justify-content: space-between;
			border: 1px solid $border-color;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 25px;
			.header-text {
				font-size: $very-small-font-size;
				@extend .font--semibold;
			}
			.header-subtext {
				color: $mc-5;
				font-size: $very-small-font-size;
				padding-top: 5px;
			}
		}
		.search-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			.at-input-container {
				width: 30%;
			}
			.multi-select-filter-container {
				width: 30%;
				opacity: 1;
				.meta-info {
					font-weight: normal;
					color: $mc-6;
				}
			}
		}
		.webhook-history-table-container {
			.transaction-header-row {
				top: 38px;
			}
			.transaction-rows {
				min-height: initial;
			}
			.at-cell-text {
				font-size: $very-small-font-size;
			}
			.delivery-id {
				padding-left: 25px;
				width: 35%;
			}
			.last-delivery {
				width: 25%;
			}
			.retries-count {
				width: 20%;
				text-align: right;
			}
			.at-header-text {
				&.status-code {
					padding-right: 25px;
					width: 20%;
					text-align: right;
				}
			}
			.at-cell-text {
				&.status-code {
					padding-right: 25px;
					width: 20%;
					text-align: right;
					span {
						display: inline-block;
						min-width: 20px;
						color: $mc-1;
						padding: 0px 6px;
						margin-left: 5px;
						border-radius: $default-radius;
						text-align: center;
						@extend .font--semibold;
					}
					&.success {
						span {
							background-color: $color-green;
						}
					}
					&.error {
						span {
							background-color: $color-red;
						}
					}
				}
			}
		}
	}
}
.history-detail-container,
.external-log-detail-container {
	.request-method,
	.purpose {
		text-transform: capitalize;
		span {
			position: relative;
			top: -2px;
			display: inline-block;
			padding: 0px 8px;
			margin-left: 5px;
			font-size: $medium-font-size;
			color: $mc-1;
			background-color: $color-green;
			border-radius: $default-radius;
			text-align: center;
		}
	}
	.description {
		display: flex;
		align-items: center;
		.status-code {
			padding-right: 10px;
			border-right: 1px solid $mc-5;
			span {
				display: inline-block;
				min-width: 20px;
				color: $mc-1;
				padding: 0px 6px;
				margin-left: 5px;
				border-radius: $default-radius;
				text-align: center;
				@extend .font--semibold;
			}
			&.success {
				span {
					background-color: $color-green;
				}
			}
			&.error {
				span {
					background-color: $color-red;
				}
			}
		}
		.webhook-url,
		.request-url {
			padding-left: 10px;
		}
	}
	.history-details,
	.log-details {
		padding: 10px 25px;
		.title {
			display: flex;
			align-items: center;
			font-size: $small-font-size;
			@extend .font--semibold;
			margin-bottom: 10px;
			&:first-child {
				margin-bottom: 30px;
				span {
					margin-left: 10px;
					color: $color-blue;
					&.desc-text {
						color: $mc-7;
						margin-left: 8px;
						@extend .font--regular;
					}
				}
			}
			.action-item {
				display: flex;
				align-items: center;
				margin-left: auto;
				transition: 180ms;
				opacity: 0.6;
				cursor: pointer;
				&:hover {
					opacity: 1;
				}
				span {
					margin-top: 1px;
					color: $color-blue;
					font-size: 12px;
					font-weight: bold;
				}
				img {
					width: 12px;
					margin-right: 5px;
				}
			}
		}
		.json-container {
			position: relative;
			overflow: auto;
			border: 1px solid $border-color-dark;
			border-radius: $default-radius;
			padding: 15px;
			margin-bottom: 30px;
			font-size: 13px;
			color: $mc-7;
			pre {
				margin: 0;
			}
			&.headers {
				min-height: 50px;
				max-height: 180px;
			}
			&.payload {
				min-height: 50px;
				max-height: 360px;
			}
		}
		@keyframes fadeIn {
			to {
				opacity: 1;
			}
		}
		.copy-btn {
			position: absolute;
			opacity: 0;
			right: 0px;
			top: 5px;
			animation: fadeIn 150ms linear both;
		}
	}
	.warnings-table-container {
		.transactions-list-table {
			margin: 0 0 30px 0;
			border: 1px solid $border-color-dark;
		}
		.transaction-rows {
			min-height: 40px;
		}
		.at-cell-text {
			font-size: $very-small-font-size;
		}
		.warnings {
			width: 100%;
			padding: 0 15px;
			display: flex;
			align-items: center;
		}
	}
}
